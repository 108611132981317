import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { CONTENT_LIST_ARTICLE_SORT_FILTERS, CONTENT_LIST_VIDEO_SORT_FILTERS, SET_CONTENT_PAGINATION_DATA } from '../Actions/Types/Types';

export function fetchContentList(data) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CONTENT.FETCH_ALL()}/${data}`;
	return dispatch => axios.get(apiURL)
	.then(res => {
		if(res.status === 200) {
			return {status: true, data: res.data}
		} else {
			return {status: false, message: res.data.message}
		}
	}).catch((err) => {
		return {status: false, message: err.response.data.error}
	});
}

export function createContent(data) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CONTENT.FETCH_ALL()}`;
	return dispatch => axios.post(apiURL, data)
	.then(res => {
		if(res.status === 200) {
			return {status: true, message: res.data.message}
		} else {
			return {status: false, message: res.data.message}
		}
	}).catch((err) => {
		return {status: false, message: err.message}
	});
}

export function fetchContent(id) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CONTENT.FETCH_ALL()}/${id}`;
	return dispatch => axios.get(apiURL)
	.then(res => {
		if(res.status === 200) {
			return {status: true, data: res.data.data}
		} else {
			return {status: false, message: res.data.message}
		}
	}).catch((err) => {
		return {status: false, message: err.response.data.message}
	});
}

export function updateContent(id, data) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CONTENT.FETCH_ALL()}/${id}`;
	return dispatch => axios.put(apiURL, data)
	.then(res => {
		if(res.status === 200) {
			return {status: true, message: res.data.message}
		} else {
			return {status: false, message: res.data.message}
		}
	}).catch((err) => {
		return {status: false, message: err.response.data.error}
	});
}

export function deleteContent(id) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CONTENT.FETCH_ALL()}${id}`;
	return dispatch => axios.delete(apiURL)
	.then(res => {
		if(res.status === 200) {
			return {success: true, data: res.data}
		} else {
			return {success: false, message: res.data.message}
		}
	}).catch((err) => {
		return {success: false, message: err.response.data.error}
	});
}

export function setContentStatus(data) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CONTENT.SET_STATUS()}`;
	return dispatch => axios.post(apiURL, data)
	.then(res => {
		if(res.status === 200) {
			return {status: true, message: res.data.message}
		} else {
			return {status: false, message: res.data.message}
		}
	}).catch((err) => {
		return {status: false, message: err.response.data.error}
	});
}

export function sendoutNotification(data) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CONTENT.SEND_NOTIFICATION()}`;
	return dispatch => axios.post(apiURL, data)
	.then(res => {
		if(res.status === 200) {
			return {status: true, message: res.data.message}
		} else {
			return {status: false, message: res.data.message}
		}
	}).catch((err) => {
		return {status: false, message: err.response.data.error}
	});
}

const articleInitailSortFilters = {per_page: '', current_page: 1, total_pages: '', total_count: ''}
export function setArticleFilters(value = articleInitailSortFilters) {
  return {
    type: CONTENT_LIST_ARTICLE_SORT_FILTERS,
    value
  }
}

const videoInitailSortFilters = {per_page: '', current_page: 1, total_pages: '', total_count: ''}
export function setVideoFilters(value = videoInitailSortFilters) {
  return {
    type: CONTENT_LIST_VIDEO_SORT_FILTERS,
    value
  }
}

export function setContentPaginationData(value = {ar_sort_by: 'title', ar_sort_order: 'asc', vi_sort_by: 'title', vi_sort_order: 'asc'}) {
  return {
    type: SET_CONTENT_PAGINATION_DATA,
    value
  }
}
