import React, {	useState, useEffect } from 'react';
import { NotificationManager} from 'react-notifications';
import { isEmpty } from 'lodash';

export default (props) => {
    let params = window.location.search
    const [flag, setFlag] = useState(false);
    const queryString = require('query-string');
    const parsed = queryString.parse(params);

	useEffect(
        () => {
		let timer1 = setTimeout(() => setFlag(true), 1500);
			return () => {
				clearTimeout(timer1)
			}
		},
		[]
	)

	if(flag) {
		if (!isEmpty(parsed))
			parsed.status === 'success' ? NotificationManager.success(parsed.message) : NotificationManager.error(parsed.message, '', 10000, () => {})
		props.history.push('/login')		
	}

	return null;
}