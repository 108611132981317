import React, { Component } from "react";
import LoginPage from "./Pages/Login/Login";

import CommunityPage from "./Pages/Community/Community";
import CampaignPage from "./Pages/Campaign/Campaign";
import EditCampaign from "./Pages/Campaign/Edit";
import CampaignOnBoarding from "./Pages/Campaign/OnBoarding/OnBoarding";
import UserListPage from "./Pages/UserList/UserList";
import IconListPage from "./Pages/IconList/IconList";
import EditIcon from "./Pages/IconList/EditIcon";
import FeedbackIcon from "./Pages/IconList/FeedbackIcon";
import PriceListPage from "./Pages/PriceList/PriceList";
import ProfilePage from "./Pages/Campaign/Profile/Profile";
import OrganizationPage from "./Pages/Organizations/Organizations";
import SettingsPage from "./Pages/Setting/Setting";
import PasswordResetPage from "./Pages/Password/PasswordReset";
import PasswordRecover from "./Pages/Password/PasswordRecover";
import InvitePassword from "./Pages/Password/Invite-password";
import IntercomWeb from "./Components/Intercom/Intercom";
import AccountVerify from "./Pages/Account/Verify";
import NoRouteFound from "./Pages/PageNotFound/PageNotFound";
import { Switch, Route, Redirect } from "react-router-dom";
import { NotificationContainer } from 'react-notifications';
import { IntercomProvider } from 'react-use-intercom';
import ContentPage from "./Pages/Content/Content";
import CreateArticlePage from "./Pages/Content/CreateArticle";
import EditArticlePage from "./Pages/Content/EditArticle";
import CreateVideoPage from "./Pages/Content/CreateVideo";
import EditVideoPage from "./Pages/Content/EditVideo";
import Loader from './Components/Common/Loader';
import Notification from "./Pages/Notification/Notification";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

class ScrollToTop extends Component {

	componentDidMount() {
		Sentry.init({
		  dsn: process.env.SENTRY_URL,
		  integrations: [new Integrations.BrowserTracing()],

		  // Set tracesSampleRate to 1.0 to capture 100%
		  // of transactions for performance monitoring.
		  // We recommend adjusting this value in production
		  tracesSampleRate: 1.0,
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
		}
	}

	render() {
		return (
			<div className="app-frame">
				{this.props.children}

				<IntercomProvider appId={process.env.INTERCOM_APPID}>
					<IntercomWeb />
				</IntercomProvider>
			</div>
		)
	}
}

function EmbedModeProvider({ children }){
	const embedMode = localStorage.getItem('embedMode');
	return embedMode ? 
		(<div className="embedded">{children}</div>) : 
		(<>{children}</>)
}


function PrivateRoute({ component: Component, authed, location, ...rest }) {
	if(!authed){
		localStorage.setItem("searchurl", `${location.pathname+location.search}`);
	}
	return (
		<Route
			{...rest}
			render={(props) => authed === true
				? <Component {...props} />
				: <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
		/>
	)
}

function PublicOnlyRoute({ component: Component, authed, location, ...rest }) {
	let path = '/'
	if(authed && location.pathname === '/recover'){
		path = '/settings'
		localStorage.setItem("recoverurl", 'recover-account');
	}
	return (
		<Route
			{...rest}
			render={(props) => authed === false
				? <Component {...props} />
				: <Redirect to={{ pathname: path, state: { from: props.location } }} />}
		/>
	)
}

const App = ({ location, history, ...props }) => {
	const authenticated = localStorage.manageToken ? true : false;
	return (
		<EmbedModeProvider>
			<ScrollToTop location={location}>
				<Loader />
				<NotificationContainer />
				<Switch>
					<PrivateRoute history={history} authed={authenticated} location={location} path="/" exact component={CommunityPage} />
					<PrivateRoute history={history} authed={authenticated} location={location} path="/communities" exact component={CommunityPage} />
					<PrivateRoute history={history} authed={authenticated} location={location} path="/campaigns" exact component={CampaignPage} />
					<PrivateRoute history={history} authed={authenticated} location={location} path="/campaigns/new" exact component={CampaignPage} />
					<PrivateRoute history={history} authed={authenticated} location={location} path="/campaigns/:id" exact component={EditCampaign} />
					<PrivateRoute history={history} authed={authenticated} location={location} path="/campaigns/:id/on-boarding" exact component={CampaignOnBoarding} />
					<PrivateRoute history={history} authed={authenticated} location={location} path="/price-list" exact component={PriceListPage} />
					<PrivateRoute history={history} authed={authenticated} location={location} path="/user-list" exact component={UserListPage} />
					<PrivateRoute history={history} authed={authenticated} location={location} path="/icon-list" exact component={IconListPage} />
					<PrivateRoute history={history} authed={authenticated} location={location} path="/icon-list/:id" exact component={EditIcon} />
					<PrivateRoute history={history} authed={authenticated} location={location} path="/icon-feedback" exact component={FeedbackIcon} />
					<PrivateRoute history={history} authed={authenticated} location={location} path="/organizations" exact component={OrganizationPage} />
					<PrivateRoute history={history} authed={authenticated} location={location} path="/settings" exact component={SettingsPage} />
					<PrivateRoute history={history} authed={authenticated} location={location} path="/profile/:id" exact component={ProfilePage} />
					<PrivateRoute history={history} authed={authenticated} location={location} path="/content" exact component={ContentPage} />
					<PrivateRoute history={history} authed={authenticated} location={location} path="/article/new" exact component={CreateArticlePage} />
					<PrivateRoute history={history} authed={authenticated} location={location} path="/article/:id" exact component={EditArticlePage} />
					<PrivateRoute history={history} authed={authenticated} location={location} path="/video/new" exact component={CreateVideoPage} />
					<PrivateRoute history={history} authed={authenticated} location={location} path="/video/:id" exact component={EditVideoPage} />
					<PrivateRoute history={history} authed={authenticated} location={location} path="/notification" exact component={Notification} />

					<PublicOnlyRoute authed={authenticated} location={location} path="/verify" component={AccountVerify} />
					<PublicOnlyRoute authed={authenticated} location={location} path="/login" component={LoginPage} />
					<PublicOnlyRoute authed={authenticated} location={location} path="/forgot_password" component={PasswordResetPage} />
					<PublicOnlyRoute authed={authenticated} location={location} path="/recover" component={PasswordRecover} />
					<PublicOnlyRoute authed={authenticated} location={location} path="/complete_profile" component={InvitePassword} />
					<Route component={NoRouteFound} />
				</Switch>
			</ScrollToTop>
		</EmbedModeProvider>
	)
}

export default App;