const CheckboxField = ({
	name = '',
	onChange = () => {},
	onClick = () => {},
	containerClass = '',
	labelClass = '',
	label = '',
	checked = false,
	required = false,
	children
}) => {
	return(
		<div className={containerClass}>
			<label className={labelClass}>{label}
				<input 
					type="checkbox"
					onChange={onChange}
					onClick={onClick}
					checked={checked}
					required={required}
				/>
				<span className="checkmark"></span>
			</label>
			{ children }
		</div>
	)
}

export default CheckboxField