import React from 'react';
import Refresh from '..//Common/Refresh';

export default ({containerClass, logoClass, children, ...props}) => {
	return (
		<>
			<div id="floating-org-reset"><Refresh /></div>
			<div id="logside-header">
				<div className={containerClass}>
					{  children }
				</div>
			</div>
		</>
	)
}