import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchContentList, setContentStatus, sendoutNotification } from '../../Redux/Actions/Content';
import { useDispatch } from "react-redux";
import CustomSelect from "../../Components/CustomSelect/Select";
import { NotificationManager } from 'react-notifications';
import classNames from 'classnames';
import Pagination from '../Common/Pagination';
import { setLoader } from '../../Redux/Actions/Loader';
import TextArea from "../../Components/TextArea/Field";
import { Multiselect } from 'multiselect-react-dropdown';
import { sendNotification } from '../../Redux/Actions/Community';
import { isEmpty } from 'lodash';
import Modal from 'react-bootstrap/Modal'

export default ({
  renderCommunitySelector = () => { },
  communities = [],
  ...props
}) => {
  const initalData = { title: "", description: "" }
  const dispatch = useDispatch();
  const [selectedCommunity, setSelectedCommunity] = useState('');
  const [articles, setArticles] = useState([]);
  const [videos, setVideos] = useState([]);
  const [search, setSearch] = useState({ article_search: '', video_search: '' });
  const [articlePaginateData, setArticlePaginateData] = useState({per_page: '', current_page: 1, total_pages: '', total_count: ''});
  const [videoPaginateData, setvideoPaginateData] = useState({per_page: '', current_page: 1, total_pages: '', total_count: ''});
  const [paginationData, setpaginationData] = useState({ar_sort_by: 'title', ar_sort_order: 'asc', vi_sort_by: 'title', vi_sort_order: 'asc'})
  const [visible, setvisible] = useState(false);
  const [notificationData, setNotificationData] = useState(initalData);
  const [selectedNotificationCommunity, setselectedNotificationCommunity] = useState([])
  const [countrySearch, setCountrySearch] = useState(false);
  
  useEffect(async () => {
		await dispatch(setLoader(true));
    getContentList(getParams({ community_id: selectedCommunity }))
  }, []);

  const handleCommunitySelect = async (e) => {
    e.preventDefault();
    setSelectedCommunity(e.target.value)
    getContentList(getParams({ community_id: e.target.value }));
  }

  const getContentList = async (params) => {
    const response = await dispatch(fetchContentList(params))
    if (response.status) {
      await dispatch(setLoader(false));
      setArticles(response.data.data.articles)
      setVideos(response.data.data.videos)
      setArticlePaginateData(response.data.data.article_pagination)
      setvideoPaginateData(response.data.data.video_pagination)
    } else {
      await dispatch(setLoader(false));
      NotificationManager.error(response.message, '', 10000, () => {})
    }
  }

  const getParams = (params) => {
    const ar_pageNumber = '&ar_page=' + (params.ar_page ? params.ar_page : articlePaginateData.current_page)
    const vi_pageNumber = '&vi_page=' + (params.vi_page ? params.vi_page : videoPaginateData.current_page)
    const community = "?community_id=" + (params.community_id || '')
    const search = (params.article_search || params.video_search) ? params.article_search ? '&article_search=' + params.article_search : '&video_search=' + params.video_search : ''
    let data = community + search + ar_pageNumber + vi_pageNumber
    if (params.sortingType) {
      if (params.sortingType === 'article') {
        const sortby = params.sort_by ? params.sort_by : paginationData.ar_sort_by
        const sortorder = params.sort_order ? params.sort_order : paginationData.ar_sort_order
        data = community + "&ar_sort_by=" + sortby + "&ar_sort_order=" + sortorder + ar_pageNumber + vi_pageNumber

        if(search !== ''){
          data = community + "&ar_sort_by=" + sortby + "&ar_sort_order=" + sortorder + "&ar_page=1" + vi_pageNumber
        }
      } else {
        const sortby = params.sort_by ? params.sort_by : paginationData.vi_sort_by
        const sortorder = params.sort_order ? params.sort_order : paginationData.vi_sort_order
        data = community + "&vi_sort_by=" + sortby + "&vi_sort_order=" + sortorder + ar_pageNumber + vi_pageNumber
        if(search !== ''){
          data = community + "&ar_sort_by=" + sortby + "&ar_sort_order=" + sortorder + ar_pageNumber + "&vi_page=1"
        }
      }
    }else{
      if(params.video_search){
        data = community + search + ar_pageNumber + "&vi_page=1"
      }

      if(params.article_search){
        data = community + search + "&ar_page=1" + vi_pageNumber
      }
    }
    return data
  }

  const applyArticleSorting = (type) => {
    const sortOrder = paginationData.ar_sort_by === type ? paginationData.ar_sort_order === 'asc' ? 'desc' : 'asc' : 'asc'
    setpaginationData(prevState => ({
      ...prevState,
      ar_sort_by: type,
      ar_sort_order: sortOrder
    }))
    getContentList(getParams({ community_id: selectedCommunity, sort_by: type, sort_order: sortOrder, sortingType: 'article' }));
  }

  const getPaginateData = (page, type) => {
    if (type === 'article'){
      getContentList(getParams({ar_page: page}));
    }else{
      getContentList(getParams({vi_page: page}));
    }
  }

  const applyVideoSorting = (type) => {
    const sortOrder = paginationData.vi_sort_by === type ? paginationData.vi_sort_order === 'asc' ? 'desc' : 'asc' : 'asc'
    setpaginationData(prevState => ({
      ...prevState,
      vi_sort_by: type,
      vi_sort_order: sortOrder
    }))
    getContentList(getParams({ community_id: selectedCommunity, sort_by: type, sort_order: sortOrder, sortingType: 'video' }));
  }

  const sortedArticleClasses = (type) => {
    var sortClasses = classNames(
      { [paginationData.ar_sort_order]: paginationData.ar_sort_by === type }
    )
    return sortClasses
  }

  const sortedVideoClasses = (type) => {
    var sortClasses = classNames(
      { [paginationData.vi_sort_order]: paginationData.vi_sort_by === type }
    )
    return sortClasses
  }

  const handleSearchString = (e) => {
    const { name, value } = e.target
    setSearch(prevState => ({
      ...prevState,
      [name]: value
    }))
    getContentList(getParams({ [name]: value }));
  }

  // const sendOutNotification = async(id) => {
  //   const data = {content_id: id}
  //   const response = await dispatch(sendoutNotification(data)); 
  //   if(response.status){
  //     NotificationManager.success(response.message);
  //     getContentList(getParams({ community_id: selectedCommunity }))
  //   }else{
  //     NotificationManager.error(response.message);
  //   }
  // }

  const changeStatus = async(id, status) => {
    const data = {id: id, status: status}
    const response = await dispatch(setContentStatus(data)); 
    if(response.status){
      NotificationManager.success(response.message);
      getContentList(getParams({ community_id: selectedCommunity }))
    }else{
      NotificationManager.error(response.message, '', 10000, () => {})
    }
  }

  const openNotificationModel = (article) =>{
    setvisible(true);
    setNotificationData(prevState => ({
      ...prevState,
      content_id: article.id
    }))
    const selectedCommunity = communities.filter(community => article.communities.indexOf(community.id) > -1)
    setselectedNotificationCommunity(selectedCommunity)
  }

  
  const renderArticles = () => {
    return (
      <div className="table-scroll-campaigns  table-responsive">
        {articles.map(article => {
          return (
            <table key={Math.random()} className="table campaigns-table">
              <tbody>
                <tr className="content-full-table-rounded">
                  <td style={{ width: '70px' }} className="text-center">
                    <div className="content-images">
                      {article.attachments.length > 0 ?
                        <img src={article.attachments[0].media} width="47" height="44" alt="img" />
                        :
                        <img src={require("../../assets/images/img_dummy.png").default} width="47" height="44" alt="img" />
                      }
                    </div>
                  </td>
                  <td style={{ width: '200px' }}><p className="text-ellips">{article.title}</p></td>
                  <td style={{ width: '85px' }}>{article.view_count}</td>
                  <td>
                    <div className="multiple-actions">
                      <div className="action-bar-status" style={{ textAlign: "left" }}>
                        <span className={`action-open ${article.status}`}>{article.status === "active" ? "live" : article.status}</span>
                      </div>
                      <div className="actions-menu-dropdown">
                        <div className="dropdown default-dropdown">
                          <a href="javascript:void(0)" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                            <img src={require("../../assets/images/icon_more_menu_blue.svg")} />
                          </a>
                          <ul className="dropdown-menu">
                            {
                              article.status === "draft" ?
                              <li><a href="javascript:void(0)" onClick={() => changeStatus(article.id, "active")}>Set article to <strong>Live</strong></a></li>
                              :
                              <li><a href="javascript:void(0)" onClick={() => changeStatus(article.id, "archived")}>Set article to <strong>Archive</strong></a></li>
                            }
                            <li><a href="javascript:void(0)" onClick={() => openNotificationModel(article)}>Send out notification</a></li>
                            {/* <li><a href="javascript:void(0)" onClick={() => sendOutNotification(article.id)}>Send out notification</a></li> */}
                          </ul>
                        </div>
                      </div>
                      <div className="action-go"><Link to={`/article/${article.id}`}><img src={require("../../assets/images/icon_arrow_right_blue.svg")} alt="Go" /></Link></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          )
        })}
      </div>
    )
  }
  const onCountryChange = (searchText) => {
		searchText ? setCountrySearch(true) : setCountrySearch(false)
	}
 

  const renderVideos = () => {
    return (
      <div className="table-scroll-campaigns table-responsive">
        {videos.map(video => {
          return (
            <table key={Math.random()} className="table campaigns-table">
              <tbody>
                <tr className="content-full-table-rounded">
                  <td style={{ width: '70px' }} className="text-center">
                    <div className="content-images">
                    {video.thumbnail ?
                        <img src={video.thumbnail} width="47" height="44" alt="img" />
                        :
                        <img src={require("../../assets/images/img_dummy.png").default} width="47" height="44" alt="img" />
                      }
                    </div>
                  </td>
                  <td style={{ width: '200px' }}><p className="text-ellips">{video.title}</p></td>
                  <td style={{ width: '85px' }}>{video.view_count}</td>
                  <td>
                    <div className="multiple-actions">
                      <div className="action-bar-status" style={{ textAlign: "left" }}>
                        <span className={`action-open ${video.status}`}>{video.status === "active" ? "live" : video.status}</span>
                      </div>
                      <div className="actions-menu-dropdown">
                        <div className="dropdown default-dropdown">
                          <a href="javascript:void(0)" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                            <img src={require("../../assets/images/icon_more_menu_blue.svg")} />
                          </a>
                          <ul className="dropdown-menu">
                            {
                              video.status === "draft" ?
                              <li><a href="javascript:void(0)" onClick={() => changeStatus(video.id, "active")}>Set article to <strong>Live</strong></a></li>
                              :
                              <li><a href="javascript:void(0)" onClick={() => changeStatus(video.id, "archived")}>Set article to <strong>Archive</strong></a></li>
                            }
                            <li><a href="javascript:void(0)" onClick={() => openNotificationModel(video)}>Send out notification</a></li>
                          </ul>
                        </div>
                      </div>
                      <div className="action-go"><Link to={`/video/${video.id}`}><img src={require("../../assets/images/icon_arrow_right_blue.svg")} alt="Go" /></Link></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          )
        })}
      </div>
    )
  }


  const NotificationModel = () => {
    return (
      <Modal
        show={visible}
        onHide={() => closeModel()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="notification-open-modal"
      >
        <div className="data-modal-form">
          <h2 className="modal-head">Notification</h2>
          <form>
            <div className="form-group">
              <input type="email" name="title" className="form-control" placeholder="Title" value={notificationData.title} required="true" onChange={handleNotificationData} />
            </div>

            <TextArea
              classes="notification-desc label-text"
              containerClass="form-group"
              name="description"
              value={notificationData.description}
              required={true}
              placeholder="Description"
              onBlur={handleNotificationData}
            />
            <div id="campaign-page__community-selector" className="form-inputs">
              <label className={(countrySearch || (selectedNotificationCommunity.length > 0)) ? "labelClass field-active top-24" : "labelClass"}>Select Brand Community</label>

              <Multiselect
                options={communities}
                selectedValues={selectedNotificationCommunity}
                onSelect={handleNotificationCommunitieschange}
                onRemove={handleNotificationCommunitieschange}
                singleSelect={false}
                onSearch={onCountryChange}
                placeholder=""
                displayValue="name"
                className="custom-select sources"
              />
            </div>
          </form>
          <div className="form-submittion">
            <button type="button" data-dismiss="modal" onClick={() => sendContentNotification()}>Send Notification</button>
            <button type="button" data-dismiss="modal" onClick={() => closeModel()}>CANCEL</button>
          </div>
        </div>
      </Modal>
    )
  }

  const handleNotificationData = (e) => {
    const { name, value } = e.target
    setNotificationData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleNotificationCommunitieschange = (selectedList, selectedItem) => {
    (selectedList.length === 0) ? setselectedNotificationCommunity([]) : setselectedNotificationCommunity([selectedItem])
  }

  const sendContentNotification = async () => {
    const { title, description, content_id } = notificationData
    const data = { title, description, community_id: selectedNotificationCommunity.length > 0 ? selectedNotificationCommunity[0].id : "", slug: content_id }
    if (validateInput(data)) {
      var formdata = new FormData()
      for (const key in data) {
        formdata.append(key, data[key]);
      }
      const response = await dispatch(sendNotification(formdata))
      if (response.status) {
        NotificationManager.success(response.message)
        setNotificationData(initalData)
        setvisible(false);
      } else {
        NotificationManager.error(response.message, '', 10000, () => {})
      }
    }
  }

  const closeModel = () => {
    setvisible(false)
    setNotificationData(initalData)
    setselectedNotificationCommunity([])
  }

  const validateInput = (data) => {
    const Errors = [];
    Object.keys(data).map(key => {
      if (isEmpty(data[key]))
        Errors.push(`Please provide value for ${key}.`)
    })

    Errors.map((error) => {
      NotificationManager.error(error, '', 10000, () => {})
    })

    return isEmpty(Errors);
  }
  return (
    <div id="view-dashboard">
      {visible ? NotificationModel() : null}
      <div className="inner-view-dashboard">
        <div className="communities-content">
          <div className="select-communities-bar">
            <CustomSelect name="sources" id="select-community01" value={selectedCommunity} className="custom-select sources" placeholder="America" onChange={(e) => handleCommunitySelect(e)}>
              <option value='' key={Math.random()}>
								All Brand Communities.
							</option>
              {renderCommunitySelector()}
            </CustomSelect>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="create-campagin">
                <Link to='/article/new' className="redirect-link"> CREATE ARTICLE</Link>
              </div>
              <div className="capsule-tabs-search">
                <h3 className="content-heading">Articles</h3>
                <div className="search-input"><input type="search" className="" name="article_search" value={search.article_search} placeholder="Search" onChange={(e) => handleSearchString(e)} /></div>
              </div>
              <div className="content-full-table">
                <div className="table-scroll-campaigns table-responsive">
                  <table className="table campaigns-table table-column-sort">
                    <thead>
                      <tr>
                        <th style={{ width: '70px' }}></th>
                        <th style={{ width: '200px' }}><div className="head-sort" onClick={(e) => applyArticleSorting('title')}>TITLE <a href="javascript:void(0)" className="table-sort" className={sortedArticleClasses('title')}></a></div></th>
                        <th style={{ width: '85px' }}><div className="head-sort" onClick={(e) => applyArticleSorting('view_count')}>VIEWS <a href="javascript:void(0)" className="table-sort" className={sortedArticleClasses('view_count')}></a></div></th>
                        <th><div className="head-sort" onClick={(e) => applyArticleSorting('status')}>STATUS <a href="javascript:void(0)" className="table-sort" className={sortedArticleClasses('status')}></a></div></th>
                      </tr>
                    </thead>
                  </table>
                </div>
                {renderArticles()}
              </div>
              {
                articlePaginateData.total_pages > 1 && (
                  <div className="padination-numbers">
                    <Pagination 
                      current_page={articlePaginateData.current_page}
                      per_page={articlePaginateData.per_page}
                      total_count={articlePaginateData.total_count}
                      setpaginationData={(e) => getPaginateData(e, "article")}
                    />
                  </div>
                )
              }
            </div>
            <div className="col-md-6">
              <div className="create-campagin">
                <Link to='/video/new' className="redirect-link"> CREATE VIDEO</Link>
              </div>
              <div className="capsule-tabs-search">
                <h3 className="content-heading">Videos</h3>
                <div className="search-input"><input type="search" className="" name="video_search" value={search.video_search} placeholder="Search" onChange={(e) => handleSearchString(e)} /></div>
              </div>
              <div className="content-full-table">
                <div className="table-scroll-campaigns table-responsive">
                  <table className="table campaigns-table table-column-sort">
                    <thead>
                      <tr>
                        <th style={{ width: '70px' }}></th>
                        <th style={{ width: '200px' }}><div className="head-sort" onClick={(e) => applyVideoSorting('title')}>TITLE <a href="javascript:void(0)" className="table-sort" className={sortedVideoClasses('title')}></a></div></th>
                        <th style={{ width: '85px' }}><div className="head-sort" onClick={(e) => applyVideoSorting('view_count')}>VIEWS <a href="javascript:void(0)" className="table-sort" className={sortedVideoClasses('view_count')}></a></div></th>
                        <th><div className="head-sort" onClick={(e) => applyVideoSorting('status')}>STATUS <a href="javascript:void(0)" className="table-sort" className={sortedVideoClasses('status')}></a></div></th>
                      </tr>
                    </thead>
                  </table>
                </div>
                {renderVideos()}
              </div>
              {
                videoPaginateData.total_pages > 1 && (
                  <div className="padination-numbers">
                    <Pagination 
                      current_page={videoPaginateData.current_page}
                      per_page={videoPaginateData.per_page}
                      total_count={videoPaginateData.total_count}
                      setpaginationData={(e) => getPaginateData(e, "video")}
                    />
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}