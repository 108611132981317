import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { fetchIcon, updateIcon } from '../../Redux/Actions/Icons';
import { fetchCategoryInterst } from '../../Redux/Actions/Categories';
import { fetchCountries } from '../../Redux/Actions/Countries';
import { NotificationManager } from 'react-notifications';
import { Multiselect } from 'multiselect-react-dropdown';
import { setLoader } from '../../Redux/Actions/Loader';
import TextArea from '../../Components/TextArea/Field';
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Field from '../../Components/TextField/Field';
import './IconList.css';


const iconData = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  gender: "",
  bio: "",
  birthday: "",
  isEmailVerfied: "",
  media: "",
  attachment_url: "",
  interest_ids: []
}
const addressData = {
  line1: "",
  line2: "",
  city: "",
  zip_code: "",
  country_id: ""
}

export default (props) => {
  const dispatch = useDispatch();
  const submitBtn = useRef();
  const calendar = useRef();
  const [icon, setIcon] = useState(iconData);
  const [address, setAddress] = useState(addressData);
  const [countries, setCountries] = useState([]);
  const [categoryInterests, setcategoryInterests] = useState([]);
  const [error, setError] = useState("");
  const [editFields, seteditFields] = useState({});
	const [countrySearch, setCountrySearch] = useState(false);
  let history = useHistory();

  const handleChange = (e, type = '') => {
    let { name, value } = e.target
    value = (name === "isEmailVerfied") ? type : value
    setIcon(prevState => ({
      ...prevState,
      [name]: value
    }))

  }

  const handleIsEditedChange = (e) => {
    let { name} = e.target
    seteditFields(prevState => ({
      ...prevState,
      [`is${name}Edited`]: true
    }))
  }

  const onCountrySelect = (selectedList, selectedItem) => {
		setAddress(prevState => ({
			...prevState,
			country_id :  selectedList.length === 0 ? [] : [selectedItem]
		}))
	}

  const handleBirthday = (value) => {
    setIcon(prevState => ({
      ...prevState,
      birthday: value
    }))
  }

  const handleAddressChange = (e) => {
    let { name, value } = e.target
    setAddress(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const onSelect = (selectedList, selectedItem) => {
    if(selectedList.length === 0){
      setError("Required")
    }else{
      setError("")
    }
    setIcon(prevState => ({
      ...prevState,
      interest_ids: selectedList
    }))
  }

  const onFileChange = (e) => {
    var value = e.target.files[0]
    setIcon(prevState => ({
      ...prevState,
      media: value,
      attachment_url: value ? URL.createObjectURL(value) : ""
    }))
  }


  useEffect(async () => {
    await dispatch(setLoader(true));
    await getCategoryInterst();
    await fetchAllCountries();
    if (props.match && props.match.params.id) {
      await getIconInfo(props.match.params.id);
    }

  }, []);

  const getCategoryInterst = async () => {
    const response = await dispatch(fetchCategoryInterst());
    if (response.success) {
      setcategoryInterests(response.data)
    }
  }

  const fetchAllCountries = async () => {
    const response = await dispatch(fetchCountries())
    if (response.success)
      setCountries(response.data);
  }

  const getIconInfo = async (id) => {
    const response = await dispatch(fetchIcon(id));
    if (response.success) {

      await dispatch(setLoader(false));
      let data = response.data.data
      data.phone = data.phone ? (data.phone.split("-")[0] + data.phone.split("-")[1] + data.phone.split("-")[2]) : null
      data.interest_ids = data.interests
      let address = data.address
      if (address){
        address.zip_code = address.zip_code ? parseInt(address.zip_code.split("-")[0] + address.zip_code.split("-")[1]) : null
        address.country_id = [{id: address.country_id, name: address.country_name}]
        setAddress(address)
      }
      setIcon(data)
    }else{
      await dispatch(setLoader(false));
      NotificationManager.error(response.message, '', 10000, () => {})
    }
  }

  const submitOrgForm = async (e) => {
    e.preventDefault();
    await dispatch(setLoader(true));
    const interest_ids =icon.interest_ids ?  icon.interest_ids.map(cat => cat.id) : []
    if (interest_ids.length > 0){
      submitBtn.current.setAttribute('disabled', true);
      const {first_name, last_name, email, phone, gender, birthday, bio, media, isEmailVerfied} = icon
      const {line1, line2, city, zip_code } = address
      let country_id = address.country_id.length > 0 ? address.country_id[0].id : ""
      const data = {first_name, last_name, email, phone, gender, birthday, bio, interest_ids, isEmailVerfied}
      const addressfields = {line1, line2, city, country_id, zip_code}
      var formdata = new FormData()
      for (const key in data){
        formdata.append(`icon[${key}]`, Array.isArray(data[key]) ? JSON.stringify(data[key]) : data[key])
      }
      if(media)
        formdata.append('icon[media]', media)
      for (const key in addressfields){
        formdata.append(`address[${key}]`, addressfields[key])
      }

      const { status, message } = await dispatch(updateIcon(props.match.params.id, formdata))
      submitBtn.current.removeAttribute('disabled');
      status ? NotificationManager.success(message) : NotificationManager.error(message, '', 10000, () => {})
      await dispatch(setLoader(false));
      await getIconInfo(props.match.params.id);
    } else{
      await dispatch(setLoader(false));
      setError("Required")
    }
    
  }

  const goToIconList = () => {
    history.push("/icon-list");
  }

  const onCountryChange = (searchText) => {
		searchText ? setCountrySearch(true) : setCountrySearch(false)
	}
  return (
    <div id="view-dashboard">
      <div className="inner-view-dashboard">
        <div className="communities-content">
          <div className="tab-content tab-content-setting">
            <div id="organization_details" className="tab-pane fade in active">
              <div className="organization_levels">
                <div className="organization_text">
                  <form className="form-set" onSubmit={submitOrgForm}>
                    <h4 className="h4basic03">Icon Profile </h4>
                      <Field
                        type="text"
                        name="first_name" 
                        onFocus={(e) => e.target.placeholder = ''}
                        onBlur={handleChange}
                        placeholder="First name" 
                        style={{width: "100%"}}
                        value={icon.first_name}
                        containerClass="form-inputs"
                      />

                      <Field
                        type="text"
                        name="last_name" 
                        onFocus={(e) => e.target.placeholder = ''}
                        onBlur={handleChange}
                        placeholder="Last name" 
                        style={{width: "100%"}}
                        value={icon.last_name}
                        containerClass="form-inputs"
                      />  


                      <Field
                        type="text"
                        name="email" 
                        onFocus={(e) => e.target.placeholder = ''}
                        onBlur={handleChange}
                        placeholder="Email" 
                        style={{width: "100%"}}
                        value={icon.email}
                        containerClass="form-inputs"
                      /> 

                    <div className="terms-labels">
                      <div className="radio-check">
                        <p className="txt-color">Email Verified</p>
                        <label className="container-checkbox">True
                          <input type="radio" name="isEmailVerfied" checked={icon.isEmailVerfied} value={icon.isEmailVerfied}  />
                          <span className="checkmark"></span>
                        </label>
                        <label className="container-checkbox">False
                          <input type="radio" name="isEmailVerfied" checked={!icon.isEmailVerfied} value={icon.isEmailVerfied} />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>

                    <div className="form-inputs">
                      <label className={icon.gender ? "labelClass field-active" : "labelClass field-active"}>Gender</label>

                      <select name="sources" className="custom-select sources grey-bg select-border-color capital" placeholder="Gender" name="gender" value={icon.gender && icon.gender.toLowerCase() } >
                      <option key={Math.random()} className="optionCss capital" value="">Select Gender</option>
                        {["male", "female", "other"].map(gender =>
                          <option key={Math.random()} className="optionCss capital" value={gender}>{gender}</option>
                        )}
                      </select>

                    </div>

                    <div className="form-inputs">
                      <label className={(editFields.isbioEdited || icon.bio) ? "labelClass top-16 field-active" : "labelClass top-16"}>Bio</label>
                      <TextArea
                        key={Math.random()}
                        classes="label-text"
                        name="bio"
                        value={icon.bio}
                        onChange={handleChange}
                        onClick={(e) => handleIsEditedChange(e)}
                      />
                    </div>
                    <div className="form-inputs">
                      <label className={(editFields.isbirthdayEdited || icon.birthday) ? "labelClass field-active" : "labelClass"}>Birthday</label>
                      <DatePicker
                        className="floating-label grey-bg"
                        ref={calendar}
                        minDate={moment().subtract(500, "years")}
                        maxDate={moment().subtract(18, "years")}
                        selected={icon.birthday ? new Date(icon.birthday) : ""}
                        showDisabledMonthNavigation
                        dateFormat="d-MMM-yyyy"
                        name="birthday"
                        onChange={(e) => handleBirthday(e)}
                        onFocus={(e) => handleIsEditedChange(e)}
                      />
                    </div>

                    <h4 className="h4basic03 mt-60">Address </h4>

                      <Field
                        type="text"
                        name="line1" 
                        onFocus={(e) => e.target.placeholder = ''}
                        onBlur={handleAddressChange}
                        placeholder="Address" 
                        style={{width: "100%"}}
                        value={address.line1}
                        containerClass="form-inputs"
                      />
                      <Field
                        type="text"
                        name="line2" 
                        onFocus={(e) => e.target.placeholder = ''}
                        onBlur={handleAddressChange}
                        placeholder="Address" 
                        style={{width: "100%"}}
                        value={address.line2}
                        containerClass="form-inputs"
                      />

                      <Field
                        type="text"
                        name="city" 
                        onFocus={(e) => e.target.placeholder = ''}
                        onBlur={handleAddressChange}
                        placeholder="City" 
                        value={address.city}
                        containerClass="form-inputs city-check"
                      />

                      <Field
                        type="number"
                        name="zip_code" 
                        onFocus={(e) => e.target.placeholder = ''}
                        onBlur={handleAddressChange}
                        placeholder="Post code" 
                        value={address.zip_code}
                        containerClass="form-inputs zip-check"
                      />
                
                    <div className="form-inputs" id="campaign-page__community-selector">
											<label className={(countrySearch || (address.country_id && address.country_id.length > 0) ) ? "labelClass field-active top-0" : "labelClass top-36"}>Country</label>
											<Multiselect
												options={countries}
												selectedValues={address.country_id}
												onSelect={onCountrySelect}
                        onRemove={onCountrySelect}
                        onSearch={onCountryChange}
												singleSelect={false}
												placeholder=""
												displayValue="name"
												className="custom-select sources"
												/>
										</div>
                  </form>
                </div>
                <div className="organization_brand">
                  <form className="form-set" onSubmit={submitOrgForm}>

                    <div className="uploadOuter">
                      <div className="dragBox">
                        Drag and drop image here or click upload image
												<input type="file" ondragover="drag()" onDrop="drop()" id="uploadFile" name="media" onChange={onFileChange} />
                        <div id="preview">
                          {icon.attachment_url && <img src={icon.attachment_url} />}
                        </div>
                      </div>
                    </div>

                    <h4 className="h4basic03 mt-60">Icon Interest</h4>
            
                    <div className="form-inputs" id="campaign-page__community-selector">

											<Multiselect
												options={categoryInterests}
												selectedValues={icon.interest_ids}
												onSelect={onSelect}
                        onRemove={onSelect}
												placeholder=""
												displayValue="name"
												className="custom-select sources"
												/>
										</div>
                    { error ? <p className="error">{error}</p> : null}
                    <div className="save-bio iconEdit">
                      <button className="grey-bg" onClick={goToIconList}>Cancel</button>

                      <button type="submit" ref={submitBtn} className="grey-bg">save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}