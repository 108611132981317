import './VerifyAlert.css'
import React, {useState} from 'react';
import Field from '../../Components/TextField/Field';
import Modal from 'react-bootstrap/Modal'
import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
export default ({
  inviteModel= false,
  handleVerifySelect = () => {},
  closeModel =() => {}
}) => {
  const [postId, setPostId] = useState("");
  const [verifiedAt, setVerifiedAt] = useState(new Date());

  const handleSubmit =(postId, verifiedAt)=> {
    handleVerifySelect(postId, verifiedAt)
    setPostId("")
  }
  
  const clsoeVerifyModel = ()=> {
    closeModel()
    setPostId("")
  }

  const handleDateSelect = (value, type) => {
    setVerifiedAt(value);
  }

  return(
    <Modal
      show={inviteModel}
      onHide={() => clsoeVerifyModel()}
      className="verify-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <p>Link to Instagram post<a  href="javascript:void(0)" data-dismiss="modal" onClick={()=> clsoeVerifyModel()} className="close color-black">X</a></p>
      <div className="data-modal-form">
        <form>
          <div className="form-group">
            <input type="text" name="postId" className="form-control" placeholder="URL" value={postId} onChange={(event) => setPostId(event.target.value)} required="true" />
          </div>
          <div className="form-group" style={{ width: '100%' }}>
            <p>Choose Verification Date</p>
            <DatePicker selected={verifiedAt} onChange={(date:Date) => setVerifiedAt(date)} />
          </div>
        </form>
        <div className="form-submittion">
          <button type="button" data-dismiss="modal" onClick={() => handleSubmit(postId, verifiedAt)}>OK</button>
        </div>
      </div>
    </Modal>
  )
}
