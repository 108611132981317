import { CONTENT_LIST_ARTICLE_SORT_FILTERS, CONTENT_LIST_VIDEO_SORT_FILTERS, SET_CONTENT_PAGINATION_DATA } from '../Actions/Types/Types';

const articleInitailSortFilters = {per_page: '', current_page: 1, total_pages: '', total_count: ''}
export const contentListArticleSortFilters = (state = articleInitailSortFilters, action) => {
  switch(action.type) {
    case CONTENT_LIST_ARTICLE_SORT_FILTERS:
      return action.value

    default: 
      return state;
  }
}

const videoInitailSortFilters = {per_page: '', current_page: 1, total_pages: '', total_count: ''}
export const contentListVideoSortFilters = (state = videoInitailSortFilters, action) => {
  switch(action.type) {
    case CONTENT_LIST_VIDEO_SORT_FILTERS:
      return action.value

    default: 
      return state;
  }
}

const initialContentPaginationData = {ar_sort_by: 'title', ar_sort_order: 'asc', vi_sort_by: 'title', vi_sort_order: 'asc'}
export const contentPaginationData = (state = initialContentPaginationData, action) => {
  switch(action.type) {
    case SET_CONTENT_PAGINATION_DATA:
      return action.value

    default: 
      return state;
  }
}
