import React, { useEffect, useState, useRef } from 'react';
import Field from '../../../Components/TextField/Field';
import TextArea from '../../../Components/TextArea/Field';
import CalendarField from "../../../Components/Calendar/CalendarCard";
import CustomSelect from "../../../Components/CustomSelect/Select";
import { useDispatch } from 'react-redux';
import { isEmpty, clone, uniq } from 'lodash';
import { fetchPlatform } from '../../../Redux/Actions/Platform';
import { fetchCountries } from '../../../Redux/Actions/Countries';
import { fetchCategories } from '../../../Redux/Actions/Categories';
import { update, create, fetchOneCampaign } from '../../../Redux/Actions/Campaign';
import { NotificationManager} from 'react-notifications';
import { useHistory } from 'react-router-dom'
import { setLoader } from '../../../Redux/Actions/Loader';
import { Multiselect } from 'multiselect-react-dropdown';
import SendOutNotification from '../../Common/Notification';
import './style.scss';
import './form_calendar.scss';
import moment from 'moment'
import { enGB } from 'date-fns/locale';
import { DatePickerCalendar } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import { fetchAll } from '../../../Redux/Actions/Community';
import {fetchAllUser} from '../../../Redux/Actions/User';

const defaultTerms = "It is important when you work with us that no other collaboration is done 24h before or 24h after. The image must also be up for at least 7 days for it to be considered as approved. You  always  have  to  upload  your  draft  in  the  app  and  get  approved  before  posting anything on Instagram";
const DATE_FIELDS = ['apply_date', 'select_reject_date', 'draft_upload_date', 'draft_approved_date'];
const initialData = {
    name: "",
    caption: "",
    description: "",
    specification: "",
    content_directive: "",
    budget: "",
		media: "",
    currency_code: "SEK",
    terms: '',
    terms_type: "Standard",
    // reward: "Social media value",
    campaigns_type: "genuine",
    mention_tags: [],
    platform_ids: [], 
    hashtags: [],
    community_ids: [],
    apply_date: '',
    select_reject_date: "",
    draft_upload_date: "",
		draft_approved_date: "",
		status: "",
		story_caption: "",
		post_caption: "",
		community_campaigns_attributes:[],
		platform_campaigns_attributes:[],
		category_id: "",
		run_duration: 7,
		manager_id: "",
		assistant_manager_id: ""
}
const attachData = [{id: "", media: "", media_url: ""}, {id: "", media: "", media_url: ""}]

export default ({
	communities = [],
	renderCommunitySelector = () => {},
	setShowForm = () => {},
	...props
}) => {
	const [campaignData, setCampaignData] = useState(initialData);
	const [attachments, setAttachments] = useState(attachData);
	const [countries, setCountries] = useState([]);
	const [activeDate, setActiveDate] = useState('apply_date')
	const [platforms, setPlatforms] = useState([]);
	const [categories, setCategories] = useState([]);
	const [orgUsers, setOrgUsers] = useState([]);
	const [tagVal, setTagVal] = useState('');
	const [mentionVal, setMentionVal] = useState('');
	const dispatch = useDispatch();
	const history = useHistory();
	const calendar = useRef();
	const tags = useRef();
	const mentions = useRef();
	const [termsData, setTermsData] = useState('');
	const [checkValues, setCheckValues] = useState(false);

	useEffect(async () => {
		if(props.match && props.match.params.id) {
			await dispatch(setLoader(true));
			const response = await dispatch(fetchOneCampaign(props.match.params.id))
			if (response.status === 'success') {
				await dispatch(setLoader(false));
				const communityData = await dispatch(fetchAll())
				const defaultCommunities = communityData.data
				let campaignVal = response.data

				if(response.data.attachments.length > 0) {
					const newAttcahments = campaignVal.attachments.map((attach) => {
						return {...attach, media: "", media_url: attach.media};
					});
					setAttachments(newAttcahments)
				}

				campaignVal.updatedPlatformIds = response.data.platform_ids ? response.data.platform_ids : []
				campaignVal.platform_ids = response.data.platforms.map(a => a.id);
				campaignVal.mention_tags = campaignVal.mentions ? campaignVal.mentions.map(a => a.name) : [];
				campaignVal.budget = campaignVal.budget ? campaignVal.budget.toString() : '';	
				campaignVal.hashtags = campaignVal.hashtags ? campaignVal.hashtags.map(a => a.name) : [];
				campaignVal.community_campaigns_attributes = campaignVal.community_ids
				campaignVal.platform_campaigns_attributes = []
				campaignVal.category_id = campaignVal.category_id
				if(campaignVal.platform_ids && campaignVal.platform_ids.length > 0){
					campaignVal.platform_ids.map(platform => {
						let obj = {
							id: campaignVal.updatedPlatformIds && campaignVal.updatedPlatformIds.length > 0 ? campaignVal.updatedPlatformIds[0].id : '',
							platform_id: platform
						}
						campaignVal.platform_campaigns_attributes.push(obj)
					})
				}

				if(campaignVal.community_ids && campaignVal.community_ids.length > 0){
					let selectedCommunity = []
					defaultCommunities.map(community => {
						campaignVal.community_ids.map(camCoummunity=> {
							if(camCoummunity.community_id == community.id && camCoummunity.default){
								selectedCommunity.push(community)
							}
						})
					})
				 campaignVal.community_ids = selectedCommunity
				}
				if(campaignVal.terms_type === null){
					campaignVal.terms = defaultTerms
					campaignVal.terms_type = 'Standard'
					setTermsData(campaignVal.terms)
				} else if(campaignVal.terms_type === 'Standard'){
					campaignVal.terms = defaultTerms
					setTermsData(campaignVal.terms)
				} else {
					setTermsData(campaignVal.terms)
				}
				setCampaignData(campaignVal)
			}else{
				await dispatch(setLoader(false));
				NotificationManager.error(response.data.message, '', 10000, () => {})
			}
		} else{
			let campaignVal = campaignData
			campaignVal.terms = defaultTerms
			setTermsData(defaultTerms)
			setCampaignData(campaignVal)
		}
		// Fetch all platforms
		await fetchAllPlatform();

		// Fetch all platforms
		await fetchAllCountries();
		await fetchAllCategories();
		await getAllUser();

	}, []);

	useEffect(() => {
		const selectedCommunity = communities.filter(community => campaignData.community_ids.indexOf(community.id) > -1)
		setCampaignData(prevState => ({
			...prevState,
			community_ids: selectedCommunity
		}))
	}, [communities])

	useEffect(() => {
		openCalendar();
	}, [])

	const handleTagChange = (e, type) => {
		let value = clone(e.target.value);
		const isHashtag = e.target.name === 'hashtags';
		const prefix = e.target.placeholder
		value = value.toString().replace(prefix,'')
		value = `${prefix}${value}`
		isHashtag ? setTagVal(value) : setMentionVal(value)
	}

	const handleKeyPress = (e, type) => {
		const isHashtag = e.target.name === 'hashtags';
		const prefix = e.target.placeholder
		if(e.key === 'Enter' && e.target.value != prefix) {
			isHashtag ? addTags(e) : addMentions(e)
		} else if(e.type === 'focus' && e.target.value === '') {
			isHashtag ? setTagVal(prefix) : setMentionVal(prefix)
		} else if(e.type === 'keydown' && e.keyCode === 9 && e.target.value != prefix){
			isHashtag ? addTags(e) : addMentions(e)
		} else if(e.type === 'keydown' && e.keyCode === 32 && e.target.value != prefix){
			isHashtag ? addTags(e) : addMentions(e)
		}
		// } else if(e.type === 'keydown' && e.keyCode === 27){
		// 	if(isHashtag){
		// 		let data = clone(campaignData['hashtags'])
		// 		data.splice(-1)
		// 		setCampaignData(prevState => ({
		// 			...prevState,
		// 			['hashtags'] : data
		// 		}));
		// 	} else{
		// 		let data = clone(campaignData['mention_tags'])
		// 		data.splice(-1)
		// 		setCampaignData(prevState => ({
		// 			...prevState,
		// 			['mention_tags'] : data
		// 		}));
		// 	}
		// }
	}

	const formattedTags = (tags, type) => {
		tags = tags ? tags : [];
		const tagIcon = type === 'hashtags' ? '#' : '@';
		return tags.map(a => a.indexOf(tagIcon) < 0 ? `${tagIcon}${a}` : a).join(' ')
	}

	const formattedFutureDate = (date) => {
		date.setDate(date.getDate() + 3)
		return date
	}

	const getMinDate = () => {
		let date = new Date();

		switch(activeDate) {
			case 'apply_date':
				date = new Date();
				break;

			case 'select_reject_date':
				date = campaignData['apply_date'] ? formattedFutureDate(new Date(campaignData['apply_date'])) : date;
				break;
			
			case 'draft_upload_date':
				date = campaignData['select_reject_date'] ? formattedFutureDate(new Date(campaignData['select_reject_date'])) : date;
				break;
			
			case 'draft_approved_date':
				date = campaignData['draft_upload_date'] ? formattedFutureDate(new Date(campaignData['draft_upload_date'])) : date;
				break;
		}

		return date;
	}

	const addTags = async (e) => {
		e.preventDefault();
		if (tags.current.value) {
			await setCampaignData(prevState => ({
				...prevState,
				hashtags: uniq([...campaignData.hashtags, `${tags.current.value}`])
			}))
			tags.current.value = '#';
			setTagVal('#');
		}
	}

	const addMentions = async (e) => {
		e.preventDefault();
		if (mentions.current.value) {
			await setCampaignData(prevState => ({
				...prevState,
				mention_tags: uniq([...campaignData.mention_tags, `${mentions.current.value}`])
			}))
			setMentionVal('@');
			mentions.current.value = '@';
		}
	}

	const openCalendar = () => {
		if (calendar.current) {
			calendar.current.render()
			calendar.current.setOpen(false);
			calendar.current.setOpen(true);
		}
	}

	const handleRemoval = (type, value) => {
		let data = clone(campaignData[type])
		data.splice(campaignData[type].indexOf(value), 1);
		setCampaignData(prevState => ({
			...prevState,
			[type] : data
		}));
	}

	const renderTags = (data, type) => {
		return data.map((record, index) => {
			return	<li key={index} className="search-choice"><span>{record}</span><a onClick={(e) => handleRemoval(type, record)} className="search-choice-close" data-option-array-index={index}></a></li>
		})
	}

	const handleCommunitySelect = (selectedList, selectedItem) => {
		let obj = {
			id: campaignData.community_campaigns_attributes && campaignData.community_campaigns_attributes.length > 0 ? campaignData.community_campaigns_attributes[0].id: '',
			community_id: selectedItem.id,
			default: true
		}

		setCampaignData(prevState => ({
			...prevState,
			community_ids : selectedItem ? [selectedItem] : [],
			community_campaigns_attributes: [obj]
		}))
		// setCheckValues(selectedItem)
	}

	const handleCommunityRemove = (removedList, removedItem) => {
		let obj = {
			id: campaignData.community_campaigns_attributes && campaignData.community_campaigns_attributes.length > 0 ? campaignData.community_campaigns_attributes[0].id: '',
			community_id: '',
			default: true
		}

		setCampaignData(prevState => ({
			...prevState,
			community_ids : [],
			community_campaigns_attributes: [obj]
		}))
		// setCheckValues(selectedItem)
	}

	const handleCategorySelect = (selectedList, selectedItem) => {
		setCampaignData(prevState => ({
			...prevState,
			category_id: selectedItem.id
		}))
	}

	const handleCategoryRemove = (removedList, removedItem) => {
		setCampaignData(prevState => ({
			...prevState,
			category_id: ''
		}))
	}


	const fetchAllCountries = async () => {
		const response = await dispatch(fetchCountries())
		if(response.success)
			setCountries(response.data);
	}

	const fetchAllCategories = async() => {
		const response = await dispatch(fetchCategories())
		if(response.success)
			setCategories(response.data);	
	}

	const fetchAllPlatform = async () => {
		const response = await dispatch(fetchPlatform())
		if(response.success)
			setPlatforms(response.data);
	}

	const getAllUser = async() => {
		const {status, message, data, response} = await dispatch(fetchAllUser())
		if(status){
			setOrgUsers(data);
			console.log("Data===>>>", data);
			console.log("usersData===>>>", orgUsers);
		}

	}

	const handleChange = (e) => {
		let {name, value, type} = e.target;
		if(type === 'file') {
			value = e.target.files[0]
			updateCampaignData(name, value);
		} else if(name === 'terms') {
			setTermsData(value)
			updateCampaignData(name, value);
		} else {
			updateCampaignData(name, value);
		}
	}

	const updateCampaignData = (name, value) => {
		if (name === 'terms_type' && value === 'Standard') {
			setTermsData(defaultTerms)
			setCampaignData(prevState => ({
				...prevState,
				[name] : value
			}))		
		} else if(name === 'terms_type' && value === 'Write your own or add something') {
			setTermsData(campaignData.terms)
			setCampaignData(prevState => ({
				...prevState,
				[name] : value
			}))
		} else if(name === 'platform_ids') {
			let obj = {
				id:campaignData.platform_campaigns_attributes && campaignData.platform_campaigns_attributes.length > 0 ? campaignData.platform_campaigns_attributes[0].id : '',
				platform_id: value
			}
			setCampaignData(prevState => ({
				...prevState,
				[name] : value,
				platform_campaigns_attributes: [obj]
			}))
		} else{
			setCampaignData(prevState => ({
				...prevState,
				[name] : value
			}))
		}
		/*if (name === 'terms_type' && value === 'Write your own or add something')
		if(name === 'platform_ids') {
			value = uniq([...campaignData.platform_ids, value])
		}*/
		/*setCampaignData(prevState => ({
			...prevState,
			[name] : value
		}))*/
	}

	const validateInput = (data) => {
		const Errors = [];
		Object.keys(data).map(key => {
			if(isEmpty(data[key]) && DATE_FIELDS.indexOf(key) < 0) {
				if((key === 'community_ids' || key === 'community_campaigns_attributes') && props.match && !props.match.params.id){
					Errors.push(`Please provide value for ${key}.`)
				} else if(key == 'manager_id') {
					Errors.push(`Please choose manager for campaign.`)
				} else if(key !== 'community_ids' && key !== 'community_campaigns_attributes') {
					Errors.push(`Please provide value for ${key}.`)
				}
				setCheckValues(false)
			}
		})

		Errors.map((error) => {
			if(error === 'Please provide value for community_ids.') error = 'Please select value for community.'
			NotificationManager.error(error, '', 10000, () => {})
		})

		return isEmpty(Errors);
	}

	const formateDates = (data) => {
		DATE_FIELDS.map(key => {
			if(data[key]) {
				const date = new Date(data[key])
				data[key] = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
			}
		})
		return data
	}

	const createCampaign = async (e) => {

		setCheckValues(true)
		setLoader(true)
		e.preventDefault();
		// await dispatch(setLoader(true));
		const status = e.target.dataset.status;
		const { name, caption, description, specification , content_directive , budget, media, secondary_media, currency_code, terms, terms_type, campaigns_type, select_reject_date, draft_upload_date, draft_approved_date, apply_date, community_ids, platform_ids, mention_tags, hashtags, post_caption, story_caption, community_campaigns_attributes, platform_campaigns_attributes, category_id, run_duration, manager_id, assistant_manager_id} = campaignData;

		const updatedHashtag = hashtags.map(a => a.toString().replace('#', ''))
		const updatedMentions = mention_tags.map(a => a.toString().replace('@', ''))
		let data = {name, description, budget, currency_code, select_reject_date, draft_upload_date, draft_approved_date, apply_date , terms, terms_type, campaigns_type, community_ids: community_ids.map(a => a.id), platform_ids,  status, community_campaigns_attributes, platform_campaigns_attributes, category_id, manager_id};
		if(data.terms_type === 'Standard'){
			data.terms = defaultTerms
		}

		data = formateDates(data);
		if(validateInput(data)) {
			data = Object.assign(data, { caption, specification , content_directive, mention_tags: updatedMentions, hashtags: updatedHashtag, post_caption, story_caption, category_id, run_duration, manager_id, assistant_manager_id } );
			var formdata = new FormData()
			for (const key in data) {
				formdata.append(`campaign[${key}]`, Array.isArray(data[key]) ? JSON.stringify(data[key]) : data[key]);
			}

			if(attachments && attachments.length > 0)
				attachments.map((attach, index) => {
					if(attach.media){
						for (const key in attach) {
							formdata.append(`campaign[attachments_attributes][][${key}]`, attach[key]);
						}
					}
				})
			
			formdata.append(`campaign[default]`, true)
			const res = campaignData.id ? await dispatch(update(campaignData.slug, formdata)) : await dispatch(create(formdata))
			await dispatch(setLoader(false));
			setCheckValues(false)
			if(res.status === 200 && (res.data.status !== 'error')) {
				NotificationManager.success(res.data.message)
				setShowForm(false)
				setCampaignData(prevState => ({
					...prevState,
					status: status
				}))
				history.push(`/campaigns/${res.data.data.slug}`)
			} else {
				let errorMsg = ''
				if(res.data.error || res.data.status == 'error') {
					errorMsg = res.data.error || res.data.message;
				} else {
					Object.keys(res.data.message).map((key) => {
						const msg =  res.data.message[key].join(' ')
						errorMsg += `${key} ${msg}`;
					})				
				}
				NotificationManager.error(errorMsg, '', 10000, () => {})
			}

		}else{
			await dispatch(setLoader(false));
		}
	}


	const renderOptions = (data = [], selected = []) => {
		return data.map((record) => {
			return <option key={Math.random()} value={record.id} selected={selected.indexOf(record.id) > -1}> {record.name}</option>
		})
	}

	const handleDateSelect = (value, type) => {
		setCampaignData(prevState => ({
			...prevState,
			[type] : value
		}))
	}

	const handleAttachmentChange = (event, index) => {
			let value = event.target.files[0];
			let temp_attach = [...attachments];
			let temp_element = { ...temp_attach[index] };
			temp_element.media = value;
			temp_element.media_url = value ? URL.createObjectURL(value) : "";
			temp_attach[index] = temp_element
			setAttachments( temp_attach );
	}

	return(
		<div id="view-dashboard">
			<div className="inner-view-dashboard">
				<form>

					<div className="communities-content create-communities">
						<h4 className="h4basic03">Choose Brand community</h4>
						<div id="campaign-page__community-selector" className="form-inputs">
							<Multiselect
								options={communities}
								selectedValues={campaignData.community_ids}
								onSelect={handleCommunitySelect}
								onRemove={handleCommunityRemove}
								singleSelect={false}
								placeholder={campaignData.community_ids.length > 0 ? "" : "Select Brand Community"}
								displayValue="name"
								className="custom-select sources"
								disable={props.match && props.match.params.id ? true : false}
								/>
						</div>

						<h4 className="h4basic03">Choose category</h4>
						
						<div className="form-inputs" style={{'margin-top':'15px', 'margin-bottom':'15px'}}>
							<CustomSelect name="category_id" className="custom-select sources" value={campaignData.category_id} onChange={handleChange}>
								<option key={Math.random()} value=''>Select category</option>{
									categories.map(category => {
										return <option key={Math.random()} value={category.id}>{category.name}</option>
									})
								}
							</CustomSelect>
						</div>

						<h4 className="h4basic03">Choose Manager</h4>
						
						<div className="form-inputs" style={{'margin-top':'15px', 'margin-bottom':'15px'}}>
							<CustomSelect name="manager_id" className="manager-dropdown custom-select sources" value={campaignData.manager_id} onChange={handleChange}>
								<option key={Math.random()} value=''>Select user</option>{
									orgUsers.map(user => {
										return <option key={Math.random()} value={user.id}>{user.full_name} ({user.email})</option>
									})
								}
							</CustomSelect>
						</div>

						<h4 className="h4basic03">Choose assistant manager</h4>
						
						<div className="form-inputs" style={{'margin-top':'15px', 'margin-bottom':'15px'}}>
							<CustomSelect name="assistant_manager_id" className="manager-dropdown custom-select sources" value={campaignData.assistant_manager_id} onChange={handleChange}>
								<option key={Math.random()} value=''>Select user</option>{
									orgUsers.map(user => {
										return <option key={Math.random()} value={user.id}>{user.full_name} ({user.email})</option>
									})
								}
							</CustomSelect>
						</div>


						<div className="width-650 ">

							<h4 className="h4basic03">Choose platform</h4>

							<div className="type-campaign-list">
								<ul>
									{
										platforms.map(platform => {
											return (
												<li key={Math.random()} className={`uploaded-img ${campaignData.platform_ids && campaignData.platform_ids.indexOf(platform.id) > -1 ? 'active' : ''}`} onClick={(e) => updateCampaignData('platform_ids', platform.id)}>
													<a href="#">
														<img className="upload-bg" src={require(platform.name == 'instagram' ? "../../../assets/images/img_type_campaign01.png" : "../../../assets/images/black_background_tiktok.png").default} alt="img" />
														<p style={{"text-transform": "capitalize"}}>
															<img src={platform.logo} width="16px" />{platform.name}
														</p>
													</a>
												</li>
											)
										})
									}
								</ul>
							</div>
						</div>

						<div className="width-400">
							<h4 className="h4basic03">Campaign budget </h4>

							<div className="form-set campaign-budget-form">
								<Field
									type="number" 
									name="budget" 
									onBlur={handleChange}
									placeholder="Budget" 
									required={true}
									value={campaignData.budget}
									containerClass="form-inputs"
								>
									<div className="select-communities-bar">
										<CustomSelect name="currency_code" className="custom-select sources" placeholder="Sek" value={campaignData.currency_code} onChange={handleChange}>
											{
												countries.map(country => {
													return <option key={Math.random()} value={country.currency_code}>{country.currency_code}</option>
												})
											}
										</CustomSelect>
									</div>
								</Field>
							</div>
						</div>

						<div className="picture-phone">
							<div className="width-650 width-650-changed">
								<h4 className="h4basic03">Campaign picture {/*<a href="#"><img src={require("../../../assets/images/icon_question_mark.svg")} alt="img" /></a>*/}</h4>

								<div className="drap-picture-upload">

									<div className="uploadOuter">
										<div className="dragBox cam-height">
										Drag and drop image here or click upload image
											<input type="file" name="media" id="uploadFile" onChange={(e) => handleAttachmentChange(e, 0)} accept="image/*"/>
											<div id="preview">
												{(attachments[0] && attachments[0].media_url) && <img src={attachments[0].media_url}/> }
											</div>
										</div>
									</div>

									<div className="add-more-images">
										<div className="more-img-upload">
											<input type="file" name="secondary_media" onChange={(e) => handleAttachmentChange(e, 1)} accept="image/*" />
											<div>{(attachments[1] && attachments[1].media_url) ? <img src={attachments[1].media_url}/> : <img src={require("../../../assets/images/icon_plus.png").default} /> }</div>
										</div>
									</div>

								</div>

								<div className="width-400">
									<h4 className="h4basic03">Campaign name</h4>
									<div className="form-set campaign-budget-form">

										<Field
											type="text"
											name="name" 
											onFocus={(e) => e.target.placeholder = ''}
											onBlur={handleChange}
											placeholder="Name" 
											required={true}
											style={{width: "100%"}}
											value={campaignData.name}
											containerClass="form-inputs"
										/>
									</div>
								</div>

								<h4 className="h4basic03">Campaign brief / Introduction to your brand{/*<a href="#"><img src={require("../../../assets/images/icon_question_mark.svg")} alt="img" /></a>*/}</h4>
								<TextArea 
									classes="label-text"
									name="description"
									value={campaignData.description}
									onBlur={handleChange}
								/>

								<h4 className="h4basic03">Assignment specifications</h4>

								<TextArea 
									classes="label-text"
									name="specification"
									value={campaignData.specification}
									onBlur={handleChange}
								/>

								<h4 className="h4basic03">Content directive</h4>

								<TextArea 
									/*key={Math.random()}*/
									classes="label-text"
									name="content_directive"
									value={campaignData.content_directive}
									onBlur={handleChange}
								/>

								<h4 className="h4basic03">Caption</h4>

								<TextArea 
									/*key={Math.random()}*/
									classes="label-text"
									name="caption"
									value={campaignData.caption}
									onBlur={handleChange}
								/>


								<h4 className="h4basic03">Post</h4>

								<TextArea
								  /*key={Math.random()}*/
									classes="label-text"
									name="post_caption"
									value={campaignData.post_caption}
									onBlur={handleChange}
								/>

								<h4 className="h4basic03">Story</h4>

								<TextArea 
								  /*key={Math.random()}*/
									classes="label-text"
									name="story_caption"
									value={campaignData.story_caption}
									onBlur={handleChange}
								/>
								<h4 className="h4basic03">@ and #</h4>

								<div className="width-233">
									<div className="form-set campaign-budget-form">
										<div className="form-inputs" style={{flexDirection: "column", alignItems: "flex-start"}}>
											<div className="mb-6 chosen-container chosen-container-multi chosen-with-drop chosen-container-active" title="">
												{
													(campaignData.mention_tags || []).length > 0 ? (
														<ul className="chosen-choices">
															{ renderTags(campaignData.mention_tags, 'mention_tags')}
														</ul>
													) : null
												}
												<div className="chosen-drop">
													<input 
														ref={mentions} 
														name="mentions"
														type="text" 
														placeholder="@" 
														value={mentionVal}
														onChange={(e) => handleTagChange(e, 'mentions')}
														onFocus={(e) => handleKeyPress(e)} 
														onKeyPress={(e) => handleKeyPress(e)} 
														onKeyDown={(e) => handleKeyPress(e)} 
														style={{width: "100%", fontWeight: "bold"}} />
												</div>
											</div>


			
											<div className="mb-6 chosen-container chosen-container-multi chosen-with-drop chosen-container-active" title="">
												{
													(campaignData.hashtags || []).length > 0 ? (
														<ul className="chosen-choices">
															{ renderTags(campaignData.hashtags, 'hashtags')}
														</ul>
													) : null
												}
												<div className="chosen-drop">
													<input
														onChange={(e) => handleTagChange(e, 'tags')} 
														value={tagVal} ref={tags} name="hashtags" type="text" placeholder="#" onFocus={(e) => handleKeyPress(e)} onKeyPress={(e) => handleKeyPress(e)} style={{width: "100%", fontWeight: "bold"}} onKeyDown={(e) => handleKeyPress(e)} />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* <div className="picture-phone-set">
								<div className="mobile-preview">
									<img src={require("../../../assets/images/img_mobile_larg.png").default} alt="img" />

									<div className="top-mike"><img src={require("../../../assets/images/img_mobile_topbar.png").default} /><img className="mike-set" src={require("../../../assets/images/img_mobile_mike_larg.png").default} /></div>


									<div className="mobile-data mobile-change-views">
										<div className="community-mobile-banner">
										<img src={(campaignData.media || campaignData.attachment_url) ? (campaignData.media ? URL.createObjectURL(campaignData.media) : campaignData.attachment_url) : require("../../../assets/images/img_dummy.png").default} className="image_to_upload" />
										</div>

										<h5>
											<img src={require('../../../assets/images/icon_arrow_left_white.svg')} />
											<p>{campaignData.name}</p>
										</h5>

										<div className="mobile-tabs-details">
											<h4>Campaign brief</h4>
											<p>{campaignData.description}</p>

											<div className={isEmpty(campaignData.caption) ? 'hidden' : ''}>
												<h4>Caption</h4>
												<p>{campaignData.caption}</p>
											</div>
											<hr/>

											<table>
												<tbody>
													<tr>
														<td className="padding-right08">
															<h4>Platform</h4>
															<p>{campaignData.platform_ids ? platforms.filter(platform => campaignData.platform_ids.indexOf(platform.id) > -1).map(a => a.name).join(',') : ''}</p>
														</td>
														<td className="padding-left08">
															<h4>Photos</h4>
															<p>1</p>
														</td>
													</tr>
													<tr>
														<td className={isEmpty(campaignData.specification) ? 'hidden' : 'padding-right08'}>
															<h4>Bio</h4>
															<p>{campaignData.specification}</p>
														</td>
														<td className="padding-left08">
															<h4>@ and #</h4>
															<p>{formattedTags(campaignData.mention_tags, 'mention_tags')} {formattedTags(campaignData.hashtags, 'hashtags')}</p>
														</td>
													</tr>
													<tr>
														<td className="padding-right08">
															<h4>Apply latest</h4>
															<p>{campaignData['apply_date'] ? new Date(campaignData['apply_date']).toDateString() : ''}</p>
														</td>
														<td className="padding-left08">
															<h4>Request answer latest  </h4>
															<p>{campaignData['select_reject_date'] ? new Date(campaignData['select_reject_date']).toDateString() : ''}</p>
														</td>
													</tr>
													<tr>
														<td className="padding-right08">
															<h4>Draft uploaded latest</h4>
															<p>{campaignData['draft_upload_date'] ? new Date(campaignData['draft_upload_date']).toDateString() : ''}</p>
														</td>
														<td className="padding-left08">
															<h4>Post on channel</h4>
															<p>{campaignData['draft_approved_date'] ? new Date(campaignData['draft_approved_date']).toDateString() : ''}</p>
														</td>
													</tr>
													<tr>
														<td className="padding-right08">
															<h4>Reward</h4>
															<p>{campaignData.reward}</p>
														</td>
													</tr>
												</tbody>
											</table>

											<hr className="mt-zero" />
											<h4>Terms</h4>
											<p>{campaignData.terms}</p>
										</div>
									</div>

								</div>
							</div> */}

						</div>

						<div className="width-650">
							{/*<h4 className="h4basic03">Type of post</h4>
							<div className="post-picture-type">
								<ul>
									<li>
										<a href="#">
											<h5>Picture</h5>
											<figure>
												<img src={require("../../../assets/images/img_picture.png").default} />
											</figure>
										</a>
									</li>
									<li className="hide" style={{maxWidth: "177px"}}>
										<a href="#">
											<h5>Picture</h5>
											<figure>
												<img src={require("../../../assets/images/img_carousel.png").default} />
											</figure>
										</a>
									</li>
									<li className="hide">
										<a href="#">
											<h5>Picture</h5>
											<figure>
												<img src={require("../../../assets/images/img_video.png").default} />
											</figure>
										</a>
									</li>
									<li className="hide">
										<a href="#">
											<h5>Picture</h5>
											<figure>
												<img src={require("../../../assets/images/img_story.png").default} />
											</figure>
										</a>
									</li>
									<li className="hide" style={{maxWidth: "177px"}}>
										<a href="#">
											<h5>Picture</h5>
											<figure>
												<img src={require("../../../assets/images/img_picture.png").default} />
												<img src={require("../../../assets/images/img_story.png").default} />
											</figure>
										</a>
									</li>
								</ul>
							</div>

							<h4 className="h4basic03">Type of draft</h4>
							<div className="post-picture-type">
								<ul>
									<li>
										<a href="#">
											<h5>Single picture</h5>
											<figure>
												<img src={require("../../../assets/images/img_picture.png").default} />
											</figure>
										</a>
									</li>
									<li className="hide" style={{maxWidth: "177px"}}>
										<a href="#">
											<h5>3 pictures draft</h5>
											<figure>
												<img src={require("../../../assets/images/img_3_picture.png").default} />
											</figure>
										</a>
									</li>
									<li className="single-line-li"><p>*You will be able to request the icon make changes to their draft</p></li>
								</ul>
							</div>*/}

							<h4 className="h4basic03">Campaign dates{/*<a href="#"><img src={require("../../../assets/images/icon_question_mark.svg")} alt="img" /></a>*/}</h4>
						</div>

						<div className="campaign-dates-order">
							{/*<div className="date-path">
								<h6>Select:</h6>
								<ul>
									<li onClick={(e) => setActiveDate('apply_date')} className={`circle-set ${activeDate === 'apply_date' ? 'circle-set-border' : ''}`}><span className="circle-apply-latest apply_date"></span>Apply latest</li>
									<li onClick={(e) => setActiveDate('select_reject_date')} className={`circle-set ${activeDate === 'select_reject_date' ? 'circle-set-border' : ''}`}><span className="circle-approve-declined"></span>Approved/declined latest</li>
									<li onClick={(e) => setActiveDate('draft_upload_date')} className={`circle-set ${activeDate === 'draft_upload_date' ? 'circle-set-border' : ''} `}><span className="circle-draft"></span>Draft latest </li>
									<li onClick={(e) => setActiveDate('draft_approved_date')} className={`circle-set ${activeDate === 'draft_approved_date' ? 'circle-set-border' : ''}`}><span className="circle-contact"></span>Content uploaded</li>
								</ul>
							</div>*/}
							<div class="campaign-dates-order-status">
				  			<ul>
				  				<li onClick={(e) => setActiveDate('apply_date')} className={`${activeDate === 'apply_date' ? 'circle-apply-latest apply_date' : ''}`}>
				  					<div className={`${activeDate === 'apply_date' ? 'date-numbers calender-text-style' : 'date-numbers'}`} >{campaignData.apply_date  ? moment(campaignData.apply_date).format('DD') : ''}</div>
				  					<div className={`${activeDate === 'apply_date' ? 'status-year-month calender-text-style' : 'status-year-month'}`}>
				  						<h1>{campaignData.apply_date ? moment(campaignData.apply_date).format('MMMM YYYY') : ''}</h1>
				  						<h6>Apply latest</h6>
				  					</div>
				  				</li>
				  				<li onClick={(e) => setActiveDate('select_reject_date')} className={`${activeDate === 'select_reject_date' ? 'circle-approve-declined' : ''}`}>
				  					<div className={`${activeDate === 'select_reject_date' ? 'date-numbers calender-text-style' : 'date-numbers'}`}>{campaignData.select_reject_date  ? moment(campaignData.select_reject_date).format('DD') : ''}</div>
				  					<div className={`${activeDate === 'select_reject_date' ? 'status-year-month calender-text-style' : 'status-year-month'}`}>
				  						<h1>{campaignData.select_reject_date ? moment(campaignData.select_reject_date).format('MMMM YYYY') : ''}</h1>
				  						<h6>Approved/declined latest</h6>
				  					</div>
				  				</li>
				  				<li onClick={(e) => setActiveDate('draft_upload_date')} className={`${activeDate === 'draft_upload_date' ? 'circle-draft' : ''}`}>
				  					<div className={`${activeDate === 'draft_upload_date' ? 'date-numbers calender-text-style' : 'date-numbers'}`}>{campaignData.draft_upload_date ? moment(campaignData.draft_upload_date).format('DD') : ''}</div>
				  					<div className={`${activeDate === 'draft_upload_date' ? 'status-year-month calender-text-style' : 'status-year-month'}`}>
				  						<h1>{campaignData.draft_upload_date ? moment(campaignData.draft_upload_date).format('MMMM YYYY') : ''}</h1>
				  						<h6>Draft latest</h6>
				  					</div>
				  				</li>
				  				<li onClick={(e) => setActiveDate('draft_approved_date')} className={`${activeDate === 'draft_approved_date' ? 'circle-contact' : ''}`}>
				  					<div className={`${activeDate === 'draft_approved_date' ? 'date-numbers calender-text-style' : 'date-numbers'}`}>{campaignData.draft_approved_date ? moment(campaignData.draft_approved_date).format('DD') : ''}</div>
				  					<div className={`${activeDate === 'draft_approved_date' ? 'status-year-month calender-text-style' : 'status-year-month'}`}>
				  						<h1>{campaignData.draft_approved_date ? moment(campaignData.draft_approved_date).format('MMMM YYYY') : ''}</h1>
				  						<h6>Content uploaded</h6>
				  					</div>
				  				</li>				
				  			</ul>
				  		</div>
							{
								activeDate && (
									<div className="DatePickerCalendar-hide">
										<div className={`date-view ${activeDate}`}>
											
											<CalendarField
												key={campaignData[activeDate]}
												type={activeDate}
												value={campaignData[activeDate] ? new Date(campaignData[activeDate]) : new Date()}
												onChange={(value, type) => handleDateSelect(value, type)}
												display="none"
											/>
											{
												activeDate === 'apply_date' && (
													<div id="calendar-field__container">
														<DatePickerCalendar date={campaignData[activeDate] ? new Date(campaignData[activeDate]) : new Date()} onDateChange={(value, type) => handleDateSelect(value, activeDate)} locale={enGB} id="calendar-field__container"/>
													</div>
												)
											}
											{
												activeDate === 'select_reject_date' && (
													<div id="calendar-field__container">
														<DatePickerCalendar date={campaignData[activeDate] ? new Date(campaignData[activeDate]) : new Date()} onDateChange={(value, type) => handleDateSelect(value, activeDate)} locale={enGB} id="calendar-field__container"/>
													</div>
												)
											}
											{
												activeDate === 'draft_upload_date' && (
													<div id="calendar-field__container">
														<DatePickerCalendar date={campaignData[activeDate] ? new Date(campaignData[activeDate]) : new Date()} onDateChange={(value, type) => handleDateSelect(value, activeDate)} locale={enGB} id="calendar-field__container"/>
													</div>
												)
											}
											{
												activeDate === 'draft_approved_date' && (
													<div id="calendar-field__container">
														<DatePickerCalendar 
														date={campaignData[activeDate] ? 
														new Date(campaignData[activeDate]) : 
														new Date()} onDateChange={(value, type) => handleDateSelect(value, activeDate)} 
														locale={enGB} 
														id="calendar-field__container"/>
													</div>
												)
											}
										</div>
									</div>
								)
							}
						</div>

						<div className="width-400">
							<h4 className="h4basic03">Run duration</h4>

							<div className="form-set campaign-budget-form">
								<Field
									type="number"
									name="run_duration"
									onBlur={handleChange} 
									required={true}
									value={campaignData.run_duration}
									containerClass="form-inputs"
								>
								</Field>
							</div>
						</div>

						<div className="width-650">

							<h4 className="h4basic03">Terms</h4>

							<div className="terms-labels">
								<div className="radio-check">
									<label className="container-checkbox">Standard
										<input type="radio" name="terms_type" checked={campaignData.terms_type === 'Standard'} onClick={(e) => updateCampaignData('terms_type', 'Standard')} />
										<span className="checkmark"></span>
									</label>
									
									<label className="container-checkbox">Write your own or add something 
										<input type="radio" name="terms_type" checked={campaignData.terms_type === 'Write your own or add something'} onClick={(e) => updateCampaignData('terms_type', 'Write your own or add something')}/>
										<span className="checkmark"></span>
									</label>
								</div>
								<TextArea
									classes="label-text"
									name="terms"
									/*key={Math.random()}*/
									value={termsData}
									onBlur={handleChange}
									readonly={campaignData.terms_type === 'Standard'}
								/>
							</div>

							{/*<h4 className="h4basic03">Reward</h4>
							<div className="terms-labels">
								<div className="radio-check">
									<label className="container-checkbox">Social media value
									  <input type="radio" name="reward" checked={campaignData.reward === 'Social media value'} onChange={() => {}} onClick={(e) => updateCampaignData('reward', 'Social media value')}/>
									  <span className="checkmark"></span>
									</label>
									<label className="container-checkbox">Product 
									  <input type="radio" name="reward" checked={campaignData.reward === 'Product'} onChange={() => {}} onClick={(e) => updateCampaignData('reward', 'Product')}/>
									  <span className="checkmark"></span>
									</label>
									<label className="container-checkbox">Social media value + Product 
									  <input type="radio" name="reward" checked={campaignData.reward === 'Social media value + Product'} onChange={() => {}} onClick={(e) => updateCampaignData('reward', 'Social media value + Product')}/>
									  <span className="checkmark"></span>
									</label>
								</div>
							</div>*/}
							<div className="campaign-status action-bar-status">
								<b>Current Status</b>&nbsp;&nbsp;<span className={`${campaignData.status}`}>  {campaignData.status}</span>
							</div>
							<div className="all-submittion">
								<ul>
									{((campaignData.status === "" || campaignData.status === 'draft') && !checkValues ) ? <li><a className="draft" style={{ textTransform: "none" }} data-status="draft" onClick={createCampaign}>Save as draft</a></li> : null }
									{campaignData.id &&	<SendOutNotification communities={communities} community_ids={campaignData.community_ids} slug={campaignData.slug}/>}
									{
										!checkValues ? (
											<li ><a className="redirect-link" data-status="active" onClick={createCampaign} >Set live</a></li>
										) : '' }
								</ul>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	)
}