import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { iconFeedback } from '../../Redux/Actions/Icons';
import { setLoader } from '../../Redux/Actions/Loader';
import { setIconFeedbackFilterData, iconFeedbackSetSearchValue } from '../../Redux/Actions/Icons';
import { NotificationManager } from 'react-notifications';
import Pagination from '../Common/Pagination';
import './IconList.css';
import { createMarkup } from "../../utils/common";
import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal'

export default () => {
	const dispatch = useDispatch();
	const [feedbackList, setfeedbackList] = useState([]);
	const [searchText, setSearchText] = useState(useSelector(state => state.iconFeedbackListSearchText));
	const [visible, setvisible] = useState(false);
	const [message, setMessage] = useState({});
	const initialFilters = useSelector(state => state.iconFeedbackFilters)
	const [paginationData, setpaginationData] = useState(initialFilters)
	let [isSearchPage, setSerachPage] = useState(0)

	useEffect(() => {
		setpaginationData(initialFilters)
	}, [initialFilters])

	useEffect(async () => {
		if(searchText === '') 
			await dispatch(setLoader(true))
		getFeedbackList(paginationData.current_page);
	}, [searchText]);

	const changePage = (current_page, sort_by = null, sort_order = null)=> {
		if(searchText && searchText !== ''){
			setSerachPage(isSearchPage++)
		}
		getFeedbackList(current_page, sort_by,sort_order)
	}

	const getFeedbackList = async (current_page, sort_by = null, sort_order = null) => {
		if(searchText && searchText !== ''){ 
			if(isSearchPage > 0){
				current_page = current_page ? current_page : paginationData.current_page
			}else{
				current_page = 1
			}
		}

    const page = "page=" + (current_page ? current_page : paginationData.current_page)
    const sortBy = "&sort_by=" + (sort_by ? sort_by : paginationData.sort_by)
    const sortOrder = "&sort_order=" + (sort_order ? sort_order : paginationData.sort_order)
    const search = "&search=" + searchText
    const data = page + sortBy + sortOrder + search
    const response = await dispatch(iconFeedback(data));
		if (response.success) {
			await dispatch(setLoader(false));
			setfeedbackList(response.data.data);
			dispatch(setIconFeedbackFilterData({
				...paginationData,
				per_page: response.data.per_page,
				current_page: response.data.current_page,
				total_count: response.data.total_count,
				total_pages: response.data.total_pages,
				sort_order: sort_order ? sort_order : paginationData.sort_order,
				sort_by: sort_by ? sort_by : paginationData.sort_by
			}))
			// setpaginationData(prevState => ({
			// 	...prevState,
			// 	per_page: response.data.per_page,
			// 	current_page: response.data.current_page,
			// 	total_count: response.data.total_count,
			// 	total_pages: response.data.total_pages
			// }))
		} else {
				await dispatch(setLoader(false));
			NotificationManager.error(response.message, '', 10000, () => {})
		}
	}

	const searchHandle = async (event) => {
		await setSearchText(event.target.value)
		dispatch(iconFeedbackSetSearchValue(event.target.value))
	}


	const applySorting = (type) => {
		const sortOrder = paginationData.sort_by === type ? paginationData.sort_order === 'asc' ? 'desc' : 'asc' : 'asc'
		
		dispatch(setIconFeedbackFilterData({
			...paginationData,
			sort_by: type,
			sort_order: sortOrder || ''			
		}))
		// setpaginationData(prevState => ({
		// 	...prevState,
		// 	sort_by: type,
		// 	sort_order: sortOrder
		// }))
		getFeedbackList(paginationData.current_page, type, sortOrder);
	}

	const sortedClasses = (type) => {
		var sortClasses = classNames(
			{ [paginationData.sort_order]: paginationData.sort_by === type }
		)
		return sortClasses
	}

	const closeModel = () => {
		setvisible(false)
	}
	const openModel = (feedback) => {
		setvisible(true);
		setMessage(feedback);
	}

	const getFeedbackMessage = () => {
		return(
			<Modal
			  show={visible}
			  onHide={() => closeModel()}
			  className="verify-modal"
			  aria-labelledby="contained-modal-title-vcenter"
			  centered
			>
			  <div className="data-modal-form">
			  	<h2 className="modal-head text-center">Feedback Message</h2>
			  	<h4 className="center-text">{getReasonWithDescription(message)}</h4>
			  	<div className="form-submittion">
			  		<button type="button" data-dismiss="modal" onClick={() => closeModel()}>CANCEL</button>
			  	</div>
			  </div>
			</Modal>
		)
	}

	const getReasonWithDescription = (feedback) => {
		if(feedback.reason.length > 0){
			return feedback.description ? feedback.reason.join(',').concat(",", feedback.description) : feedback.reason.join(',')
		}else{
			return feedback.description
		}

	}

	return (
		<div id="view-dashboard">
			{visible ? getFeedbackMessage() : null}

			<div className="inner-view-dashboard">
				<div className="organizations-list">
					<ul className="organizations-count">
						<li>
							<h1>{paginationData.total_count}</h1>
							<p>Creator feedback <strong>total</strong></p>
						</li>
					</ul>
				</div>

				<div className="user-table-list">
					<div className="search-input" style={{ margin: "0 0 30px" }}><input type="search" className="" name="searchText" placeholder="Search" value={searchText} onChange={searchHandle} /></div>
					<div className="table-responsive">
						<table className="table-column-sort">
							<tr>
								<th style={{ minWidth: "150px" }}><div className="head-sort" onClick={(e) => applySorting('created_at')}>TIME <a href="javascript:void(0)" className="table-sort" className={sortedClasses('created_at')}></a></div></th>
								<th style={{ minWidth: "150px" }}><div className="head-sort" onClick={(e) => applySorting('full_name')}>FULL NAME <a href="javascript:void(0)" className="table-sort" className={sortedClasses('full_name')}></a></div></th>
								<th style={{ minWidth: "150px" }}><div className="head-sort" onClick={(e) => applySorting('forum_type')}>TYPE <a href="javascript:void(0)" className="table-sort" className={sortedClasses('forum_type')}></a></div></th>
								<th style={{ minWidth: "210px" }}><div className="head-sort" onClick={(e) => applySorting('description')}>MESSAGE <a href="javascript:void(0)" className="table-sort" className={sortedClasses('description')}></a></div></th>
								<th style={{ width: "60px" }}></th>
							</tr>
							{feedbackList.map(feedback =>
								<tr key={Math.random()}>
									<td>{feedback.created_at}</td>
									<td>{feedback.icon_name}</td>
									<td>{feedback.forum_type}</td>
									<td>{getReasonWithDescription(feedback).substring(0,20)} {getReasonWithDescription(feedback).length > 20 ? "..." : null}</td>
									<td><a href='javascript:void(0)' onClick={() => openModel(feedback)}>View </a></td>
								</tr>
							)}
						</table>
					</div>
					{
						paginationData.total_pages > 1 && (
							<div className="padination-numbers">
								<Pagination
									current_page={paginationData.current_page}
									per_page={paginationData.per_page}
									total_count={paginationData.per_page*paginationData.total_pages}
									setpaginationData={changePage}
								/>
							</div>
						)
					}
				</div>
			</div>
		</div>
	)
}