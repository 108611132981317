import React from 'react';
import $ from "jquery";
import './style.scss';

const CustomSelet = (props) => {
	const elmt = React.useRef()
	
	React.useLayoutEffect(()=> {
		const $elmt = $(elmt.current)
		const handleChange = (e) => {
			props.onChange(e);
		}

		$elmt.on('change', handleChange)

		if(window.handleSelect) {
			const prevElement = document.querySelectorAll('div.custom-select');
			if(prevElement.length > 0) {
				for(var i=prevElement.length-1;i >= 0;i--){
					var selectNode = prevElement[i];
					if(selectNode){
						selectNode.remove()
					}
				}
			}

			window.handleSelect();
		}
		
		return () => {
			$elmt.off('change', handleChange);
		}
	}, [props.children])
	
	return (
		<select ref={elmt} {...props}>
			{props.children}
		</select>
	)
}

export default CustomSelet;