import React, { useEffect, useState, useRef } from 'react';
import Header from '../../../Components/Header/Header';
import SideBar from '../../../Components/SideBar/SideBar';
import IconCard from '../../../Components/IconCard/Card';
import PendingRequest from './PendingRequest/PendingRequest';
import Selected from './Selected/Selected';
import Draft from './Draft/Draft';
import Posted from './Posted/Posted';
import Pagination from '../../../Components/Common/Pagination';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom'
import { useQueryState } from 'use-location-state'
import { isEmpty, cloneDeep, orderBy, includes, sumBy, _ } from 'lodash';
import { fetchIconProfile, editAnIcon, iconDraftApprove, soloCheckAvailibityRequest, soloConfirmAvailibityRequest } from '../../../Redux/Actions/Icons';
import { logout, fetchCurrentUser } from '../../../Redux/Actions/Auth';
import { setActiveTab } from '../../../Redux/Actions/Community';
import { setCampaignOnBoardFilters, fetchCampaignApplications, selectAnIcon, removeAnIcon, unSelectIcon, setSelection, updateRequest, approveDrafts, approvePost, declineDrafts, updateDraftCaption, deleteCampaignIcon, rejectCampaignIcon, moveCampaignIcon, socialMediaValue, uploadDrafts, sendTabNotification } from '../../../Redux/Actions/Campaign';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationManager} from 'react-notifications';
import { setLoader } from '../../../Redux/Actions/Loader';
import Refresh from '../../../Components/Common/Refresh';
import './style.scss';

import ConfirmationAlert from '../../../Components/ConfirmationAlert/Alert';

import { getCode, getName, getData } from 'country-list'
import HeaderLogo from '../../../Components/Header/HeaderLogo';
import Modal from 'react-bootstrap/Modal'
import { Multiselect } from 'multiselect-react-dropdown'
import CustomSelect from "../../../Components/CustomSelect/Select";

const initialData = {
	data: []
}

const initialStatus = [
	{ key: 'pending', value: 'Pending' },
	{ key: 'shortlisted', value: 'Shortlisted' },
	{ key: 'availability_pending', value: 'Availability Pending' },
	{ key: 'availability_accepted', value: 'Availability Accepted' },
	{ key: 'availability_declined', value: 'Availability Declined' },
	{ key: 'selected', value: 'Selected' },
	{ key: 'draft_uploaded', value: 'Draft Uploaded' },
	{ key: 'draft_approved', value: 'Draft Approved' }
]
// const initialDrafts = {
// 	campaign_icon_id: '',
// 	draftIds: []
// }
const initialPageData = {per_page: '', current_page: 1, total_pages: '', total_count: '', sort_by: 'created_at', sort_order: 'asc'}
export default (props) => {
	let current_tab = window && window.location.href;
	current_tab = current_tab && current_tab.split('?');
	current_tab = current_tab && current_tab[1];
	current_tab = current_tab && current_tab.split(/[\#\=\&]/);
	current_tab = current_tab && current_tab[0];

	const [page, setPage] = useQueryState('page', 1)
	let initialPage = parseInt(page); // Default to page 1 if no page param found


	// Check if current_tab is one of the allowed values, if not, set it to 'pending'
	const allowedTabs = ['selected', 'draft', 'posted', 'pending'];
	if (!allowedTabs.includes(current_tab)) {
		current_tab = 'pending';
	}
	
	const initialFilter = useSelector(state => state.campaignOnBoardFilters);
	const [applicationData, setApplicationData] = useState(initialData);
	const initialSelectedTab = useSelector(state => state.activeOnBoardingTab);
	const [activeStatus, setActiveStatus] = useState(current_tab);
	const [searchVal, setSearchValue] = useState('');
	const [filterData, setFilterData] = useState(initialFilter);	
	const [paginationData, setpaginationData] = useState({...initialPageData, current_page: initialPage});
	const organization = useSelector(state => state.organization.organization);
	let attachment_url = organization && organization.attachment_url && organization.attachment_url[0] && organization.attachment_url[0].media && organization.attachment_url[0].media.url
	const dispatch = useDispatch();
	const [itemDrafts, setitemDrafts] = useState([]);	
	const history = useHistory();
	const [itemStories, setitemStories] = useState([]);	
	const [organizationDetails, setOrganizationDetails] = useState({});

	const [genderInsight, setGenderInsight] = useState([]);
	const [ageInsight, setAgeInsight] = useState([]);
	const [countryInsight, setCountryInsight] = useState([]);
	const [cityInsight, setCityInsight] = useState([]);

	const [iconAvailability, setIconAvailability] = useState(false);
	const [activeAlertType, setActiveAlertType] = useState('Availability');
	const [iconAvailabilityId, setIconAvailabilityId] = useState(null);

	const [confirmAvailability, setConfirmAvailability] = useState(false);
	const [confirmAlertType, setConfirmAlertType] = useState('Availability');
	const [confirmAvailabilityId, setConfirmAvailabilityId] = useState(null);
	const [checkConfirm, setCheckConfirm] = useState(false);
	const [checkAvailable, setCheckAvailable] = useState(false);


	const [deleteCampaignAlert, setDeleteCampaignAlert] = useState(false);
	const [deleteCampaignType, setDeleteCampaignType] = useState('');
	const [deleteCampaignId, setDeleteCampaignId] = useState(null);
	const [checkDelete, setCheckDelete] = useState(false);

	const [rejectCampaignAlert, setRejectCampaignAlert] = useState(false);
	const [rejectCampaignType, setRejectCampaignType] = useState('');
	const [rejectCampaignId, setRejectCampaignId] = useState(null);
	const [checkReject, setCheckReject] = useState(false);

	const [moveIconAlert, setMoveIconAlert] = useState(false);
	const [moveIconStatus, setMoveIconStatus] = useState('');
	const [moveIconId, setMoveIconId] = useState(null);
	const [checkMove, setCheckMove] = useState(false);

	const [socialMediaAlert, setSocialMediaAlert] = useState(false);
	const [socialMediaData, setSocialMediaData] = useState({});
	const [socialMediaId, setSocialMediaId] = useState(null);
	const [checkSocial, setCheckSocial] = useState(false);
	const [errors, setErrorMsg] = useState([]);

	const [sendNotificationAlert, setSendNotificationAlert] = useState(false);	
	const [tabStatus, setTabStatus] = useState([]);
	const [selectedTab, setSelectedTab] = useState([]);
  const [notificationDescription, setNotificationDescription] = useState('');
  const [notificationTitle, setNotificationTitle] = useState('');


	useEffect(() => {
		setActiveStatus(current_tab)
	}, [current_tab])

	useEffect(async () => {
		// Set Campaign as active tab
		dispatch(setActiveTab('Campaigns'))
		await dispatch(setLoader(true));
		// Fetch campaign Applcations
		fetchCampaignApplicationsData({}, initialPage)

		// Fetch current user
		await getCurrentUser()

	}, [activeStatus, paginationData.sort_order, paginationData.sort_by, filterData]);

	const getCurrentUser = async() => {
		const token = localStorage.getItem('manageToken')
		const response = await dispatch(fetchCurrentUser(token));
		if(response.data && response.data.status === 'success'){
			setOrganizationDetails(response.data.data)
		}
	}

	const fetchCampaignApplicationsData = async (data = {}, page = paginationData.current_page) => {
		setGenderInsight([])
		setAgeInsight([])
		setCountryInsight([])
		setCityInsight([])
		
		data = {...data, ...filterData}
		let clickEvent = localStorage && localStorage.getItem('buttonClick')
		data.page = page;
		if(clickEvent && clickEvent !== null){
			data.search = '';
		}else {
			data.search = searchVal;
		}
		localStorage && localStorage.removeItem('buttonClick')
		data.sort_by = paginationData.sort_by
		data.sort_order = paginationData.sort_order
		const response = await dispatch(fetchCampaignApplications(props.match.params.id, activeStatus, data))

		if (response.data.status === "error"){
			NotificationManager.error(response.data.message);
		} 
		let itemDraftData = []
		let itemStoriesData = []
		if(response.status === 'success')
			response.data.map(record => {
				if(record.status !== 'posted'){
					let result = record.stories && record.drafts.filter(item => item.status == 'approved')
					if(result && result.length > 0){
						var draftIds = []
						result.map(item => { draftIds.push(item.id) })
						let data = { campaign_icon_id: record.icon.id, draftIds: draftIds }
						itemDraftData.push(data)
					}

					let resultStories = record.stories ? record.stories.filter(item => item.status == 'approved') : []
					if(resultStories && resultStories.length > 0){
						var storiesIds = []
						resultStories.map(item => { storiesIds.push(item.id) })
						let data = { campaign_icon_id: record.icon.id, draftIds: storiesIds }
						itemStoriesData.push(data)
					}
				}
			})
		setCheckConfirm(false)
		setCheckAvailable(false)
		setCheckDelete(false)
		setCheckReject(false)

		setitemDrafts([...itemDraftData])
		setitemStories([...itemStoriesData])
		setApplicationData(response)
		setpaginationData(prevState => ({
			...prevState,
			per_page: response.per_page,
			current_page: response.current_page,
			total_count: response.total_count,
			total_pages: response.total_pages
		}))
		//======================================== Strat Insight data for graph =====================================//
		
		let genderData = response && response.insight_data && response.insight_data.age_gender_distribution_per && response.insight_data.age_gender_distribution_per.gender_distribution_data
		

		let ageData = response && response.insight_data && response.insight_data.age_gender_distribution_per && response.insight_data.age_gender_distribution_per.age_distribution_data
		
		let countryData = response && response.insight_data && response.insight_data.country_distribution 
		let cityData = response && response.insight_data && response.insight_data.city_distribution 

		// Gender Data
		let genderArr = [
			['Gender', { role: 'annotation' }]
		]

		let totalGender = 0

		if(genderData){
			genderData = preferredOrder(genderData, [
			    "M",
			    "F",
			    "U"
			]);

		}

		for(var key in genderData) {
			let name = ''
			if(key === 'M'){
				name = 'Male'
			} else if(key === 'F'){
				name = 'Female'
			} else if(key === 'U') {
				name = 'Prefer not to say'
			}
			let obj = [
				name, genderData[key], 
			]
			genderArr.push(obj)
	  }

	  setGenderInsight(genderArr)
	  // Age Data
		let ageRawArr = []
		let arrangeAge = []
		let totalFollowerAge = 0
		for(var key in ageData) {
			let split_1 = key.split('.')
			if(!arrangeAge.includes(split_1[1])){
				arrangeAge.push(split_1[1])
			}
			let obj = {
				type: split_1[0],
				name: split_1[1],
				value: ageData[key]
			}
			ageRawArr.push(obj)
    }
    let customizeAge = []
    let finalAgeData = []
    ageRawArr.map(a_item=> {
    	if(arrangeAge.includes(a_item.name)){
	    	let itemIndex = finalAgeData.findIndex(o => o.name == a_item.name)
	    	if(itemIndex > -1){
	    		finalAgeData[itemIndex].totalAge = finalAgeData[itemIndex].totalAge+a_item.value
	    		totalFollowerAge = totalFollowerAge+a_item.value
	    		let customArr = finalAgeData[itemIndex].data
	    		customArr.push({	type:a_item.type, value:a_item.value})
	    		finalAgeData[itemIndex].data = customArr
	    	}else{
	    		totalFollowerAge = totalFollowerAge+a_item.value
	    		finalAgeData.push({
	    			name:a_item.name,
	    			totalAge: a_item.value,
	    			data: [{type:a_item.type, value:a_item.value }]
	    		})
	    	}
    	}
    })

    finalAgeData = orderBy(finalAgeData, ['name'],['asc'])

    let ageGraphData = [
    	['Age', 'Male', { role: 'tooltip'}, 'Female', { role: 'tooltip'}]
    ]
		finalAgeData.map(r_item=> {
			let maleObj = r_item.data.find(o => o.type === 'M');
			let femaleObj = r_item.data.find(o => o.type === 'F');
			let malePer =  maleObj ? (maleObj.value).toFixed(1)+'%' : "0%"
			let femalePer =  femaleObj ? (femaleObj.value).toFixed(1)+'%' : "0%"
			let obj = [
				r_item.name, maleObj ? maleObj.value : 0,   malePer ,femaleObj ? femaleObj.value : 0, femalePer
			]
			ageGraphData.push(obj)
    })

		setAgeInsight(ageGraphData)

	  // Country Data
		let countryArr = [
			['Country', 'Percentage', { role: 'tooltip' }]
		]

		let totalCountry = 0
		let countries = []
		for(var key in countryData) {
			let obj = {
				name: getName(key),
				value: countryData[key]
			}
			countries.push(obj)
			totalCountry = countryData[key]+totalCountry
    }

    countries = orderBy(countries, ['value'],['desc'])

		let countrySize = 0
		for(var key in countryData) {
			countrySize++
    }

		// Get the array of keys
		countries.map(country_item=> {
			let obj = [
				country_item.name, country_item.value, (country_item.value).toFixed(1)+"%"
			]
			countryArr.push(obj)
		})

	  if(countryArr.length > 1){
	  	setCountryInsight(countryArr.slice(0, 11))
	  }

	  // City Data
		let cityArr = [
			['City', 'Percentage', { role: 'tooltip' }]
		]

		let totalCity = 0
		let cities = []
		let commonCities = []
		for(var key in cityData) {
			let obj = {
				name: key,
				value: cityData[key]
			}
			cities.push(obj)
    }
    cities = orderBy(cities, ['value'],['desc'])
    cities.map(city_item=> {
    	let obj = [
    		city_item.name, city_item.value, (city_item.value).toFixed(1)+"%"
    	]
    	cityArr.push(obj)
    })

    if(cityArr.length > 1){
    	setCityInsight(cityArr.slice(0, 11))
    }

		//======================================== End Insight data for graph =====================================//

		await dispatch(setLoader(false));
	}

	const preferredOrder=(obj, order)=> {
	    var newObject = {};
	    for(var i = 0; i < order.length; i++) {
	        if(obj.hasOwnProperty(order[i])) {
	            newObject[order[i]] = obj[order[i]];
	        }
	    }
	    return newObject;
	}

	const resetFilters = () => {
		setApplicationData(initialData);
		setSearchValue('');
		setpaginationData(prevState => ({
			...prevState,
			current_page: 1,
			sort_by: 'created_at',
			sort_order: 'asc'
		}))
	}

	const handleTabChange = async (e, type) => {
		e.preventDefault();
		if(activeStatus != type) {
			history.push(`/campaigns/${props.match.params.id}/on-boarding?${type}`)
			await dispatch(setSelection(type))
			resetFilters();
			setGenderInsight([])
			setAgeInsight([])
			setCountryInsight([])
			setCityInsight([])
		}
	}

	const handleLogout = (e) => {
		e.preventDefault();
		dispatch(logout());
		props.history.go()
	}

	const handlePageChange = async (page) => {
		await setpaginationData(prevState => ({
			...prevState,
			current_page: page
		}));

		await fetchCampaignApplicationsData({}, page);
		setPage(page)
	}

	const applyFilters = async (e, data = {}) => {
		// TO DO - make more generic function
		e.preventDefault();
		setFilterData(data)
		await dispatch(setCampaignOnBoardFilters(data))
	}

	const clearFilters = async (e, data = {}) => {
		// TO DO - make more generic function
		e.preventDefault();
		data = {}
		setFilterData(data)
		await dispatch(setLoader(true));
		await dispatch(setCampaignOnBoardFilters(data))
	}

	const sortedClasses = (type) => {
	    var sortClasses = classNames(
			{'sort-activer': paginationData.sort_by === type},
			{[paginationData.sort_order]: paginationData.sort_by === type}
		)
		return sortClasses
	}

	const applySorting = (type) => {
		const sortOrder = paginationData.sort_by === type ? paginationData.sort_order === 'asc' ? 'desc' : 'asc' : 'asc'
		setpaginationData(prevState => ({
			...prevState,
			sort_by: type,
			sort_order: sortOrder
		}))
	}

	const handleIconSelect = async (e, id) => {
		e.preventDefault();
		const data = {
			campaign_id: props.match.params.id,
			icon_id: id
		}
		const response = await dispatch(iconDraftApprove(data))
		const isSuccess = response.status === 200
		isSuccess ? NotificationManager.success(response.data.message) : NotificationManager.error(response.data.error ||  response.data.message, '', 10000, () => {})
		if (isSuccess)
			handleAfterIconUpdate(id, 'draft_approved')
	}

	const handleEditRequest = async (data) => {
		data['campaign_id'] = props.match.params.id
		const response = await dispatch(editAnIcon(data))
		response.status === 200 ? NotificationManager.success(response.data.message) : NotificationManager.error(response.data.error ||  response.data.message, '', 10000, () => {})
	}

	const handleAfterIconUpdate = async (value, status) => {
		let newState = cloneDeep(applicationData)
		let iconRecord = newState.data.find(a => a && a.id === value)
		iconRecord.status = status
		setApplicationData(newState)
		await fetchCampaignApplicationsData({}, paginationData.current_page ? paginationData.current_page : 1);

	}

	const handleSelect = async (value, event) => {
		event.preventDefault();
		const data = {
			campaign_id: props.match.params.id,
			campaign_icon_id: value
		}

		const response = await dispatch(selectAnIcon(data))

		const success = (response.status === 200)
		success ? NotificationManager.success(response.data.message) : NotificationManager.error(response.data.message, '', 10000, () => {})
		if (success)
			handleAfterIconUpdate(value, 'selected')
	}

	const handleUnSelect = async (value, event) => {
		event.preventDefault();
		const data = {
			campaign_id: props.match.params.id,
			campaign_icon_id: value
		}
		const response = await dispatch(unSelectIcon(data))

		const success = (response.status === 200)
		// success ? NotificationManager.success(response.data.message) : NotificationManager.error(response.data.error)
		if (success)
			handleAfterIconUpdate(value, 'draft')
	}

	const handleRemove = async (value, event) => {
		event.preventDefault();
		const data = {
			campaign_id: props.match.params.id,
			campaign_icon_id: value
		}
		const response = await dispatch(removeAnIcon(data))

		const success = (response.status === 200)
		success ? NotificationManager.success(response.data.message) : NotificationManager.error(response.data.message, '', 10000, () => {})
		if (success)
			handleAfterIconUpdate(value, 'pending')
	}

	const draftRequest = async (data = {}) => {
		data.campaign_id = props.match.params.id
		const response = await dispatch(updateRequest(data))
		response.status === 200 ? NotificationManager.success(response.data.message) : NotificationManager.error(response.data.error ||  response.data.message)
		await fetchCampaignApplicationsData({}, paginationData.current_page ? paginationData.current_page : 1);
	}


	const draftApproved = async (data = {}, type='') => {
		data.campaign_id = props.match.params.id
		const response = await dispatch(approveDrafts(data, type))
		response.status === 200 ? NotificationManager.success(response.data.message) : NotificationManager.error(response.data.error ||  response.data.message)
		await fetchCampaignApplicationsData({}, paginationData.current_page ? paginationData.current_page : 1);
	}

	const draftDecline = async (data = {}, type='') => {
		data.campaign_id = props.match.params.id
		const response = await dispatch(declineDrafts(data, type))
		response.status === 200 ? NotificationManager.success(response.data.message) : NotificationManager.error(response.data.error ||  response.data.message)
		await fetchCampaignApplicationsData({}, paginationData.current_page ? paginationData.current_page : 1);
	}

	const postApproved = async(data = {}) => {
		data.campaign_id = props.match.params.id
		const response = await dispatch(approvePost(data))
		response.status === 200 ? NotificationManager.success(response.data.message) : NotificationManager.error(response.data.error ||  response.data.message)
		await fetchCampaignApplicationsData({}, paginationData.current_page ? paginationData.current_page : 1);
	}

	const handleCheckAvailability = async(icon_id, e)=> {
		setIconAvailabilityId(icon_id)
		setIconAvailability(true);
		setActiveAlertType('Availability');
	}

	const handleConfirmAvailability = async(icon_id, e)=> {
		setConfirmAvailabilityId(icon_id)
		setConfirmAvailability(true);
		setConfirmAlertType('Availability');
	}

	const closeAlert = (type = '') => {
		let data = {
			campaign_id: props.match.params.id,
			icon_id: iconAvailabilityId
		}
		if(type === 'YES'){
			submitIconAvailability(data)
		}	
		setIconAvailability(false);
		setActiveAlertType('Availability');
	}


	const closeConfirmAlert =(type = '')=> {
		let data = {
			campaign_id: props.match.params.id,
			campaign_icon_id: confirmAvailabilityId,
			status: 'availability_accepted'
		}

		if(type === 'YES'){
			submitConfirmAvailibity(data)
		}	
		setConfirmAvailability(false);
		setConfirmAlertType('Availability');
	}

	const handleDeleteCampaign = (icon_id, e)=> {
		setDeleteCampaignId(icon_id)
		setDeleteCampaignAlert(true);
	}

	const closeDeleteCampaignAlert = (type = '')=> {
		let data = {
			campaign_id: props.match.params.id,
			campaign_icon_id: deleteCampaignId,
		}
		if(type === 'YES'){
			submitDeleteCampaign(data)
		}	
		setDeleteCampaignId(null)
		setDeleteCampaignAlert(false);
	}

	const handleRejectCampaign = (icon_id, e)=> {
		setRejectCampaignId(icon_id)
		setRejectCampaignAlert(true);
	}

	const closeRejectCampaignAlert = (type = '')=> {
		let data = {
			campaign_id: props.match.params.id,
			campaign_icon_id: rejectCampaignId,
		}
		if(type === 'YES'){
			submitRejectCampaign(data)
		}	
		setRejectCampaignId(null)
		setRejectCampaignAlert(false);
	}

	const handleMoveCampaignIcon = (icon_id, e)=> {
		setMoveIconId(icon_id)
		setMoveIconAlert(true);
	}

	const handleIconChange = (e) => {

		setMoveIconStatus(e.target.value)
	}

	const handleIconRemove = (removeList, removeItem) => {
		setMoveIconStatus([])
	}

	const closeMoveCampaignIcon = (type= '')=> {
		setMoveIconId(null)
		setMoveIconAlert(false)
		setMoveIconStatus([])
	}
	const closeSendNotificationModel = (type= '')=> {
		setSendNotificationAlert(false)
	}

	const submitMoveCampaignIcon = ()=> {
		if(moveIconStatus){
			let data = {
				status: moveIconStatus,
				campaign_icon_id: moveIconId,
			}

			submitMoveIconCampaign(data)	
			setMoveIconId(null)
			setMoveIconAlert(false)
			setMoveIconStatus([])
		} else {
			NotificationManager.error('Please select value for status', '', 10000, () => {})
		}
	}

	const handleSocialMediaIcon = (engagement_id, e)=> {
		setSocialMediaId(engagement_id)
		setSocialMediaAlert(true)
	}

	const closeSocialMediaIcon = ()=> {
		setSocialMediaId(null)
		setSocialMediaAlert(false)
	}

	const handleChangeSocialValues = async(e)=> {
		let { name, value } = e.target
		await setSocialMediaData(prevState => ({
			...prevState,
			[name]: value
		}))
	}

	const submitSocialMediaData = async()=> {
		let { price, social_media_value, comment } = socialMediaData
		let data = {
			price,
			social_media_value,
			comment
		}
		if(validateInput(data)){
			let formdata = new FormData()
			for (const key in data) {
				formdata.append(`engagement[${key}]`, data[key]);
			}
			formdata.append(`engagement_id`, socialMediaId)
			setCheckSocial(true)
			const response = await dispatch(socialMediaValue(formdata))
			if(response.status === 200){
				NotificationManager.success(response.data.message)
				setSocialMediaId(null)
				setSocialMediaAlert(false)
				setErrorMsg([])
				await fetchCampaignApplicationsData({}, 1);
			}else{
				NotificationManager.error(response.data.error ||  response.data.message)
				setSocialMediaId(null)
				setSocialMediaAlert(false)
				setErrorMsg([])
			}
		}
	}

	const submitIconAvailability = async(data)=> {
		setCheckAvailable(true)
		const response = await dispatch(soloCheckAvailibityRequest(data))
		response.status === 200 ? NotificationManager.success(response.data.message) : NotificationManager.error(response.data.error ||  response.data.message)
		await fetchCampaignApplicationsData({}, paginationData.current_page ? paginationData.current_page : 1);
	}

	const submitConfirmAvailibity = async(data)=> {
		setCheckConfirm(true)
		const response = await dispatch(soloConfirmAvailibityRequest(data))
		response.status === 200 ? NotificationManager.success(response.data.message) : NotificationManager.error(response.data.error ||  response.data.message)
		await fetchCampaignApplicationsData({}, paginationData.current_page ? paginationData.current_page : 1);
	}
	
	const submitDeleteCampaign = async(data)=> {
		setCheckDelete(true)
		const response = await dispatch(deleteCampaignIcon(data))
		response.status === 200 ? NotificationManager.success(response.data.message) : NotificationManager.error(response.data.error ||  response.data.message)
		await fetchCampaignApplicationsData({}, paginationData.current_page ? paginationData.current_page : 1);
	}

	const submitRejectCampaign = async(data)=> {
		setCheckReject(true)
		const response = await dispatch(rejectCampaignIcon(data))
		response.status === 200 ? NotificationManager.success(response.data.message) : NotificationManager.error(response.data.error ||  response.data.message)
		await fetchCampaignApplicationsData({}, paginationData.current_page ? paginationData.current_page : 1);
	}

	const submitMoveIconCampaign = async(data)=> {
		setCheckMove(true)
		const response = await dispatch(moveCampaignIcon(data))
		response.status === 200 ? NotificationManager.success(response.data.message) : NotificationManager.error(response.data.error ||  response.data.message)
		await fetchCampaignApplicationsData({}, paginationData.current_page ? paginationData.current_page : 1);
	}

	const submitSentTabNotification = async(data) =>{
		closeSendNotification();
		const response = await dispatch(sendTabNotification(data))
		response.status === 200 ? NotificationManager.success(response.data.message) : NotificationManager.error(response.data.error ||  response.data.message)
	}

	const editCaptionData = async(data)=> {
		let data1 = {
			campaign_id: props.match.params.id,
			campaign_icon_id: data.campaign_icon_id,
			caption: data.caption
		}

		const response = await dispatch(updateDraftCaption(data1))
		response.status === 200 ? NotificationManager.success(response.data.message) : NotificationManager.error(response.data.error ||  response.data.message)
		await fetchCampaignApplicationsData({}, paginationData.current_page ? paginationData.current_page : 1);
	}

	const submitUploadDrafts = async(campaign_icon_id, data)=> {
		const response = await dispatch(uploadDrafts(campaign_icon_id, data))
		response.status === 200 ? NotificationManager.success(response.data.message) : NotificationManager.error(response.data.error ||  response.data.message)
		await fetchCampaignApplicationsData({}, paginationData.current_page ? paginationData.current_page : 1);
	}

	const validateInput = (data) => {
		const Errors = [];
		Object.keys(data).map(key => {
			if(isEmpty(data[key])) {
				Errors.push({
					name: key,
					value: `Please provide value for ${key}.`
				})
				setErrorMsg(Errors)
			}
		})
		return isEmpty(Errors);
	}

	const renderCommunitySelector = () => {
		return initialStatus.map(community => {
			return(
				<option key={Math.random()} value={community.key}>{community.value}
				</option>
			)
		})
	}

	const handleSendNotification = ()=> {
		loadTabStatus();
		setSendNotificationAlert(true);
	}

	const closeSendNotification = ()=> {
		setSendNotificationAlert(false);
		setNotificationDescription('');
		setNotificationTitle('');
	}

	const sendTabBasedNotification = ()=> {
		if(sendNotificationAlert){
			let data = {
				status: selectedTab,
				slug: props.match.params.id,
				tab: activeStatus,
				description: notificationDescription,
				title: notificationTitle
			}
			submitSentTabNotification(data);
		} else {
			NotificationManager.error('Please select value for status', '', 10000, () => {})
		}
	}
	const onSelect = (selectedList, selectedItem) => {
		console.log("selectedList:==========", selectedList)
    if(selectedList && selectedList.length > 0 ){
			selectedTab.push(selectedItem.id)
		}
    setSelectedTab([...selectedTab])
	}

	const onRemove = (selectedList, removedItem) => {
		selectedTab.splice(selectedTab.findIndex(a => a === removedItem.id) , 1);
    setSelectedTab([...selectedTab]);    
	}

	const loadTabStatus = () => {
		if(activeStatus === 'pending'){
			setTabStatus([{name: 'Pending', id: 'pending'}])
			setSelectedTab(['pending']);
		}
		else if(activeStatus === 'posted'){
			setTabStatus([
				{name: 'Posted', id: 'posted'},
				{name: 'Verified', id: 'Verified'}
			])
			setSelectedTab(['posted', 'verified']);
		}
		else if(activeStatus === 'selected'){
			setTabStatus([
				{name: 'Shortlisted', id: 'shortlisted'},
				{name: 'Availability Accepted', id: 'availability_accepted'},
				{name: 'Availability Declined', id: 'availability_declined'},
				{name: 'Availability Pending', id: 'availability_pending'}
			])
			setSelectedTab(['shortlisted', 'availability_accepted', 'availability_declined', 'availability_pending']);
		}
		else if(activeStatus === 'draft'){
			setTabStatus([
				{name: 'Selected', id: 'selected'},
				{name: 'Draft Uploaded', id: 'draft_uploaded'},
				{name: 'Draft Approved', id: 'draft_approved'}
			])
			setSelectedTab(['selected', 'draft_approved', 'draft_uploaded'])
		}
	}

	const renderCardMarkup = (showSelection = false, showStatus = false, showUnselect = false) => {
		if(applicationData.data.length > 0) {
			return applicationData.data.map(application => {
				return(
					<li key={Math.random()}>
						<IconCard 
							icon={application.icon} 
							iStatus={application.status} 
							engagements={application.engagements} 
							showSelection={showSelection} 
							showStatus={showStatus}
							showUnselect={showUnselect}
							handleUnSelect={handleUnSelect}
							handleSelect={handleSelect}
							handleRemove={handleRemove}
							handleCheckAvailability={handleCheckAvailability}
							handleConfirmAvailability={handleConfirmAvailability}
							main_campaign_id={application.id}
							handleDeleteCampaign={handleDeleteCampaign}
							handleRejectCampaign={handleRejectCampaign}
							handleMoveCampaignIcon={handleMoveCampaignIcon}
							handleSocialMediaIcon={handleSocialMediaIcon}
							platform_icons={application.platform_icon}
						/>
					</li>
				)
			})
		} else {
			return <li>No results.</li>
		}
	}

	

	return(
		<div id="wrapper">
			<Header
				containerClass="header-inner"
				logoClass="logo"
			>
				<div className="top-header-right">
					<h2 className="header-heading"><Link to="/Campaigns" className="back-to"><img src={require("../../../assets/images/icon_arrow_back.svg")} /></Link> Campaigns</h2>

					<div className="user-settings-menu">
						<Refresh />
						{/* <div className="dollar-menu select-default hide">
							<div className="dropdown default-dropdown">
							  <a href="javascript:void(0)" className="dropdown-toggle" type="button" dataToggle="dropdown">
							  	<img src={require("../../../assets/images/icon_currency.svg")} />
							  </a>

							  <ul className="dropdown-menu">
							    <li><a href="javascript:void(0)">Price 01</a></li>
							    <li><a href="javascript:void(0)">Price 02</a></li>
							  </ul>
							</div>
						</div> */}
{/* 
						<div className="notification-menu select-default">
							<div className="dropdown default-dropdown">
							  <a href="javascript:void(0)" className="dropdown-toggle" type="button" data-toggle="dropdown">
							  	<img src={require("../../../assets/images/icon_bell.svg")} />
							  </a>

							  <ul className="dropdown-menu">
							    <li><a href="javascript:void(0)"> Notification 01</a></li>
							    <li><a href="javascript:void(0)"> Notification 02</a></li>
							  </ul>
							</div>
						</div> 
						<div className="user-settings select-default">
							<div className="dropdown default-dropdown">
							  <a href="javascript:void(0)" className="dropdown-toggle" type="button" data-toggle="dropdown">
							  	<img src={organization.attachment_url} className="profile-user-icon" />
							  </a>
						
							  <ul className="dropdown-menu">
							    <li><a href="#" onClick={handleLogout}>logout</a></li>
							  </ul>
							</div>
						</div>*/}

						<HeaderLogo 
							containerClass="user-settings select-default"
							logoClass="dropdown default-dropdown"
							attachment_url={attachment_url}
							handleLogout={handleLogout}
						/>
					</div>
				</div>
			</Header>

			<main id="main">
				<div className="main-inner">
					<SideBar />
					<div id="view-dashboard">
						<div className="inner-view-dashboard">
							<div className="communities-content">
								<div className="tabs-with-mark">
									<ul className="nav nav-tabs default-tabs">
										<li onClick={(e) => handleTabChange(e, 'pending')} className={activeStatus === 'pending' && "active"}><a data-toggle="tab" href="#community_pending_request">Pending requests</a></li>
										<li onClick={(e) => handleTabChange(e, 'selected')} className={activeStatus === 'selected' && "active"}><a data-toggle="tab" href="#community_Selected">Select</a></li>
										<li onClick={(e) => handleTabChange(e, 'draft')} className={activeStatus === 'draft' && "active"}><a data-toggle="tab" href="#community_Drafts">Drafts</a></li>
										<li onClick={(e) => handleTabChange(e, 'posted')} className={activeStatus === 'posted' && "active"}><a data-toggle="tab" href="#community_Posted">Posted</a></li>
									</ul>
									{/*<a className="more-question" href="javascript:void(0)"><img src={require("../../../assets/images/icon_question_mark.svg")} alt="img" /></a>*/}
								</div>

								<div className="tab-content">
									<div id="community_pending_request" className={activeStatus === 'pending' ? "tab-pane fade in active" : "tab-pane fade"}>
										{
											activeStatus === 'pending' ? (
												<PendingRequest
													renderCardMarkup={renderCardMarkup}
													applyFilters={applyFilters}
													filterData={filterData}
													applicationData={applicationData}
													applySorting={applySorting}
													sortedClasses={sortedClasses}
													setSearchValue={setSearchValue}
													searchVal={searchVal}
													clearFilters={clearFilters}
							            handleSendNotification={handleSendNotification}
												/>
												) : null
										}
									</div>

								    <div id="community_Selected" className={activeStatus === 'selected' ? "tab-pane fade in active" : "tab-pane fade"}>
								    	{
								    		activeStatus === 'selected' ? (
												<Selected
													renderCardMarkup={renderCardMarkup}
													campaignId={props.match.params.id}
													applyFilters={applyFilters}
													filterData={filterData}
													applySorting={applySorting}
													applicationData={applicationData}
													sortedClasses={sortedClasses}
													setSearchValue={setSearchValue}
													searchVal={searchVal}
													clearFilters={clearFilters}
													genderInsight={genderInsight}
													ageInsight={ageInsight}
													countryInsight={countryInsight}
													cityInsight={cityInsight}
													handleSendNotification={handleSendNotification}
												/>
								    		) : null
								    	}
								    </div>

								    <div id="community_Drafts" className={activeStatus === 'draft' ? "tab-pane fade in active" : "tab-pane fade"}>
								    	{
								    		activeStatus === 'draft' ? (
												<Draft
													applicationData={applicationData}
													setApplicationData={setApplicationData}
													handleEditRequest={handleEditRequest}
													handleIconSelect={handleIconSelect}
													handleUnSelect={handleUnSelect}
													setSearchValue={setSearchValue}
													applySorting={applySorting}
													sortedClasses={sortedClasses}
													applyFilters={applyFilters}
													filterData={filterData}
													searchVal={searchVal}
													total_count={paginationData.total_count}
													draft_count={applicationData.total_draft_uploaded }
													clearFilters={clearFilters}
													draftRequest={draftRequest}
													draftApproved={draftApproved}
													postApproved={postApproved}
													itemDrafts={itemDrafts}
													genderInsight={genderInsight}
													ageInsight={ageInsight}
													countryInsight={countryInsight}
													cityInsight={cityInsight}
													itemStories={itemStories}
													draftDecline={draftDecline}
													editCaptionData={editCaptionData}
													handleDeleteCampaign={handleDeleteCampaign}
													handleRejectCampaign={handleRejectCampaign}
													handleMoveCampaignIcon={handleMoveCampaignIcon}
													handleSocialMediaIcon={handleSocialMediaIcon}
													cuurent_campaign_id={props.match.params.id}
													handleSendNotification={handleSendNotification}
													submitUploadDrafts={submitUploadDrafts}
												/>
							    			) : null
								    	}
								    </div>

								    <div id="community_Posted" className={activeStatus === 'posted' ? "tab-pane fade in active" : "tab-pane fade"}>
								    	{ activeStatus === 'posted' ? <Posted 
									    		applicationData={applicationData}
												setSearchValue={setSearchValue}
												applySorting={applySorting}
												sortedClasses={sortedClasses}
												applyFilters={applyFilters}
												filterData={filterData}
												searchVal={searchVal}
												total_count={paginationData.total_count}
												draft_count={applicationData.total_draft_uploaded }
												total_posted={applicationData.total_posted}
												total_verified={applicationData.total_verified}
												clearFilters={clearFilters}
												genderInsight={genderInsight}
												ageInsight={ageInsight}
												countryInsight={countryInsight}
												cityInsight={cityInsight}
												handleDeleteCampaign={handleDeleteCampaign}
												handleRejectCampaign={handleRejectCampaign}
												handleMoveCampaignIcon={handleMoveCampaignIcon}
												handleSendNotification={handleSendNotification}
												handleSocialMediaIcon={handleSocialMediaIcon}
								    		/> : null }
								    </div>
								</div>
								{
									paginationData.total_pages > 1 && (
										<div className="padination-numbers">
											<Pagination 
												current_page={paginationData.current_page}
												per_page={paginationData.per_page}
												total_count={paginationData.total_count}
												setpaginationData={handlePageChange}
											/>
										</div>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</main>

			<ConfirmationAlert
				visible={iconAvailability}
				handleClose={closeAlert}
				activeAlertType={activeAlertType}
				message="A notification will be sent out to this icon mobile devices asking them if they are available for this campaign according to the dates specified"
				checkSubmission={checkAvailable}
			/>

			<ConfirmationAlert
				visible={confirmAvailability}
				handleClose={closeConfirmAlert}
				activeAlertType={confirmAlertType}
				message=""
				checkSubmission={checkConfirm}
			/>

			<ConfirmationAlert
				visible={deleteCampaignAlert}
				handleClose={closeDeleteCampaignAlert}
				activeAlertType={''}
				message=""
				checkSubmission={checkDelete}
			/>

			<ConfirmationAlert
				visible={rejectCampaignAlert}
				handleClose={closeRejectCampaignAlert}
				activeAlertType={''}
				message=""
				checkSubmission={checkReject}
			/>

			{
				moveIconAlert ? (
					<Modal
		        show={moveIconAlert}
		        onHide={() => closeMoveCampaignIcon('CANCEL')}
		        aria-labelledby="contained-modal-title-vcenter"
		        centered
		        className="caption-model status-model"
		      >
		      <h3>Move to status<a  href="javascript:void(0)" data-dismiss="modal" onClick={()=> closeMoveCampaignIcon('CANCEL')} className="close color-black">X</a></h3>
		      <div className="data-modal-form">
		        <form>
		          <div className="form-group">
        				<CustomSelect 
        					name="status" 
        					id="select-community02" 
        					value={moveIconStatus} 
        					className="sources custom-select" 
        					placeholder="Source Type" 
        					onChange={(e) => handleIconChange(e)}
        				>
        					<option value='' key={Math.random()}>
        						Select status.
        					</option>
        					
        					{	renderCommunitySelector() }
        				</CustomSelect>
		          </div>
		        </form>
		        <div className="form-submittion">
		          <button type="button" data-dismiss="modal" onClick={()=> submitMoveCampaignIcon()}>Submit</button>
		        </div>
		      </div>
		      </Modal>
				) : null
			}
			{
				sendNotificationAlert ? (
					<Modal
		        show={handleSendNotification}
		        onHide={() => closeSendNotification()}
		        aria-labelledby="contained-modal-title-vcenter"
		        centered
		        className="caption-model status-model"
		        autoFocus={false}
		      >
		      <h3>Send Notification<a  href="javascript:void(0)" data-dismiss="modal" onClick={()=> closeSendNotification()} className="close color-black">X</a></h3>
		      <div className="data-modal-form">
		        <form>
		          <div className="form-group">
        				<label className="form-label">Choose status</label>
        				<Multiselect
		              options={tabStatus}
		              selectedValues={tabStatus}
		              onSelect={onSelect}
		              onRemove={onRemove}
		              displayValue="name"
		              className="custom-select sources"
		              />
		          </div>
		          <div className="form-group">
        				<label className="form-label">Title</label>
        				<input autoFocus={true} type="text" name="title"  className="form-control" value={notificationTitle} required="true" onChange={(e)=> setNotificationTitle(e.target.value)} autofocus/>
		          </div>
							<div className="form-group">
								<label className="form-label">Description</label>
							  <div className="caption-area">
							    <textarea
							      classes="label-text"
							      name="notificationDescription"
							      value={notificationDescription}
							      placeholder="Please add description"
							      onChange={(e)=> setNotificationDescription(e.target.value)}
							      id="textarea-focus"
							    ></textarea>
							  </div>
							</div>
		        </form>
		        <div className="form-submittion">
		          <button type="button" data-dismiss="modal" onClick={()=> sendTabBasedNotification()}>Submit</button>
		        </div>
		      </div>
		      </Modal>
				) : null
			}
			{
				socialMediaAlert ? (
					<Modal
		        show={socialMediaAlert}
		        onHide={() => closeSocialMediaIcon()}
		        aria-labelledby="contained-modal-title-vcenter"
		        centered
		        className="caption-model social-media"
		      >
		      <h3>Add Social Media Values<a  href="javascript:void(0)" data-dismiss="modal" onClick={()=> closeSocialMediaIcon()} className="close color-black">X</a></h3>
		      <div className="data-modal-form">
		        <form className="form-set">
		        	<div className="form-inputs" style={{"margin": "37px 0 0"}}>
		        		<label className={socialMediaData.price ? "labelClass field-active" : "labelClass"} >Price</label>
		        	  <input type="number" name="price" required="true" value={socialMediaData.price} onChange={(e)=>handleChangeSocialValues(e)}/>
		        	</div>
		        	<h5 className="error">{errors && errors[0] && errors[0].name === 'price' ? errors && errors[0].value : ''}</h5>
		        	<div className="form-inputs" style={{"margin": "37px 0 0"}}>
		        		<label className={socialMediaData.social_media_value ? "labelClass field-active" : "labelClass"} >Social Media Value</label>
		        	  <input type="number" name="social_media_value" value={socialMediaData.social_media_value} required="true" onChange={(e)=>handleChangeSocialValues(e)}/>
		        	</div>
		        	<h5 className="error">{errors && errors[0] && errors[0].name === 'social_media_value' ? 'Please provide value for social media' : ''}</h5>
		        	<div className="form-inputs" style={{"margin": "37px 0 0"}}>
		        		<label className={socialMediaData.comment ? "labelClass field-active" : "labelClass"} >Comment</label>
		        	  <input type="text" name="comment" required="true" value={socialMediaData.comment} onChange={(e)=>handleChangeSocialValues(e)}/>
		        	</div>
		        	<h5 className="error">{errors && errors[0] && errors[0].name === 'comment' ? errors && errors[0].value : ''}</h5>
		        </form>
		        <br/>
		        <div className="form-submittion">
		          <button type="button" data-dismiss="modal" onClick={()=> submitSocialMediaData()}>Submit</button>
		        </div>
		      </div>
		      </Modal>
				) : null
			}
		</div>
	)
}