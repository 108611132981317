import axios from 'axios';
import jwtdecode from 'jwt-decode';
import setAuthorizationToken from '../../utils/SetAuthorizationToken';
import { API_CONFIG } from '../../config/api';
import { SET_CURRENT_USER } from '../../Redux/Actions/Types/Types';
import {fetchOrganization} from '../Actions/Organizations';

export function login(loginData) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.AUTH.SIGN_IN()}`;
	return dispatch => axios.post(apiURL, loginData)
	.then(res => {
		const success = res.data.status !== 'error'
		if(success) {
			const token = res.headers.authorization;
			dispatch(fetchCurrentUser(token));
			setUserToken(token);
			setAuthorizationToken(token, "");
		}
		return { status: success, message: res.data.message };
	}).catch((err) => {
		return { status: false, message: err.response.data.error || err.response.data.message};
	});
}


export function signup(signupData) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.AUTH.SIGN_UP()}`;
  return dispatch => axios.post(apiURL, signupData)
	.then(res => {
		const success = res.data.status !== 'error'
		return { status: success, message: res.data.message };
	}).catch((err) => {
		return { status: false, message: err.response.data.error || err.response.data.message};
	});
}

export function passwordRecover(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.USER.PASSWORD_RECOVER()}`;
  return dispatch => axios.put(apiURL, data)
	.then(res => {
		const success = res.data.status !== 'error'
		return { status: success, message: res.data.message };
	}).catch((err) => {
		return { status: false, message: err.response.data.error || err.response.data.message};
	});
}

export function passwordReset(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.USER.PASSWORD_RESET()}`;
  return dispatch => axios.post(apiURL, data)
	.then(res => {
		const success = res.data.status !== 'error'
		return { status: success, message: res.data.message };
	}).catch((err) => {
		return { status: false, message: err.response.data.message};
	});
}

export function fetchCurrentUser(token) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.USER.FETCH_CURRENT()}`;
  return dispatch => axios.get(apiURL, { headers: { Authorization: token } })
    .then(res => {
		const success = res.data.status !== 'error'
	    if(success) {
				dispatch(setCurrentUser(res.data.data));
				dispatch(fetchOrganization(res.data.data.id));
	    } else {
	    	throw Error(res.statusText);
	    }
	    return res;
    }).catch((err) => {
      return err.response;
    });
}

export function changePassword(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.USER.CHANGE_PASSWORD()}`;
	const token = localStorage.getItem('manageToken')
	return dispatch => axios.put(apiURL, data, { headers: { Authorization: token } })
	.then(res => {
		const success = res.data.status !== 'error'
		return { status: success, message: res.data.message };
	}).catch((err) => {
		return { status: false, message: err.response.data.message};
	});
}


export function setCurrentUser(user) {
  return {
	type: SET_CURRENT_USER,
	user: user
  }
}

export function logout() {
  return dispatch => {
	localStorage.removeItem('manageToken');
	localStorage.removeItem('manageTokenExpiry')
	localStorage.removeItem("searchurl")
	localStorage.removeItem("organization_id")
	window.history.replaceState(null, null, "/");
	setAuthorizationToken(false, "");
  };
}

export function setUserToken(token) {
  const tokenVal = jwtdecode(token.replace('Bearer ', ''))
  localStorage.removeItem('manageToken');
  localStorage.removeItem('manageTokenExpiry')

  localStorage.setItem('manageToken', token);
  localStorage.setItem('manageTokenExpiry', tokenVal.exp + '000')
}

export function isUserTokenValid() {
	const now = (new Date()).getTime()
	return localStorage.manageToken && localStorage.manageTokenExpiry && parseInt(localStorage.manageTokenExpiry, 10) - now >= 0
}

export function completeProfile(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.USER.COMPLETE_PROFILE()}?token=${data.token}`;

  let finalData = {
  	full_name: data.full_name,
  	password: data.password,
  	email: data.email,
  }
  return dispatch => axios.post(apiURL, finalData)
	.then(res => {
		const success = res.data.status !== 'error'
		return { status: success, message: res.data.message };
	}).catch((err) => {
		return { status: false, message: err.response.data.error || err.response.data.message};
	});
}
