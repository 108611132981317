import PlatformDetails from '../Platform/Details';
import { Link } from 'react-router-dom'
import { iconStatus } from '../../utils/common';
import { useHistory } from "react-router-dom";
import Tooltip from '../Tooltip'
import { trim, toLower } from 'lodash';
import { useSelector } from 'react-redux';

export default ({ 
	icon = {},
	engagements = {},
	showSelection = false,
	handleSelect = () => {},
	handleRemove = () => {},
	handleUnSelect = () => {},
	handleCheckAvailability = () => {},
	handleConfirmAvailability = () => {},
	showUnselect = false,
	showPlatform = true,
	showStatus = false,
	iStatus = '',
	main_campaign_id = '',
	handleDeleteCampaign = () => {},
	handleRejectCampaign = () => {},
	handleMoveCampaignIcon = () => {},
	handleSocialMediaIcon = () => {},
	platform_icons = {}
}) => {
	const user = useSelector(state => state.auth.user);
	const id = icon.id || icon.icon_id
	const isSelected = showSelection ? ['selected', 'availability_accepted', 'availability_declined', 'shortlisted', 'availability_pending'].indexOf(iStatus) > -1 : false
	const history = useHistory();
	const renderPlatforms = (data = [], icon={} ) => {
		data = data ? data : []
		return data.map(platform => {
			return <PlatformDetails key={Math.random()} platform={platform} clickOnLogo={clickOnLogo} icon={icon} />
		})
	}

	const clickOnLogo = (platform, icon)=> {
		let socialData = []
		icon.platform_icons.map(item => {
			if(platform.platform.id == item.platform_id){
				socialData.push(item)
			}
		})
		if(socialData && socialData.length > 0){
			if(platform && platform.platform.name === 'instagram'){
				window.open(`https://www.instagram.com/${socialData[0].username}`, '_blank')
			}else {
				window.open(`https://www.tiktok.com/@${socialData[0].username}`, '_blank')
			}
		}
	}
	const handleRedirect = (id) => {
		window.open(`/profile/${id}`, '_self')
	}

	const statusClass = () => {
		let classVal;
		switch(iStatus) {
			case 'availability_accepted':
				classVal = 'btn btn-success'
				break;
			case 'availability_declined':
				classVal = 'btn btn-danger';
				break;
			case 'shortlisted':
				classVal = 'hide'
				break;
			case 'availability_pending':
				classVal = 'btn btn-default'
				break;

			default:
				classVal = 'hide'
				break;
		}
		return classVal;
	}

	const profile_pic_icon = (icon) => {
		let profile_pic = icon && icon.attachments && icon.attachments[0] && icon.attachments[0].media && icon.attachments[0].media.url ? icon.attachments[0].media.url : null
		if (profile_pic) {
			return profile_pic
		} else {
			switch (toLower(trim(icon.gender))) {
				case 'male':
					return require('../../assets/images/default_male_user_icon.svg')
				case 'female':
					return require('../../assets/images/default_female_user_icon.svg')
				default:
					return require('../../assets/images/default_male_female_icon.svg')
			}
		}
	}

	const renderPlatformsLinks = (platform_icons, engagements) =>{
		let instagramData = engagements.find(item => item.platform.id == platform_icons.platform_id)
		if(instagramData){
			switch (engagements && engagements[0] && engagements[0].platform && engagements[0].platform.name) {
				case 'instagram':
					return <li><a target="_blank" href={`https://www.instagram.com/${(icon.username || platform_icons.username)}`}>Go to Instagram</a></li>
				case 'tiktok':
					return <li><a target="_blank" href={`https://www.tiktok.com/${(icon.username || platform_icons.username)}`}>Go to Tiktok</a></li>
				default:
					return null
			}
		}else{
			return null
		}
	}

	const getAge = (icon_date)=> {
    var dob = new Date(icon_date);
    var today = new Date();
    var age = Math.floor((today-dob) / (365.25 * 24 * 60 * 60 * 1000));
    return age;
	}


	const redirectSocialMedia = (platform_name, username)=> {
		switch (platform_name.toString().toLowerCase()) {
			case 'instagram':
				window && window.open(`https://www.instagram.com/${username}`, '_blank')
			case 'tiktok':
				window && window.open(`https://www.tiktok.com/${username}`, '_blank')
			default:
				return null
		}
	}

	let platformIconId = engagements && engagements[0] && engagements[0].platform && engagements[0].platform.id
	let engagementId = engagements && engagements[0]  && engagements[0].id
	let platformData = engagements && engagements[0] && engagements[0].platform && engagements[0].platform

	let instagramData = null
	if(platform_icons && platform_icons.icon){
		icon = platform_icons.icon
	}
	if(platform_icons.platform_id == platformIconId){
		instagramData = platform_icons
	}

	return(
		<>
			<div className="community-user-details">
				<div className="small-profile-photo">
					<img src={profile_pic_icon(icon)} />
				</div>
				
				<div className="user-mark-details">
					<h3>
						<div style={{"cursor": "pointer"}} onClick={(e)=> handleRedirect(id)}>
							<Tooltip title={icon.first_name+' '+icon.last_name} position="bottom" >
									{icon.first_name+' '+icon.last_name}
							</Tooltip>
						</div>
						{
							instagramData && instagramData.username && (
								<p style={{"cursor": "pointer", "font-size": "14px", "margin-top":"4px"}} onClick={(e)=> redirectSocialMedia(platformData.name, instagramData.username)}>{instagramData && instagramData.username}</p>
							)
						}
					</h3>
					<div className="star-more">
					  <div className="mark-star">
						{/* <a href="javascript:void(0)" className="saved-star"> */}
						{/* </a> */}
					  </div>
					  <div className="dropdown default-dropdown">
						<a href="javascript:void(0)" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
						  <img src={require("../../assets/images/icon_more_menu.svg")} />
						</a>
						<ul className="dropdown-menu">
							{renderPlatformsLinks(platform_icons, engagements)}
						  {
						  	showUnselect && (
							  <li>
								<a href="javascript:void(0)" onClick={(e) => handleUnSelect(main_campaign_id, e)}>Unselect
								</a>
							  </li>
						  	)
						  }
						  { iStatus === 'shortlisted' && (
						  	  <li>
						  			<a href="javascript:void(0)" onClick={(e)=> handleCheckAvailability(icon.id, e)}>Check Availability </a>
						  	  </li>
						  	)
						  }
						  { (iStatus !== 'availability_declined' && iStatus !== 'availability_accepted') && (iStatus === 'availability_pending' || iStatus === 'shortlisted') && user && user.role === 'superadmin' &&  (
						  	  <li>
						  			<a href="javascript:void(0)" onClick={(e)=> handleConfirmAvailability(main_campaign_id, e)}>Confirm Availability </a>
						  	  </li>
						  	)
						  }
	  				  { user && user.role === 'superadmin' && 'magnus@societyicon.com' === 'magnus@societyicon.com' &&  (
	    			  	  <li>
	    			  			<a href="javascript:void(0)" onClick={(e)=> handleSocialMediaIcon(engagementId, e)}>Social Media Value</a>
	    			  	  </li>
	  				  	)
	  				  }
						  { user && user.role === 'superadmin' &&  (
		  			  	  <li>
		  			  			<a href="javascript:void(0)" onClick={(e)=> handleMoveCampaignIcon(main_campaign_id, e)}>Move to status</a>
		  			  	  </li>
						  	)
						  }
						  { user && user.role === 'superadmin' && (
					  		  <li>
					  				<a href="javascript:void(0)" onClick={(e)=> handleDeleteCampaign(main_campaign_id, e)}>Delete</a>
					  		  </li>
						    )
							}

						  { user && user.role === 'superadmin' && (
					  		  <li>
					  				<a href="javascript:void(0)" onClick={(e)=> handleRejectCampaign(main_campaign_id, e)} >Reject</a>
					  		  </li>
						    )
							}
						</ul>
					  </div>
					</div>
					<div className="bio-details">
						<Tooltip title="Gender" position="bottom">
							<span className="capitalize">{icon.gender || 'N/A'}
							</span>
						</Tooltip>
					  <span>{icon.birthday ? `${getAge(icon.birthday)} years` : "" }
					  </span>
						<Tooltip title="City" position="bottom">
							<span>{icon.addresses && icon.addresses[0] && icon.addresses[0].city || 'N/A'}
							</span>
						</Tooltip>
					</div>
					{showStatus &&  (
						<div className="icon_status">
						  <button type="button" className={statusClass()}>{iconStatus[iStatus]}</button>
						</div>
					)}
					
					{icon.status === 'rejected' && (
						<div className="icon_status icon_status_rejected">
						  <button type="button" className="btn btn-danger">{iconStatus[iStatus]}</button>
						</div>
					)}
				</div>
			</div>

			<div className="social-person-details">
				{ showPlatform ? renderPlatforms(engagements, icon) : null }
			</div>

			<div className={`see_profile ${showSelection ? 'see_profile_between' : ''}`}>
				<Link to={`/profile/${id}`} target="_self">SEE PROFILE
				</Link>
				{ showSelection ? <a href="javascript:void(0)" onClick={(e) => isSelected ? handleRemove(main_campaign_id, e) : handleSelect(main_campaign_id, e)} className={isSelected ? "minus-remove" : "plus-select"}>{isSelected ? '- DESELECT' : '+ SELECT'}</a> : null }
			</div>
		</>
	)
}