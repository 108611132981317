import { combineReducers } from 'redux';

import auth from './Auth';
import {organization, organizationSearchText, organizationPaginationData} from './Organization';
import categories from './Categories';
import { communityActiveTab, activeTab, communityFilters, activeSettingTab, communityIconPaginationData, selectedCommunity } from './Community';
import { campaigListSearchValue, campaigListSortFilters, campaignOnBoardFilters, activeOnBoardingTab, activeCampaignListTab, selectedCampiagn, campaignListSelectedCommunity } from './Campaign';
import { contentListArticleSortFilters, contentListVideoSortFilters, contentPaginationData } from './Content';
import {iconFeedbackListSearchText, iconFeedbackFilters, iconListPaginationData, iconListSearchText} from './Icon';
import { activeAside } from './Header';
import loader from './Loader';

export default combineReducers({
  auth,
  activeTab,
  activeOnBoardingTab,
  activeSettingTab,
  activeCampaignListTab,
  categories,
  contentListArticleSortFilters, 
  contentListVideoSortFilters,
  communityIconPaginationData,
  contentPaginationData,
  campaigListSortFilters,
  campaignListSelectedCommunity,
  campaigListSearchValue,
  communityActiveTab,
  activeTab,
  activeAside,
  iconFeedbackFilters,
  iconListPaginationData,
  iconFeedbackListSearchText,
  iconListSearchText,
  communityFilters,
  campaignOnBoardFilters,
  loader,
  organization,
  selectedCommunity,
  organizationPaginationData,
  organizationSearchText,
  selectedCampiagn
});
