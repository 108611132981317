export default ({
	community = {}
}) => {
	const mediaData = community.media ? (community.media.name ? URL.createObjectURL(community.media) : community.media) : 'img_dummy.png';

	return(
		<div className="mobile-details">
			<div className="mobile-preview">

				<img src="img_mobile.png" alt="img" />

				<div className="top-mike">
					<img src="img_mobile_mike.png" />
				</div>

				<div className="mobile-data">
					<div className="community-mobile-banner">
						<img src={mediaData} className="image_to_upload" />
					</div>

					<h5>
						<img src={require('../../assets/images/icon_arrow_left_white.svg')} /> {community.name}
					</h5>

					<div className="mobile-tabs-details">
						<ul className="mobile-tabs">
							<li>
								<a href="javascript:void(0)">Campaigns
								</a>
							</li>

							<li>
								<a href="javascript:void(0)">Articles
								</a>
							</li>

							<li>
								<a href="javascript:void(0)">Videos
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}