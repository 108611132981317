import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";

import { createContent, fetchContent, updateContent } from '../../Redux/Actions/Content';
import { NotificationManager } from 'react-notifications';
import './ContentList.css';
import { isEmpty } from 'lodash';
import { useHistory } from "react-router-dom";
import { setLoader } from '../../Redux/Actions/Loader';
import SendOutNotification from '../Common/Notification';
import TextArea from '../../Components/TextArea/Field';
import { Multiselect } from 'multiselect-react-dropdown';

const initialData = {
  title: '',
  body: '',
  status: '',
  content_type: 'image',
  community_ids: [],
  media: '',
  attachment_url: ''
}
const attachData = [{id: "", media: "", media_url: ""}, {id: "", media: "", media_url: ""}]

export default ({
  renderCommunitySelector = () => { },
  communities = [],
  ...props
}) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [articleData, setArticleData] = useState(initialData);
	const [attachments, setAttachments] = useState(attachData);




  useEffect(async () => {
    if (props.match && props.match.params.id) {
      await dispatch(setLoader(true));
      const response = await dispatch(fetchContent(props.match.params.id))
      if (response.status) {
        await dispatch(setLoader(false));
        let data = response.data
        if (data.attachments.length > 0) {
          const newAttcahments = data.attachments.map((attach) => {
						return {...attach, media: "", media_url: attach.media};
					});
					setAttachments(newAttcahments)
          // data.attachment_url = response.data.attachments[0].media
          // data.secondary_attachment_url = response.data.attachments[1] ? response.data.attachments[1].media : ''
        }
        data.content_type = 'image'
        setArticleData(data);
      } else {
        await dispatch(setLoader(false));
        NotificationManager.error(response.message, '', 10000, () => {})
      }
    }

  }, []);

  useEffect(() => {
    const selectedCommunity = communities.filter(community => articleData.community_ids.indexOf(community.id) > -1)
    setArticleData(prevState => ({
      ...prevState,
      community_ids: selectedCommunity
    }))
  }, [communities])

  const titleChange = async (e) => {
    e.preventDefault();
    let { value } = e.target
    setArticleData(prevState => ({
      ...prevState,
      title: value
    })
    )
  }

  const bodyChange = async (e) => {
    e.preventDefault();
    let { value } = e.target
    setArticleData(prevState => ({
      ...prevState,
      body: value
    })
    )
  }

  const mediaChange = async (e) => {
    e.preventDefault();
    let value = e.target.files[0]
    const name = e.target.name
    setArticleData(prevState => ({
      ...prevState,
      [name]: value,
    })
    )
  }

  const handleCommunitySelect = (selectedList, selectedItem) => {
    setArticleData(prevState => ({
      ...prevState,
      community_ids: selectedItem ? [selectedItem] : []
    }))
  }

  const renderOptions = (data = [], selected = []) => {
    return data.map((record) => {
      return <option key={Math.random()} value={record.id} selected={selected.indexOf(record.id) > -1}> {record.name}</option>
    })
  }

  const validateInput = (data) => {
    const Errors = [];
    Object.keys(data).map(key => {
      if (key === "media") {
        if (!(data[key] && data[key].name || attachments[0].media_url || attachments[1].media_url)) {
          Errors.push(`Please provide value for ${key}.`)
        }
      } else {
        if (isEmpty(data[key]))
          Errors.push(`Please provide value for ${key}.`)
      }
    })

    Errors.map((error) => {
      NotificationManager.error(error, '', 10000, () => {})
    })

    return isEmpty(Errors);
  }

  const createArticle = async (e) => {
    const status = e.target.dataset.status;
    const { title, body, community_ids, content_type } = articleData
    const {media} = (attachments[0] || attachments[1])
    let data = { title, body, community_ids: community_ids.map(a => a.id), content_type, status }
    const validateData = { title, body, community_ids, content_type, status, media }
    if (validateInput(validateData)) {
      var formdata = new FormData()
      for (const key in data) {
        formdata.append(`content[${key}`, Array.isArray(data[key]) ? JSON.stringify(data[key]) : data[key]);
      }

      if(attachments && attachments.length > 0)
				attachments.map((attach, index) => {
					if(attach.media){
						for (const key in attach) {
							formdata.append(`content[attachments_attributes][][${key}]`, attach[key]);
						}
					}
				})

      await dispatch(setLoader(true));
      const res = (props.match && props.match.params.id) ? await dispatch(updateContent(props.match.params.id, formdata)) : await dispatch(createContent(formdata))
      if (res.status) {
        await dispatch(setLoader(false));
        NotificationManager.success(res.message)
        history.push("/content");
      } else {
        await dispatch(setLoader(false));
        NotificationManager.error(res.message, '', 10000, () => {})
      }
    }
  }

  const handleAttachmentChange = (event, index) => {
    let value = event.target.files[0];
    let temp_attach = [...attachments];
    let temp_element = { ...temp_attach[index] };
    temp_element.media = value;
    temp_element.media_url = value ? URL.createObjectURL(value) : "";
    temp_attach[index] = temp_element
    setAttachments( temp_attach );
}

  return (
    <div id="view-dashboard">
      <div className="inner-view-dashboard">
        <div className="communities-content create-communities">
          <div id="campaign-page__community-selector" className="form-inputs">
            <Multiselect
              options={communities}
              selectedValues={articleData.community_ids}
              onSelect={handleCommunitySelect}
              onRemove={handleCommunitySelect}
              singleSelect={false}
              placeholder={articleData.community_ids.length > 0 ? "" : "Select Brand Community"}
              displayValue="name"
              className="custom-select sources"
            />
          </div>
          <div className="width-650">
            <div className="drap-picture-upload">
              <div className="uploadOuter">
                <div className="dragBox cam-height">
                  Drag and drop image here or click upload image
                  <input type="file" name="media" id="uploadFile" onChange={(e) => handleAttachmentChange(e, 0)} />
                  <div id="preview">
											{attachments[0] && attachments[0].media_url && <img src={attachments[0].media_url}/>  }
                  </div>
                </div>
                <div className="text-center"><label for="uploadFile" type="button" className="redirect-link mt-30" style={{ textTransform: "none" }}>Upload image</label></div>
              </div>

              <div className="add-more-images">
                <div className="more-img-upload">
                  <div className="">
                    <input type="file" name="secondary_media" id="uploadFile" onChange={(e) => handleAttachmentChange(e, 1)} />
                    <span><img src={require("../../assets/images/icon_plus_blue.svg")} alt="" /></span>
                    <div id="preview">
											<div>{(attachments[1] && attachments[1].media_url) ? <img src={attachments[1].media_url}/> : null }</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="picture-phone">

            <div className="width-650 width-650-changed">
              <div className="add-blocks">
                <form className="form-set campaign-budget-form" >
                  <div className="form-inputs">
                    <input type="text" placeholder="Title" required="true" name="title" value={articleData.title} onChange={titleChange} className="h1-basic02" />
                  </div>
                  <TextArea
                    key={Math.random()}
                    classes="label-text"
                    name="body"
                    value={articleData.body}
                    onBlur={bodyChange}
                    placeholder="Write the description here"
                  />
                </form>
              </div>
            </div>
            <div className="picture-phone-set">
              <div className="mobile-preview mobile-preview-478">
                <img src={require("../../assets/images/img_mobile_478.png").default} alt="img" />
                <div className="top-mike">
                  <img className="mike-set" src={require("../../assets/images/img_mobile_mike_479.png").default} />
                </div>
                <div className="mobile-data mobile-data-height">
                  <div className="community-mobile-banner" >
										{attachments[0] ? <img src={attachments[0].media_url}/> : null }

                  </div>
                  <h5>
                    <img src={require('../../assets/images/icon_arrow_left_white.svg')} />
                    <p>{articleData.title}</p>
                  </h5>
                  <div className="mobile-tabs-details">
                    <h6>{articleData.body}</h6>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="content-submitions">
            <div className="all-submittion">
              <ul>
                {!(articleData.id && (articleData.status === 'active')) ?
                  <li><a href="javascript:void(0)" className="draft" style={{ textTransform: "none" }} type='submit' data-status="draft" onClick={createArticle}>Save as draft</a></li>
                  : null}
                <SendOutNotification communities={communities} community_ids={articleData.community_ids}/>
                <li><a href="javascript:void(0)" className="redirect-link" style={{ textTransform: "none" }} data-status="active" onClick={createArticle}>Set live</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}