import Pagination from "react-js-pagination";
import './Pagination.css'
export default ({
  current_page='', 
  per_page='',
  total_count='',
  setpaginationData = (event) => {}
}) => {
  return (
    <Pagination
      activePage={current_page}
      itemsCountPerPage={per_page ? parseInt(per_page) : 5}
      totalItemsCount={total_count}
      pageRangeDisplayed={10}
      hideDisabled={true}
      hideNavigation={false}
      linkClass="page"
      activeLinkClass="active-page"
      linkClassLast="linkClassNext"
      linkClassFirst="linkClassNext"
      prevPageText="Prev"
      firstPageText="<"
      lastPageText=">"
      nextPageText="Next"
      linkClassLast="next-btn"
      linkClassFirst="next-btn"
      linkClassNext="next"
      linkClassPrev="next"
      onChange={(event) =>setpaginationData(event)}
    />
  )

}