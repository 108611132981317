import React from 'react';
import { Link } from 'react-router-dom'
import { useSelector } from "react-redux";
import { setActiveAside } from '../../Redux/Actions/Header';
import { setActiveTab } from '../../Redux/Actions/Community';
import { useDispatch } from 'react-redux';

export default ({ children }) => {
	const embedMode = localStorage.getItem('embedMode');

	const activeTab = useSelector(state => state.activeTab);
	const activeAside = useSelector(state => state.activeAside);
	const user = useSelector(state => state.auth.user);
	const dispatch = useDispatch();
	const isActive = (type) => {
		return type === activeTab ? 'active-menu-link' : ''
	}

	const handleTabClick = async (type)=> {
		localStorage.removeItem('recoverurl')
		await dispatch(setActiveTab(type))
		await dispatch(setActiveAside(false))
	}

	return embedMode ? null : (
		<>

		<aside>
			<a className="aside-menu-open-global" onClick={(e) => dispatch(setActiveAside(true))} href="javascript:void(0)"><img src={require("../../assets/images/icon_menu.svg")} alt="X" /></a>

			<div id="aside" className={activeAside ? 'aside-menu-open' : ''}>
				<div className="aside-menu">
					<a className="aside-menu-close" onClick={(e) => dispatch(setActiveAside(false))} href="javascript:void(0)"><img src={require("../../assets/images/icon_close.svg")} alt="X" /></a>
				</div>

				<ul className="side-menu-list">
					<li>
						<Link onClick={() => handleTabClick('Communities')} to="/communities" className={isActive('Communities')}>Brand Communities
						</Link>
					</li>
					<li>
						<Link onClick={() => handleTabClick('Campaigns')} to="/campaigns" className={isActive('Campaigns')}>Campaigns
						</Link>
					</li>
					<li>
						<Link onClick={() => handleTabClick('Content')} to="/content" className={isActive('Content') || isActive('New Article') || isActive('New Video') || isActive('Edit Article') || isActive('Edit Video')}>Content
					</Link>
					</li>
					{ 
						!embedMode && user && (user.role === 'superadmin') && (
						<>
							<li>
								<Link onClick={() => handleTabClick('Team members')} to="/user-list" className={isActive('Team members')}>Team members
								</Link>
							</li>
							<li>
								<Link onClick={() => handleTabClick('Settings')} to="/settings" className={isActive('Settings')}>Settings
								</Link>
							</li>
							<li>
								<Link onClick={() => handleTabClick('Organizations')} to="/organizations" className={isActive('Organizations')}>Companies
								</Link>
							</li>
						</>
						)
					}
					{
						user && user.role === 'superadmin' ? (
							<>
								<li className="menu-separator"><h5>Super user features</h5></li>
								<li>
									<Link onClick={() => handleTabClick('Price-List')} to="/price-list" className={isActive('Price-List')}>Price list
									</Link>
								</li>
								<li>
									<Link onClick={() => handleTabClick('Creator List')} to="/icon-list" className={isActive('Creator List')}>Creator List
									</Link>
								</li>
								<li>
									<Link onClick={() => handleTabClick('Creator Feedback')} to="/icon-feedback" className={isActive('Creator Feedback')}>Creator Feedback
									</Link>
								</li>
								<li>
									<Link onClick={() => dispatch(setActiveTab('Notification'))} to="/notification" className={isActive('Notification')}>Notification
									</Link>
								</li>
							</>
						) : null
					}

				</ul>
				{children}
			</div>
		</aside>
		</>
	)
}