import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchIconList, deleteIcon, setIconListPaginationData, setSearchValue } from '../../Redux/Actions/Icons';
import { setLoader } from '../../Redux/Actions/Loader';
import { NotificationManager } from 'react-notifications';
import DeletePopup from './DeletePopup';
import Pagination from '../Common/Pagination';
import { Link } from 'react-router-dom'
import './IconList.css';
import classNames from 'classnames';


export default () => {
	const dispatch = useDispatch();
	const [iconList, setIconList] = useState([]);
	const [searchText, setSearchText] = useState(useSelector(state => state.iconListSearchText));
	const [visible, setvisible] = useState(false);
	const [activeIcon, setActiveIcon] = useState("");
	const [countryWiseCount, setCountryWiseCount] = useState([]);
	const initialPaginationData = useSelector(state => state.iconListPaginationData)
	const [paginationData, setpaginationData] = useState(initialPaginationData)
	let [isSearchPage, setSerachPage] = useState(0)

	useEffect(() => {
		setpaginationData(initialPaginationData)
	}, [initialPaginationData])

	useEffect(async () => {
		if(searchText === '') 
			await dispatch(setLoader(true))
		geticonList(paginationData.current_page);
	}, [searchText]);

	const changePage = (current_page, sort_by = null, sort_order = null)=> {
		if(searchText && searchText !== ''){
			setSerachPage(isSearchPage++)
		}
		geticonList(current_page, sort_by,sort_order)
	}

	const geticonList = async (current_page, sort_by = null, sort_order = null) => {
		if(searchText && searchText !== ''){ 
			if(isSearchPage > 0){
				current_page = current_page ? current_page : paginationData.current_page
			}else{
				current_page = 1
			}
		}
		const data = { page: current_page ? current_page : paginationData.current_page, search: searchText, sort_by: sort_by ? sort_by : paginationData.sort_by, sort_order: sort_order ? sort_order : paginationData.sort_order }
		const response = await dispatch(fetchIconList(data));
		if (response.success) {
			setIconList(response.data.data);
			setCountryWiseCount(response.data.country_wise_count)
			dispatch(setIconListPaginationData({
				...paginationData,
				per_page: response.data.per_page,
				current_page: response.data.current_page,
				total_count: response.data.total_count,
				total_pages: response.data.total_pages,
				sort_order: data.sort_order,
				sort_by: data.sort_by
			}))
			// setpaginationData(prevState => ({
			// 	...prevState,
			// 	per_page: response.data.per_page,
			// 	current_page: response.data.current_page,
			// 	total_count: response.data.total_count,
			// 	total_pages: response.data.total_pages
			// }))
		} else {
			NotificationManager.error(response.message, '', 10000, () => {})
		}
		await dispatch(setLoader(false));
	}

	const searchHandle = async (event) => {
		dispatch(setSearchValue(event.target.value))
		await setSearchText(event.target.value)
	}

	const deleteIconRecord = async () => {
		await dispatch(setLoader(true));
		const { status, message } = await dispatch(deleteIcon(activeIcon));
		if (status) {
			NotificationManager.success(message)
			geticonList();
		} else {
			NotificationManager.error(message, '', 10000, () => {})
		}
		await dispatch(setLoader(false));
	}

	const getcountryRecords = Object.keys(countryWiseCount).map(key => {
		return (
			<li key={Math.random()}>
				<h1>{countryWiseCount[key]}</h1>
				<p>Creators in <strong>{key}</strong></p>
			</li>
		)
	})

	const openModel = (id) => {
		setvisible(true)
		setActiveIcon(id)
	}

	const closeModel = (actionType) => {
		actionType && deleteIconRecord()
		setvisible(false)
	}

	const getPlatform = (platforms) => {
		return platforms.map(platform => {
			return (
				<a className="platform_link" href={platform.name === "tiktok" ? `https://www.tiktok.com/${platform.username}` : `https://www.instagram.com/${platform.username}`} target="_blank"><img src={platform.name === "tiktok" ? "https://societyicon-assets.s3.eu-north-1.amazonaws.com/public/tiktok.png" : platform.logo || null} className="platformImg" /></a>
			)
		})
	}

	const applySorting = (type) => {
		const sortOrder = paginationData.sort_by === type ? paginationData.sort_order === 'asc' ? 'desc' : 'asc' : 'asc'
		dispatch(setIconListPaginationData({
			...paginationData,
			sort_by: type,
			sort_order: sortOrder
		}))
		// setpaginationData(prevState => ({
		// 	...prevState,
		// 	sort_by: type,
		// 	sort_order: sortOrder
		// }))
		geticonList(paginationData.current_page, type, sortOrder);
	}

	const sortedClasses = (type) => {
		var sortClasses = classNames(
			{ [paginationData.sort_order]: paginationData.sort_by === type }
		)
		return sortClasses
	}

	return (
		<div id="view-dashboard">
			<DeletePopup visible={visible} closeModel={closeModel} />
			<div className="inner-view-dashboard">
				<div className="organizations-list">
					<ul className="organizations-count">
						<li>
							<h1>{paginationData.total_count}</h1>
							<p>Creators in <strong>Total</strong></p>
						</li>
						{getcountryRecords}
					</ul>
				</div>

				<div className="user-table-list">
					<div className="search-input" style={{ margin: "0 0 30px" }}><input type="search" className="" name="searchText" placeholder="Search" value={searchText} onChange={searchHandle} /></div>
					<div className="table-responsive">
						<table className="table-column-sort">
							<tr>
								<th style={{ minWidth: "150px" }}><div className="head-sort" onClick={(e) => applySorting('first_name')}>FULL NAME <a href="javascript:void(0)" className="table-sort" className={sortedClasses('first_name')}></a></div></th>
								<th style={{ minWidth: "110px" }}><div className="head-sort" onClick={(e) => applySorting('birthday')}>DOB <a href="javascript:void(0)" className="table-sort" className={sortedClasses('birthday')}></a></div></th>
								<th style={{ minWidth: "150px" }}><div className="head-sort">PLATFORM </div></th>
								<th style={{ minWidth: "210px" }}><div className="head-sort" onClick={(e) => applySorting('email')}>EMAIL <a href="javascript:void(0)" className="table-sort" className={sortedClasses('email')}></a></div></th>
								<th style={{ minWidth: "140px" }}><div className="head-sort" onClick={(e) => applySorting('country')}>COUNTRY <a href="javascript:void(0)" className="table-sort" className={sortedClasses('country')}></a></div></th>
								<th style={{ width: "60px" }}></th>
								<th style={{ width: "90px" }}></th>
							</tr>
							{iconList.map(icon =>
								<tr key={Math.random()}>
									<td>{icon.name}</td>
									<td>{icon.dob}</td>
									<td>{getPlatform(icon.platforms)}</td>
									<td>{icon.email}</td>
									<td>{icon.country}</td>
									<td><Link to={`/icon-list/${icon.id}`}>EDIT </Link></td>
									<td><a href='javascript:void(0)' onClick={() => openModel(icon.id)} className="link-delete">DELETE</a></td>
								</tr>
							)}
						</table>
					</div>
					{
						paginationData.total_pages > 1 && (
							<div className="padination-numbers">
								<Pagination
									current_page={paginationData.current_page}
									per_page={paginationData.per_page}
									total_count={paginationData.per_page*paginationData.total_pages}
									setpaginationData={changePage}
								/>
							</div>
						)
					}
				</div>
			</div>
		</div>
	)
}