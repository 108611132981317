import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from "react-redux";

import { createContent, fetchContent, updateContent } from '../../Redux/Actions/Content';
import { NotificationManager } from 'react-notifications';
import { useHistory } from "react-router-dom";
import { setLoader } from '../../Redux/Actions/Loader';
import { isEmpty,} from 'lodash';
import './ContentList.css';
import SendOutNotification from '../Common/Notification';
import TextArea from '../../Components/TextArea/Field';
import { Multiselect } from 'multiselect-react-dropdown';


const initialData = {
	id: '',
	title: '',
	body: '',
	status: '',
	content_type: 'video',
	community_ids: [],
	video: '',
	attachment_url: '',
	vi_thumbnail: '',
	vi_thumbnail_acchment_url: ''
}

export default ({
	renderCommunitySelector = () => { },
	communities = [],
	...props
}) => {
	const dispatch = useDispatch();
	const videoRef = useRef(null);
	const previewVideoRef = useRef(null);
	let history = useHistory();
	const [videoData, setVideoData] = useState(initialData);
	const [metadata, setMetadata] = React.useState(null);

	useEffect(() => {
		const selectedCommunity = communities.filter(community => videoData.community_ids.indexOf(community.id) > -1)
		setVideoData(prevState => ({
			...prevState,
			community_ids: selectedCommunity
		}))
	}, [communities])
	
	useEffect(async () => {
		if (props.match && props.match.params.id) {
			await dispatch(setLoader(true));
			const response = await dispatch(fetchContent(props.match.params.id))
			if (response.status) {
				await dispatch(setLoader(false));
				let data = response.data
				if (response.data.videos.length > 0) {
					data.attachment_url = response.data.videos[0].videos
				}
				data.vi_thumbnail_acchment_url = data.thumbnail
				data.content_type = 'video'
				setVideoData(data);
			}
		} else {
			await dispatch(setLoader(false));

		}

	}, []);

	const handleCommunitySelect = (selectedList, selectedItem) => {
		setVideoData(prevState => ({
			...prevState,
			community_ids: selectedItem ? [selectedItem] : []
		}))
	}

	const renderOptions = (data = [], selected = []) => {
		return data.map((record) => {
			return <option key={Math.random()} value={record.id} selected={selected.indexOf(record.id) > -1}> {record.name}</option>
		})
	}

	const titleChange = async (e) => {
		e.preventDefault();
		let { value } = e.target
		let errorValue = value === '' ? "Required" : ''
		setVideoData(prevState => ({
			...prevState,
			title: value,
			titleError: errorValue
		})
		)
	}

	const bodyChange = async (e) => {
		e.preventDefault();
		let { value } = e.target
		let errorValue = value === '' ? "Required" : ''
		setVideoData(prevState => ({
			...prevState,
			body: value,
			bodyError: errorValue
		})
		)
	}
	const thumbnilChange = async (e) => {
		e.preventDefault();
		let value = e.target.files[0]
		let errorValue = value === '' ? "Required" : ''
		setVideoData(prevState => ({
			...prevState,
			vi_thumbnail: value,
			vi_thumbnail_acchment_url: value ? URL.createObjectURL(value) : "",
			vi_thumbnailError: errorValue
		})
		)
	}


	const handleVideoSize = async (e) => {
		e.preventDefault();
		let { name } = e.target
		const value = e.target.files[0]
		let size = value ? (Math.round(value.size / 1024 / 1024)) : 0
		if (size > 30) {
			NotificationManager.error("Video is too large please upload video less then 30 MB size", '', 10000, () => {})
		} else {
			let attachUrlname = 'attachment_url'
			let errorName = 'videoError'
			let errorValue = value === ('' || undefined) ? "Required" : ''
			setVideoData(prevState => ({
				...prevState,
				[name]: value,
				[attachUrlname]: value ? URL.createObjectURL(value) : "",
				[errorName]: errorValue
			}));

			if (videoData.video || videoData.attachment_url) {
				videoRef.current.load();
				previewVideoRef.current.load();
			}
		}
	}

	const validateInput = (data) => {
		const Errors = [];
		Object.keys(data).map(key => {
			if (key === "video") {
				if (!(data[key] && data[key].name || videoData.attachment_url)) {
					Errors.push(`Please provide value for ${key}.`)
				}
			} else {
				if (isEmpty(data[key]))
					Errors.push(`Please provide value for ${key}.`)
			}
		})

		Errors.map((error) => {
			NotificationManager.error(error, '', 10000, () => {})
		})

		return isEmpty(Errors);
	}

	const createVideo = async (e) => {
		const status = e.target.dataset.status;
		const { title, body, video, community_ids, vi_thumbnail, content_type } = videoData
		let data = { title, body, content_type, status, community_ids: community_ids.map(a => a.id) }
		const validateData = { title, body, community_ids, content_type, video, status }
		if (validateInput(validateData)) {
			var formdata = new FormData()
			for (const key in data) {
				formdata.append(`content[${key}`, Array.isArray(data[key]) ? JSON.stringify(data[key]) : data[key]);
			}
			if (video && video.name)
				formdata.append('content[video][]', video);

			if (vi_thumbnail && vi_thumbnail.name)
				formdata.append('content[vi_thumbnail]', vi_thumbnail);
			await dispatch(setLoader(true));
			const res = (props.match && props.match.params.id) ? await dispatch(updateContent(props.match.params.id, formdata)) : await dispatch(createContent(formdata))
			if (res.status) {
				await dispatch(setLoader(false));
				NotificationManager.success(res.message)
				history.push("/content");
			} else {
				await dispatch(setLoader(false));
				NotificationManager.error(res.message, '', 10000, () => {})
			}
		}
	}

	const getVideoUrl = () => {
		if (videoData.video) {
			return URL.createObjectURL(videoData.video)
		} else if (videoData.attachment_url) {
			return videoData.attachment_url
		} else {
			return ''
		}
	}

	const getPreviewVideo = () => {
		return (
			<>
				{(videoData.video || videoData.attachment_url) ?
					(
						<video
							ref={videoRef}
							controls={true}
							width="100%"
							onLoadedMetadata={e => {
								setMetadata({
									videoHeight: e.target.videoHeight,
									videoWidth: e.target.videoWidth,
									duration: e.target.duration
								})
							}}
						>
							<source src={videoData.video ? URL.createObjectURL(videoData.video) : videoData.attachment_url} type="video/mp4" />
						</video>
					)
					: null}
			</>
		)
	}

	return (
		<div id="view-dashboard">
			<div className="inner-view-dashboard">
				<div className="communities-content create-communities">
					<div id="campaign-page__community-selector" className="form-inputs">
						<Multiselect
							options={communities}
							selectedValues={videoData.community_ids}
							onSelect={handleCommunitySelect}
							onRemove={handleCommunitySelect}
							singleSelect={false}
							placeholder={videoData.community_ids.length > 0 ? "" : "Select Brand Community"}
							displayValue="name"
							className="custom-select sources"
						/>
					</div>

					<div className="picture-phone">
						<div className="width-100">
							<div className="width-450 uploaders-450">
								<h4 className="h4basic03">Video</h4>
								<div className="uploadOuter">
									<div className="dragBox plan-border">
										Drag and drop video here or click upload video
										<input type="file" name="video" id="uploadFile" onChange={handleVideoSize} accept="video/*" />
										<div id="preview">
											{getPreviewVideo()}
										</div>
									</div>
									<p className="error" style={{ textAlign: 'left' }}>{videoData.videoError}</p>
								</div>
							</div>
							<div className="width-450 uploaders-450">
								<h4 className="h4basic03">Thumbnail</h4>
								<div className="uploadOuter">
									<div className="dragBox">
										Drag and drop video here or click upload video
										<input type="file" name="vi_thumbnail" id="uploadFile" onChange={thumbnilChange} />
										<div id="preview">
											{(videoData.vi_thumbnail || videoData.vi_thumbnail_acchment_url) ? <img src={videoData.vi_thumbnail ? URL.createObjectURL(videoData.vi_thumbnail) : videoData.vi_thumbnail_acchment_url} className="image_to_upload" /> : null}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="picture-phone-set picture-phone-set-uploader" style={{ margin: 0 }}>
							<div className="width-450 uploaders-450">
								<form className="form-set campaign-budget-form">
									<div className="form-inputs">
										<input type="text" placeholder="Title" required="" style={{ width: '100%' }} name="title" value={videoData.title} onChange={titleChange} />
									</div>
								</form>
								<TextArea
									key={Math.random()}
									classes="label-text"
									name="body"
									value={videoData.body}
									onBlur={bodyChange}
									placeholder="Write the description here"
								/>
							</div>
							<div className="mobile-preview mobile-preview-sp">
								<img src={require("../../assets/images/img_mobile.png").default} alt="img" />
								<div className="top-mike">
									<img className="mike-set" src={require("../../assets/images/img_mobile_mike.png").default} />
								</div>
								<div className="mobile-data">
									<div className="community-mobile-banner" >
										{(videoData.video || videoData.attachment_url) ? (
											<video
												ref={previewVideoRef}
												controls={true}
												width="100%"
												onLoadedMetadata={e => {
													setMetadata({
														videoHeight: e.target.videoHeight,
														videoWidth: e.target.videoWidth,
														duration: e.target.duration
													})
												}}>
												<source src={getVideoUrl()} />
											</video>
										) :
											<img src={require("../../assets/images/img_dummy.png").default} className="image_to_upload" />
										}
									</div>
									<h5>
										<img src={require('../../assets/images/icon_arrow_left_white.svg')} />
										<p>{videoData.title}</p>
									</h5>
									<div className="mobile-tabs-details" style={{ marginTop: '-13px' }}>
										<p>{videoData.body}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="all-submittion">
							<ul>
								{!(videoData.id && (videoData.status === 'active')) ?
									<li><a href="javascript:void(0)" className="draft" style={{ textTransform: "none" }} data-status="draft" onClick={createVideo}>Save as draft</a></li>
									: null
								}
								<SendOutNotification communities={communities} community_ids={videoData.community_ids}/>
								<li><a href="javascript:void(0)" className="redirect-link" style={{ textTransform: "none" }} data-status="active" onClick={createVideo}>Set live</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}