import { SELECT_COMMUNITY, SET_COMMUNITY_ACTIVE_TAB, SET_ACTIVE_SETTING_TAB, SET_ACTIVE_TAB, SET_COMMUNITY_FILTER_DATA, SET_COMMUNITY_ICON_PAGINATION_DATA } from '../Actions/Types/Types';

export const activeTab = (state = 'Communities', action) => {
  switch(action.type) {
    case SET_ACTIVE_TAB:
      return action.value

    default: 
      return state;
  }
}

export const communityActiveTab = (state = 'Community_icons', action) => {
  switch(action.type) {
    case SET_COMMUNITY_ACTIVE_TAB:
      return action.value

    default: 
      return state;
  }
}


export const communityFilters = (state = {}, action) => {
  switch(action.type) {
    case SET_COMMUNITY_FILTER_DATA:
      return action.value

    default: 
      return state;
  }
}

export const activeSettingTab = (state = 'organization_details', action) => {
  switch(action.type) {
    case SET_ACTIVE_SETTING_TAB:
      return action.value

    default: 
      return state;
  }
}

const communityIconInitialData = {per_page: '', current_page: 1, total_pages: '', total_count: '', sort_by: 'created_at', sort_order: 'asc'}
export const communityIconPaginationData = (state = communityIconInitialData, action) => {
  switch(action.type) {
    case SET_COMMUNITY_ICON_PAGINATION_DATA:
      return action.value

    default: 
      return state;
  }
}

export const selectedCommunity = (state = '', action) => {
  switch(action.type) {
    case SELECT_COMMUNITY:
      return action.value

    default: 
      return state;
  }
}