import './Loader.css';
import { useSelector } from "react-redux";

export default () => {
	let  loading = useSelector(state => state.loader.loading)

  return (
    <>
    {loading ? 
      (<div className="gooey">
        <div className="dots">
          <span className="dot"></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>)
    
    : null
    }
    </>
  )

}