import React, { useEffect, useRef } from 'react';
import $ from 'jquery';

export default ({
	name = '',
	onChange = () => {},
	minDate = new Date(),
	placeholder = '',
	description = '',
	containerClass = '',
	type,
	value,
	...props
}) => {
  const elmt = React.useRef()
  
  React.useLayoutEffect(()=> {
    const $elmt = $(elmt.current)
	var year = value.getFullYear();
	var month = value.getMonth();
	var date = value.getDate();

	let endDate = new Date()
	endDate.setFullYear(endDate.getFullYear() + 1)

	if(window.$(elmt.current).calendar) {
		window.$(elmt.current).calendar({
			monthArray: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
			date: new Date(year, month, date),
			minDate: new Date(),
		    selectedRang: [minDate, endDate],
			onSelected: function (view, date, data) {
				onChange(date, type)
			},
			viewChange: function (view, y, m) {
			    console.log(view, y, m)
			}
		})
	}

    return () => {
    }
  }, [value])

	return(
		<>
			<div className={containerClass}>
				<div 
					ref={elmt}
					id="calendar-field__container"
				/>
			</div>
		</>
	)
}