import React, { useState } from 'react';
import Filters from '../../../../Components/Filters/Filters';
import IconCard from '../../../../Components/IconCard/Card';
import '../Draft/style.scss';
import { verifyPost, updateEngagementPost, updatePostedDate, retryEngagementData } from '../../../../Redux/Actions/Campaign';
import { isEmpty, cloneDeep } from 'lodash';
import { NotificationManager } from 'react-notifications';
import { setLoader } from '../../../../Redux/Actions/Loader';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '../../../../Components/Tooltip/index';
import Field from '../../../../Components/TextField/Field';
import '../style.scss';
import $ from 'jquery';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { enGB } from 'date-fns/locale';
import { DatePickerCalendar } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import moment from "moment";
import { Chart } from "react-google-charts";
import Modal from 'react-bootstrap/Modal'

const initialEngagementData = {
  campaign_icon_id: '',
  platform_id: '',
  followers_count: '',
  likes_count: '',
  comments_count: ''
}

const initialActiveData = {
  followers_count: false,
  likes_count: false,
  comments_count: false
}

const settings = {
  dots: true,
};

const initialPostedData = {
  campaign_icon_id: '',
  engagement_id: '',
  posted_date: '',
}

const initialRetry = {
  engagement_id:'',
  post_id: '',
  campaign_icon_id: ''
}

export default ({
  applicationData = {},
  setApplicationData = () => {},
  setSearchValue = () => {},
  applyFilters = () => {},
  clearFilters = () => {},
  applySorting = () => {},
  sortedClasses = () => {},
  filterData = {},
  total_count = 0,
  draft_count = 0,
  searchVal = '',
  total_posted = 0,
  total_verified = 0,
  genderInsight = [],
  ageInsight = [],
  countryInsight = [],
  cityInsight = [],
  main_campaign_id = '', 
  handleDeleteCampaign = () => {},
  handleRejectCampaign = () => {},
  handleMoveCampaignIcon = () => {},
  handleSocialMediaIcon = () => {},
  handleSendNotification = () => {}
}) => {

  const [selectedPreviewIcon, setSelectedPreviewIcon] = useState(null);
  // const iconPreview = applicationData.data.find(record => record.id === selectedPreviewIcon)
  const [engagementModel, setEngagementModel] = useState(false);
  const [postEngagement, setPostEngagement] = useState([]);
  const [engagementData, setEngagementData] = useState(initialEngagementData);
  const [activeData, setActiveData] = useState(initialActiveData);
  const [errors, setErrorMsg] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const [calendar, setCalendar] = useState(false);
  const [datetime, setDatetime] = useState(new Date());
  const [postedData, setPostedData] = useState(initialPostedData);
  const [clipboardStatus, setClipboardStatus] = useState(false);
  const [openRetry, setOpenRetry] = useState(false);
  const [editRetry, setEditRetry] = useState(false);
  const [retryData, setRetryData] = useState(initialRetry);
  const [postIconView, setPostIconView] = useState(false);
  const [isDisplayGraph, setIsDisplayGraph] = useState(false);


  let completed_slug = localStorage && localStorage.getItem('active-slug')
  const getIconProfilePic = (icon) => {
    let profile_pic = icon && icon.attachments && icon.attachments[0] && icon.attachments[0].media && icon.attachments[0].media.url ? icon.attachments[0].media.url : null
    return (profile_pic ? profile_pic : (icon.gender && icon.gender === "female") ?  require("../../../../assets/images/female-user.svg") : require("../../../../assets/images/male-user.svg"))
  }

  const openEngagementModel = (campaign_icon_id, engagements) => {
    setEngagementData(prevState => ({
      ...prevState,
      campaign_icon_id: campaign_icon_id,
      platform_id: engagements[0].platform.id
    }))
    setEngagementModel(true);
  }
  
  const closeEngagementModel = (postData) => {
    setEngagementModel(false);
    setEngagementData(initialEngagementData)
    setActiveData(initialActiveData)
    setErrorMsg([])
  }

  const handleChange = (e, event) => {
    let {name , value, type} = e.target;
    if(event == 'click') {
      setEngagementData(prevState => ({
        ...prevState,
        [name] : value
      }))
    }
    
    if(event == 'change'){
      setEngagementData(prevState => ({
        ...prevState,
        [name] : value
      }))

      if(!value){
        setActiveData(prevState => ({
          ...prevState,
          [name] : false
        }))
      }
    }
  }

  const openRetryModal = (id, data)=> {
    setRetryData(prevState => ({
      ...prevState,
      engagement_id : data.id,
      post_id : data.entered_post_id,
      campaign_icon_id: id
    }))
    setOpenRetry(true)
  }

  const closeRetryModal = ()=> {
    setOpenRetry(false)
  }

  const updateEngagement = async () => {
    const { campaign_icon_id, platform_id, followers_count, likes_count, comments_count } = engagementData
    let data = {
      followers:followers_count,
      likes:likes_count,
      comments:comments_count
    }
    if(validateInput(data)){
      const response = await dispatch(updateEngagementPost(engagementData))
      if (response && response.status) {
        setEngagementModel(false);
        setPostEngagement([])
        NotificationManager.success(response.message)
        setEngagementData(initialEngagementData)
        setErrorMsg([])
        let newState = Object.assign({}, applicationData)
        let iconRecord = newState.data.find(a => a.id === campaign_icon_id)
        let iconRecordIndex = newState.data.findIndex(a => a.id === campaign_icon_id)
        if(iconRecord.engagements && iconRecord.engagements[0] && iconRecord.engagements[0].latest_engagement) {
          iconRecord.engagements[0].latest_engagement.likes_count = response.data.likes_count
          iconRecord.engagements[0].latest_engagement.comments_count = response.data.comments_count
          iconRecord.engagements[0].latest_engagement.percentage = response.data.percentage
        }
        applicationData.data[iconRecordIndex] = iconRecord
        setPostEngagement(applicationData.data)
        setActiveData(initialActiveData)
      } else {
        NotificationManager.error(response.message)
        setEngagementModel(false);
        setEngagementData(initialEngagementData)
        setActiveData(initialActiveData)
        setErrorMsg([])
      }
    }
  }

  const validateInput = (data) => {
    const Errors = [];
    Object.keys(data).map(key => {
      if(isEmpty(data[key])) {
        Errors.push({
          name: key,
          value: `Please provide value for ${key}.`
        })
        setErrorMsg(Errors)
      }
    })
    return isEmpty(Errors);
  }

  const openPostPreview = (platform)=> {
    setPostIconView(true)
    setSelectedPreviewIcon(platform)
  }

  const closePostPreview = ()=> {
    setPostIconView(false)
    setSelectedPreviewIcon(null)
  }

  const handleGraph = () => {
    setIsDisplayGraph(current => !current);
  }

  const iconPreview = selectedPreviewIcon
  const renderSlider = (drafts, id, caption, icon) => {
    return drafts.map((platform, index)  => {
      let media_url = platform.attachment && platform.attachment.media && platform.attachment.media.url 
      let media_type = platform.attachment && platform.attachment.attachment_type 
      platform.icon = icon
      platform.caption = caption
      return (
          <div key={platform.id} onClick={() => openPostPreview(platform)}>
            {
                media_type === 'video' ? (
                  <video controls >
                    <source src={media_url ? media_url : ''} type="video/mp4" />
                  </video>
                ) : (
                  <img  src={media_url ? media_url : ''} alt="img" />
                )
            }
            <span className="number-label">{index+1}/{drafts.length}</span>
            <a className="expand-img" href="javascript:void(0)">
              <img src={require( "../../../../assets/images/img_expand.png").default} alt="Expand" />
            </a>
          </div>

        )
    })
  }
  const renderIconMarkup = () => {
    let data = postEngagement && postEngagement.length > 0 ? postEngagement : applicationData.data
    return data.map(record => {
      return(
        <li key={record.id}>
          <div className="profile-caption-left">
            <IconCard 
              icon={record.icon} 
              engagements={record.engagements} 
              main_campaign_id={record.id} 
              handleDeleteCampaign={handleDeleteCampaign} 
              handleRejectCampaign={handleRejectCampaign} 
              handleMoveCampaignIcon={handleMoveCampaignIcon} 
              handleSocialMediaIcon={handleSocialMediaIcon} 
              platform_icons={record.platform_icon}
            />
          </div>
          <div className="profile-caption-right">
            <div className="caption-image-box caption-text-box-slider">
              {
                record.drafts && record.drafts.length > 0 ? (
                    <figure>
                  <Slider {...settings}>
                        {renderSlider(record.drafts, record.id, record.caption, record.icon)}
                  </Slider>
                    </figure>
                  ) : (
                  <figure>
                  </figure>
                  )
                
              }
              <div className="caption-text-box">
                <div className="caption-head-text">
                  <div style={{ float:'left' }}>
                  	<h3>Post statistics</h3>
                  	<h5><span style={{'font-weight': 'bold'}}>Posted at</span> - {
                      record.engagements && record.engagements[0].start_date ? 
                        moment(record.engagements[0].start_date).format('LL, h:mm:ss A')
                      : 
                        <a href="javascript:void(0)" onClick={() => openCalenderModel(record.engagements[0].id, record.id)}>Select Date</a>
                      
                    }
                    </h5>
                    {
                    	record.engagements && record.engagements[0].latest_engagement && record.engagements[0].latest_engagement.created_at && 
	                    <h5 style={{'margin': '5px 0 5px 0'}}><span style={{'font-weight': 'bold'}}>Last Updated at</span> - {moment(record.engagements[0].latest_engagement.created_at).format('LL, h:mm:ss A')}
                    	</h5>
	                  }
                   </div>


                  {
                    user && user.role === 'superadmin' ? (
                      <Tooltip title="Update Engagement" position="bottom" >
                        <div className="edit_posted" onClick={() => openEngagementModel(record.id, record.engagements)}><img className="edit__icon__posted" src={require("../../../../assets/images/icon_edit_gery.svg")} alt="Close" /></div>
                      </Tooltip>
                    ) : ('')
                  }
                </div>
                <div className="total-post-stastics">
                  <ul>
                    <li>
                      <h3>{record.engagements && record.engagements[0].latest_engagement && record.engagements[0].latest_engagement.likes_count || 0}</h3>
                      <p>Likes</p>
                    </li>
                    <li>
                      <h3>{record.engagements && record.engagements[0].latest_engagement && record.engagements[0].latest_engagement.comments_count || 0}</h3>
                      <p>Comments</p>
                    </li>
                    <li>
                      <h3>{record.engagements && record.engagements[0].latest_engagement && record.engagements[0].latest_engagement.percentage || 0}%</h3>
                      <p>Engagement</p>
                    </li>
                    {
                      user && user.role === 'superadmin' && (
                        <li>
                          {
                            record.insight  && record.insight.response ? (
                              record.insight.response.reach && record.insight.response.reach[0] ? (
                                <div>
                                  <h3>{record.insight.response.reach[0].value}</h3>
                                  <p>True reach</p>
                                </div>
                              ) : record.insight.response.carousel_album_reach && record.insight.response.carousel_album_reach[0]  ? (
                                <div>
                                  <h3>{record.insight.response.carousel_album_reach[0].value}</h3>
                                  <p>True reach</p>
                                </div>
                              ) : null
                            ) : (
                              null
                            )
                          }
                        </li>
                      )
                    }
                    {
                      user && user.role === 'superadmin' && (
                        <li>
                          {
                            record.insight  && record.insight.response ? (
                              record.insight.response.impressions && record.insight.response.impressions[0] ? (
                                <div>
                                  <h3>{record.insight.response.impressions[0].value}</h3>
                                  <p>Impressions</p>
                                </div>
                              ) : record.insight.response.carousel_album_impressions && record.insight.response.carousel_album_impressions[0]  ? (
                                <div>
                                  <h3>{record.insight.response.carousel_album_impressions[0].value}</h3>
                                  <p>Impressions</p>
                                </div>
                              ) : record.insight.response.plays && record.insight.response.plays[0]  ? (
                                <div>
                                  <h3>{record.insight.response.plays[0].value}</h3>
                                  <p>Impressions</p>
                                </div>
                              ) : null
                            ) : (
                              null
                            )
                          }
                        </li>
                      )
                    }
                    <li>
                    </li>
                  </ul>
                </div>
                  <div className="request-btns select-user-end">
                    {
                      <label className="select-user">
                        {
                          ((record.engagements && record.engagements[0].shortcode) || (record.engagements && record.engagements[0].entered_post_id)) && record.engagements[0].platform.name.toString().toLowerCase() == 'instagram' && (
                          	<a className="post-btn btn-rounded" target="_blank" href={record.engagements && record.engagements[0].shortcode ? `https://www.instagram.com/p/${record.engagements && record.engagements[0].shortcode}` : (record.engagements && record.engagements[0].entered_post_id.indexOf("www.instagram.com") > -1 ? record.engagements && record.engagements[0].entered_post_id : `https://www.instagram.com/p/${record.engagements && record.engagements[0].entered_post_id}`)} type="button"><img src={require("../../../../assets/images/img_instagram_white.svg")} />GO TO POST
                          	</a>
                          )
                        }
                        {
                        	((record.engagements && record.engagements[0].entered_post_id)) && record.engagements[0].platform.name.toString().toLowerCase() == 'tiktok' && (
                          	<a className="post-btn btn-rounded" target="_blank" href={record.engagements && record.engagements[0].entered_post_id} type="button"><img src={record.engagements[0].platform.logo} width="18%" />GO TO POST
                          	</a>
                          )	
                        }
                        {/*(applicationData.slug !== completed_slug) &&*/}
                        {
                           user && user.role === 'superadmin' && (
                            <a className="post-btn btn-rounded" href="javascript:void(0)" type="button" onClick={()=> openRetryModal(record.id, record.engagements[0])}>RETRY</a>
                          )
                        }
                      </label>
                    }
                  </div>
              </div>
            </div>
          </div>

        </li>
      )
    })
  }

  const EngagementModel = () => {
    return (
      <div id="invites" className="modal modal-flex" data-backdrop='static'>
        <div className="modal-dialog">
          <div className="data-modal-form">
            <h2 className="modal-head text-center">Update Engagement</h2>
            <form className="form-set">
              <div className="form-inputs" style={{"margin": "37px 0 0"}}>
                <label className={engagementData.followers_count ? "labelClass field-active" : "labelClass"}>Followers Count</label>
                <input type="number" name="followers_count" onClick={(e) => handleChange(e, 'click')}
                  onChange={(e) => handleChange(e, 'change')} value={engagementData.followers_count} required="true"/>
              </div>
              <h5 className="error">{errors && errors[0] && errors[0].name === 'followers' ? errors && errors[0].value : ''}</h5>
              <div className="form-inputs" style={{"margin": "37px 0 0"}}>
                <label className={engagementData.likes_count ? "labelClass field-active" : "labelClass"}>Likes Count</label>
                <input type="text" name="likes_count" value={engagementData.likes_count} required="true" onClick={(e) => handleChange(e, 'click')}
                  onChange={(e) => handleChange(e, 'change')}/>
              </div>
              <h5 className="error">{errors && errors[0] && errors[0].name === 'likes' ? errors && errors[0].value : ''}</h5>
              <div className="form-inputs" style={{"margin": "37px 0 0"}}>
                <label className={engagementData.comments_count ? "labelClass field-active" : "labelClass"}>Comments Count</label>
                <input type="text" name="comments_count" value={engagementData.comments_count} required="true" onClick={(e) => handleChange(e, 'click')}
                  onChange={(e) => handleChange(e, 'change')}/>
              </div>
              <h5 className="error">{errors && errors[0] && errors[0].name === 'comments' ? errors && errors[0].value : ''}</h5>
            </form>
            <br/>
            <div className="form-submittion">
              <button type="button" data-dismiss="modal" onClick={() => updateEngagement()}>Update</button>
              <button type="button" data-dismiss="modal" onClick={() => closeEngagementModel()}>CANCEL</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const CalenderModel = () => {
    return (
      <div id="invites" className="modal modal-flex" data-backdrop='static'>
        <div className="modal-dialog">
          <div className="data-modal-form">
            <form>
              <div>
                <DatePickerCalendar date={postedData.posted_date ?  new Date(postedData.posted_date) : new Date()} locale={enGB} onDateChange={(value, type) => handleDateSelect(value, type)}/>
              </div>
            </form>
            <div className="form-submittion">
              <button type="button" data-dismiss="modal" onClick={()=> submitPostedDate()}>Confirm</button>
              <button type="button" data-dismiss="modal" onClick={()=> closeCalenderModel()}>CANCEL</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const retryModel = () => {
    return (
      <Modal
        show={openRetry}
        onHide={()=> closeRetryModal()}
        className="posted-retry"
      >
        <div id="icon_request_proview" className="full-picture-popup">
          <div className="inner-bg-popup">
            <div className="inner-white-bg-popup">
              <div className="close_popup"><a href="javascript:void(0)" data-dismiss="modal" onClick={()=> closeRetryModal()}><img src={require("../../../../assets/images/icon_close_black.svg")} alt="Close" /></a></div>
              <h1 className="popup-head">Retry Engagement</h1>
              <form>
                <div className="form-group">
                  <h5 className="error">{errors && errors[0] && errors[0].name === 'post_id' ? 'Please provide value for post url' : ''}</h5>
                  <input type="text" name="post_id" value={retryData.post_id} onChange={(e)=> handleChangeRetry(e)} className="form-control" placeholder="URL" required="true" />
                </div>
              </form>
              <div className="form-submittion">
                <button type="button" data-dismiss="modal" onClick={()=> submitRetry()}>OK</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }


  const submitRetry = async ()=> {
    if(validateInput(retryData)){
      let data = {
        engagement_id: retryData.engagement_id,
        post_id: retryData.post_id
      }
      const response = await dispatch(retryEngagementData(data))
      if (response && response.status && response.data) {
        NotificationManager.success(response.data.message)
        setOpenRetry(false)
        setRetryData(initialRetry)
        setErrorMsg([])
        let newState = Object.assign({}, applicationData)
        let iconRecord = newState.data.find(a => a.id === retryData.campaign_icon_id)
        let iconRecordIndex = newState.data.findIndex(a => a.id === retryData.campaign_icon_id)
        iconRecord.engagements[0].entered_post_id = response.data && response.data.data && response.data.data.entered_post_id
        
        applicationData.data[iconRecordIndex] = iconRecord
        setPostEngagement(applicationData.data)
      } else {
        setOpenRetry(false)
        setRetryData(initialRetry)
      }
    }
  }
  const handleChangeRetry = (e)=> {
    let {name , value, type} = e.target;
    setRetryData(prevState => ({
      ...prevState,
      [name] : value,
    }))

  }

  const openCalenderModel = (engagement_id, campaign_icon_id) => {
    setCalendar(true);
    setPostedData(prevState => ({
      ...prevState,
      campaign_icon_id: campaign_icon_id,
      engagement_id: engagement_id
    }))

  }

  const closeCalenderModel = () => {
    setCalendar(false);
    setDatetime(null);
    setPostedData(initialPostedData)
  }

  const handleDateSelect = (value, type) => {
    setDatetime(value);
    setPostedData(prevState => ({
      ...prevState,
      posted_date: value
    }))
  }

  const submitPostedDate = async ()=> {
    const response = await dispatch(updatePostedDate(postedData))
    if (response && response.data && response.data.status) {
      setCalendar(false);
      setDatetime(null);
      NotificationManager.success(response.data.message)
      setErrorMsg([])
      let newState = Object.assign({}, applicationData)
      let iconRecord = newState.data.find(a => a.id === postedData.campaign_icon_id)
      let iconRecordIndex = newState.data.findIndex(a => a.id === postedData.campaign_icon_id)
      iconRecord.engagements[0].start_date = postedData.posted_date
      applicationData.data[iconRecordIndex] = iconRecord
      setPostEngagement(applicationData.data)
      setPostedData(initialPostedData)
    } else {
      NotificationManager.error(response && response.data.message)
      setCalendar(false);
      setDatetime(null);
      setPostEngagement(applicationData.data)
      setPostedData(initialPostedData)
      setErrorMsg([])
    }
  }

   const copyToClipboard = (slug)=> {
     navigator.clipboard.writeText(slug)
     setClipboardStatus(true)

     setTimeout(function(){ 
       setClipboardStatus(false)
     }, 1000);
   }
  
  return(
    <>
      <div className="pending-request-details pending-request-details-update">
        {engagementModel ? EngagementModel() : null}
        {calendar ? CalenderModel() : null}
        {openRetry ? retryModel() : null}
        <div className="requester-name">
        	<div className="campaignNameStatus">
            <div>
              <h2>  
                {applicationData.campaign_name}
                &nbsp; {user && user.role === 'superadmin' && <img src={require("../../../../assets/images/notification-bell.svg")} alt="img" onClick={handleSendNotification} />}
              </h2>
            </div>
            <div  className='action-bar-status' style={{'float':'right','margin-right':'15px'}}>
              STATUS: <span className={`${applicationData.campaign_status}`} style={{'text-transform':'uppercase'}}>{applicationData.campaign_status}</span>
            </div>
          </div>
          {
            user && user.role === 'superadmin' && applicationData.slug && (
              <p className="campaign-icon-slug campaign-icon-slug-image" id="slug-text">
                {applicationData.slug ? `Campaign ID : `+applicationData.slug : ''} 
                <img src={require("../../../../assets/images/clipboard.svg")} alt="img" onClick={(e)=> copyToClipboard(applicationData.slug)}/>
                { clipboardStatus && (<span>Copied !</span>)}
              </p>
            )
          }
          <p>{applicationData.community_name}</p>
        </div>
{/*				<div>
					<button type="button" class="btn" style={{fontWeight: 'bold', color: 'white', background: '#209AF2', marginTop:'1px',marginBottom:'10px'}} onClick={handleSendNotification}>Send Notification</button>
				</div>*/}
        <div className="requester-other-details requester-other-details-update">
          <ul>
            <li style={{"width":"22.33%"}}>
              <h2>{total_verified}/{total_count}</h2>
              <p>Verified</p>
            </li>
            <li style={{"width":"22.33%"}}>
              <h2 style={{"font-size":"25px"}}>{applicationData.posted_tab_engagements && applicationData.posted_tab_engagements.overall_post_engagement !== null ? (applicationData.posted_tab_engagements.overall_post_engagement).toFixed(2) : 0}%</h2>
              <p style={{"line-height": "12px", "width": "190px"}}>Campaign Engagement</p>
              <h2 style={{"font-size":"25px","margin": "0 0 7px"}}>{applicationData.posted_tab_engagements && applicationData.posted_tab_engagements.average_post_engagement !== null ? applicationData.posted_tab_engagements.average_post_engagement : 0}%</h2>
              <p style={{"line-height": "12px"}}>Avg. Post Engagement</p>
            </li>
            <li style={{"width":"22.33%"}}>
              <h2>{applicationData.potential_reach || 0}</h2>
              <p>Total Followers</p>
            </li>
            <li style={{"width":"22.33%"}}>
              <h2>{applicationData.posted_tab_engagements && applicationData.posted_tab_engagements.total_interactions && applicationData.posted_tab_engagements.total_interactions !== null ? applicationData.posted_tab_engagements.total_interactions : 0}</h2>
              <p>Total Interactions</p>
            </li>
          </ul>
          {/*<div className="last-child-link"><a href="javascript:void(0)">GENERATE PDF</a></div>*/}
        </div>
        {
          user && user.role === 'superadmin' && (
            ageInsight && ageInsight.length > 1 && (
              <div className="center-block">
                <button className="login-btn btn-rounded mt-30" onClick={(e) => handleGraph()}>{isDisplayGraph ? "Hide Graph" : "Show Graph"}</button>
              </div>
            )
          )
        }
        {
          user && user.role === 'superadmin' && (
            <div className={"graph-box" + (isDisplayGraph ? "" : " hide")}>
              <ul>
                {
                  genderInsight && genderInsight.length > 1 && (
                    <li>
                      <h3>Gender Distribution</h3>
                        <div className="outer-graph-box">
                          <div class="graph-set-box">
                            <Chart
                              width={'280px'}
                              height={'280px'}
                              chartType="PieChart"
                              loader={<div>Loading Chart</div>}
                              data={genderInsight}
                              options={{
                                title: '',
                                legend: { position: 'top', 'alignment':'center' },
                                pieStartAngle: 100,
                                slices: [ {color: "#add8e6"}, {color: "#FFB6C1"}, {color: "#808080"} ],
                                tooltip: {
                                  text: 'percentage'
                                },
                                chartArea:{left:0,top:50,width: "100%"},
                               /* tooltip : { trigger: 'none' },*/
                              }}
                              rootProps={{ 'data-testid': '1' }}
                            />
                          </div>
                        </div>
                    </li>
                  )
                }
                {
                  ageInsight && ageInsight.length > 1 && (
                    <li>
                      <h3>Age Distribution</h3>
                        <div className="outer-graph-box">
                          <div class="graph-set-box">
                            <Chart
                              chartType="ColumnChart"
                              loader={<div>Loading Chart</div>}
                              data={ageInsight}
                              options={{
                                // Material design options
                                legend: { position: 'top' },
                               /* tooltip : { trigger: 'none' },*/
                                hAxis: {format: 'percent'},
                                colors: ['#add8e6', '#FFB6C1'],
                                vAxis: { minValue: 0},
                                chartArea:{top:50,width: "100%"},
                              }}
                              rootProps={{ 'data-testid': '2' }}
                            />
                          </div>
                        </div>
                    </li>
                  )
                }
              </ul>
              <ul>
                {
                  countryInsight && countryInsight.length > 1 && (
                    <li className="li-50">
                      <h3>Top Countries</h3>
                      <div className="outer-graph-box">
                        <div class="graph-set-box">
                          <Chart
                            width={'580px'}
                            height={'400px'}
                            chartType="BarChart"
                            loader={<div>Loading Chart</div>}
                            data={countryInsight}
                            options={{
                              title: '',
                              legend: { position: 'none' },
                              vAxis: { format: 'percent' },
                              tooltip : { text: 'percentage' },
                              chartArea:{left:170,top:40},
                              colors: ['#add8e6'],
                              annotations: {
                                textStyle: {
                                  color: 'black',
                                }
                              }
                            }}
                            // For tests
                            rootProps={{ 'data-testid': '1' }}
                          />
                        </div>
                      </div>
                    </li>
                  )
                }
                {
                  cityInsight && cityInsight.length > 1 && (
                    <li className="li-50">
                      <h3>Top Cities</h3>
                      <div className="outer-graph-box">
                        <div class="graph-set-box">
                          <Chart
                            width={'580px'}
                            height={'400px'}
                            chartType="BarChart"
                            loader={<div>Loading Chart</div>}
                            data={cityInsight}
                            options={{
                              title: '',
                              legend: { position: 'none' },
                              vAxis: { format: 'percent' },
                              tooltip : { text: 'percentage' },
                              chartArea:{left:120,top:40},
                              colors: ['#add8e6'],
                              annotations: {
                                textStyle: {
                                  color: 'black',
                                }
                              }
                            }}
                            // For tests
                            rootProps={{ 'data-testid': '1' }}
                          />
                        </div>
                      </div>
                    </li>
                  )
                }
              </ul>
            </div>
          )
        }
        
      </div>
      <div className="filter-views">
        <Filters 
          applyFilters={applyFilters}
          filterData={filterData}
          clearFilters={clearFilters}
        />

        <div className="communities-views">
          <div className="inner-communities-views">
            {
              applicationData.budget ? (
                <div className="progess-action-bar">
                  <div className="bar-setup">
                    <div className="progress-bar-value"> <strong>{applicationData.budget.budget_reached}</strong> <span>{applicationData.budget.budget_percent}%</span> <strong>{applicationData.budget.total_budget}</strong> </div>
                    <div className="bar-value-fill">
                      <div className="unfill-bar"></div>
                      <div className="filled-bar filled-percent" style={{width: `${applicationData.budget ? (applicationData.budget.budget_percent > 100) ? 100 : applicationData.budget.budget_percent : 0}%`}}></div>
                    </div>
                    <div className="currency-signature">Budget reached ({applicationData.budget.currency_code})</div>
                  </div>
                  <div className="action-bar text-right hide"> <a href="javascript:void(0)" className="redirect-link">APPROVE DRAFTS</a> </div>
                </div>
                ) : null
            }

            <div className="sort-search-bar">
              <ul>
                <li onClick={(e) => applySorting('created_at')}><a className={sortedClasses('created_at')} href="javascript:void(0)">Recent</a></li>
                <li onClick={(e) => applySorting('followers')}><a className={sortedClasses('followers')} href="javascript:void(0)">Followers</a></li>
                <li onClick={(e) => applySorting('percentage')}><a className={sortedClasses('percentage')} href="javascript:void(0)">Engagement</a></li>
                <li onClick={(e) => applySorting('price')}><a className={sortedClasses('price')} href="javascript:void(0)">Cost</a></li>
                <li onClick={(e) => applySorting('city')}><a className={sortedClasses('city')} href="javascript:void(0)">City</a></li>
                <li>
                  <form onSubmit={(e) => applyFilters(e)}>
                    <div className="search-input">
                      <input type="search" value={searchVal} onChange={(e) => setSearchValue(e.target.value)} name="searchVal" placeholder="Search" />
                    </div>
                  </form>
                </li>
              </ul>
            </div>
            <div className="communities-users-list">
              <div className="users-list-sets clearfix">
                <ul className="full-profile-caption">
                  {renderIconMarkup()}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        postIconView ? (
          <Modal
            show={postIconView}
            onHide={() => closePostPreview()}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <div id="posted_icon_preview" className="full-picture-popup">
              <div className="inner-bg-popup">
                <div className="inner-white-bg-popup">
                  <div className="close_popup"><a href="javascript:void(0)" data-dismiss="modal" onClick={()=> closePostPreview()}><img src={require("../../../../assets/images/icon_close_black.svg")} alt="Close" /></a></div>
                  <h1 className="popup-head">Preview</h1>
                  <div className="draft-image-view">
                    <figure>
                      {
                        iconPreview &&  iconPreview.attachment && iconPreview.attachment.attachment_type === 'video' ? (
                          <video controls>
                            <source src={iconPreview && iconPreview.attachment && iconPreview.attachment.media.url} type="video/mp4" />
                          </video>
                        ) : (
                          <img src={iconPreview && iconPreview.attachment && iconPreview.attachment.media.url} alt="img" />
                        )
                      }
                    </figure>
                  </div>
                  <div className="post-caption">
                    <p className="">{iconPreview && iconPreview.caption}</p>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        ) : null
      }
    </>
  )
}