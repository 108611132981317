import './DeletePopup.css'
import React, { useEffect,useState} from 'react';
import Field from '../../Components/TextField/Field';
import Modal from 'react-bootstrap/Modal'
import { Multiselect } from 'multiselect-react-dropdown';
import CustomSelect from "../../Components/CustomSelect/Select";
import { capitalize } from 'lodash';

export default ({
  editCommunities= false,
  updateUserCommunities = () => {},
  closeModel = () => {},
  communitiesData = [],
  selectedCommunities = [],
  onSelect = () => {},
  onRemove = () => {},
  roles = [],
  userRole = ''
}) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [role, setRole] = useState(userRole);

  const closeModelAction = () => {
    setErrorMsg("");
    setRole("");
    closeModel(false);
  }

  const handleChange = (e) => {
    const name = e.target.name;
    if (name === 'role'){
      setRole(e.target.value);
    }
  }

  return(
    <Modal
      show={editCommunities}
      onHide={() => closeModelAction()}
      className="verify-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="data-modal-form">
        <h2 className="modal-head">Update User</h2>
        <form>
          <div className="form-group">
            <label>Role</label>
            <CustomSelect name="role" className="custom-select" value={(role || userRole)} onChange={handleChange}>
              {
                roles.map(role => {
                  return <option key={Math.random()} value={role}>{capitalize(role || userRole)}</option>
                })
              }
            </CustomSelect>
          </div>
          {
            ((role || userRole) === 'user' || (role || userRole) === '') && (
            <div className="form-group">
              <Multiselect
              options={communitiesData}
              selectedValues={selectedCommunities}
              onSelect={onSelect}
              onRemove={onRemove}
              displayValue="name"
              className="custom-select sources"
              />
            </div>)
          }
        </form>
        <div className="form-submittion">
          <button type="button" data-dismiss="modal" onClick={() => updateUserCommunities((role || userRole))}>UPDATE</button>
          <button type="button" data-dismiss="modal" onClick={() => closeModelAction()}>CANCEL</button>
        </div>
      </div>
    </Modal>
  )
}