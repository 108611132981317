import React, { useState, useEffect } from 'react';
import IconCard from '../../CommunityIconCard/Card';
import Pagination from '../../Common/Pagination';
import Filters from '../../../Components/Filters/Filters';
import CustomSelect from "../../../Components/CustomSelect/Select";
import classNames from 'classnames';
import { fetchCommunityIcons, fetchAll, setCommunityFilters, setCommunityIconPaginationData } from '../../../Redux/Actions/Community';
import { fetchPlatform } from '../../../Redux/Actions/Platform';
import { useDispatch, useSelector } from 'react-redux';
import { uniq } from 'lodash';
import { NotificationManager } from 'react-notifications';
import { setLoader } from '../../../Redux/Actions/Loader';

export default ({
	selectedCommunity = '',
	renderCommunitySelector = () => { },
	handleCommunitySelect = () => { }
}) => {
	const initialFilter = useSelector(state => state.communityFilters)
	const [icons, setIcons] = useState([]);
	const [platforms, setPlatforms] = useState([]);
	let [filterData, setFilterData] = useState(initialFilter);
	const initialPaginationData = useSelector(state => state.communityIconPaginationData)
	const [paginationData, setpaginationData] = useState(initialPaginationData);
	const dispatch = useDispatch();

	useEffect(async () => {
		// Fetch all created communities
		await dispatch(setLoader(true));
		await fetchCommunityIconRecords(selectedCommunity, filterData);
	}, [selectedCommunity, paginationData.sort_order, paginationData.sort_by]);

	useEffect(async () => {
		setpaginationData(initialPaginationData)
	}, [initialPaginationData]);


	useEffect(async () => {
		// Fetch all Platforms
		await fetchAllPlatform();

	}, []);

	const applyFilters = async (e, data = {}) => {
		// TO DO - add filter paramters on fetch Request
		e.preventDefault();
		data.search = filterData.search
		data.page = 1

		Object.keys(data).map(key => {
			if (!data[key]) {
				delete data[key]
			}
		})
		await dispatch(setCommunityFilters(data))
		setFilterData(data)
		await dispatch(setLoader(true));
		fetchCommunityIconRecords(selectedCommunity, data)
	}

	const handleFilterChange = (e) => {
		let { name, value, checked, id } = e.target;
		if (name === 'platform' || name === 'gender') {
			value = checked ? [...filterData[name], value] : filterData[name].splice(filterData[name].indexOf(id), 1)
			value = uniq(value)
		}

		setFilterData(prevState => ({
			...prevState,
			[name]: value
		}))
	}

	const applySorting = (type) => {
		const sortOrder = paginationData.sort_by === type ? paginationData.sort_order === 'asc' ? 'desc' : 'asc' : 'asc'
		dispatch(setCommunityIconPaginationData({
			...paginationData,
			sort_by: type,
			sort_order: sortOrder
		}))
		// setpaginationData(prevState => ({
		// 	...prevState,
		// 	sort_by: type,
		// 	sort_order: sortOrder
		// }))
	}

	const sortedClasses = (type) => {
		var sortClasses = classNames(
			{ [paginationData.sort_order]: paginationData.sort_by === type }
		)
		return sortClasses
	}

	const fetchAllPlatform = async () => {
		const response = await dispatch(fetchPlatform())
		if (response.success)
			setPlatforms(response.data);
	}

	const fetchCommunityIconRecords = async (communityId, params = {}) => {
		if(filterData.search && filterData.search !== ''){
			filterData.page = 1
		}
		params = { ...params, ...filterData }
		params['sort_by'] = paginationData.sort_by
		params['sort_order'] = paginationData.sort_order
		const response = await dispatch(fetchCommunityIcons(communityId, params))
		if (response.success) {
			await dispatch(setLoader(false));
			setIcons(response.data.data)
			dispatch(setCommunityIconPaginationData({
				...paginationData,
				per_page: response.data.per_page,
				current_page: response.data.current_page,
				total_count: response.data.total_count,
				total_pages: response.data.total_pages
			}))
			// setpaginationData(prevState => ({
			// 	...prevState,
			// 	per_page: response.data.per_page,
			// 	current_page: response.data.current_page,
			// 	total_count: response.data.total_count,
			// 	total_pages: response.data.total_pages
			// }))
		} else {
			await dispatch(setLoader(false));
			NotificationManager.error(response.data.error, '', 10000, () => {})
		}
	}

	const renderIconMarkup = () => {
		return icons.map((icon) => {
			return <li key={Math.random()}><IconCard icon={icon} /></li>
		})
	}

	const handlePageChange = (page) => {
		filterData.page = page
		fetchCommunityIconRecords(selectedCommunity, { page: page, ...filterData })
	}

	const clearFilters = async (e, data = {}) => {
		// TO DO - add filter paramters on fetch Request
		e.preventDefault();
		data = {}
		filterData = {}
		await dispatch(setCommunityFilters(filterData))
		setFilterData(filterData)
		await dispatch(setLoader(true));
		fetchCommunityIconRecords(selectedCommunity, filterData)
	}

	return (
		<div className="filter-views">
			<Filters
				applyFilters={applyFilters}
				clearFilters={clearFilters}
				filterData={filterData}
				filterType="community"
			/>

			<div className="communities-views">
				<div className="inner-communities-views">
					<div className="select-communities-bar d-flex" style={{ alignItems: "flex-end" }}>
						<CustomSelect name="sources" id="select-community01" value={selectedCommunity} className="custom-select sources communities-selector" placeholder="Source Type" onChange={(e) => handleCommunitySelect(e)}>
							<option value='' key={Math.random()}>
								All Brand Communities.
						</option>

							{renderCommunitySelector()}
						</CustomSelect>
						<div className="requester-other-details">
							<ul>
								<li>
									<h2>{paginationData.total_count}</h2>
									<p>Brand Community Creators</p>
								</li>
							</ul>
						</div>
					</div>

					<div className="sort-search-bar">
						<ul>
							<li onClick={(e) => applySorting('created_at')}>
								<a href="javascript:void(0)" className={sortedClasses('created_at')}>Recent
					  </a>
							</li>
							<li onClick={(e) => applySorting('followers_count')}>
								<a className="sort-activer" href="javascript:void(0)" className={sortedClasses('followers_count')}>Followers
					  </a>
							</li>
							{/*<li onClick={(e) => applySorting('engagement')}>
								<a href="javascript:void(0)" className={sortedClasses('engagement')}>Engagement
					  </a>
							</li>
								<li>
									<a href="javascript:void(0)" className="display-none-cost">Cost
					  		</a>
							</li>*/}
							<li onClick={(e) => applySorting('city')}>
								<a href="javascript:void(0)" className={sortedClasses('city')}>City
						</a>
							</li>
							<li>
								<form onSubmit={(e) => applyFilters(e)}>
									<div className="search-input">
										<input type="search" value={filterData.search} className="" name="search" placeholder="Search" onChange={handleFilterChange} />
									</div>
								</form>
							</li>
						</ul>
					</div>
					<div className="communities-users-list">
						<div className="users-list-sets clearfix">
							<ul>
								{
									icons.length > 0 ? renderIconMarkup() : <li>No results.</li>
								}
							</ul>
						</div>
					</div>
					{
						paginationData.total_pages > 1 && (
							<div className="padination-numbers">
								<Pagination
									current_page={paginationData.current_page}
									per_page={paginationData.per_page}
									total_count={paginationData.total_pages * paginationData.per_page}
									setpaginationData={handlePageChange}
								/>
							</div>
						)
					}
				</div>
			</div>
		</div>
	)
}