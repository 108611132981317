import { SET_ACTIVE_ASIDE } from '../Actions/Types/Types';

export const activeAside = (state = false, action) => {
  switch(action.type) {
    case SET_ACTIVE_ASIDE:
      return action.value

    default: 
      return state;
  }
}
