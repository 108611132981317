import './DeletePopup.css'
import Modal from 'react-bootstrap/Modal'

export default({
  visible = false,
	closeModel= () => {},
  handleCloseModal= () => {},
	type = ''
}) =>{
  return(
    <Modal
      show={visible}
      onHide={() => handleCloseModal()}
      className="verify-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="data-modal-form">
        <h2 className="modal-head text-center">Are you sure?</h2>
        <h4 className="center-text">You want to {type}</h4>
        <div className="form-submittion">
          <button type="button" data-dismiss="modal" onClick={() => closeModel(true, type)}>YES</button>
          <button type="button" data-dismiss="modal" onClick={() => handleCloseModal()}>CANCEL</button>
        </div>
      </div>
    </Modal>
  )

}