import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { SET_CAMPAIGN_LIST_COMMUNITY, SET_CAMPAIGN_LIST_SEARCH_VALUE, SET_ICON_FILTERS, CAMPAIGN_LIST_SORT_FILTERS, SET_CAMPAIGN_ONBOARDING_FILTER_DATA, SET_TAB_SELECTION, SET_CAMPAIGN_LIST_TAB, SET_SELECTED_CAMPIAGN} from '../Actions/Types/Types';

export function create(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.CREATE()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function update(slug, data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.UPDATE()}/${slug}`;
  return dispatch => axios.put(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function fetchCommunityCampaigns(id, params = {}, status = '') {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.FETCH_ALL_CAMPAING()}?community_id=${id}&status=${status}`;
	return dispatch => axios.get(apiURL, {
    params: params
  })
	.then(res => {
		return res.data;
	}).catch((err) => {
		return err.response;
	});
}

export function fetchCampaignApplications(id = '', status = 'pending', params = {}) {
	let apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH_V2}${API_CONFIG.END_POINTS.CAMPAIGN.FETCH_APPLICATIONS()}?campaign_id=${id}&status=${status}`;
  // To Do - Pass params in formatted manner

  apiURL += `&sort_by=${params.sort_by}`
  apiURL += `&sort_order=${params.sort_order}`

	return dispatch => axios.get(apiURL, {
    params: params
  })
  .then(res => {
    dispatch(setActiveCampaign(`${id}/on-boarding`));
		return res.data;
	}).catch((err) => {
		return err.response;
	});
}

export function setCampaignsOpen(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.SET_OPEN()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function updateCampaignStatus(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.UPDATE_CAMPAIGN_STATUS()}`;
  return dispatch => axios.put(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function selectAnIcon(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH_V2}${API_CONFIG.END_POINTS.CAMPAIGN.SELECT_ICON()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function iconApproveDraft(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.APPROVE_DRAFT()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function unSelectIcon(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH_V2}${API_CONFIG.END_POINTS.CAMPAIGN.UNSELECT_ICON()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function removeAnIcon(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH_V2}${API_CONFIG.END_POINTS.CAMPAIGN.REMOVE_ICON()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}


export function fetchOneCampaign(id) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.FETCH_ONE()}/${id}`;
	return dispatch => axios.get(apiURL)
	.then(res => {
		return res.data;
	}).catch((err) => {
		return err.response;
	});
}

export function verifyPost(data) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH_V2}${API_CONFIG.END_POINTS.CAMPAIGN.VERIFY_POST()}`;
	return dispatch => axios.post(apiURL, data)
	.then(res => {
		return res.data;
	}).catch((err) => {
		return err.response;
	});
}

export function setCampaignsCompleted(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.SET_COMPLETED()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      return res.data;
    }).catch((err) => {
      return  {status: false, message: err.response.data.message}
    });
}

export function deleteCampaign(slug) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.DELETE()}?slug=${slug}`;
  return dispatch => axios.delete(apiURL)
  .then(res => {
    if(res.status) {
      return {status: true, message: res.data.message}
    } else {
      return {status: false, message: res.message}
    }
  }).catch((err) => {
    return {status: false, message: err.response.data.message}
  });
}

export function updateEngagementPost(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.UPDATE_ENGAGEMENT()}`;
  return dispatch => axios.post(apiURL, data)
  .then(res => {
    return res.data;
  }).catch((err) => {
    return {status: false, message: err.response.data.message}
  });
}

export function updateRequest(data) {
  let apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.SAVE_COMMENT()}`;
  if(data.type === 'request'){
    apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.SEND_REQUEST()}`;
  }
  delete data.type
  return dispatch => axios.put(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function approveDrafts(data, type) {
  let apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.APPROVED_DRAFTS()}`;
  if(type === 'story'){
    apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.APPROVED_STORY()}`;
  }
  return dispatch => axios.put(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function approvePost(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.APPROVED_POST()}`;
  return dispatch => axios.put(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function updatePostedDate(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.UPDATE_POSTED_DATE()}`;
  return dispatch => axios.put(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function declineDrafts(data, type) {
  let apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.DECLINE_DRAFTS()}`;
  if(type === 'story'){
    apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.DECLINE_STORY()}`;
  }

  return dispatch => axios.put(apiURL, data)
  .then(res => {
    return res;
  }).catch((err) => {
    return err.response;
  });
}
export function retryEngagementData(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.RETRY_ENGAGEMENT()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function updateDraftCaption(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.UPDATE_CAPTION()}`;
  return dispatch => axios.put(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function refreshIconInsight(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.REFRESH_ICON_INSIGHT()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function deleteCampaignIcon(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.DELETE_CAMPAIGN_ICON()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function rejectCampaignIcon(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.REJECT_CAMPAIGN_ICON()}`;
  return dispatch => axios.put(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function moveCampaignIcon(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.MOVE_CAMPAIGN_ICON()}`;
  return dispatch => axios.put(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function socialMediaValue(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.SOCIAL_MEDIA_VALUE()}`;
  return dispatch => axios.put(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}


export function allCampaigns() {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.ALL_CAMPAIGNS()}`;
  return dispatch => axios.get(apiURL)
  .then(res => {
    return res.data;
  }).catch((err) => {
    return err.response;
  });
}

export function uploadDrafts(id, data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_SUPERADMIN_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.UPLOAD_DRAFT()}${id}/upload_draft`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function sendTabNotification(data){
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CAMPAIGN.TAB_NOTIFICATION()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function setCampaignOnBoardFilters(value = {}) {
  return {
    type: SET_CAMPAIGN_ONBOARDING_FILTER_DATA,
    value
  }
}

export function setSelection(value = 'pending') {
  return {
    type: SET_TAB_SELECTION,
    value
  }
}

export function setCampaignListTab(value = '') {
  return {
    type: SET_CAMPAIGN_LIST_TAB,
    value
  }
}

const initailSortFilters = { per_page: '', current_page: 1, total_pages: '', total_count: '', sort_by: 'created_at', sort_order: 'asc' }
export function setIconFilters(value = initailSortFilters) {
  return {
    type: SET_ICON_FILTERS,
    value
  }
}

const initialPageData = { per_page: 25, current_page: 1, total_pages: '', total_count: '', sort_by: 'created_at', sort_order: 'asc' }
export function setCampaignFiltersData(value = initialPageData) {
  return {
    type: CAMPAIGN_LIST_SORT_FILTERS,
    value
  }
}

export function setCampaingListSearch(value = '') {
  return {
    type: SET_CAMPAIGN_LIST_SEARCH_VALUE,
    value
  }
}

export function setActiveCampaign(value = '') {
  return {
    type: SET_SELECTED_CAMPIAGN,
    value
  }
}

export function setCampaignListCommunity(value = '') {
  return {
    type: SET_CAMPAIGN_LIST_COMMUNITY,
    value
  }
}

