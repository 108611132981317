import axios from 'axios';

export default function setAuthorizationToken(token, organization_id) {
  const storeorganization_id = localStorage.getItem('organization_id') || "";

  if(token) {
    axios.defaults.headers.common['Authorization'] = token;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['organization_id'] = (organization_id === undefined) ? storeorganization_id : organization_id
}
