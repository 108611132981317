import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import setAuthorizationToken from '../../utils/SetAuthorizationToken';
import { SET_ORGANIZATION_SEARCH_DATA, SET_ORGANIZATION, SET_ORGANIZATION_LIST, SET_ORGANIZATION_PAGINATION_DATA } from '../../Redux/Actions/Types/Types';

export function fetchOrganization(userId) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION.FETCH_ONE()}${userId}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
    const success = res.data.status !== 'error'
	    if(success) {
        dispatch(setOrganization(res.data.data));
        return {status: true, data: res.data.data}
	    } else {
        return { status: false, message: res.data.message };
	    }
    }).catch((err) => {
      return err.response;
    });
}

export function updateOrganization(organizationId, data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION.FETCH_ONE()}${organizationId}`;
  return dispatch => axios.put(apiURL, data)
    .then(res => {
    const success = res.data.status !== 'error'
	    if(success) {
        dispatch(setOrganization(res.data.data));
        return {status: true, message: res.data.message}
	    } else {
        return { status: false, message: res.data.message };
	    }
    }).catch((err) => {
      return { status: false, message: err.response.data.error || err.response.data.message};
    });
}

export function organizationList(data) {
  const params = `?page=${data.page}&search=${data.search}&sort_by=${data.sort_by}&sort_order=${data.sort_order}`
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_SUPERADMIN_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION.FETCH_ONE()}${params}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      const success = res.data.status !== 'error'
	    if(success) {
        
        return {status: true, data: res.data}
	    } else {
        return { status: false, message: res.data.message };
	    }
    }).catch((err) => {
      return err.response;
      // return { status: false, message: err.response.data.error || err.response.data.message};
    });
}


export function createNotification(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION.CREATE_NOTIFICATION()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      const success = res.data.status !== 'error'
      if(success) {
        return {status: true, message:res.data.message }
      } else {
        return { status: false, message: res.data.message };
      }
  }).catch((err) => {
    return err.response;
    // return { status: false, message: err.response.data.error || err.response.data.message};
  });
}

export function saveOrganization(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_SUPERADMIN_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION.CREATE_ORGANISATION()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      const success = res.data.status !== 'error'
      if(success) {
        return {status: true, data: res.data, message: res.data.message}
      } else {
        return { status: false, message: res.data.message };
      }
    }).catch((err) => {
      return err.response;
      // return { status: false, message: err.response.data.error || err.response.data.message};
    });
}


export function setOrganization(organization) {
  return {
	type: SET_ORGANIZATION,
	organization: organization
  }
}

export function setOrganizationList(organizationList) {
  return {
	type: SET_ORGANIZATION_LIST,
	organizationList: organizationList
  }
}

export function switchOrganization(organizationId) {
  const token = localStorage.getItem('manageToken')
  setAuthorizationToken(token, organizationId)
}

export function setOrganizationPaginationData(value) {
  return {
    type: SET_ORGANIZATION_PAGINATION_DATA,
    value
  }
}

export function setSearchValue(value) {
  return {
    type: SET_ORGANIZATION_SEARCH_DATA,
    value
  }
}
