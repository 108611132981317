import React from 'react';
import { useSelector} from "react-redux";
import {switchOrganization} from '../../Redux/Actions/Organizations'
import { NotificationManager} from 'react-notifications';

export default () => {
	const user = useSelector(state => state.auth.user);
	const organization_id = localStorage.getItem('organization_id') || ""

  const resetOrganization = () => {
	localStorage.removeItem('organization_id');
    switchOrganization("");
    NotificationManager.success('Company reset successfully.')
    window.location.reload();
  }
  
  return(
    <>
    {user && user.role === 'superadmin' && organization_id ? (
        <div className="reset-data">
          <a href="javascript:void(0)" title="Reset" onClick={resetOrganization}><img src={require("../../assets/images/refresh.png").default} /></a>
        </div>
      ) : null
    } 
    </>
  )
}