import PlatformDetails from '../Platform/Details';
import { Link } from 'react-router-dom'
import { iconStatus } from '../../utils/common';
import { useHistory } from "react-router-dom";
import Tooltip from '../Tooltip'
import { trim, toLower } from 'lodash';

export default ({ 
	icon = {},
	showSelection = false,
	handleSelect = () => {},
	handleRemove = () => {},
	handleUnSelect = () => {},
	showUnselect = false,
	showPlatform = true,
	showStatus = false
}) => {
	const id = icon.id || icon.icon_id
	const isSelected = showSelection ? ['selected', 'availability_accepted', 'availability_declined', 'shortlisted', 'availability_pending'].indexOf(icon.status) > -1 : false
	const history = useHistory();
	const renderPlatforms = (data = []) => {
		data = data ? data : []
		return data.map(platform => {
			// return <PlatformDetails key={Math.random()} platform={platform} />
			return (

					<div className="social-media">
						<h5 onClick={(e)=> clickOnLogo(platform)} style={{"cursor": "pointer"}}>
							{platform.name === "instagram" ? <img src={require("../../assets/images/img_instagram.svg")} alt="img" /> : <img src={require("../../assets/images/img_tiktok.svg")} alt="img" />}
							{platform.name}
						</h5>

						<div className="social-meter">
							<div className="social-percentages">
								<strong>{platform.followers_count || platform.followers_count || 0}
								</strong>Followers
							</div>
							<div className="social-percentages">
								<strong>{platform.engagement || 0}%
								</strong>Engagement
							</div>
							<div className="social-percentages">
								<strong>{trim(platform.cost) || 'N/A'}
								</strong>Cost
							</div>
						</div>
					</div>

				)
		})
	}

	const clickOnLogo = (platform) => {
		if(platform && platform.name === 'instagram'){
			window.open(`https://www.instagram.com/${platform.username}`, '_blank')
		} else {
			window.open(`https://www.tiktok.com/${platform.username}`, '_blank')
		}
	}

	const handleRedirect = (id) => {
		window.open(`/profile/${id}`, '_self')
	}

	const statusClass = () => {
		let classVal;
		switch(icon.status) {
			case 'availability_accepted':
				classVal = 'btn btn-success'
				break;
			case 'availability_declined':
				classVal = 'btn btn-danger';
				break;
			case 'shortlisted':
				classVal = 'hide'
				break;
			case 'availability_pending':
				classVal = 'btn btn-default'
				break;

			default:
				classVal = 'hide'
				break;
		}
		return classVal;
	}

	const profile_pic_icon = (icon) => {
		if (icon.profile_pic) {
			return icon.profile_pic
		} else {
			switch (toLower(trim(icon.gender))) {
				case 'male':
					return require('../../assets/images/default_male_user_icon.svg')
				case 'female':
					return require('../../assets/images/default_female_user_icon.svg')
				default:
					return require('../../assets/images/default_male_female_icon.svg')
			}
		}
	}

	const renderPlatformsLinks = (icon) =>{
		return icon.platform.map(platform => {
			switch (platform.name) {
				case 'instagram':
					return <li><a target="_blank" href={`https://www.instagram.com/${(icon.username || platform.username)}`}>Go to Instagram</a></li>
				case 'tiktok':
					return <li><a target="_blank" href={`https://www.tiktok.com/${(icon.username || platform.username)}`}>Go to Tiktok</a></li>
				default:
					return null
			}
		})
	}

	return(
		<>
			<div className="community-user-details">
				<div className="small-profile-photo">
					<img src={profile_pic_icon(icon)} />
				</div>
				
				<div className="user-mark-details">
					<h3>
						<Tooltip title={icon.name} position="bottom" >
							<p style={{"cursor": "pointer"}} onClick={(e)=> handleRedirect(id)} >{icon.name}</p>
						</Tooltip>
					</h3>
					<div className="star-more">
					  <div className="mark-star">
						{/* <a href="javascript:void(0)" className="saved-star"> */}
						{/* </a> */}
					  </div>
					  <div className="dropdown default-dropdown">
						<a href="javascript:void(0)" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
						  <img src={require("../../assets/images/icon_more_menu.svg")} />
						</a>
						<ul className="dropdown-menu">
							{renderPlatformsLinks(icon)}
						  {
						  	showUnselect && (
							  <li>
								<a href="javascript:void(0)" onClick={(e) => handleUnSelect(icon.id, e)}>Unselect
								</a>
							  </li>
						  	)
						  }
						</ul>
					  </div>
					</div>
					<div className="bio-details">
						<Tooltip title="Gender" position="bottom">
							<span className="capitalize">{icon.gender || 'N/A'}
							</span>
						</Tooltip>
					  <span>{icon.age ? `${icon.age} years` : "" }
					  </span>
						<Tooltip title="City" position="bottom">
							<span>{icon.city || 'N/A'}
							</span>
						</Tooltip>
					</div>
					{showStatus &&  (
						<div className="icon_status">
						  <button type="button" className={statusClass()}>{iconStatus[icon.status]}</button>
						</div>
					)}
					
					{icon.status === 'rejected' && (
						<div className="icon_status icon_status_rejected">
						  <button type="button" className="btn btn-danger">{iconStatus[icon.status]}</button>
						</div>
					)}
				</div>
			</div>

			<div className="social-person-details">
				{ showPlatform ? renderPlatforms(icon.platform) : null }
			</div>

			<div className={`see_profile ${showSelection ? 'see_profile_between' : ''}`}>
				<Link to={`/profile/${id}`} target="_self">SEE PROFILE
				</Link>
				{ showSelection ? <a href="javascript:void(0)" onClick={(e) => isSelected ? handleRemove(icon.id, e) : handleSelect(icon.id, e)} className={isSelected ? "minus-remove" : "plus-select"}>{isSelected ? '- DESELECT' : '+ SELECT'}</a> : null }
			</div>
		</>
	)
}