import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { SET_CATEGORY_INTERST } from '../../Redux/Actions/Types/Types';

export function fetchCategories(data) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CATEGORY.FETCH_ALL()}`;
	return dispatch => axios.get(apiURL)
	.then(res => {
		if(res.status) {
			return {success: true, data: res.data.data}
		} else {
			throw Error(res.statusText);
		}
	}).catch((err) => {
		return err.response;
	});
}

export function fetchCategoryInterst() {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.CATEGORY.FETCH_ALL_CATEGORY_INTERESTS()}`;
	return dispatch => axios.get(apiURL)
	.then(res => {
		if(res.status) {
			dispatch(setCategoryInterst(res.data.data));
			return {success: true, data: res.data.data}
		} else {
			return { status: false, message: res.data.message };
		}
	}).catch((err) => {
		return err.response;
	});
}

export function setCategoryInterst(categoryInterest) {
  return {
	type: SET_CATEGORY_INTERST,
	categoryInterest: categoryInterest
  }
}