import { SET_ICON_FEEDBACK_LIST_SEARCH_VALUE, SET_ICON_LIST_SEARCH_VALUE, SET_ICON_FILTERS, SET_ICON_FEEDBACK_FILTERS, SET_ICON_LIST_INITIAL_DATA } from '../Actions/Types/Types';

const initailSortFilters = { per_page: '', current_page: 1, total_pages: '', total_count: '', sort_by: 'created_at', sort_order: 'asc' }
export const iconFeedbackFilters = (state = initailSortFilters, action) => {
  switch(action.type) {
    case SET_ICON_FEEDBACK_FILTERS:
      return action.value

    default: 
      return state;
  }
}

const iconListInitialData = { per_page: '', current_page: 1, total_pages: '', total_count: '', sort_by: 'first_name', sort_order: 'asc' }
export const iconListPaginationData = (state = iconListInitialData, action) => {
  switch(action.type) {
    case SET_ICON_LIST_INITIAL_DATA:
      return action.value

    default: 
      return state;
  }
}

export const iconListSearchText = (state = '', action) => {
  switch(action.type) {
    case SET_ICON_LIST_SEARCH_VALUE:
      return action.value

    default: 
      return state;
  }
}

export const iconFeedbackListSearchText = (state = '', action) => {
  switch(action.type) {
    case SET_ICON_FEEDBACK_LIST_SEARCH_VALUE:
      return action.value

    default: 
      return state;
  }
}