import React, { useEffect, useState } from 'react';
import Header from '../../../Components/Header/Header';
import SideBar from '../../../Components/SideBar/SideBar';
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash';
import { setActiveTab } from '../../../Redux/Actions/Community';
import { fetchIconProfile } from '../../../Redux/Actions/Icons';
import { logout, fetchCurrentUser } from '../../../Redux/Actions/Auth';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationManager} from 'react-notifications';
import { setLoader } from '../../../Redux/Actions/Loader';
import Refresh from '../../../Components/Common/Refresh';
import { trim, toLower, startCase, orderBy, includes, sumBy, _ } from 'lodash';
import './styles.scss';
import { Chart } from "react-google-charts";
import { getCode, getName, getData } from 'country-list'
import moment from 'moment'
import HeaderLogo from '../../../Components/Header/HeaderLogo';
import ConfirmationAlert from '../../../Components/ConfirmationAlert/Alert';
import { refreshIconInsight } from '../../../Redux/Actions/Campaign';
import Tooltip from '../../../Components/Tooltip/index';

export default ({...props}) => {
  const activeTab = useSelector(state => state.activeTab);
  const selectedCampiagn = useSelector(state => state.selectedCampiagn);
  const organization = useSelector(state => state.organization.organization);
  const [icon, setIcon] = useState({});
  const [genderInsight, setGenderInsight] = useState([]);
  const [ageInsight, setAgeInsight] = useState([]);
  const [countryInsight, setCountryInsight] = useState([]);
  const [cityInsight, setCityInsight] = useState([]);
  const [growthInsight, setGrowthInsight] = useState([]);
  const [ageRaw, setAgeRawData] = useState([]);
  const [organizationDetails, setOrganizationDetails] = useState({});
  const [openModal, setOpenModal] = useState(false);
  let followers_count = 0;
  let attachment_url = organization && organization.attachment_url && organization.attachment_url[0] && organization.attachment_url[0].media && organization.attachment_url[0].media.url
  const dispatch = useDispatch();
  useEffect(async () => {

    // Fetch current user
    await getCurrentUser();

    // Fetch Icon Profile Details
    await dispatch(setLoader(true));
    await fetchProfile(props.match.params.id)
    dispatch(setActiveTab(activeTab))
     const file = require('../../../assets/javascript/external.js');
     const script = document.createElement('script');
    script.src = file;
     script.async = true;
     document.body.appendChild(script);
    return () => {
        document.body.removeChild(script);
    }

  }, []);

  const getCurrentUser = async() => {
    const token = localStorage.getItem('manageToken')
    const response = await dispatch(fetchCurrentUser(token));
    if(response.data && response.data.status === 'success'){
      setOrganizationDetails(response.data.data)
    }
  }

  const fetchProfile = async (id) => {
    const response = await dispatch(fetchIconProfile(id))
    if(response.success) {
      await dispatch(setLoader(false));
      // Create graph data
      let growthData = response.data && response.data.insight_data && response.data.insight_data.follower_growth
      let genderData = response.data && response.data.insight_data && response.data.insight_data.gender_percentage
      let ageData = response.data && response.data.insight_data && response.data.insight_data.age_percentage
      let countryData = response.data && response.data.insight_data && response.data.insight_data.country_percentage
      let cityData = response.data && response.data.insight_data && response.data.insight_data.city_percentage
      let ageRawData = response.data && response.data.insight_data && response.data.insight_data.age_raw_data && response.data.insight_data.age_raw_data[0].value
      let followers_count = response.data && response.data.insight_data && response.data.insight_data.latest_followers_count
      let ageRawArr = []
      let arrangeAge = []
      let totalFollowerAge = 0
      for(var key in ageRawData) {
        let split_1 = key.split('.')
        if(!arrangeAge.includes(split_1[1])){
          arrangeAge.push(split_1[1])
        }
        let obj = {
          type: split_1[0],
          name: split_1[1],
          value: ageRawData[key]
        }
        ageRawArr.push(obj)
      }
      let customizeAge = []
      let finalAgeData = []
      ageRawArr.map(a_item=> {
        if(arrangeAge.includes(a_item.name)){
          let itemIndex = finalAgeData.findIndex(o => o.name == a_item.name)
          if(itemIndex > -1){
            finalAgeData[itemIndex].totalAge = finalAgeData[itemIndex].totalAge+a_item.value
            totalFollowerAge = totalFollowerAge+a_item.value
            let customArr = finalAgeData[itemIndex].data
            customArr.push({  type:a_item.type, value:a_item.value})
            finalAgeData[itemIndex].data = customArr
          }else{
            totalFollowerAge = totalFollowerAge+a_item.value
            finalAgeData.push({
              name:a_item.name,
              totalAge: a_item.value,
              data: [{type:a_item.type, value:a_item.value }]
            })
          }
        }
      })

      finalAgeData = orderBy(finalAgeData, ['name'],['asc'])

      let ageGraphData = [
        ['Age', 'Male', { role: 'tooltip'}, 'Female', { role: 'tooltip'}]
      ]
      finalAgeData.map(r_item=> {
        let maleObj = r_item.data.find(o => o.type === 'M');
        let femaleObj = r_item.data.find(o => o.type === 'F');
        let malePer =  maleObj ? (maleObj.value/totalFollowerAge*100).toFixed(1)+'%' : "0%"
        let femalePer =  femaleObj ? (femaleObj.value/totalFollowerAge*100).toFixed(1)+'%' : "0%"
        let obj = [
          r_item.name, maleObj ? (maleObj.value/totalFollowerAge*100) : 0,   malePer ,femaleObj ? (femaleObj.value/totalFollowerAge*100) : 0, femalePer
        ]
        ageGraphData.push(obj)
      })

      setAgeRawData(ageGraphData)
      // Follwers Data
      let growthArr = [
        ['Date', 'Followers']
      ]
      let growthCom = []
      for(var key in growthData) {
        let obj = {
          name: key,
          value: growthData[key]
        }
        growthCom.push(obj)
      }
      var revMyArr = growthCom;
      revMyArr = orderBy(revMyArr, ['name'],['asc'])
      revMyArr.map(r_item=> {
        let obj = [
          moment(r_item.name).format('YYYY-MM-DD'), r_item.value
        ]
        growthArr.push(obj)
      })

      setGrowthInsight(growthArr)

      // Gender Data
      let genderArr = [
        ['Gender', { role: 'annotation' }]
      ]

      let totalGender = 0

      for(var key in genderData) {
        totalGender = genderData[key]+totalGender
      }

      for(var key in genderData) {
        let obj = [
          startCase(toLower(key)) == 'Other' ? 'Prefer not to say' : startCase(toLower(key)), genderData[key]/totalGender * 100, 
        ]
        genderArr.push(obj)
      }

      setGenderInsight(genderArr)
      // Age Data

      let ageArr = [
        ['Age', 'Percentage', { role: 'annotation' }]
      ]

      let totalAge = 0

      for(var key in ageData) {
        totalAge = ageData[key]+totalAge
      }

      for(var key in ageData) {
        let obj = [
          key, (ageData[key]/totalAge * 100), (ageData[key]/totalAge * 100).toFixed(1)+'%'
        ]
        ageArr.push(obj)
      }
      setAgeInsight(ageArr)

      // Country Data
      
      let countryArr = [
        ['Country', 'Percentage', { role: 'annotation' }]
      ]

      let totalCountry = 0
      let countries = []
      for(var key in countryData) {
        let obj = {
          name: getName(key),
          value: countryData[key]
        }
        countries.push(obj)
        totalCountry = countryData[key]+totalCountry
      }

      countries = orderBy(countries, ['value'],['desc'])

      let countrySize = 0
      for(var key in countryData) {
        countrySize++
      }
      // Get the array of keys
      countries.map(country_item=> {
        let obj = [
          country_item.name, country_item.value/followers_count * 100, (country_item.value/followers_count * 100).toFixed(1)+"%"
        ]
        countryArr.push(obj)
      })

      if(countryArr.length > 1){
        setCountryInsight(countryArr.slice(0, 11))
      }

      // City Data
      let cityArr = [
        ['City', 'Percentage', { role: 'annotation' }]
      ]


      let totalCity = 0
      let cities = []
      let commonCities = []
      for(var key in cityData) {
        let obj = {
          name: key,
          value: cityData[key]
        }
        cities.push(obj)
        commonCities.push(obj)
        totalCity = cityData[key]+totalCity
      }
      cities = orderBy(cities, ['value'],['desc'])

      let citySize = 0
      for(var key in cityData) {
        citySize++
      }
      
      cities.map(city_item=> {
        if(city_item.name.includes("County")){
          city_item.name = city_item.name.replace("County", "")
        }
      	let city_name = city_item.name.split(', ')[0]
        let obj = [
          city_name, city_item.value/followers_count * 100, (city_item.value/followers_count * 100).toFixed(1)+"%"
        ]
        cityArr.push(obj)
      })

      if(cityArr.length > 1){
        setCityInsight(cityArr.slice(0, 11))
      }

      setIcon(response.data)
    } else {
      NotificationManager.error(response.data.message, '', 10000, () => {})
      props.history.push('/communities')
    }

  }

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
    props.history.go()
  }

  var settings = {
      dots: false,
    autoplay: true,
       slidesToShow: 3,
       slidesToScroll: 1
   };


   const renderInterest = (data = []) => {
     return data.map(value => {
       return <li key={Math.random()}>{value}</li>
     })
   }
   
  const renderChannels = (data = []) => {
    return data.map(value => {
      if(value.name === "instagram"){
        return <li key={Math.random()}><a target="_blank" href={`https://www.instagram.com/${value.username}`}><img src={require("../../../assets/images/img_instagram.svg")} />{value.username}</a></li>
      }else if(value.name === "tiktok"){
        return <li key={Math.random()}><a target="_blank" href={`https://www.tiktok.com/${value.username}`}><img src={require("../../../assets/images/img_tiktok.svg")} />{value.username}</a></li>
      }else{
        return <li key={Math.random()}><a target="_blank" href={`https://www.instagram.com/${value.username}`}><img src={require("../../../assets/images/img_instagram.svg")} />{value.username}</a></li>
      }
    })
  }

  const renderPlatform = (platform = []) => {
    return platform.map((value) => {
      const activeClass = value.name === "instagram" ? "active" : ""
      return <li  key={Math.random()} className={activeClass}><a data-toggle="tab" href={`#profile_${value.name}`}>{value.name}</a></li>
    })
  }

  const renderPlatformChild = (platform = []) => {
    return platform.map((value) => {
      const activeClass = value.name === "instagram" ? "active" : ""
      return (
        <div key={Math.random()} id={`profile_${value.name}`} className={`tab-pane fade in ${activeClass}`}>
          <div className="total-score-insta">
            <ul>
              <li><h3>{value.followers_count ? value.followers_count : "N/A"}</h3><p>Followers</p></li>
              <li><h3>{value.engagement ? value.engagement : "N/A"}</h3><p>Avg. Engagement</p></li>
              <li><h3>{value.avg_likes ? value.avg_likes : "N/A"}</h3><p>Avg. likes per post</p></li>
              {/*<li><h3>{value.cost ? value.cost : "N/A"}</h3><p>Cost</p></li>*/}
            </ul>
          </div>
        </div>
      )
    })
  }


  

  const handleBack = (e) => {
    e.preventDefault();
    props.history.push(activeTab === 'Campaigns' ? `/${activeTab}/${selectedCampiagn}` : `/${activeTab}`)
    // props.history.push(props.location.state && props.location.state.path ? props.location.state.path : `/${activeTab}`)
  }

  const profile_pic = (icon) => {
    if (icon.attachment_url) {
      return icon.attachment_url
    } else {
      switch (toLower(trim(icon.gender))) {
        case 'male':
          return require('../../../assets/images/default_male_user_icon.svg')
        case 'female':
          return require('../../../assets/images/default_female_user_icon.svg')
        default:
          return require('../../../assets/images/default_male_female_icon.svg')
      }
    }
  }

  const closeModal =  async (type)=> {
    setOpenModal(!openModal)
    let instagramData = icon.platform && icon.platform.find((value)=> { if(value.name === 'instagram') return value})
    let data = {
      icon_id: props.match.params.id,
      platform_id: instagramData && instagramData.id ? instagramData && instagramData.id : '' 
    }
    if(type === 'YES'){
      const response = await dispatch(refreshIconInsight(data))
      await dispatch(setLoader(true));
      if(response && response.data.status == 'success'){
        NotificationManager.success(response.data.message)
        await fetchProfile(props.match.params.id)
      } else {
        NotificationManager.error(response.data.message)
        await fetchProfile(props.match.params.id)
      }
    }else{
      setOpenModal(!openModal)
    }
    
  }

  return(
    <div id="wrapper">
      <Header
        containerClass="header-inner"
        logoClass="logo"
      >
        <div className="top-header-right">
          <h2 className="header-heading"><Link to="#" onClick={(e) => handleBack(e)} className="back-to"><img src={require("../../../assets/images/icon_arrow_back.svg")} /></Link> Profile</h2>

          <div className="user-settings-menu">
          <Refresh />
            {/* <div className="dollar-menu select-default">
              <div className="dropdown default-dropdown">
                <a href="javascript:void(0)" className="dropdown-toggle" type="button" dataToggle="dropdown">
                  <img src={require("../../../assets/images/icon_currency.svg")} />
                </a>

                <ul className="dropdown-menu">
                  <li><a href="javascript:void(0)">Price 01</a></li>
                  <li><a href="javascript:void(0)">Price 02</a></li>
                </ul>
              </div>
            </div> */}

            {/* <div className="notification-menu select-default">
              <div className="dropdown default-dropdown">
                <a href="javascript:void(0)" className="dropdown-toggle" type="button" data-toggle="dropdown">
                  <img src={require("../../../assets/images/icon_bell.svg")} />
                </a>

                <ul className="dropdown-menu">
                  <li><a href="javascript:void(0)"> Notification 01</a></li>
                  <li><a href="javascript:void(0)"> Notification 02</a></li>
                </ul>
              </div>
            </div>
            <div className="user-settings select-default">
              <div className="dropdown default-dropdown">
                <a href="javascript:void(0)" className="dropdown-toggle" type="button" data-toggle="dropdown">
                  <img src={organization.attachment_url} className="profile-user-icon" />
                </a>
            
                <ul className="dropdown-menu">
                  <li><a href="#" onClick={handleLogout}>logout</a></li>
                </ul>
              </div>
            </div>*/}

            <HeaderLogo 
              containerClass="user-settings select-default"
              logoClass="dropdown default-dropdown"
              attachment_url={attachment_url}
              handleLogout={handleLogout}
            />
          </div>
        </div>
      </Header>

      <main id="main">
        <div className="main-inner">
          <SideBar />

          <div id="view-dashboard">
            <div className="inner-view-dashboard">
              <div className="communities-content">
                <div className="profile_image_graph">
                  <div className="profile-picture-view">
                    <figure className="img-view">
                      <img src={ profile_pic(icon)} />
                    </figure>
                    <div className="interests-label">
                      <h4>Interests</h4>
                      <ul>
                        {renderInterest(icon.interests ? icon.interests : [])}
                      </ul>
                    </div>
                    
                     <div className="growth-label">
                      {
                        growthInsight && growthInsight.length > 1 && (
                          <div>
                            <h4>Growth</h4>
                            <Chart
                              width={'600px'}
                              height={'400px'}
                              chartType="AreaChart"
                              loader={<div>Loading Chart</div>}
                              data={ growthInsight }
                              options={{
                                legend: 'none',
                                color:['#FFF', '#FFF'],
                                hAxis: {
                                  'backgroundColor': {
                                    'fill': '#FFF',
                                    'opacity': 50
                                  },
                                },
                                vAxis: {
                                  title: '',
                                  format: "decimal",
                                  minValue: 0,
                                   'backgroundColor': {
                                     'fill': '#FFF',
                                     'opacity': 100
                                   },
                                   gridlines: { count: 4}
                                },
                                series: {
                                  0: {
                                    color: '#add8e6'
                                  }
                                },
                                curveType: 'function',
                                         pointSize: 4,
                              }}
                              rootProps={{ 'data-testid': '1' }}
                            />
                          </div>
                        )
                      }
                      <br/>
                      <br/>
                      <br/>
                      {
                        genderInsight && genderInsight.length > 1 && (
                          <div>
                            <h4>Gender Distribution </h4>
                            <Chart
                              width={'600px'}
                              height={'400px'}
                              chartType="PieChart"
                              loader={<div>Loading Chart</div>}
                              data={genderInsight}
                              options={{
                                title: '',
                                legend: { position: 'bottom' },
                                pieStartAngle: 100,
                                slices: [ {color: "#add8e6"}, {color: "#FFB6C1"}, {color: "#808080"} ],
                                tooltip: {
                                  text: 'percentage'
                                },
                               /* tooltip : { trigger: 'none' },*/
                              }}
                              rootProps={{ 'data-testid': '7' }}
                            />
                          </div>
                        )
                      }
                      <br/>
                      <br/>
                      <br/>
                      {
                        ageRaw && ageRaw.length > 1 && (
                          <div>
                            <h4>Age Distribution</h4>
                            <Chart
                              width={'600px'}
                              height={'400px'}
                              chartType="ColumnChart"
                              loader={<div>Loading Chart</div>}
                              data={ageRaw}
                              options={{
                                // Material design options
                                legend: { position: 'bottom' },
                               /* tooltip : { trigger: 'none' },*/
                                hAxis: {format: 'percent'},
                                colors: ['#add8e6', '#FFB6C1'],
                                vAxis: { minValue: 0}
                              }}
                              // For tests
                              rootProps={{ 'data-testid': '2' }}
                            />
                          </div>
                        )
                      }

                      {/*{
                        ageInsight && ageInsight.length > 1 && (
                          <div>
                            <h4>Age Distribution</h4>
                            <Chart
                              width={'600px'}
                              height={'400px'}
                              chartType="ColumnChart"
                              loader={<div>Loading Chart</div>}
                              data={ageInsight}
                              options={{
                                // Material design options
                                legend: { position: 'none' },
                                tooltip : { trigger: 'none' }
                              }}
                              // For tests
                              rootProps={{ 'data-testid': '2' }}
                            />
                          </div>
                        )
                      }*/}
                      <br/>
                      <br/>
                      <br/>
                      {
                        countryInsight && countryInsight.length > 1 && (
                          <div>
                            <h4>Top 10 Countries Distribution</h4>
                            <Chart
                              width={'600px'}
                              height={'400px'}
                              chartType="BarChart"
                              loader={<div>Loading Chart</div>}
                              data={countryInsight}
                              options={{
                                title: '',
                                legend: { position: 'none' },
                                vAxis: { format: 'percent' },
                                tooltip : { trigger: 'none' },
                                chartArea:{left:170,top:70},
                                colors: ['#add8e6'],
                                annotations: {
                                  textStyle: {
                                    color: 'black',
                                  }
                                }
                              }}
                              // For tests
                              rootProps={{ 'data-testid': '3' }}
                            />
                          </div>
                        )
                      }

                      <br/>
                      <br/>
                      <br/>
                      {
                        cityInsight && cityInsight.length > 1 && (
                          <div>
                            <h4>Top 10 Cities Distribution</h4>
                            <Chart
                              width={'600px'}
                              height={'400px'}
                              chartType="BarChart"
                              loader={<div>Loading Chart</div>}
                              data={cityInsight}
                              options={{
                                title: '',
                                legend: { position: 'none' },
                                vAxis: { format: 'percent' },
                                tooltip : { trigger: 'none' },
                                chartArea:{left:120,top:70},
                                colors: ['#add8e6'],
                                annotations: {
                                  textStyle: {
                                    color: 'black',
                                  }
                                }
                              }}
                              // For tests
                              rootProps={{ 'data-testid': '3' }}
                            />
                          </div>
                        )
                      }
                    </div> 
                  </div>
                  <div className="other-profile-details">
                    <div className="personal-profile-details">
                      <div className="personal-bio">
                        <h2>{!isEmpty(icon) ? `${icon.name}` : ''}</h2>
                        <ul>
                          <li><strong>Age:</strong> {icon.age || 'N/A'}</li>
                          <li><strong>City:</strong> {icon.city || 'N/A'}</li>
                          <li><strong>Gender:</strong> {icon.gender || 'N/A'}</li>
                          <li><strong>Country:</strong> {icon.country_name || 'N/A'}</li>
                          <li className="bio-100"><strong>Bio:</strong> {icon.bio || 'N/A'}</li>
                        </ul>
                      </div>
                      <div className="personal-bio-accounts">
                        <h4>Social media channels</h4>
                        <ul>
                          {renderChannels(icon.channels ? icon.channels : [])}
                        </ul>
                      </div>
                    </div>
                    {(icon.platform && (icon.platform.length > 0)) && 
                    <div className="profile_social_tabs">
                      <div className="tabs-with-mark">
                        <ul className="nav nav-tabs default-tabs">
                          {renderPlatform(icon.platform ? icon.platform : [])}
                        </ul>
                      </div>
                      <div className="tab-content">
                        {renderPlatformChild(icon.platform ? icon.platform : [])}
                      </div>
                    </div>
                    }
                    <div className="insight-label">
                      <h4></h4>
                      <Tooltip title="Refresh Insight" position="top" >
                        <a onClick={()=> setOpenModal(!openModal)} href="javascript:void(0)"><img className="img-refresh" src={require("../../../assets/images/cropped-Refresh_icon.png").default} /></a>
                      </Tooltip>
                    </div>
                    <div className="profile_posts_campaigns hide">
                      <div className="tabs-with-mark">
                        <ul className="nav nav-tabs default-tabs">
                          <li className="active"><a data-toggle="tab" href="#profile_latest_posts">Latest posts</a></li>
                          <li><a data-toggle="tab" href="#profile_previous_campaigns">Previous campaigns</a></li>
                        </ul>

                      </div>
                      <div className="tab-content">

                        <div id="profile_latest_posts" className="tab-pane fade in active">
                          <div className="product-slide01 slider">

                          <Slider {...settings}>
                            <div className="slide">
                              <div className="social-slide">
                                <h5>Instagram</h5>
                                <figure className="social-profile-image"><img src={require("../../../assets/images/img_slide01.png").default} /></figure>
                                <div className="social-actions">
                                  <ul>
                                    <li><a href="javascript:void(0)" className="social-like"><img src={require("../../../assets/images/icon_heart.svg")} /></a></li>
                                    <li><a href="javascript:void(0)" className="social-comment"><img src={require("../../../assets/images/icon_comment.svg")} /></a></li>
                                    <li><a href="javascript:void(0)" className="social-forward"><img src={require("../../../assets/images/icon_forward.svg")} /></a></li>
                                  </ul>
                                  <a href="javascript:void(0)" className="social-bookmark"><img src={require("../../../assets/images/icon_bookmark.svg")} /></a>
                                </div>
                                <div className="social-caption">Sed sapien erat, viverra id placerat id, sagittis eu magna. Curabitur et aliquet est.</div>
                              </div>
                            </div>

                            <div className="slide">
                              <div className="social-slide">
                                <h5>Instagram</h5>
                                <figure className="social-profile-image"><img src={require("../../../assets/images/img_slide02.png").default} /></figure>
                                <div className="social-actions">
                                  <ul>
                                    <li><a href="javascript:void(0)" className="social-like"><img src={require("../../../assets/images/icon_heart.svg")} /></a></li>
                                    <li><a href="javascript:void(0)" className="social-comment"><img src={require("../../../assets/images/icon_comment.svg")} /></a></li>
                                    <li><a href="javascript:void(0)" className="social-forward"><img src={require("../../../assets/images/icon_forward.svg")} /></a></li>
                                  </ul>
                                  <a href="javascript:void(0)" className="social-bookmark"><img src={require("../../../assets/images/icon_bookmark.svg")} /></a>
                                </div>
                                <div className="social-caption"></div>
                              </div>
                            </div>

                            <div className="slide">
                              <div className="social-slide">
                                <h5>Instagram</h5>
                                <figure className="social-profile-image"><img src={require("../../../assets/images/img_slide01.png").default} /></figure>
                                <div className="social-actions">
                                  <ul>
                                    <li><a href="javascript:void(0)" className="social-like"><img src={require("../../../assets/images/icon_heart.svg")} /></a></li>
                                    <li><a href="javascript:void(0)" className="social-comment"><img src={require("../../../assets/images/icon_comment.svg")} /></a></li>
                                    <li><a href="javascript:void(0)" className="social-forward"><img src={require("../../../assets/images/icon_forward.svg")} /></a></li>
                                  </ul>
                                  <a href="javascript:void(0)" className="social-bookmark"><img src={require("../../../assets/images/icon_bookmark.svg")} /></a>
                                </div>
                                <div className="social-caption">Sed sapien erat, viverra id placerat id, sagittis eu magna. Curabitur et aliquet est.</div>
                              </div>
                            </div>

                            <div className="slide">
                              <div className="social-slide">
                                <h5>Instagram</h5>
                                <figure className="social-profile-image"><img src={require("../../../assets/images/img_slide02.png").default} /></figure>
                                <div className="social-actions">
                                  <ul>
                                    <li><a href="javascript:void(0)" className="social-like"><img src={require("../../../assets/images/icon_heart.svg")} /></a></li>
                                    <li><a href="javascript:void(0)" className="social-comment"><img src={require("../../../assets/images/icon_comment.svg")} /></a></li>
                                    <li><a href="javascript:void(0)" className="social-forward"><img src={require("../../../assets/images/icon_forward.svg")} /></a></li>
                                  </ul>
                                  <a href="javascript:void(0)" className="social-bookmark"><img src={require("../../../assets/images/icon_bookmark.svg")} /></a>
                                </div>
                                <div className="social-caption">Sed sapien erat, viverra id placerat id, sagittis eu magna. Curabitur et aliquet est.</div>
                              </div>
                            </div>
                          </Slider>
                        </div>
                        </div>

                        <div id="profile_previous_campaigns" className="tab-pane fade">
                          previous_campaigns
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <ConfirmationAlert
        visible={openModal}
        handleClose={closeModal}
        activeAlertType={null}
        message=""
      />
      </main>
    </div>
  )
}