import './DeletePopup.css'
import React, {useState} from 'react';
import Field from '../../Components/TextField/Field';
import Modal from 'react-bootstrap/Modal'
import { Multiselect } from 'multiselect-react-dropdown';
import CustomSelect from "../../Components/CustomSelect/Select";
import { capitalize } from 'lodash';

export default ({
  inviteModel= false,
  sendInvite = () => {},
  closeModel =() => {},
  communitiesData = [],
  onSelect = () => {},
  onRemove = () => {},
  currentUser = () => {},
  roles = []
}) => {
  const [email, setemail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [role, setRole] = useState("");

  const sendInviteAction = (email, role) => {

    if (email){
      var pattern = new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
      // var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if(email.includes("%") || email.includes("#")){
        setErrorMsg("Please enter valid email address without # and % tag.");
      } else if(pattern.test(email)){
        setemail("");
        setErrorMsg("");
        setRole("");
        sendInvite(email, role);
      } else{
        setErrorMsg("Please enter valid email address.");
      }
    }else{
      setErrorMsg("Please enter email")
    }
  }

  const closeModelAction = () => {
    // debugger
    setemail("");
    setErrorMsg("");
    closeModel();
  }

  const handleChange = (e) => {
    const name = e.target.name
    if (name === 'role'){
      setRole(e.target.value);
    } else if(name === 'email'){
      setemail(e.target.value);
    }
  }

  return(
    <Modal
      show={inviteModel}
      onHide={() => closeModelAction()}
      className="verify-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="data-modal-form">
        <h2 className="modal-head">Invite your team!</h2>
        <form>
          <h5 className="error">{errorMsg ? errorMsg : ''}</h5>
          <div className="form-group">
            <input type="email" name="email" className="form-control" placeholder="Email" value={email}  onChange={handleChange} required="true" />
          </div>
          <div className="form-group">
            <CustomSelect name="role" className="custom-select" value={role} onChange={handleChange}>
              {
                roles.map(role => {
                  return <option key={Math.random()} value={role}>{capitalize(role)}</option>
                })
              }
            </CustomSelect>
          </div>
          {
            (role === 'user' || role === '') && ( 
            <div className="form-group">
              <Multiselect
              options={communitiesData}
              selectedValues={[]}
              onSelect={onSelect}
              onRemove={onRemove}
              displayValue="name"
              className="custom-select sources"
              />
            </div>)
          }
        </form>
        <div className="form-submittion">
          <button type="button" data-dismiss="modal" onClick={() => sendInviteAction(email, role)}>SEND INVITE</button>
          <button type="button" data-dismiss="modal" onClick={() => closeModelAction()}>CANCEL</button>
        </div>
      </div>
    </Modal>
  )
}