import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route } from 'react-router-dom';
import setAuthorizationToken from './utils/SetAuthorizationToken';
import { Provider } from 'react-redux';
import { logout, fetchCurrentUser, setUserToken, isUserTokenValid } from './Redux/Actions/Auth';

import 'jquery/src/jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import 'react-notifications/lib/notifications.css';
import "react-datepicker/dist/react-datepicker.css";
import './assets/css/common.css';
import './assets/css/calendar.css';
import './assets/css/responsive.css';
import './assets/css/rebrand.css'
import { store } from './Redux/store';

// Attempt to grab token from parent, in embedded mode
// @TODO: Long-term this should be moved to a header, or POST body instead of query param
const inIframe = () => {
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
};
const urlParams = new URLSearchParams(window.location.search);
// Reset previously stored org_id, if any.
if(localStorage.getItem('organization_id')) localStorage.removeItem('organization_id');
// Set new org id if provided.
const organization_id = urlParams.get('organization_id');
if(organization_id){
  localStorage.setItem('organization_id', organization_id)
}
// Check if we are in embedmode
if( inIframe ) {
  // Do we need to refresh the stored user token?
  const urlToken = urlParams.get('authtoken');
  // TODO: Does it work with urlToken below or do we need the isUserTokenValid() call/check?
  if(urlToken){
    // const urlToken = urlParams.get('authtoken');
    setUserToken(urlToken);
    localStorage.setItem('embedMode', true);
  }
} else {
  if(localStorage.getItem('embedMode')) localStorage.removeItem('embedMode');
  if(localStorage.getItem('organization_id')) localStorage.removeItem('organization_id');
}

// Check if token is valid
if(isUserTokenValid()){
  store.dispatch(fetchCurrentUser(localStorage.manageToken))
  setAuthorizationToken(localStorage.manageToken);
}else{
  store.dispatch(logout());
}


ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Route component={Routes} />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
