import React, { useEffect, useState } from 'react';
import { sendNotification } from '../../Redux/Actions/Community';
import { NotificationManager } from 'react-notifications';
import { Multiselect } from 'multiselect-react-dropdown';
import TextArea from "../../Components/TextArea/Field";
import { isEmpty } from 'lodash';
import { useDispatch } from "react-redux";
import '../Content/ContentList.css';
import './Notification.scss';

export default ({
  communities = [],
  community_ids = [],
  slug = "",
  ...props
}) => {
  const initalData = { title: "", description: "" }
  const [visible, setvisible] = useState(false);
  const dispatch = useDispatch();
  const [notificationData, setNotificationData] = useState(initalData);
  const [selectedCommunity, setSelectedCommunity] = useState([])
  const [countrySearch, setCountrySearch] = useState(false);


  useEffect(async () => {
    setSelectedCommunity(community_ids)
  }, [community_ids])

  const handleNotificationData = (e) => {
    const { name, value } = e.target
    setNotificationData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const validateInput = (data) => {
    const Errors = [];
    Object.keys(data).map(key => {
      if (isEmpty(data[key]))
        Errors.push(`Please provide value for ${key}.`)
    })

    Errors.map((error) => {
      NotificationManager.error(error, '', 10000, () => {})
    })

    return isEmpty(Errors);
  }

  const handleNotificationCommunitieschange = (selectedList, selectedItem) => {
    (selectedList.length === 0) ? setSelectedCommunity([]) : setSelectedCommunity([selectedItem])
  }

  const sendOutNotification = async () => {
    const { title, description } = notificationData
    const data = { title, description, community_id: selectedCommunity.length > 0 ? selectedCommunity[0].id : "" }
    if (validateInput(data)) {
      data.slug = slug
      var formdata = new FormData()
      for (const key in data) {
        formdata.append(key, data[key]);
      }
      const response = await dispatch(sendNotification(formdata))
      if (response.status) {
        NotificationManager.success(response.message)
        setNotificationData(initalData)
        setvisible(false);
      } else {
        NotificationManager.error(response.message, '', 10000, () => {})
      }
    }
  }

  const closeModel = () => {
    setvisible(false)
    setNotificationData(initalData)
  }
  const onCountryChange = (searchText) => {
		searchText ? setCountrySearch(true) : setCountrySearch(false)
	}

  const NotificationModel = () => {
    return (
      <div id="invites" className="modal modal-flex" data-backdrop='static'>
        <div className="modal-dialog">
          <div className="data-modal-form">
            <h2 className="modal-head">Notification</h2>
            <form>
              <div className="form-group">
                <input type="email" name="title" className="form-control" placeholder="Title" value={notificationData.title} required="true" onChange={handleNotificationData} />
              </div>

              <TextArea
                classes="notification-desc label-text"
                containerClass="form-group"
                name="description"
                value={notificationData.description}
                required={true}
                placeholder="Description"
                onBlur={handleNotificationData}
              />

              <div id="campaign-page__community-selector" className="form-inputs">
                <label className={(countrySearch || (selectedCommunity.length > 0)) ? "labelClass field-active top-24" : "labelClass"}>Select Brand Community</label>

                <Multiselect
                  options={communities}
                  selectedValues={selectedCommunity}
                  onSelect={handleNotificationCommunitieschange}
                  onRemove={handleNotificationCommunitieschange}
                  singleSelect={false}
                  placeholder=""
                  displayValue="name"
                  onSearch={onCountryChange}
                  className="custom-select sources"
                />
              </div>
            </form>
            <div className="form-submittion">
              <button type="button" data-dismiss="modal" onClick={() => sendOutNotification()}>Send Notification</button>
              <button type="button" data-dismiss="modal" onClick={() => closeModel()}>CANCEL</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {visible ? NotificationModel() : null}
      <li className="save-bio"><button type="button" style={{ textTransform: "none", letterSpacing: '1.8425px' }} onClick={() => setvisible(true)}>Send notification</button></li>
    </>
  )
}