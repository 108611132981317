import './style.scss'
import Modal from 'react-bootstrap/Modal'
import './VerifyAlert.css'
export default({
  visible = false,
	handleClose = () => {},
	message = '',
	activeAlertType = '',
  checkSubmission = false
}) =>{
  return(
    <Modal
      show={visible}
      onHide={() => handleClose('CANCEL')}
      className="verify-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
       <div id="confirmation"  data-backdrop='static'>
          <div className="data-modal-form">
            <h2 className="modal-head text-center">Are you sure?</h2>
            <h4 className="center-text">{activeAlertType === "Availability" ? message : null}</h4>
            <div className="form-submittion">
              <button disabled={checkSubmission} type="button" data-dismiss="modal" onClick={() => handleClose('YES')}>YES</button>
              <button type="button" data-dismiss="modal" onClick={() => handleClose('CANCEL')}>CANCEL</button>
            </div>
          </div>
        </div>
    </Modal>

    
  )

}