import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoryInterst } from '../../Redux/Actions/Categories';
import { fetchOrganization, updateOrganization } from '../../Redux/Actions/Organizations';
import { updateUser } from '../../Redux/Actions/User';
import { fetchCurrentUser } from '../../Redux/Actions/Auth';
import { setSettingTab } from '../../Redux/Actions/Setting';
import { fetchCountries } from '../../Redux/Actions/Countries';
import { Multiselect } from 'multiselect-react-dropdown';
import { NotificationManager} from 'react-notifications';
import { setLoader } from '../../Redux/Actions/Loader';
import Field from '../../Components/TextField/Field';
import './settings.scss';

export default ({
	handleReloadRequest = () => { },
}) => {
	const dispatch = useDispatch();
	const submitBtn = useRef();
	let activeTab = useSelector(state => state.activeSettingTab)
	const submitProfileBtn = useRef();
	const user = useSelector(state => state.auth.user)
	const [categoryInterests, setcategoryInterests] = useState([]);
	const [organizationData, setOrganizationData] = useState({});
	const [countries, setCountries] = useState([])
	const [countrySearch, setCountrySearch] = useState(false);
	const [profileData, setProfileData] = useState({id: '', email: '', full_name: '', password: '', current_password: '', attachment_url: '', userMedia: ''})

	const authenticated = localStorage.manageToken ? true : false;
	const recoverurl = localStorage.recoverurl
	activeTab = authenticated && recoverurl === 'recover-account' ? 'account_details_notifications' : 'organization_details'
	useEffect(async () => {
		await dispatch(setLoader(true));
		await getCategoryInterst();
		await fetchAllCountries();
		await setUserDetails();
		await getOrganizationData();		
	}, []);


	const setUserDetails = async() => {
		const token = localStorage.getItem('manageToken')
		const response = await dispatch(fetchCurrentUser(token));
		if (response.data.status === "success"){
			const data = response.data.data
			setProfileData(prevState => ({
					...prevState,
					id: data.id,
					full_name: data.full_name,
					email: data.email,
					attachment_url: data.attachment_url,
				}))
		}
	}
	const getOrganizationData = async() => {
		const response = await dispatch(fetchOrganization(user.id));
		if(response.status){
			const organization = response.data
			setOrganizationData({
				id: organization.id,
				name: organization.name,
				media: organization.attachment_url,
				commission_percentage: organization.commission_percentage,
				organization_number: organization.organization_number, 
				billing_email: organization.billing_email,
				// line1: organization.full_address ? organization.full_address.line1 || '' : '' ,
				// city: organization.full_address ? organization.full_address.city || '':'' ,
				// state: organization.full_address ? organization.full_address.state || '' : '',
				// zip_code: organization.full_address ? organization.full_address.zip_code || '' : '',
				// country_id: organization.full_address ? [{id: organization.full_address.country_id, name: organization.full_address.country_name}] : [],// organization.full_address.country_id,
				image_url: organization.attachment_url && organization.attachment_url.length > 0 ? organization.attachment_url[0].media.url : '',
				image_id: organization.attachment_url && organization.attachment_url.length > 0 ? organization.attachment_url[0].id : '',
				invoice_ref_name: organization.invoice_ref_name ? organization.invoice_ref_name : '',
				address_id: organization.full_address ? organization.full_address.id : ''
			});
		}
		await dispatch(setLoader(false));
	}

	const getCategoryInterst = async() => {	
		const response = await dispatch(fetchCategoryInterst());
		if(response.success){
			setcategoryInterests(response.data)
		}
	}

	const fetchAllCountries = async () => {
		const response = await dispatch(fetchCountries())
		if (response.success)
			setCountries(response.data);
	}

	const handleChange = (e, type) => {
		const {name , value} = e.target  
		setOrganizationData(prevState => ({
			...prevState,
			[name] : value,
		}))
	}

	const handleProfileChange = (event) => {
		const {name , value} = event.target  
		setProfileData(prevState => ({
			...prevState,
			[name] : value		
		}))
	}

	const onFileChange = event => {
		let value = event.target.files[0]
		let width = 0
		let height = 0

		if (value) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var img = new Image;
        img.onload = function() {
        	width = img.width
        	height = img.height
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(value);
      setTimeout(function(){ 
				setOrganizationData(prevState => ({
					...prevState,
					width,
					height
				}))
      }, 3000);
			let file_type = value && value.type
			let file_size = value && value.size
			let extension = value && value.name.split('.')
			extension = '.'+extension[1]
			setOrganizationData(prevState => ({
				...prevState,
				media: value,
				image_url: value ? URL.createObjectURL(value) : "",
				file_type,
				file_size,
				extension
			}))
    }
	};

	const onUserMediaChange = event => {
		var value = event.target.files[0]
		setProfileData(prevState => ({
			...prevState,
			userMedia: value,
			attachment_url: value ? URL.createObjectURL(value) : ""
		}))
	}
	
	const submitOrgForm = async (e) => {
		await dispatch(setLoader(true));
		e.preventDefault();
		submitBtn.current.setAttribute('disabled', true);
		const {id, name, media, commission_percentage, organization_number, width, height, file_size, file_type, extension, image_id, invoice_ref_name, billing_email } = organizationData
		let data ={name, commission_percentage, organization_number, billing_email, invoice_ref_name }
		// let country_id = organizationData.country_id.length > 0 ? organizationData.country_id[0].id : ""
		// let address = {line1, city, state, zip_code, country_id }
		let media_data = { media, width, height, file_size,file_type, extension }
		var formdata = new FormData()
	    for (const key in data) {
				formdata.append(`organization[${key}]`, Array.isArray(data[key]) ? JSON.stringify(data[key]) : data[key]);
			}

			if (media && media.name) {
				for (const key in media_data) {
					formdata.append(`[organization][attachments_attributes][][${key}]`, media_data[key]);
				}
			}

		// for (const key in address) {
		// 	formdata.append(`[organization][address][${key}]`, address[key]);
		// }
		// for (const key in address) {
		// 	formdata.append(`[organization][addresses_attributes][][${key}]`, address[key]);
		// }

		// if(address_id && address_id != ''){
		// 	formdata.append(`[organization][addresses_attributes][][id]`, address_id);
		// }

		if(image_id && image_id != ''){
			formdata.append(`[organization][attachments_attributes][][id]`, image_id);
		}

		const { status, message } = await dispatch(updateOrganization(id, formdata))
		submitBtn.current.removeAttribute('disabled');
		status ? NotificationManager.success(message) : NotificationManager.error(message, '', 10000, () => {})
		await dispatch(setLoader(false));
		await getOrganizationData();
		handleReloadRequest()
	}
	const submitProfile = async (event) => {
		await dispatch(setLoader(true));
		event.preventDefault();
		submitProfileBtn.current.setAttribute('disabled', true);
		const {password, current_password, full_name, userMedia } = profileData
		const data = {password, current_password, full_name }
		var formdata = new FormData()
		for (const key in data) {
			formdata.append(`user[${key}]`, data[key]);
		}
		if(userMedia && userMedia.name)
			formdata.append('user[media]', userMedia);
		const { status, message }  = await dispatch(updateUser(formdata, profileData.id));
		submitProfileBtn.current.removeAttribute('disabled');
		status ? NotificationManager.success(message) : NotificationManager.error(message, '', 10000, () => {})
		await dispatch(setLoader(false));
		await getOrganizationData();
	}

	const onCountrySelect = (selectedList, selectedItem) => {
		setOrganizationData(prevState => ({
			...prevState,
			country_id :  selectedList.length === 0 ? [] : [selectedItem]
		}))
	}

	const onCountryChange = (searchText) => {
		searchText ? setCountrySearch(true) : setCountrySearch(false)
	}
 	
	return(
		<div id="view-dashboard">
			<div className="inner-view-dashboard">
				<div className="communities-content">
					<ul className="nav nav-tabs default-tabs">
						<li onClick={(e) => dispatch(setSettingTab('organization_details'))} className={activeTab === 'organization_details' ? 'active' : ''}><a data-toggle="tab" href="#organization_details">Company details</a></li>
						<li onClick={(e) => dispatch(setSettingTab('account_details_notifications'))} className={activeTab === 'account_details_notifications' ? 'active' : ''}><a data-toggle="tab" href="#account_details_notifications">Account details</a></li>
					</ul>
					<div className="tab-content tab-content-setting">
						<div id="organization_details" className={activeTab === 'organization_details' ? 'tab-pane fade in active' : 'tab-pane fade'}>
							<div className="organization_levels">
								<div className="organization_text">
									<form className="form-set" onSubmit={submitOrgForm}>
										<h4 className="h4basic03">Company {/*<a href="#"><img src={require("../../assets/images/icon_question_mark.svg")} /></a>*/}</h4>

										<div className="form-inputs">
											<Field
													type="text" 
													name="name" 
													onBlur={handleChange}
													placeholder="Company Name" 
													value={organizationData.name}
													containerClass="grey-bg"
												></Field>
										</div>

										<div className="form-inputs">
											<Field
													type="text" 
													name="commission_percentage" 
													onBlur={handleChange}
													placeholder="Commission" 
													value={organizationData.commission_percentage}
													containerClass="grey-bg"
												></Field>
										</div>

										<h4 className="h4basic03 mt-60">Billing {/*<a href="#"><img src={require("../../assets/images/icon_question_mark.svg")} /></a>*/}</h4>

										<div className="form-inputs">
											<Field
													type="text" 
													name="organization_number" 
													onBlur={handleChange}
													placeholder="Company number" 
													required={true}
													value={organizationData.organization_number}
													containerClass="grey-bg"
												></Field>
										</div>

										{/*<div className="form-inputs">
											<Field
													type="text" 
													name="line1" 
													onBlur={handleChange}
													placeholder="Address" 
													required={true}
													value={organizationData.line1}
													containerClass="grey-bg"
												></Field>
										</div>

										<div className="form-inputs city-check">
											<Field
													type="text" 
													name="city" 
													onBlur={handleChange}
													placeholder="City" 
													required={true}
													value={organizationData.city}
													containerClass="grey-bg"
												></Field>
										</div>

										<div className="form-inputs zip-check">
											<Field
													type="number" 
													name="zip_code" 
													onBlur={handleChange}
													placeholder="Post code" 
													required={true}
													value={organizationData.zip_code}
													containerClass="grey-bg"
												></Field>
										</div>

										<div className="form-inputs" id="campaign-page__community-selector">
											<label className={(countrySearch || (organizationData.country_id && organizationData.country_id.length > 0)) ? "labelClass field-active top-0" : "labelClass top-36"}>Country</label>
											<Multiselect
												options={countries}
												selectedValues={organizationData.country_id}
												onSelect={onCountrySelect}
												onRemove={onCountrySelect}
												singleSelect={false}
												onSearch={onCountryChange}
												placeholder=""
												displayValue="name"
												className="custom-select sources"
												/>
										</div>*/}

										<div className="form-inputs">
											<Field
													type="text" 
													name="billing_email" 
													onBlur={handleChange}
													placeholder="Invoice email" 
													required={true}
													value={organizationData.billing_email}
													containerClass="grey-bg"
												></Field>
										</div>

										<div className="form-inputs">
											<Field
													type="text" 
													name="invoice_ref_name" 
													onBlur={handleChange}
													placeholder="Invoice reference name" 
													required={true}
													value={organizationData.invoice_ref_name}
													containerClass="grey-bg"
												></Field>
										</div>
									</form>
								</div>
								<div className="organization_brand">
									<form className="form-set" onSubmit={submitOrgForm}>
										<h4 className="h4basic03">Brand logo {/*<a href="#"><img src={require("../../assets/images/icon_question_mark.svg")} /></a>*/}</h4>

										<div className="uploadOuter">
											<div className="dragBox">
												Drag and drop image here or click upload image
												<input type="file" ondragover="drag()" onDrop="drop()" id="uploadFile"  name="media" onChange={onFileChange}/>
												<div id="preview">
													{organizationData.image_url &&	<img src={organizationData.image_url} />}
												</div>
											</div>
										</div>

										<div className="save-bio">
											<button type="submit" ref={submitBtn} className="grey-bg">save</button>
										</div>
									</form>
								</div>
							</div>
						</div>

						<div id="account_details_notifications" className={activeTab === 'account_details_notifications' ? 'tab-pane fade in active' : 'tab-pane fade'}>
							<div id="organization_details" className="tab-pane fade in active">
							<form onSubmit={submitProfile}>
								<div className="organization_levels">
									<div className="organization_text">
										<form className="form-set">
											<h4 className="h4basic03">Account details</h4>
											<div className="uploadOuter">
												<div className="dragBox profile-pic">
													Drag and drop image here or click upload image
													<input type="file" ondragover="drag()" ondrop="drop()" id="uploadFile"  name="media" onChange={onUserMediaChange}/>
													<div id="preview">
														{(profileData.attachment_url !== undefined) &&	<img src={profileData.attachment_url} />}
													</div>
												</div>
											</div>
											
											<div className="form-inputs">
												<Field
													type="text" 
													name="full_name" 
													onBlur={handleProfileChange}
													placeholder="Full name" 
													required={true}
													value={profileData.full_name}
													containerClass="grey-bg"
												></Field>
											</div>
											
											<div className="form-inputs">
												<label className="labelClass field-active">Email</label>
												<input className="grey-bg" type="email" required="" value={profileData.email} disabled/>
											</div>
		
											<h4 className="h4basic03 mt-60">Change password</h4>
		
											<div className="form-inputs">
												<Field
													type="password" 
													name="current_password" 
													onBlur={handleProfileChange}
													placeholder="Current password" 
													value={profileData.current_password}
													containerClass="grey-bg"
												></Field>
											</div>
		
											<div className="form-inputs">
												<Field
													type="password" 
													name="password" 
													onBlur={handleProfileChange}
													placeholder="New password" 
													value={profileData.password}
													containerClass="grey-bg"
												></Field>
											</div>
										</form>
									</div>
									{/* <div className="organization_brand notification-account">
										<form className="form-set">
											<h4 className="h4basic03">Notifications</h4>
											<div className="select-switch">
												<label className="switch">
												  <input type="checkbox" />
												  <span className="slider round"></span>
												</label>Pause all notifcations in Manage
											</div>
											
											<div className="select-switch">
												<label className="switch">
												  <input type="checkbox" />
												  <span className="slider round"></span>
												</label>Pause all notifcations to email
											</div>
											
											<h5 className="h5basic01">Campaigns</h5>

											<table>
												<tr>
													<th>Manage</th>
													<th>Email</th>
													<th></th>
												</tr>

												<tr>
													<td>
														<label className="container-checkbox">
															  <input type="checkbox" />
															  <span className="checkmark"></span>
															</label>
														</td>
													<td>
														<label className="container-checkbox">
															  <input type="checkbox" />
															  <span className="checkmark"></span>
															</label>
														</td>
													<td>1 day before last day to approve or decline creators</td>
												</tr>

												<tr>
													<td>
														<label className="container-checkbox">
															  <input type="checkbox" />
															  <span className="checkmark"></span>
															</label>
														</td>
													<td>
														<label className="container-checkbox">
															  <input type="checkbox" />
															  <span className="checkmark"></span>
															</label>
														</td>
													<td>Icons has uploaded a draft</td>
												</tr>

												<tr>
													<td>
														<label className="container-checkbox">
															  <input type="checkbox" />
															  <span className="checkmark"></span>
															</label>
														</td>
													<td>
														<label className="container-checkbox">
															  <input type="checkbox" />
															  <span className="checkmark"></span>
															</label>
														</td>
													<td>Icon has posted to their social channel</td>
												</tr>
											</table>
											<h5 className="h5basic01">Messages</h5>
											<table>
												<tr>
													<th>Manage</th>
													<th>Email</th>
													<th></th>
												</tr>
												<tr>
													<td>
														<label className="container-checkbox">
															  <input type="checkbox" />
															  <span className="checkmark"></span>
															</label>
														</td>
													<td>
														<label className="container-checkbox">
															  <input type="checkbox" />
															  <span className="checkmark"></span>
															</label>
														</td>
													<td>Incoming messages</td>
												</tr>
											</table>
										
										</form>
									</div> */}
								</div>
								<div className="save-bio">
									<button type="submit" ref={submitProfileBtn} className="grey-bg">save</button>
								</div>
							</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}