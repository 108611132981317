import { SET_ORGANIZATION_SEARCH_DATA, SET_ORGANIZATION, SET_ORGANIZATION_LIST, SET_ORGANIZATION_PAGINATION_DATA } from '../Actions/Types/Types';
import isEmpty from 'lodash/isEmpty';

const initialState = {
  isAuthenticated: false,
  organization: {},
  full_address: {},
  organizationCategories: [],
  organizationList: [],
  switchOrganizationId: null
}

export const organization = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_ORGANIZATION:
      return {
        isAuthenticated: !isEmpty(action.organization),
        organization: action.organization,
        full_address: action.organization.full_address,
        organizationCategories: action.organization.categories
      }

    case SET_ORGANIZATION_LIST:
      return {
        organizationList: action.organizationList
      }
    default: return state;
  }
}

const initialPaginationData = { per_page: '', current_page: 1, total_pages: '', total_count: '', sort_by: 'name', sort_order: 'asc'  }
export const organizationPaginationData = (state = initialPaginationData, action) => {
  switch(action.type) {
    case SET_ORGANIZATION_PAGINATION_DATA:
      return action.value

    default: 
      return state;
  }
}

export const organizationSearchText = (state = '', action) => {
  switch(action.type) {
    case SET_ORGANIZATION_SEARCH_DATA:
      return action.value

    default: 
      return state;
  }
}