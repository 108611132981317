import Field from '../../Components/TextField/Field';
import RadioField from '../../Components/RadioField/Field';
import Choosen from "../../Components/Choosen/Choosen";
import { Multiselect } from 'multiselect-react-dropdown';

export default ({
	id = '',
	handleSubmit = () => {},
	communityData = {},
	handleChange = () => {},
	handleCountrySelect = () => {},
	onSelect = () => {},
	countries = [],
	categories = [],
	submitRef = null,
	isEdit = false
}) => {
	const renderOptions = (data = [], selected = []) => {
		return data.map((record) => {
			return <option key={Math.random()} value={record.id} selected={selected.indexOf(record.id) > -1}> {record.name}</option>
		})
	}

	const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };

	return(
		<form className="form-set" onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>
			<div className="edit-community-banner">
				<h4 className="h4basic03 mt-60">Brand Community image {/*<a href="#"><img src={require("../../assets/images/icon_question_mark.svg")} /></a>*/}</h4>
				<div className="uploadOuter">
					<div className="dragBox cam-height">
						Drag and drop image here or click upload image
						<input 
							type="file" 
							ondragover="drag()" 
							onDrop="drop()" 
							id="uploadFile" 
							name="media" 
							required={communityData.media ? false : true}
							onChange={handleChange}
						/>
						<div id="preview">
							{(communityData.media) && <img src={communityData.media.name ? URL.createObjectURL(communityData.media) : communityData.media} />}
						</div>
					</div>
				</div>
			</div>

			<div className="form-inputs select-country">
				<Choosen onChange={handleCountrySelect} dataplaceholder="Select Countries" className="chosen-select" multiple tabIndex="4">
					{renderOptions(countries, communityData.country_ids)}
				</Choosen>
			</div>

			<h4 className="h4basic03 mt-60">Brand logo {/*<a href="#"><img src={require("../../assets/images/icon_question_mark.svg")} /></a>*/}</h4>
			<div className="uploadOuter">
				<div className="dragBox cam-height">
					Drag and drop image here or click upload image
					<input 
						type="file" 
						ondragover="drag()" 
						onDrop="drop()" 
						id="uploadFile" 
						name="upload_logo" 
						required={communityData.upload_logo ? false : true}
						onChange={handleChange}
					/>
					<div id="preview">
						{(communityData.upload_logo) && <img src={communityData.upload_logo.name ? URL.createObjectURL(communityData.upload_logo) : communityData.upload_logo} />}
					</div>
				</div>
			</div>

			<h4 className="h4basic03 mt-60">Brand category{/*<a href="#"><img src={require("../../assets/images/icon_question_mark.svg")} /></a>*/}</h4>
			<div className="form-inputs">
				<Multiselect
					options={categories}
					selectedValues={communityData.category_ids}
					onSelect={onSelect}
					onRemove={onSelect}
					displayValue="name"
					className="custom-select sources"
					/>
			</div>

			<Field
				type="text"
				name="name"
				onBlur={handleChange}
				placeholder="Brand Community name" 
				required={true}
				value={communityData.name}
				containerClass="form-inputs"
			/>

			<p className="open-close-community">Open or closed brand community? 
				{/*<a href="javascript:void(0)">
					<img src={require('../../assets/images/icon_showmore.svg')} />
				</a>*/}
			</p>

			<RadioField
				name="status"
				onChange={handleChange}
				required={true}
				value={communityData.status}
				containerClass="radio-check"
				labelClass="container-checkbox"
				options={['Open', 'Closed']}
			/>

			{
				communityData.status === 'Closed' ? (
					<Field
						type="text"
						name="code"
						onBlur={handleChange}
						placeholder="Code"
						value={communityData.code}
						required={true}
						containerClass="form-inputs"
					/>
					) : null
			}
			<button type="submit" className="redirect-link mt-30" ref={submitRef} disabled={isEdit ? (id ? false : true) : false} >{isEdit ? 'Update' : 'Create'}
			</button>
		</form>
	)
}