import './style.scss';
import Modal from 'react-bootstrap/Modal'
import { useHistory } from 'react-router-dom'
import React, { useState } from 'react'
import CustomSelect from "../../Components/CustomSelect/Select";
import { Multiselect } from 'multiselect-react-dropdown';
import { createNotification } from '../../Redux/Actions/Organizations'
import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { startCase, toLower } from 'lodash';

const initialStatus = [
  { key: 'communities', value: 'Communities' },
  { key: 'campaigns', value: 'Campaigns' },
  { key: 'icons', value: 'Creators' }
]

const initialData = {
  title: '',
  description: '',
  community_ids: [],
  campaign_ids: [], 
  campaign_icons_status: [], 
  icon_ids: []
}

const campaignIconsStatus = [
  { key: 'pending', value: 'Pending' },
  { key: 'shortlisted', value: 'Shortlisted' },
  { key: 'availability_pending', value: 'Availability Pending' },
  { key: 'availability_accepted', value: 'Availability Accepted' },
  { key: 'availability_declined', value: 'Availability Declined' },
  { key: 'selected', value: 'Selected' },
  { key: 'draft_uploaded', value: 'Draft Uploaded' },
  { key: 'draft_approved', value: 'Draft Approved' },
  { key: 'posted', value: 'Posted' },
  { key: 'verified', value: 'Verified' },
]

export default ({
  show=false,
  communities=[],
  campaigns=[],
  ...props
}) => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [notificationType, setNotificationType] = useState('');
  const [openTypeModal, setOpenTypeModal] = useState(false);

  const [communityIds, setCommunityIds] = useState([]);
  const [community_attributes, setCommunityAttributes] = useState([]);

  const [campaignsIds, setCamapignIds] = useState([])
  const [campaigns_attributes, setCamapignAttributes] = useState([])

  const [selectType, setSelectType] = useState('');

  const [campignIconStatusIds, setCampignIconStatusIds] = useState([])
  const [campaigns_icon_status_attributes, setCamapignIconStatusAttributes] = useState([])

  const [iconDescription, setIconDescription] = useState('') 

  const [notificationData, setNotificationData] = useState(initialData);
  const dispatch = useDispatch();


  const closeModel = ()=> {
    setOpenModal(false)
    props.history.push('/communities')
  }

  const renderNotificationSelector = () => {
    return initialStatus.map(community => {
      return(
        <option key={Math.random()} value={community.key}>{community.value}
        </option>
      )
    })
  }

  const renderCommunitySelector = () => {
    return communities.map(community => {
      return(
        <option key={Math.random()} value={community.id}>{community.name}
        </option>
      )
    })
  }

  const handleNotificationChange =(e)=> {
    setNotificationType(e.target.value)
  }

  const closeTypeModel =()=> {
    setNotificationType('')
    setNotificationData(initialData)
    // setOpenModal(true)
    // setOpenTypeModal(false)
  }

  const handleCommunitySelect =(selectedList, selectedItem)=> {
    setCommunityIds(prevArray => [...prevArray, selectedItem])
    setCommunityAttributes(prevArray => [...prevArray, selectedItem.id])
  }

  const handleCommunityRemove = (removedList, removedItem) => {
    let communityArr = communityIds
    let communityIdsArr = community_attributes

    let indexObj = communityArr.indexOf(removedItem)
    let indexId = communityIdsArr.indexOf(removedItem.id)

    if(indexObj > -1) {
      communityArr.splice(indexObj, 1)
      setCommunityIds([...communityArr])
    }

    if(indexId > -1) {
      communityIdsArr.splice(indexId, 1)
      setCommunityAttributes([...communityIdsArr])
    }
  }


  const handleCampaignSelect =(selectedList, selectedItem)=> {
    setCamapignIds(prevArray => [...prevArray, selectedItem])
    setCamapignAttributes(prevArray => [...prevArray, selectedItem.id])
  }

  const handleCampaignRemove = (removedList, removedItem) => {
    let campaignArr = campaignsIds
    let campaignIdsArr = campaigns_attributes

    let indexObj = campaignArr.indexOf(removedItem)
    let indexId = campaignIdsArr.indexOf(removedItem.id)

    if(indexObj > -1) {
      campaignArr.splice(indexObj, 1)
      setCamapignIds([...campaignArr])
    }

    if(indexId > -1) {
      campaignIdsArr.splice(indexId, 1)
      setCamapignAttributes([...campaignIdsArr])
    }
  }


  const handleCampaignIconStatusSelect =(selectedList, selectedItem)=> {
    setCampignIconStatusIds(prevArray => [...prevArray, selectedItem])
    setCamapignIconStatusAttributes(prevArray => [...prevArray, selectedItem.key])
  }

  const handleCampaignIconStatusRemove = (removedList, removedItem) => {
    let iconStatusArr = campignIconStatusIds
    let iconStatusIdsArr = campaigns_icon_status_attributes

    let indexObj = iconStatusArr.indexOf(removedItem)
    let indexId = iconStatusIdsArr.indexOf(removedItem.id)

    if(indexObj > -1) {
      iconStatusArr.splice(indexObj, 1)
      setCampignIconStatusIds([...iconStatusArr])
    }

    if(indexId > -1) {
      iconStatusIdsArr.splice(indexId, 1)
      setCamapignIconStatusAttributes([...iconStatusIdsArr])
    }
  }


  const handleChange = (e)=> {
    setNotificationData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const handleSubmit = async()=> {

    notificationData.community_ids = community_attributes
    notificationData.campaign_ids = campaigns_attributes
    notificationData.campaign_icons_status = campaigns_icon_status_attributes

    let icon_ids = iconDescription
    if(icon_ids && icon_ids !== '' && icon_ids !== null && icon_ids !== undefined){
      icon_ids = icon_ids && icon_ids.split(",").map(function (value) {
         return value.trim()
      })
    }

    notificationData.icon_ids = icon_ids
    let errorMsg = false
    if(notificationData.title === ''){
      errorMsg = true
      NotificationManager.error('Please insert text for title', '', 10000, () => {})
    }

    if(notificationData.description === ''){
      errorMsg = true
      NotificationManager.error('Please insert text for description', '', 10000, () => {})
    }

    if(notificationType === 'communities'){
      if(communityIds && communityIds.length === 0 ){
        errorMsg = true
        NotificationManager.error('Please select any community', '', 10000, () => {})
      }

      if(selectType == ''){
        errorMsg = true
        NotificationManager.error('Please select any option', '', 10000, () => {})
      }

      if(selectType == 'icons'){
        if(iconDescription == ''){
          errorMsg = true
          NotificationManager.error("Please insert comma separated creators id's.", '', 10000, () => {})
        }
      }
    }

    if(notificationType === 'campaigns'){
      if(campaignsIds && campaignsIds.length === 0){
        errorMsg = true
        NotificationManager.error('Please select any campaigns', '', 10000, () => {})
      }

      if(selectType == ''){
        errorMsg = true
        NotificationManager.error('Please select any option', '', 10000, () => {})
      }

      if(selectType == 'icons'){
        if(iconDescription == ''){
          errorMsg = true
          NotificationManager.error("Please insert comma separated creators id's.", '', 10000, () => {})
        }
      }

      if(selectType == 'status'){
        if(campignIconStatusIds && campignIconStatusIds.length === 0){
          errorMsg = true
          NotificationManager.error("Please select any status", '', 10000, () => {})
        }
      }
    }

    if(notificationType === 'icons'){
      if(iconDescription == ''){
        errorMsg = true
        NotificationManager.error("Please insert comma separated creators id's.", '', 10000, () => {})
      }
    }

    if(!errorMsg){
      const response = await dispatch(createNotification(notificationData))
      if(response.status){
        NotificationManager.success(response.message, '', 10000, () => {})
        // setOpenModal(false)
        // setOpenTypeModal(false)

        setCommunityIds([])
        setCommunityAttributes([])

        setCamapignIds([])
        setCamapignAttributes([])

        setCampignIconStatusIds([])
        setCamapignIconStatusAttributes([])

        setIconDescription([])
        setNotificationType('')
        setNotificationData(initialData)
        
        // props.history.push('/communities')
      }else{
        NotificationManager.error(response.message, '', 10000, () => {})
      }
    }
  }

  return (
      <div id="view-dashboard">
        <div className="inner-view-dashboard">
          <div>
            <div className="form-group">
              <CustomSelect 
                name="status" 
                id="select-community02" 
                value={notificationType} 
                className="sources custom-select notification-modal" 
                placeholder="Source Type" 
                onChange={(e) => handleNotificationChange(e)}
              >
                <option value='' key={Math.random()}>
                  Select Type
                </option>
                
                { renderNotificationSelector() }
              </CustomSelect>
            </div>
          </div>
          {
            (notificationType !== '' && notificationType !== 'Select Type') && (
            <div className="notification-content">
              <form>
                <h3>{startCase(toLower(notificationType))}</h3>
                <br/>
                <div className="form-set notification-start">
                  <div className="form-inputs" style={{"margin": "37px 0 0"}}>
                    <label className={notificationData.title ? "labelClass field-active" : "labelClass"}>Title</label>
                    <input type="text" name="title" value={notificationData.title} required="true" onChange={(e)=> handleChange(e)} />
                  </div>
                  <div className="form-inputs" style={{"margin": "37px 0 0"}}>
                    <label className={notificationData.description ? "labelClass field-active" : "labelClass"} >Description</label>
                    <input type="text" name="description" value={notificationData.description} required="true" onChange={(e)=> handleChange(e)} />
                  </div>
                </div>
                <br/>
                {
                  notificationType === 'communities' && (
                    <div className="notification-content-select">
                      <div className="form-group">
                        <Multiselect
                          options={communities}
                          selectedValues={communityIds}
                          onSelect={handleCommunitySelect}
                          onRemove={handleCommunityRemove}
                          singleSelect={false}
                          placeholder={communityIds.length > 0 ? "" : "Select Brand Community"}
                          displayValue="name"
                          className="custom-select sources"
                          />
                      </div>
                      <div className="radio-check">
                        <label className="container-checkbox">Creators
                          <input type="radio" name="icons" checked={selectType === 'icons'} onClick={() => setSelectType('icons')} />
                          <span className="checkmark"></span>
                        </label>
                        <label className="container-checkbox">All
                          <input type="radio" name="all" checked={selectType === 'all'} onClick={() => setSelectType('all')} />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <br/>
                      { selectType === 'icons' && (
                          <div className="form-group">
                            <div className="caption-area">
                              <textarea
                                classes="label-text"
                                name="iconDescription"
                                value={iconDescription}
                                placeholder="Please insert comma separated creators uid"
                                onChange={(e)=> setIconDescription(e.target.value)}
                                id="textarea-focus"
                              ></textarea>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  )
                }

                {
                  notificationType === 'campaigns' && (
                    <div className="notification-content-select">
                      <div className="form-group">
                        <Multiselect
                          options={campaigns}
                          selectedValues={campaignsIds}
                          onSelect={handleCampaignSelect}
                          onRemove={handleCampaignRemove}
                          singleSelect={false}
                          placeholder={campaignsIds.length > 0 ? "" : "Select Campaign"}
                          displayValue="name"
                          className="custom-select sources"
                          />
                      </div>
                      <div className="radio-check">
                        <label className="container-checkbox">Creators
                          <input type="radio" name="icons" checked={selectType === 'icons'} onClick={() => setSelectType('icons')} />
                          <span className="checkmark"></span>
                        </label>
                        <label className="container-checkbox">Status
                          <input type="radio" name="status" checked={selectType === 'status'} onClick={() => setSelectType('status')} />
                          <span className="checkmark"></span>
                        </label>
                        <label className="container-checkbox">All
                          <input type="radio" name="all" checked={selectType === 'all'} onClick={() => setSelectType('all')} />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <br/>
                      { selectType === 'status' && (
                          <div className="form-group">
                            <Multiselect
                              options={campaignIconsStatus}
                              selectedValues={campignIconStatusIds}
                              onSelect={handleCampaignIconStatusSelect}
                              onRemove={handleCampaignIconStatusRemove}
                              singleSelect={false}
                              placeholder={campignIconStatusIds.length > 0 ? "" : "Select Status"}
                              displayValue="value"
                              className="custom-select sources"
                              />
                          </div>
                        )
                      }

                      { selectType === 'icons' && (
                          <div className="form-group">
                            <div className="caption-area">
                              <textarea
                                classes="label-text"
                                name="iconDescription"
                                value={iconDescription}
                                placeholder="Please insert comma separated creators uid"
                                onChange={(e)=> setIconDescription(e.target.value)}
                                id="textarea-focus"
                              ></textarea>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  )
                }
                
                {
                  notificationType === 'icons' && (
                    <div className="notification-content-select">
                      <div className="form-group">
                        <div className="caption-area">
                          <textarea
                            classes="label-text"
                            name="iconDescription"
                            value={iconDescription}
                            placeholder="Please insert comma separated creators uid"
                            onChange={(e)=> setIconDescription(e.target.value)}
                            id="textarea-focus"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  )
                }

                <div className="form-submittion">
                  <button type="button" data-dismiss="modal" onClick={()=> handleSubmit()}>SUBMIT</button>
                </div>
              </form>
            </div>
            )
          }
        </div>
      </div>
    )
};