import React, { useState, useRef, useCallback, useEffect } from 'react';
import Filters from '../../../../Components/Filters/Filters';
import IconCard from '../../../../Components/IconCard/Card';
import VerifyAlert from '../../../../Components/ConfirmationAlert/VerifyAlert';
import { verifyPost, uploadDrafts } from '../../../../Redux/Actions/Campaign';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import './style.scss';
import $ from 'jquery';
import { Link } from 'react-router-dom'
import { remove, map, pick, trim, toLower } from 'lodash'
import ReactPlayer from "react-player"
import { Chart } from "react-google-charts";
import Modal from 'react-bootstrap/Modal'
import CustomSelect from "../../../../Components/CustomSelect/Select";

const initialData = {
  post_id: '',
  campaign_id: '',
  platform_id: '',
  campaign_icon_id: '',
  record_id: ''
}

const initialRequestData = {
  campaign_icon_id: '',
  comment: '',
  draft_id: '',
  campaign_id: '',
  type: ''
}

// const initialDraftData = {
//   campaign_icon_id: '',
//   draftIds: []
// }

let initialDraftSlider = []
// let initialDrafts = []

const initialCaption = {
  campaign_icon_id: '',
  campaign_id: '',
  caption: ''
}

const initialUploadDraft = {
  campaign_icon_id: '',
  caption: '',
  draft_type: '',
  attachments_attributes: []
}

export default ({
  applicationData = {},
  handleEditRequest = () => { },
  setApplicationData = () => {},
  handleIconSelect = () => { },
  handleUnSelect = () => { },
  applyFilters = () => { },
  setSearchValue = () => { },
  applySorting = () => { },
  sortedClasses = () => { },
  filterData = {},
  draft_count = 0,
  total_count = 0,
  searchVal = '',
  campaignId = '',
  clearFilters =  () => {},
  draftRequest =  () => {},
  draftApproved =  () => {},
  postApproved =  () => {},
  itemDrafts =  [],
  itemDraftData =  [],
  genderInsight = [],
  ageInsight = [],
  countryInsight = [],
  cityInsight = [],
  itemStories = [],
  draftDecline =  () => {},
  editCaptionData =  () => {},
  handleDeleteCampaign  =  () => {},
  handleRejectCampaign =  () => {},
  handleMoveCampaignIcon =  () => {},
  handleSocialMediaIcon =  () => {},
  cuurent_campaign_id =  '',
  submitUploadDrafts =  () => {},
  handleSendNotification = () => {}
}) => {
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [selectedPreviewIcon, setSelectedPreviewIcon] = useState(null);
  const [description, setDescription] = useState('');
  const [visible, setvisible] = useState(false);
  const [inviteModel, setinviteModel] = useState(false);
  const [verifyData, setVerifyData] = useState(initialData);
  const dispatch = useDispatch();
  const [selectedRequestData, setSelectedRequestData] = useState(null);
  const [requestData, setrequestData] = useState(initialRequestData);
  const [comment, setComment] = useState('');
  const [draftData, setDraftData] = useState([]);
  const [draftIconIds, setDraftIconIds] = useState([]);
  const valueRef = useRef(initialDraftSlider);
  const [scrollData, setScrollData] = useState([]);
  const [videoDraft, setVideoDraft] = useState([]);
  const [draftUpdated, setDraftUpdated] = useState(false);
  const [show, setShow] = useState(false);
  const user = useSelector(state => state.auth.user);
  const node = useRef();
  const [clipboardStatus, setClipboardStatus] = useState(false)
  const [storiesData, setStoriesData] = useState([]);
  const [storiesScrollData, setStoriesScrollData] = useState([]);
  const [storyUpdated, setStoryUpdated] = useState(false);
  const [captionModal, setCaptionModal] = useState(false)
  const [captionData, setCaptionData] = useState(initialCaption)
  const [activeDraft, setActiveDraft] = useState('post')
  const [uploadCaptionModal, setUploadCaption] = useState(false)
  const [draftImages, setDraftImages] = useState([])
  const [draftVideos, setDraftVideos] = useState([])
  const [postType, setPostType] = useState('')
  const [uploadDraftData, setUploadDraftData] = useState(initialUploadDraft)
  const [isDisplayGraph, setIsDisplayGraph] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      request_text: description,
      campaign_icon_id: selectedIcon
    }
    await handleEditRequest(data)
    setSelectedIcon('')
    setDescription('')
  }

  const scroll = (value, direction, id, draftCount)=> {
    let far = $(`#image-slide${id}`).width()/3*direction;
    let pos = $(`#image-slide${id}`).scrollLeft() + far;
    $(`#image-slide${id}`).animate( { scrollLeft: pos }, 500)
  }

  const storyScroll = (value, direction, id, draftCount)=> {
    let far = $(`#image-slide-stories${id}`).width()/3*direction;
    let pos = $(`#image-slide-stories${id}`).scrollLeft() + far;
    $(`#image-slide-stories${id}`).animate( { scrollLeft: pos }, 500)
  }

  const renderPlatformData = (icon = {}, engagements=[], status= '') => {
    icon = icon ? icon : {}
    return (
      <div>
        <div className="profile-caption-left">
          <IconCard
            icon={icon}
            engagements={engagements}
            handleUnSelect={handleUnSelect}
            showUnselect={status === 'selected'}
            showPlatform={status !== 'selected'}
          />
        </div>
        <div className="profile-caption-right">
          <div className="draft_social-media" key={Math.random}>
            <span>
              <img src={require("../../../../assets/images/img_instagram.svg")} alt="img" /><strong>{icon.platform_icons && icon.platform_icons.username}</strong>
            </span>

            <span>
              <strong>{engagements && engagements[0].followers}
              </strong>Followers
            </span>
            <span>
              <strong>{engagements && engagements[0].percentage}%
              </strong>Engagement
            </span>
            <span>
              <strong>{engagements && engagements[0].price}
              </strong>Cost
            </span>
            <div className="social-meter">
            </div>
          </div>
        </div>  
      </div>
    )
  }

  const clickOnLogo = (platform) => {
    window.open(`https://www.instagram.com/${platform.username}`, '_blank')
  }

  const sliderView = ( media_url = '', media_type = '', draft={}, id='', status='' ) => {
    let draftIcon = videoDraft.filter(item => item.draft_id == draft.id)
    return (
        media_type === 'video' ? (
          <div className="slide-big-img" onClick={(e) => openDraft(draft, id, status)} >
          <ReactPlayer
            url={media_url ? media_url : ''}
            playing={draftIcon && draftIcon[0] && draftIcon[0].type === 'on' ? true : false}
            volume={1}
          />
          </div>
        ) : (
          <img className="slide-big-img" src={media_url ? media_url : ''} alt="img" onClick={(e) => openDraft(draft, id, status)} />
        )
      )
  }

  const videoPlayHandle = (type, draftId)=> {
    if(videoDraft.map(function(e) { return e.draft_id; }).indexOf(draftId) > -1){
      let index =  videoDraft.map(function(e) { return e.draft_id; }).indexOf(draftId)
      let obj =  videoDraft.find(o => o.draft_id == draftId)
      videoDraft[index].type = type
      setVideoDraft([...videoDraft])
    }else{

      if(videoDraft && videoDraft.length > 0) {
        let newArr = videoDraft.filter(item => item.draft_id !== draftId)
        let data = { type: type, draft_id: draftId }
        newArr.push(data)
        setVideoDraft([...newArr])
      }else{
        let data = { type: type, draft_id: draftId }
        setVideoDraft(prevArray => [...prevArray, data])
      }
    }
  }

  const openDraft = (platform, id, status)=> {
    $(document).ready(function(){
      $('#textarea-focus').focus();
    });

    platform.icon_status = status
    setSelectedRequestData(platform)
    const item =  platform.request_text
    setComment(item)
    setShow(true)
  }


  const selectStoriesDraft = (story, id)=> {
    setStoryUpdated(true)
    if(storiesData && storiesData.length === 0){
      if(itemStories.map(function(e) { return e.campaign_icon_id; }).indexOf(id) > -1){
        let index =  itemStories.map(function(e) { return e.campaign_icon_id; }).indexOf(id)
        let obj =  itemStories.find(o => o.campaign_icon_id == id)
        if(obj.draftIds.includes(story.id)){
          obj.draftIds = obj.draftIds.filter(id => id !== story.id)
          if(obj.draftIds && obj.draftIds.length > 0){
            itemStories[index].draftIds = obj.draftIds
            setStoriesData([...itemStories])
          }else{
            let newArr = itemStories.filter(item => item.campaign_icon_id !== id)
            setStoriesData([...newArr])
          }
        } else{
          obj.draftIds.push(story.id)
          itemStories[index].draftIds = obj.draftIds
          setStoriesData([...itemStories])
        }
      } else{
        let data = {
          campaign_icon_id: id,
          draftIds: []
        }
        data.draftIds.push(story.id)
        setStoriesData(prevArray => [...prevArray, data])
      }
    } else {
      if(storiesData.map(function(e) { return e.campaign_icon_id; }).indexOf(id) > -1){
        let index =  storiesData.map(function(e) { return e.campaign_icon_id; }).indexOf(id)
        let obj =  storiesData.find(o => o.campaign_icon_id == id)
        if(obj.draftIds.includes(story.id)){
          obj.draftIds = obj.draftIds.filter(id => id !== story.id)
          if(obj.draftIds && obj.draftIds.length > 0){
            storiesData[index].draftIds = obj.draftIds
            setStoriesData([...storiesData])
          }else{
            let newArr = storiesData.filter(item => item.campaign_icon_id !== id)
            setStoriesData([...newArr])
          }
        } else{
          obj.draftIds.push(story.id)
          storiesData[index].draftIds = obj.draftIds
          setStoriesData([...storiesData])
        }
      } else{
        let data = { campaign_icon_id: id, draftIds: [] }
        data.draftIds.push(story.id)
        setStoriesData(prevArray => [...prevArray, data])
      }
    }
  }

  const selectedDraft = (draft, id)=> {
    setDraftUpdated(true)
    if(draftData && draftData.length === 0){
      if(itemDrafts.map(function(e) { return e.campaign_icon_id; }).indexOf(id) > -1){
        let index =  itemDrafts.map(function(e) { return e.campaign_icon_id; }).indexOf(id)
        let obj =  itemDrafts.find(o => o.campaign_icon_id == id)
        if(obj.draftIds.includes(draft.id)){
          obj.draftIds = obj.draftIds.filter(id => id !== draft.id)
          if(obj.draftIds && obj.draftIds.length > 0){
            itemDrafts[index].draftIds = obj.draftIds
            setDraftData([...itemDrafts])
          }else{
            let newArr = itemDrafts.filter(item => item.campaign_icon_id !== id)
            setDraftData([...newArr])
          }
        } else{
          obj.draftIds.push(draft.id)
          itemDrafts[index].draftIds = obj.draftIds
          setDraftData([...itemDrafts])
        }
      } else{
        let data = {
          campaign_icon_id: id,
          draftIds: []
        }
        data.draftIds.push(draft.id)
        setDraftData(prevArray => [...prevArray, data])
      }
    } else {
      if(draftData.map(function(e) { return e.campaign_icon_id; }).indexOf(id) > -1){
        let index =  draftData.map(function(e) { return e.campaign_icon_id; }).indexOf(id)
        let obj =  draftData.find(o => o.campaign_icon_id == id)
        if(obj.draftIds.includes(draft.id)){
          obj.draftIds = obj.draftIds.filter(id => id !== draft.id)
          if(obj.draftIds && obj.draftIds.length > 0){
            draftData[index].draftIds = obj.draftIds
            setDraftData([...draftData])
          }else{
            let newArr = draftData.filter(item => item.campaign_icon_id !== id)
            setDraftData([...newArr])
          }
        } else{
          obj.draftIds.push(draft.id)
          draftData[index].draftIds = obj.draftIds
          setDraftData([...draftData])
        }
      } else{
        let data = { campaign_icon_id: id, draftIds: [] }
        data.draftIds.push(draft.id)
        setDraftData(prevArray => [...prevArray, data])
      }
    }
  }

  const approveDraft = (campaign_icon_id, id, type)=> {
    let draftIcon = draftData.filter(item => item.campaign_icon_id == id)
    if(type === 'story'){
      draftIcon = storiesData.filter(item => item.campaign_icon_id == id)
      if(draftIcon && draftIcon.length == 0){
        draftIcon = itemStories.filter(item => item.campaign_icon_id == id)
      }
    }
    if(draftIcon && draftIcon.length == 0){
      draftIcon = itemDrafts.filter(item => item.campaign_icon_id == id)
    }
    let data = {
      campaign_icon_id,
      draft_ids:draftIcon[0].draftIds
    }
    draftApproved(data, type)
  }

  const declineDraft = (campaign_icon_id, id, type)=> {
    let draftIcon = []
    if(type === 'story'){
      if(storiesData && storiesData.length > 0){
        draftIcon = storiesData.filter(item => item.campaign_icon_id == id)
      } else{
        draftIcon = itemStories.filter(item => item.campaign_icon_id == id)
      }
      let newArr = []
      setStoriesData([...newArr])
    } else {
      if(draftData && draftData.length > 0){
        draftIcon = draftData.filter(item => item.campaign_icon_id == id)
      } else {
        draftIcon = itemDrafts.filter(item => item.campaign_icon_id == id)
      }
      let newArr = []
      setDraftData([...newArr])
    }
    
    let data = {
      campaign_icon_id,
      draft_ids:draftIcon[0].draftIds
    }
    
    draftDecline(data, type)
  }

  const approvePost = (campaign_icon_id, id)=> {
    let data = { campaign_icon_id:campaign_icon_id}
    postApproved(data)    
  }

  const renderStoriesSlider = (stories=[], id='', campaign_id='', status='')=> {
    let storyIcon = storiesData.filter(item => item.campaign_icon_id == id)
    let scrollPos = storiesScrollData.filter(item=> item.icon_id == id)
    return (
      <div className="slider__main">
        <div className="wrapper">
          <a className="prev" onClick={(e) => storyScroll(null,-1, id, storiesData.length)}>&#10094;</a>
          <div className="image-container" id={`image-slide-stories${id}`} key={`image-slide-stories${id}`}>
            {
              stories.map((platform, index)  => {
                let media_url = platform.attachment && platform.attachment.media && platform.attachment.media.url 
                let media_type = platform.attachment && platform.attachment.attachment_type 
                platform.icon_id = id
                platform.campaign_id = campaign_id
                let isChecked = false
                if(storyIcon && storyIcon.length > 0) {
                  isChecked = storyIcon[0] && storyIcon[0].draftIds && storyIcon[0].draftIds.includes(platform.id)
                }else if(!storyUpdated){
                  isChecked = platform.status === 'approved' ? true : false
                }

                let showPlayIcon = true
                if(videoDraft && videoDraft.length > 0){
                  let itemStories = videoDraft.filter(item => item.draft_id == platform.id)
                  if(itemStories && itemStories.length > 0){
                    showPlayIcon = itemStories && itemStories[0] && itemStories[0].type === 'on' ? false : true
                  }
                } else{
                  showPlayIcon = true
                }
                return (
                  <div className="slider__image" key={platform.id} id={`image-move-stories${platform.id}`}>
                    {sliderView(media_url, media_type, platform, id, status)}
                    <div className="draft-labels">
                    {/*<span className="number-label">{index+1}/{drafts.length}</span>*/}
                      {
                        platform.status === 'declined' && (
                          <span>Draft declined</span>
                        )
                      }

                      {
                        platform.status === 'approved' && (
                          <span className="approved-label">Draft approved</span>
                        )
                      }
                      {
                        platform.status === 'commented' && (
                          <span className="commented-label-sent">Comment Sent</span>
                        )
                      }
                      {
                        platform.status === 'uploaded' && platform.request_text && platform.request_text !== '' && platform.request_text !== null && (
                          <span className="commented-label-saved">Comment Saved</span>
                        )
                      }
                    </div>
                    <div className="post-check">
                      {
                        status === 'draft_approved' ? (
                          <input 
                            type="checkbox" 
                            id={`check-stories${id+index}`} 
                            defaultChecked={platform.status === 'approved' ? true : false}
                            disabled="disabled"
                          />
                        ) : (
                          <input 
                            type="checkbox" 
                            id={`check-stories${id+index}`} 
                            onChange={()=> selectStoriesDraft(platform, id)}
                            checked={isChecked}
                          />
                        )
                      }
                      <label for={`check-stories${id+index}`}></label>
                    </div>

                    {
                      media_type === 'video' && (
                        <span className="play_video">
                          {
                            showPlayIcon ? (
                              <img className="" src={require("../../../../assets/images/icon_play_video.svg")} onClick={()=> videoPlayHandle('on', platform.id)}/>
                            ) : (
                              <img className="" src={require("../../../../assets/images/icon_pause.svg")} onClick={()=> videoPlayHandle('off', platform.id)}/>
                            )
                          }
                        </span>
                      )
                    }
                  </div>
                )
              })
            }
          </div>
          <a className="next" onClick={(e) => storyScroll(null,1, id, stories.length)}>&#10095;</a>
        </div>
      </div>
    )
  }

  const renderSlider = (drafts = [], id='', campaign_id='', status='') => {
    let draftIcon = draftData.filter(item => item.campaign_icon_id == id)
    let scrollPos = scrollData.filter(item=> item.icon_id == id)
    return (
      <div className="slider__main">
        <div className="wrapper">
          <a className="prev" onClick={(e) => scroll(null,-1, id, drafts.length)}>&#10094;</a>
          <div className="image-container" id={`image-slide${id}`} key={`image-slide-${id}`}>
            {
              drafts.map((platform, index)  => {
                let media_url = platform.attachment && platform.attachment.media && platform.attachment.media.url 
                let media_type = platform.attachment && platform.attachment.attachment_type 
                platform.icon_id = id
                platform.campaign_id = campaign_id
                let isChecked = false
                if(draftIcon && draftIcon.length > 0) {
                  isChecked = draftIcon[0] && draftIcon[0].draftIds && draftIcon[0].draftIds.includes(platform.id)
                }else if(!draftUpdated){
                  isChecked = platform.status === 'approved' ? true : false
                }

                let showPlayIcon = true
                if(videoDraft && videoDraft.length > 0){
                  let itemDraft = videoDraft.filter(item => item.draft_id == platform.id)
                  if(itemDraft && itemDraft.length > 0){
                    showPlayIcon = itemDraft && itemDraft[0] && itemDraft[0].type === 'on' ? false : true
                  }
                } else{
                  showPlayIcon = true
                }
                return (
                  <div className="slider__image" key={platform.id} id={`image-move-${platform.id}`}>
                    {sliderView(media_url, media_type, platform, id, status)}
                    <div className="draft-labels">
                    {/*<span className="number-label">{index+1}/{drafts.length}</span>*/}
                      {
                        platform.status === 'declined' && (
                          <span>Draft declined</span>
                        )
                      }

                      {
                        platform.status === 'approved' && (
                          <span className="approved-label">Draft approved</span>
                        )
                      }
                      {
                        platform.status === 'commented' && (
                          <span className="commented-label-sent">Comment Sent</span>
                        )
                      }
                      {
                        platform.status === 'uploaded' && platform.request_text && platform.request_text !== '' && platform.request_text !== null && (
                          <span className="commented-label-saved">Comment Saved</span>
                        )
                      }
                    </div>
                    <div className="post-check">
                      {
                        status === 'draft_approved' ? (
                          <input 
                            type="checkbox" 
                            id={`check${id+index}`} 
                            defaultChecked={platform.status === 'approved' ? true : false}
                            disabled="disabled"
                          />
                        ) : (
                          <input 
                            type="checkbox" 
                            id={`check${id+index}`} 
                            onChange={()=> selectedDraft(platform, id)}
                            checked={isChecked}
                          />
                        )
                      }
                      <label for={`check${id+index}`}></label>
                    </div>

                    {
                      media_type === 'video' && (
                        <span className="play_video">
                          {
                            showPlayIcon ? (
                              <img className="" src={require("../../../../assets/images/icon_play_video.svg")} onClick={()=> videoPlayHandle('on', platform.id)}/>
                            ) : (
                              <img className="" src={require("../../../../assets/images/icon_pause.svg")} onClick={()=> videoPlayHandle('off', platform.id)}/>
                            )
                          }
                        </span>
                      )
                    }
                  </div>
                )
              })
            }
          </div>
          <a className="next" onClick={(e) => scroll(null,1, id, drafts.length)}>&#10095;</a>
        </div>
        {/*<div>
          <span className="number-label">{scrollPos && scrollPos.length ? scrollPos[0].count : 1}/{drafts.length}</span>
        </div>*/}
      </div>
    )
  }

  const getAge = (icon_date)=> {
    var today = new Date();
    var birthDate = new Date(icon_date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
      age--;
    }
    return age;
  }

  const handleRedirect = (id) => {
    window && window.open(`/profile/${id}`, '_self')
  }

  const redirectSocialMedia = (username)=> {
    window && window.open(`https://www.instagram.com/${username}`, '_blank')
  }

  const renderCaption = (captions)=> {
    return captions.map((capt, index) => {
      return (
        <div key={index+1} className="caption-reader-loop">
          <h3>Story #{index+1}</h3>
          <p>{capt}</p>
        </div>
      )
    })
  }

  const profile_pic_icon = (icon) => {
    let profile_pic = icon && icon.attachments && icon.attachments[0] && icon.attachments[0].media && icon.attachments[0].media.url ? icon.attachments[0].media.url : null
    if (profile_pic) {
      return profile_pic
    } else {
      switch (toLower(trim(icon.gender))) {
        case 'male':
          return require('../../../../assets/images/default_male_user_icon.svg')
        case 'female':
          return require('../../../../assets/images/default_female_user_icon.svg')
        default:
          return require('../../../../assets/images/default_male_female_icon.svg')
      }
    }
  }

  

  const renderPostData = (icon = {}, engagements = [], drafts = [], caption = '', status = '', campaign_id= '', main_campaign_id='', stories= [], platform_icon = {} ) => {
    icon = icon ? icon : {}
    engagements = engagements ? engagements : []
    drafts = drafts ? drafts : []
    stories = stories ? stories : []

    let current_icon = []
    let current_story = []
    let captionData = stories && stories.map((item, index) => {
      return item.caption
    })

    if(draftData && draftData.length > 0){
      current_icon = draftData.filter(item => item.campaign_icon_id == icon.id && item.draftIds.length > 0)
    }else {
      current_icon = itemDrafts.filter(item => item.campaign_icon_id == icon.id && item.draftIds.length > 0)
    }

    if(storiesData && storiesData.length > 0){
      current_story = storiesData.filter(item => item.campaign_icon_id == icon.id && item.draftIds.length > 0)
    }else{
      current_story = itemStories.filter(item => item.campaign_icon_id == icon.id && item.draftIds.length > 0)
    }

    let isDraft = drafts.filter(item => item.status == 'approved')
    if(isDraft && isDraft.length === 0){
      isDraft = stories.filter(item => item.status == 'approved')
    }

    if(platform_icon && platform_icon.icon){
      icon = platform_icon.icon
    }

    let profile_pic = icon && icon.attachments && icon.attachments[0] && icon.attachments[0].media && icon.attachments[0].media.url ? icon.attachments[0].media.url : null
    let platformIconId = engagements && engagements[0] && engagements[0].platform && engagements[0].platform.id
    let instagramData = null
    if(platform_icon.platform_id == platformIconId){
      instagramData = platform_icon
    }
    // let instagramData = icon.platform_icons.find(item => item.platform_id == platformIconId)
    let engagementId = engagements && engagements[0]  && engagements[0].id
    return (
        <div class="selection-profiles">
          {/*<div class="check-all-profile">
            
          </div>*/}
          <div class="single-profile-check">
            {/*<div class="single-check">
              <input type="checkbox" name="" />
            </div>*/}
            <div class="profile-msg-description">
              <div class="profile-figure">
                <figure><img src={profile_pic_icon(icon)} alt="img" /></figure>
                <div class="photo-profile">
                  <div class="profile-name-starts">  
                    <h3 onClick={(e)=> handleRedirect(icon.id)} >{icon.first_name+' '+icon.last_name}</h3>
                    <p style={{"cursor": "pointer"}} onClick={(e)=> redirectSocialMedia(instagramData.username)} >{instagramData && instagramData.username}</p>
                  </div>
                  <ul class="person-details">
                    <li>{icon.gender || 'N/A'}</li>
                    <li> {icon.birthday ? `${getAge(icon.birthday)} years` : "" }</li>
                    <li> {icon.addresses && icon.addresses[0] && icon.addresses[0].city || 'N/A'}</li>
                  </ul>
                </div>
              </div>
              <div class="profile-followers-details">
                <ul className="insta-profile-details">
                  <li style={{"cursor": "pointer"}} onClick={()=> clickOnLogo(instagramData)}><img src={require("../../../../assets/images/instagram_icon.svg")} /><strong>Instagram</strong></li>
                  <li><strong>{engagements && engagements[0] && (engagements[0].followers || engagements[0].followers_count)}</strong>Followers</li>
                  <li><strong>{engagements && engagements[0] && engagements[0].percentage}%</strong>Engagement</li>
                  <li><strong>{engagements && engagements[0] && engagements[0].price}</strong>Cost</li>
                </ul>
                {/*<div className="msg-link"><a className="" href="javascript:void(0)">Message Vanessa</a></div>*/}
              </div>
              <div className="dropdown default-dropdown default-dropdown-draft">
                <a href="javascript:void(0)" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                  <img src={require("../../../../assets/images/icon_more_menu.svg")} />
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link to={`/profile/${icon.id}`} target="_self">See profile</Link>
                  </li>
                  <li>
                    <a href={`https://www.instagram.com/${instagramData && instagramData.username}`} target="_blank">Go to Instagram</a>
                  </li>
                  {
                    (status === 'draft_uploaded' || status === 'selected') && user && user.role === 'superadmin' && (
                      <li>
                        <a href="javascript:void(0)" onClick={()=> approvePost(campaign_id, icon.id)}>Approve Post</a>
                      </li>
                    )
                  }
                  { user && user.role === 'superadmin' && 'magnus@societyicon.com' === 'magnus@societyicon.com' &&  (
                      <li>
                        <a href="javascript:void(0)" onClick={(e)=> handleSocialMediaIcon(engagementId, e)}>Social Media Value</a>
                      </li>
                    )
                  }
                  { user && user.role === 'superadmin' &&  (
                      <li>
                        <a href="javascript:void(0)" onClick={(e)=> handleMoveCampaignIcon(campaign_id, e)}>Move to status</a>
                      </li>
                    )
                  }
                  { user && user.role === 'superadmin' && (
                      <li>
                        <a href="javascript:void(0)" onClick={(e)=> handleDeleteCampaign(campaign_id, e)}>Delete</a>
                      </li>
                    )
                  }

                  { user && user.role === 'superadmin' && (
                      <li>
                        <a href="javascript:void(0)" onClick={(e)=> handleRejectCampaign(campaign_id, e)} >Reject</a>
                      </li>
                    )
                  }
                      { user && user.role === 'superadmin' && (
                          <li>
                            <a href="javascript:void(0)" onClick={(e)=> handleUploadCaption(campaign_id, e)} >Upload Content</a>
                          </li>
                        )
                      }
                </ul>
              </div>
            </div>

            {
              status === 'draft_approved' && (
                <div className="post-stories-details">
                  <div className="manage-status">
                    {
                      status === 'draft_approved' ? 
                      "POST APPROVED" 
                      :
                      (status === 'draft_uploaded' || status === 'selected') && drafts.length > 0 && activeDraft == 'post' ? (
                        <div class={isDraft && isDraft.length > 0 ? "approvel-btns" : "approvel-btns approvel-btns-disabled"}>
                          <a href="javascript:void(0)" ></a>
                          <a href="javascript:void(0)" className="approve-draft-btns" onClick={()=> approvePost(campaign_id, icon.id)} >Approve Post</a>
                        </div>
                      ) : (
                        (status === 'draft_uploaded' || status === 'selected') && stories.length > 0 && activeDraft == 'stories' && (
                          <div class={isDraft && isDraft.length > 0 ? "approvel-btns" : "approvel-btns approvel-btns-disabled"}>
                            <a href="javascript:void(0)" ></a>
                            <a href="javascript:void(0)" className="approve-draft-btns" onClick={()=> approvePost(campaign_id, icon.id)} >Approve Post</a>
                          </div>
                        ) 
                      )
                    }
                  </div>
                  <ul className="nav nav-tabs">
                    <li className="active" onClick={()=> setActiveDraft('post')}><a data-toggle="tab" href={`#Post-${icon.id}`}>Post</a></li>
                    <li onClick={()=> setActiveDraft('stories')}><a data-toggle="tab" href={`#Stories-${icon.id}`}>Stories</a></li>
                  </ul>
                  <div className="tab-content">
                    <div id={`Post-${icon.id}`} className="tab-pane fade in active">
                      <div className="slider-caption">
                        {
                          drafts && drafts.length > 0 ? (
                            <div className="slider-profile">
                              {renderSlider(drafts, icon.id, campaign_id, status)}
                            </div>
                          ) : (
                            <div className="slider-profile">
                            </div>
                          )
                        }
                        <div className="caption-details">
                          <h3>Caption</h3>
                          <div className="caption-reader">
                            {caption}
                          </div>
                          {
                            user && user.role === 'superadmin' && (
                              <div class="edit-text"><a href="javascript:void(0)" onClick={()=> handleEditCaption(campaign_id, icon.id, caption)}>Edit Text</a></div>
                            )
                          }
                          {
                            status === 'draft_approved' ? (
                              <div class="approvel-btns">
                                <a href="javascript:void(0)"></a>
                                {
                                  user && user.role === 'superadmin' && (
                                  <a href="javascript:void(0)" className="draft-verfy-btn" onClick={()=> setVerifyModel(campaign_id, icon.id, platform_icon && platform_icon.platform_id, campaign_id)}>VERIFY</a>

                                  )
                                }
                              </div>
                            ) : (
                            <div class={current_icon && current_icon.length > 0 ? "approvel-btns" : "approvel-btns approvel-btns-disabled"} id={`approval-${icon.id}`}>
                              <a href="javascript:void(0)" className="approve-draft-btns decline-draft-danger-btns" onClick={()=> declineDraft(campaign_id, icon.id, 'post')}>Decline</a>
                              <a href="javascript:void(0)" className="approve-draft-btns" onClick={()=> approveDraft(campaign_id, icon.id, 'post')} >Approve draft</a>
                            </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                    <div id={`Stories-${icon.id}`} class="tab-pane fade">
                      <div className="slider-caption">
                        {
                          stories && stories.length > 0 ? (
                            <div className="slider-profile">
                              {renderStoriesSlider(stories, icon.id, campaign_id, status)}
                            </div>
                          ) : (
                            <div className="slider-profile">
                            </div>
                          )
                        }
                        <div className="caption-details">
                            {
                              captionData && captionData.length > 0 ? (
                                <div className="caption-reader">
                                  {renderCaption(captionData)}
                                </div>
                              ) : ('')
                            }
                          {/*<div class="edit-text"><a href="javascript:void(0)">Edit text>*/}
                          {
                            status === 'draft_approved' ? (
                              <div class="approvel-btns">
                                <a href="javascript:void(0)"></a>
                                {
                                  user && user.role === 'superadmin' && (
                                  <a href="javascript:void(0)" className="draft-verfy-btn" onClick={()=> setVerifyModel(campaign_id, icon.id, platform_icon && platform_icon.platform_id, campaign_id)}>VERIFY</a>

                                  )
                                }
                              </div>
                            ) : (
                            <div class={current_story && current_story.length > 0 ? "approvel-btns" : "approvel-btns approvel-btns-disabled"} id={`approval-${icon.id}`}>
                              <a href="javascript:void(0)" className="approve-draft-btns decline-draft-danger-btns" onClick={()=> declineDraft(campaign_id, icon.id, 'story')}>Decline</a>
                              <a href="javascript:void(0)" className="approve-draft-btns" onClick={()=> approveDraft(campaign_id, icon.id, 'story')} >Approve draft</a>
                            </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }

            {
              ((drafts && drafts.length > 0) || (stories && stories.length > 0)) && (status === 'selected' || status === 'draft_uploaded') ? (
                <div className="post-stories-details"> 
                  <div className="manage-status">
                    {
                      status === 'draft_approved' ? 
                      "POST APPROVED" 
                      :
                      (status === 'draft_uploaded' || status === 'selected') && drafts.length > 0 && activeDraft == 'post' ? (
                        <div class={isDraft && isDraft.length > 0 ? "approvel-btns" : "approvel-btns approvel-btns-disabled"}>
                          <a href="javascript:void(0)" ></a>
                          <a href="javascript:void(0)" className="approve-draft-btns" onClick={()=> approvePost(campaign_id, icon.id)} >Approve Post</a>
                        </div>
                      ) : (
                        (status === 'draft_uploaded' || status === 'selected') && stories.length > 0 && activeDraft == 'stories' && (
                          <div class={isDraft && isDraft.length > 0 ? "approvel-btns" : "approvel-btns approvel-btns-disabled"}>
                            <a href="javascript:void(0)" ></a>
                            <a href="javascript:void(0)" className="approve-draft-btns" onClick={()=> approvePost(campaign_id, icon.id)} >Approve Post</a>
                          </div>
                        ) 
                      )
                    }
                  </div>
                  
                  <ul className="nav nav-tabs">
                    <li className="active" onClick={()=> setActiveDraft('post')}><a data-toggle="tab" href={`#Post-${icon.id}`}>Post</a></li>
                    <li onClick={()=> setActiveDraft('stories')}><a data-toggle="tab" href={`#Stories-${icon.id}`}>Stories</a></li>
                  </ul>

                  <div className="tab-content">
                    <div id={`Post-${icon.id}`} className="tab-pane fade in active">
                      <div className="slider-caption">
                        {
                          drafts && drafts.length > 0 ? (
                            <div className="slider-profile">
                              {renderSlider(drafts, icon.id, campaign_id, status)}
                            </div>
                          ) : (
                            <div className="slider-profile">
                            </div>
                          )
                        }
                        <div className="caption-details">
                          <h3>Caption</h3>
                          <div className="caption-reader">
                            {caption}
                          </div>
                          {
                            user && user.role === 'superadmin' && (
                              <div class="edit-text" onClick={()=> handleEditCaption(campaign_id, icon.id, caption)}><a href="javascript:void(0)">Edit Text</a></div>
                            )
                          }
                          {
                            status === 'draft_approved' ? (
                              <div class="approvel-btns">
                                <a href="javascript:void(0)" className="draft-verfy-btn" onClick={()=> setVerifyModel(campaign_id, icon.id, platform_icon && platform_icon.platform_id, campaign_id)}>VERIFY</a>
                                <a href="javascript:void(0)" className="draft-verfy-btn approvel-btns-disabled">GO TO POST</a>
                              </div>
                            ) : (
                            <div class={current_icon && current_icon.length > 0 ? "approvel-btns" : "approvel-btns approvel-btns-disabled"} id={`approval-${icon.id}`}>
                              <a href="javascript:void(0)" className="approve-draft-btns decline-draft-danger-btns" onClick={()=> declineDraft(campaign_id, icon.id, 'post')}>Decline</a>
                              <a href="javascript:void(0)" className="approve-draft-btns" onClick={()=> approveDraft(campaign_id, icon.id, 'post')} >Approve draft</a>
                            </div>
                            )
                          }
                        </div>
                      </div>
                    </div>

                    <div id={`Stories-${icon.id}`} class="tab-pane fade">
                      <div className="slider-caption">
                        {
                          stories && stories.length > 0 ? (
                            <div className="slider-profile">
                              {renderStoriesSlider(stories, icon.id, campaign_id, status)}
                            </div>
                          ) : (
                            <div className="slider-profile">
                            </div>
                          )
                        }
                        <div className="caption-details">
                            {
                              captionData && captionData.length > 0 ? (
                                <div className="caption-reader">
                                  {renderCaption(captionData)}
                                </div>
                              ) : ('')
                            }
                          {/*<div class="edit-text"><a href="javascript:void(0)">Edit text>*/}
                          {
                            status === 'draft_approved' ? (
                              <div class="approvel-btns">
                                <a href="javascript:void(0)" className="draft-verfy-btn" onClick={()=> setVerifyModel(campaign_id, icon.id, platform_icon && platform_icon.platform_id, campaign_id)}>VERIFY</a>
                                <a href="javascript:void(0)" className="draft-verfy-btn approvel-btns-disabled">GO TO POST</a>
                              </div>
                            ) : (
                            <div class={current_story && current_story.length > 0 ? "approvel-btns" : "approvel-btns approvel-btns-disabled"} id={`approval-${icon.id}`}>
                              <a href="javascript:void(0)" className="approve-draft-btns decline-draft-danger-btns" onClick={()=> declineDraft(campaign_id, icon.id, 'story')}>Decline</a>
                              <a href="javascript:void(0)" className="approve-draft-btns" onClick={()=> approveDraft(campaign_id, icon.id, 'story')} >Approve draft</a>
                            </div>
                            )
                          }
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              ) : ('')
            }
          </div>
        </div>
      )
  }

  const recordToEdit = null
  // const recordToEdit = applicationData.data.find(record.icon => record.icon[0].id === selectedIcon)
  // const iconPreview = applicationData.data.find(record.icon => record.icon[0].id === selectedPreviewIcon)

  const iconPreview = selectedRequestData
  const openRequestEditPopUp = (icon) => {
    setSelectedPreviewIcon(null)
    setSelectedIcon(icon.id)
  }

  const getIconProfilePic = (icon) => {
    return (icon.profile_pic ? icon.profile_pic : (icon.gender && icon.gender === "female") ? require("../../../../assets/images/female-user.svg") : require("../../../../assets/images/male-user.svg"))
  }


  const handleVerifySelect = async (postId, verifiedAt) => {
    const post_id = postId
    const verified_at = verifiedAt
    setinviteModel(false);
    setVerifyData(prevState => ({
      ...prevState,
      post_id: post_id,
      verified_at: verified_at
    }))
    const { campaign_icon_id, campaign_id, platform_id, record_id } = verifyData
    const data = { campaign_icon_id, campaign_id, platform_id, post_id, verified_at }
    const response = await dispatch(verifyPost(data))
    if(response.status === "success"){
      NotificationManager.success(response.message) 
      let newState = Object.assign({}, applicationData)
      let record = newState.data.find(item => item.id === record_id)
      if(record) {
        record.status = 'posted'
        setApplicationData(newState)
      }
      setVerifyData(initialData);
      // window && window.location.reload();
    }  else {
      NotificationManager.error(response.data.message, '', 10000, () => {})
      // NotificationManager.error(response.data.message)
    }
  }

  const closeModel = () => {
    const item = ''
    setVerifyData(initialData);
    setComment(item)
    setinviteModel(false);
  }

  const closePreviewModel = ()=>{
    // $('#icon_request_proview').modal('hide');
    const item = ''
    setComment(item)
    // setSelectedRequestData(null)
    setShow(false)
  }

  const setVerifyModel = (campaign_id, icon_id, platform_id, record_id) => {
    setinviteModel(true);
    setVerifyData(prevState => ({
      ...prevState,
      campaign_icon_id: campaign_id,
      campaign_id: cuurent_campaign_id,
      platform_id: platform_id,
      record_id: record_id
    }))

  }

  const handleChangeRequestComment =(e)=> {
    let {name , value, type} = e.target;
    setComment(value)
  }

  const sendRequest = async (draft, type)=> {
    const item = ''
    let data = {
      campaign_icon_id: draft.campaign_id,
      comment: comment,
      draft_id: draft.id,
      campaign_id: draft.campaign_id,
      type: type
    }
    setComment(item)
    draftRequest(data)
    setSelectedRequestData(null)
    setShow(false)
  }


  const renderApplicationMarkup = () => {
    return applicationData.data.map(record => {
      return (
        record.status !== 'posted' ? (
          <li key={record.id}>
            {
              renderPostData(record.icon, record.engagements, record.drafts, record.caption, record.status, record.id, applicationData.camapign_id, record.stories, record.platform_icon)
            }
          </li>  
        ) : ('')
      )
    })
  }
 
   const copyToClipboard = (slug)=> {
     navigator.clipboard.writeText(slug)
     setClipboardStatus(true)

     setTimeout(function(){ 
       setClipboardStatus(false)
     }, 1000);
   }

   const handleEditCaption =(campaign_icon_id, id, caption)=> {
     setCaptionModal(true)
     setCaptionData(prevState => ({
       ...prevState,
       campaign_icon_id,
       campaign_id: '',
       caption
     }))
   }

   const closeCaptionModel =()=> {
     setCaptionModal(false)
     setCaptionData(initialCaption)
   }

  const handleChangeCaption = (e)=> {
    let { name, value } = e.target
    setCaptionData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const updateCaption =()=> {
    editCaptionData(captionData)
    setCaptionModal(false)
    setCaptionData(initialCaption)
  }

  const handleUploadCaption =(campaign_icon_id, e)=> {
    setUploadDraftData(prevState => ({
      ...prevState,
      campaign_icon_id: campaign_icon_id
    }))
    setUploadCaption(true)
  }

  const closeUploadModal =()=> {
    setDraftImages([])
    setDraftVideos([])
    setUploadDraftData(initialUploadDraft)
    setPostType('')
    setUploadCaption(false)
  }

  const handleChangePostType =(e)=> {
    setPostType(e.target.value)
    if(e.target.value !== ''){
      setUploadDraftData(prevState => ({
        ...prevState,
        draft_type: e.target.value
      }))
    }
  }

  const handleFileChange =(e)=> {
    let files = e.target.files
    let imageArr = []
    let videoArr = []
    for (let i = 0; i < files.length; i++) {
      let width = 0
      let height = 0
      let _file = files[i]

      if(_file){
        var reader = new FileReader();
        reader.onload = function (e) {
          var img = new Image;
          img.onload = function() {
            width = img.width
            height = img.height
          };
          img.src = reader.result;
        };
        reader.readAsDataURL(_file);
        let type = _file && _file.type
        type = type && type.split('/')
        let file_type = _file && _file.type
        let file_size = _file && _file.size
        let extension = _file && _file.name.split('.')
        extension = '.'+extension[1]
        if(type[0] && type[0] === 'image'){
          let imgObj = {
            media: _file,
            url: _file ? URL.createObjectURL(_file) : "",
            file_type: file_type,
            file_size: file_size,
            extension: extension,
            name: _file.name,
            id:'',
            caption:'',
            draft_type: postType,
            width,
            height
          }
          imageArr.push(imgObj)
        } else if(type[0] && type[0] === 'video'){
          let videoObj = {
            media: _file,
            url: _file ? URL.createObjectURL(_file) : "",
            file_type: file_type,
            file_size: file_size,
            extension: extension,
            name: _file.name,
            id:'',
            caption:'',
            draft_type: postType,
            width,
            height
          }
          videoArr.push(videoObj)
        }
      }
      setDraftImages(imageArr)
      setDraftVideos(videoArr)
    }
  }

  const renderImgaesDrafts = (draftImages)=> {
    return draftImages.map((capt, index) => {
      return (
        <div class="pip" key={capt.file_size+'-'+index}>
          <img class="imageThumb" src={capt.url} title={capt.name}/><span class="remove" onClick={()=> removeSingleDraft('image', index)}>X</span>
          {/*<div class="story-textarea"><textarea></textarea></div>*/}
        </div>
      )
    })
  }

  const renderVideosDrafts = (draftVideos)=> {
    return draftVideos.map((capt, index) => {
      return (
        <div class="pip" key={capt.file_size+'-'+index}>
          <span class="remove" onClick={()=> removeSingleDraft('video', index)}>X</span>
          <video controls>
            <source class="imageThumb" src={capt.url} title={capt.name}/>
          </video>
          {/*<div class="story-textarea"><textarea></textarea></div>*/}
        </div>
      )
    })
  }

  const removeSingleDraft =(type, index)=> {
    if(type === 'image'){
      draftImages.splice(index, 1);
      setDraftImages([...draftImages])
    } else if(type === 'video'){
      draftVideos.splice(index, 1);
      setDraftVideos([...draftVideos])
    }
  }

  const submitUploadDraftData =  async()=> {
    const { campaign_icon_id, draft_type, caption, attachments_attributes } = uploadDraftData
    if(draft_type && draft_type !== '' && ((draftImages && draftImages.length > 0) || (draftVideos && draftVideos.length > 0))){
      var formdata = new FormData()
      formdata.append(`campaign_icon[draft_type]`,  draft_type)
      formdata.append(`campaign_icon[caption]`,  caption)
      if(draftImages && draftImages.length > 0 ){
        draftImages.map(data => {
          formdata.append(`campaign_icon[attachments_attributes][][id]`, data.id)
          formdata.append(`campaign_icon[attachments_attributes][][media]`, data.media)
          formdata.append(`campaign_icon[attachments_attributes][][file_type]`, data.file_type)
          formdata.append(`campaign_icon[attachments_attributes][][file_size]`, data.file_size)
          formdata.append(`campaign_icon[attachments_attributes][][extension]`, data.extension)
          formdata.append(`campaign_icon[attachments_attributes][][caption]`, data.caption)
          formdata.append(`campaign_icon[attachments_attributes][][draft_type]`, data.draft_type)
          formdata.append(`campaign_icon[attachments_attributes][][width]`, data.width)
          formdata.append(`campaign_icon[attachments_attributes][][height]`, data.height)
        })
      }

      if(draftVideos && draftVideos.length > 0 ){
        draftVideos.map(data => {
          formdata.append(`campaign_icon[attachments_attributes][][id]`, data.id)
          formdata.append(`campaign_icon[attachments_attributes][][media]`, data.media)
          formdata.append(`campaign_icon[attachments_attributes][][file_type]`, data.file_type)
          formdata.append(`campaign_icon[attachments_attributes][][file_size]`, data.file_size)
          formdata.append(`campaign_icon[attachments_attributes][][extension]`, data.extension)
          formdata.append(`campaign_icon[attachments_attributes][][caption]`, data.caption)
          formdata.append(`campaign_icon[attachments_attributes][][draft_type]`, data.draft_type)
          formdata.append(`campaign_icon[attachments_attributes][][width]`, data.width)
          formdata.append(`campaign_icon[attachments_attributes][][height]`, data.height)
        })
      }
      await submitUploadDrafts(campaign_icon_id, formdata)
      setUploadCaption(false)
      setDraftImages([])
      setDraftVideos([])
      setUploadDraftData(initialUploadDraft)
      setPostType('')
    } else {
      NotificationManager.error('Please select require fields', '', 10000, () => {})
    }
  }

  const handleGraph = () => {
    setIsDisplayGraph(current => !current);
  }

  return (
    <>
      <div className="pending-request-details pending-request-details-update">
        <div className="requester-name">
          <div className="campaignNameStatus">
            <div>
              <h2>  
                {applicationData.campaign_name}
                &nbsp; {user && user.role === 'superadmin' && <img src={require("../../../../assets/images/notification-bell.svg")} alt="img" onClick={handleSendNotification} />}
              </h2>
            </div>
            <div  className='action-bar-status' style={{'float':'right','margin-right':'15px'}}>
              STATUS: <span className={`${applicationData.campaign_status}`} style={{'text-transform':'uppercase'}}>{applicationData.campaign_status}</span>
            </div>
          </div>
          {
            user && user.role === 'superadmin' && applicationData.slug && (
              <p className="campaign-icon-slug campaign-icon-slug-image" id="slug-text">
                {applicationData.slug ? `Campaign ID : `+applicationData.slug : ''} 
                <img src={require("../../../../assets/images/clipboard.svg")} alt="img" onClick={(e)=> copyToClipboard(applicationData.slug)}/>
                { clipboardStatus && (<span>Copied !</span>)}
              </p>
            )
          }
          <p>{applicationData.community_name}</p>
        </div>
{/*				<div>
					<button type="button" class="btn" style={{fontWeight: 'bold', color: 'white', background: '#209AF2', marginTop:'1px',marginBottom:'10px'}} onClick={handleSendNotification}>Send Notification</button>
				</div>*/}
        <div className="requester-other-details requester-other-details-update">
          <ul>
            <li>
              <h2>{draft_count}/{total_count}</h2>
              <p>Drafts uploaded</p>
            </li>
            <li>
              <h2>{applicationData.avg_engagement || 0}%</h2>
              <p>Avg. Engagement</p>
            </li>
            <li>
              <h2>{applicationData.potential_reach || 0}</h2>
              <p>Total Followers</p>
            </li>
          </ul>
            {/*<div className="last-child-link"><a href="javascript:void(0)">GENERATE PDF</a></div>*/}
        </div>
        {
          user && user.role === 'superadmin' && (
            ageInsight && ageInsight.length > 1 && (
              <div className="center-block">
                <button className="login-btn btn-rounded mt-30" onClick={(e) => handleGraph()}>{isDisplayGraph ? "Hide Graph" : "Show Graph"}</button>
              </div>
            )
          )
        }
        {
          user && user.role === 'superadmin' && (
            <div className={"graph-box" + (isDisplayGraph ? "" : " hide")}>
              <ul>
                {
                  genderInsight && genderInsight.length > 1 && (
                    <li>
                      <h3>Gender Distribution</h3>
                      <div className="outer-graph-box">
                        <div class="graph-set-box">
                          <Chart
                            width={'280px'}
                            height={'280px'}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={genderInsight}
                            options={{
                              title: '',
                              legend: { position: 'top', 'alignment':'center' },
                              pieStartAngle: 100,
                              slices: [ {color: "#add8e6"}, {color: "#FFB6C1"}, {color: "#808080"} ],
                              tooltip: {
                                text: 'percentage'
                              },
                              chartArea:{left:0,top:50,width: "100%"},
                             /* tooltip : { trigger: 'none' },*/
                            }}
                            rootProps={{ 'data-testid': '1' }}
                          />
                        </div>
                      </div>
                    </li>
                  )
                }
                {
                  ageInsight && ageInsight.length > 1 && (
                    <li>
                      <h3>Age Distribution</h3>
                      <div className="outer-graph-box">
                        <div class="graph-set-box">
                          <Chart
                            chartType="ColumnChart"
                            loader={<div>Loading Chart</div>}
                            data={ageInsight}
                            options={{
                              // Material design options
                              legend: { position: 'top'},
                             /* tooltip : { trigger: 'none' },*/
                              hAxis: {format: 'percent'},
                              colors: ['#add8e6', '#FFB6C1'],
                              vAxis: { minValue: 0},
                              chartArea:{top:50,width: "100%"},
                            }}
                            rootProps={{ 'data-testid': '2' }}
                          />
                        </div>
                      </div>
                    </li>
                  )
                }
              </ul>
              <ul>
                {
                  countryInsight && countryInsight.length > 1 && (
                    <li className="li-50">
                      <h3>Top Countries</h3>
                      <div className="outer-graph-box">
                        <div class="graph-set-box">
                          <Chart
                            width={'580px'}
                            height={'400px'}
                            chartType="BarChart"
                            loader={<div>Loading Chart</div>}
                            data={countryInsight}
                            options={{
                              title: '',
                              legend: { position: 'none' },
                              vAxis: { format: 'percent' },
                              tooltip : { text: 'percentage' },
                              chartArea:{left:170,top:40},
                              colors: ['#add8e6'],
                              annotations: {
                                textStyle: {
                                  color: 'black',
                                }
                              }
                            }}
                            // For tests
                            rootProps={{ 'data-testid': '1' }}
                          />
                        </div>
                      </div>
                    </li>
                  )
                }
                {
                  cityInsight && cityInsight.length > 1 && (
                    <li className="li-50">
                      <h3>Top Cities</h3>
                      <div className="outer-graph-box">
                        <div class="graph-set-box">
                          <Chart
                            width={'580px'}
                            height={'400px'}
                            chartType="BarChart"
                            loader={<div>Loading Chart</div>}
                            data={cityInsight}
                            options={{
                              title: '',
                              legend: { position: 'none' },
                              vAxis: { format: 'percent' },
                              tooltip : { text: 'percentage' },
                              chartArea:{left:120,top:40},
                              colors: ['#add8e6'],
                              annotations: {
                                textStyle: {
                                  color: 'black',
                                }
                              }
                            }}
                            // For tests
                            rootProps={{ 'data-testid': '1' }}
                          />
                        </div>
                      </div>
                    </li>
                  )
                }
              </ul>
            </div>
          )
        }
        
      </div>

      <div className="filter-views">
        <Filters
          applyFilters={applyFilters}
          filterData={filterData}
          clearFilters={clearFilters}
        />

        <div className="communities-views">
          <div className="inner-communities-views">
            <div className="progess-action-bar">
              <div className="bar-setup">
                <div className="progress-bar-value">
                  <strong>{applicationData.budget ? applicationData.budget.budget_reached : 0}</strong>
                  <span>{applicationData.budget ? applicationData.budget.budget_percent : 0}%</span>
                  <strong>{applicationData.budget ? applicationData.budget.total_budget : 0}</strong>
                </div>

                <div className="bar-value-fill">
                  <div className="unfill-bar"></div>
                  <div className="filled-bar filled-percent" style={{ width: `${applicationData.budget ? (applicationData.budget.budget_percent > 100) ? 100 : applicationData.budget.budget_percent : 0}%` }}></div>
                </div>
                <div className="currency-signature">Budget reached ({applicationData.budget ? applicationData.budget.currency_code : null})</div>
              </div>
              <div className="action-bar text-right hide"> <a href="javascript:void(0)" className="redirect-link">APPROVE DRAFTS</a> </div>
            </div>
            <div className="sort-search-bar">
              <ul>
                <li onClick={(e) => applySorting('created_at')}><a className={sortedClasses('created_at')} href="javascript:void(0)">Recent</a></li>
                <li onClick={(e) => applySorting('followers')}><a className={sortedClasses('followers')} href="javascript:void(0)">Followers</a></li>
                <li onClick={(e) => applySorting('percentage')}><a className={sortedClasses('percentage')} href="javascript:void(0)">Engagement</a></li>
                <li onClick={(e) => applySorting('price')}><a className={sortedClasses('price')} href="javascript:void(0)">Cost</a></li>
                <li onClick={(e) => applySorting('city')}><a className={sortedClasses('city')} href="javascript:void(0)">City</a></li>
                <li>
                  <form onSubmit={(e) => applyFilters(e)}>
                    <div className="search-input">
                      <input type="search" value={searchVal} onChange={(e) => setSearchValue(e.target.value)} className="" name="" placeholder="Search" />
                    </div>
                  </form>
                </li>
              </ul>
            </div>
            {/*<div className="communities-users-list">
              <div className="users-list-sets clearfix">
                <ul className="full-profile-caption">
                  {renderApplicationMarkup()}
                </ul>
              </div>
            </div>*/}
            {renderApplicationMarkup()}
          </div>
        </div>
      </div>

      {
        recordToEdit ? (
          <div id="change_icon_details" className="modal fade" role="dialog">
            <div className="modal-dialog width-1400">
              <h1 className="top-modal-heading">Choose a draft you wish to request an edit for

              <a href="javascript:void(0)" data-dismiss="modal"><img className="close-box" src={require("../../../../assets/images/icon_close.svg")} alt="Close" /></a>

              </h1>
              <p className="model-title" >An edit request will be sent to the icon</p>

              <div className="popup-video-descriptiom draft-popup-form">
                <form onSubmit={handleSubmit}>
                  <div className="multiple-draft-form">
                    <div className="form-flex">
                      <div className="post-user-name">
                        <img src={getIconProfilePic(recordToEdit)} alt="img" />
                        <div className="name-poster">{recordToEdit.name}</div>
                      </div>
                      <div className="video-box">
                        {/*<img src={recordToEdit.drafts.media.url} alt="profile" />*/}
                        {
                          recordToEdit.drafts && recordToEdit.drafts.attachment_type === 'video' ? (
                            <video controls>
                              <source src={recordToEdit.drafts && recordToEdit.drafts.media ? recordToEdit.drafts.media.url : ''} type="video/mp4" />
                            </video>
                          ) : (
                            <img src={recordToEdit.drafts && recordToEdit.drafts.media ? recordToEdit.drafts.media.url : ''} alt="img" />
                          )
                        }
                      </div>
                      <div className="post-like-dislike">
                        <div className="popst-mark">
                          <ul>
                            <li><a href="#"><img src={require("../../../../assets/images/heart.svg")} alt="" /></a></li>
                            <li><a href="#"><img src={require("../../../../assets/images/comment.svg")} alt="" /></a></li>
                            <li><a href="#"><img src={require("../../../../assets/images/send.svg")} alt="" /></a></li>
                          </ul>
                          <a className="post-bookmark"><img src={require("../../../../assets/images/boolmark.svg")} alt="" /></a>
                        </div>
                        <div className="post-caption">
                          <p className="">{recordToEdit.drafts.caption}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="submit-description">
                    <textarea placeholder="Describe what changes you wish to be made here" value={description} onChange={(e) => setDescription(e.target.value)} required></textarea>
                    <button type="submit">SEND REQUEST</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : null
      }
      {/*{
        iconPreview ? (
          <div id="icon_request_proview" className="modal fade icon_request_proview_modal" role="dialog" ref={node}>
            <div className="modal-dialog">
              <div className="full-picture-popup">
                <div className="inner-bg-popup">
                  <div className="inner-white-bg-popup">
                    <div className="close_popup"><a href="javascript:void(0)" data-dismiss="modal" onClick={()=> closePreviewModel()}><img src={require("../../../../assets/images/icon_close_black.svg")} alt="Close" /></a></div>
                    <h1 className="popup-head">What do you want to change about this picture?<p>An edit request will be sent to the icon</p></h1>
                    <div className="draft-image-view">
                      <figure>
                        {
                          iconPreview && iconPreview.attachment && iconPreview.attachment.attachment_type === 'video' ? (
                            <video controls>
                              <source src={iconPreview.attachment && iconPreview.attachment.media.url} type="video/mp4" />
                            </video>
                          ) : (
                            <img src={iconPreview.attachment && iconPreview.attachment.media.url} alt="img" />
                          )
                        }
                      </figure>
                    </div>
                    {
                      iconPreview && iconPreview.icon_status !== 'draft_approved' && (
                        <div>
                          <div className="caption-area">
                            <textarea
                              classes="label-text"
                              name="comment"
                              value={comment}
                              placeholder="Describe what changes you wish to be made here"
                              onChange={(e)=> handleChangeRequestComment(e)}
                              id="textarea-focus"
                            ></textarea>
                          </div>
                          <div className="request-btns">
                            <a className={(comment && comment !== '' && comment !== null && comment !== undefined) ? '' :'request-disable'} href="javascript:void(0)" onClick={()=> sendRequest(iconPreview, 'comment')}>Save comment</a>
                            <a className={(comment && comment !== '' && comment !== null && comment !== undefined) ? '' :'request-disable'} href="javascript:void(0)" onClick={()=> sendRequest(iconPreview, 'request')}>Send request</a>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null
      }*/}

      {
        inviteModel ? (
          <VerifyAlert inviteModel={inviteModel} handleVerifySelect={handleVerifySelect} closeModel={closeModel} />
        ) : null
      }

      {
        show ? (
          <Modal
            show={show}
            onHide={() => closePreviewModel()}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <div id="icon_request_proview" className="full-picture-popup">
              <div className="inner-bg-popup">
                <div className="inner-white-bg-popup">
                  <div className="close_popup"><a href="javascript:void(0)" data-dismiss="modal" onClick={()=> closePreviewModel()}><img src={require("../../../../assets/images/icon_close_black.svg")} alt="Close" /></a></div>
                  <h1 className="popup-head">What do you want to change about this picture?<p>An edit request will be sent to the icon</p></h1>
                  <div className="draft-image-view">
                    <figure>
                      {
                        iconPreview && iconPreview.attachment && iconPreview.attachment.attachment_type === 'video' ? (
                          <video controls>
                            <source src={iconPreview && iconPreview.attachment && iconPreview.attachment.media.url} type="video/mp4" />
                          </video>
                        ) : (
                          <img src={iconPreview && iconPreview.attachment && iconPreview.attachment.media.url} alt="img" />
                        )
                      }
                    </figure>
                  </div>
                  {
                    iconPreview && iconPreview.icon_status !== 'draft_approved' && (
                      <div>
                        <div className="caption-area">
                          <textarea
                            classes="label-text"
                            name="comment"
                            value={comment}
                            placeholder="Describe what changes you wish to be made here"
                            onChange={(e)=> handleChangeRequestComment(e)}
                            id="textarea-focus"
                          ></textarea>
                        </div>
                        <div className="request-btns">
                          <a className={(comment && comment !== '' && comment !== null && comment !== undefined) ? '' :'request-disable'} href="javascript:void(0)" onClick={()=> sendRequest(iconPreview, 'comment')}>Save comment</a>
                          <a className={(comment && comment !== '' && comment !== null && comment !== undefined) ? '' :'request-disable'} href="javascript:void(0)" onClick={()=> sendRequest(iconPreview, 'request')}>Send request</a>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </Modal>
        ) : null
      }

      {
        captionModal ? (
          <Modal
            show={captionModal}
            onHide={() => closeCaptionModel()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="caption-model"
          >
          <div className="close_popup"><a href="javascript:void(0)" data-dismiss="modal" onClick={()=> closeCaptionModel()}><img src={require("../../../../assets/images/icon_close_black.svg")} alt="Close" /></a></div>
          <h1 className="popup-head">Edit Caption</h1>
          <br/>
          <div className="data-modal-form">
            <div>
              <div className="caption-area">
                <textarea
                  classes="label-text"
                  name="caption"
                  value={captionData.caption}
                  placeholder="Describe what changes you wish to be made here"
                  onChange={(e)=> handleChangeCaption(e)}
                  id="textarea-focus"
                ></textarea>
              </div>
              <div className="request-btns">
                <a className={(captionData.caption && captionData.caption !== '' && captionData.caption !== null && captionData.caption !== undefined) ? '' :'request-disable'} href="javascript:void(0)" onClick={()=> updateCaption()}>Update</a>
                
              </div>
            </div>
          </div>
          </Modal>
        ) : null
      }

        {
          uploadCaptionModal ? (
            <Modal
              show={uploadCaptionModal}
              onHide={() => closeUploadModal()}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <div id="icon_request_proview" className="full-picture-popup">
                <div className="inner-bg-popup">
                  <div className="inner-white-bg-popup">
                    <div className="close_popup"><a href="javascript:void(0)" data-dismiss="modal" onClick={()=> closeUploadModal()}><img src={require("../../../../assets/images/icon_close_black.svg")} alt="Close" /></a></div>
                    <h1 className="popup-head"><p>Upload Drafts</p></h1>
                    <form>
                      <div id="field_upload" style={{'max-width':'100%'}}>
                        <div className="select-communities-bar select-communities-bar-update">
                          <CustomSelect name="post_type" id="select-community02" value={postType} className="sources custom-select sources-custom-select-update" placeholder="Post Type" onChange={(e)=> handleChangePostType(e)}>
                            <option value='' key={Math.random()}>
                              Select Draft Type
                            </option>
                            <option value='draft' key={Math.random()}>
                              Post
                            </option>
                            <option value='story' key={Math.random()}>
                              Story
                            </option>
                          </CustomSelect>
                        </div>

                        {
                          postType && postType !== '' && (
                            <div style={{'width':'100%'}}>
                              <div class="upload-box"><input type="file" id="files" name="file" accept=".jpg,.jpeg.,.gif,.png,.mov,.mp4,.webm" multiple onChange={(e)=> handleFileChange(e)}/>
                              </div>
                              <div id="pip-container">
                                <div id="inner-pip-container"></div>
                                {
                                  draftImages && draftImages.length > 0 && (
                                    renderImgaesDrafts(draftImages)
                                  )
                                }

                                {
                                  draftVideos && draftVideos.length > 0 && (
                                    renderVideosDrafts(draftVideos)
                                  )
                                }
                              </div>
                            </div>
                          )
                        }
                      </div>
                      {/*<div class="common-draft-textarea"><textarea></textarea></div>*/}
                      <div className="request-btns request-btns-update">
                        <a className='' href="javascript:void(0)" onClick={()=> submitUploadDraftData()}>Submit</a>
                        <a className='' href="javascript:void(0)" onClick={()=> closeUploadModal()}>Cancel</a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Modal>
          ) : null
        }
    </>
  )
}