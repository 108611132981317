import React, { useState, useRef } from 'react';
import Header from '../../Components/Header/Header';
import Field from '../../Components/TextField/Field';
import { NotificationManager} from 'react-notifications';
import { useDispatch } from 'react-redux';
import { passwordReset } from '../../Redux/Actions/Auth';
import './style.scss';

export default (props) => {
	const [email, setEmail] = useState('');
	const submitBtn = useRef();
	const dispatch = useDispatch();

	const submitDetails = async (e) => {
		e.preventDefault();
		submitBtn.current.setAttribute('disabled', true);
		const { status, message } = await dispatch(passwordReset({email}))

		submitBtn.current.removeAttribute('disabled');
		status ? NotificationManager.success(message) : NotificationManager.error(message, '', 10000, () => {})
		if(status)
			props.history.push('/')
	}

	return(
		<div id="wrapper">

			<div id="main">
				<div className="login-form">
					<div className="login-inner">
						<h1>Forgot your password?</h1>

						<h3>Fill in your email and we’ll send a password reset mail </h3>

						<form className="form-set" onSubmit={submitDetails}>
							<Field
								type="email" 
								name="email" 
								onBlur={(e) => setEmail(e.target.value)}
								placeholder="Email" 
								required={true}
								value={email}
								containerClass="form-inputs email-space"
							/>

							<button type="button" onClick={submitDetails} ref={submitBtn} className="login-btn btn-rounded send-mail">SEND</button>

							<p className="center-heading"></p>

							<a href="/login" className="signup-btn btn-rounded">BACK TO LOGIN</a>
						</form>
					</div>
				</div>
			</div>			
		</div>
	)
}