import React from 'react';

export default ({containerClass, logoClass, attachment_url, handleLogout, ...props}) => {

	const avatar = <img src={attachment_url} className="profile-user-icon" />;
	const embedMode = localStorage.getItem('embedMode');

	return embedMode ? null : (
		<div className={containerClass}>
			<div className={logoClass}>
			<a href="javascript:void(0)" className="dropdown-toggle" type="button" data-toggle="dropdown">
				{ avatar }
			</a>
		
			<ul className="dropdown-menu">
				<li><a href="#" onClick={handleLogout}>logout</a></li>
			</ul>
			</div>
		</div>
	)
}