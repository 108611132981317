import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header/Header';
import SideBar from '../../Components/SideBar/SideBar';
import CommunityDetails from '../../Components/Community/CommunityDetails';
import { fetchCountries } from '../../Redux/Actions/Countries';
import { fetchCategoryInterst } from '../../Redux/Actions/Categories';
import { logout, fetchCurrentUser } from '../../Redux/Actions/Auth';
import { fetchAll, setActiveTab } from '../../Redux/Actions/Community';
import { setActiveAside } from '../../Redux/Actions/Header';
import { useDispatch, useSelector } from 'react-redux';
import Refresh from '../../Components/Common/Refresh';
import './style.scss';
import '../../assets/javascript/external.js';
import HeaderLogo from '../../Components/Header/HeaderLogo';

export default ({...props}) => {
	const activeTab = useSelector(state => state.activeTab);
	const activeAside = useSelector(state => state.activeAside);
	const organization = useSelector(state => state.organization.organization);
	const [communities, setCommunities] = useState([]);
	const [countries, setCountries] = useState([]);
	const [categoryInterst, setcategoryInterests] = useState([]);
	const [organizationDetails, setOrganizationDetails] = useState({});
	const dispatch = useDispatch();

	let attachment_url = organization && organization.attachment_url && organization.attachment_url[0] && organization.attachment_url[0].media && organization.attachment_url[0].media.url
	
	useEffect(async () => {
		dispatch(setActiveTab('Communities'));
		// Fetch all created communities
		await fetchAllCommunities();

		// Fetch all Countries
		await fetchAllCountries();

		// Fetch all Categories
		await getCategoryInterst();

		// Fetch current user
		await getCurrentUser();

 		const file = require('../../assets/javascript/external.js');
	 	const script = document.createElement('script');
		script.src = file;
	 	script.async = true;
	 	document.body.appendChild(script);
		return () => {
	    	document.body.removeChild(script);
		}

	}, []);

	const getCurrentUser = async() => {
		const token = localStorage.getItem('manageToken')
		const response = await dispatch(fetchCurrentUser(token));
		if(response.data && response.data.status === 'success'){
			setOrganizationDetails(response.data.data)
		}
	}

	const getCategoryInterst = async() => {	
		const response = await dispatch(fetchCategoryInterst());
		if(response.success){
			setcategoryInterests(response.data)
		}
	}

	const fetchAllCountries = async () => {
		const response = await dispatch(fetchCountries())
		if(response.success)
			setCountries(response.data);
	}

	const fetchAllCommunities = async () => {
		const response = await dispatch(fetchAll())
		if(response.success)
			setCommunities(response.data);
	}

	const renderCommunitySelector = () => {
		return communities.map(community => {
			return(
				<option key={Math.random()} value={community.id}>{community.name}
				</option>
			)
		})
	}

	const handleLogout = (e) => {
		e.preventDefault();
		dispatch(logout());
		props.history.go()
	}
	return(
		<div id="wrapper">
			<Header
				containerClass="header-inner"
				logoClass="logo"
			>
				<div className="top-header-right">
					<h2 className="header-heading">{activeTab === 'Communities' ? 'Brand Communities' : activeTab}</h2>

					<div className="user-settings-menu">
						<Refresh />
						{/* <div className="dollar-menu select-default">
							<div className="dropdown default-dropdown">
							  <a href="javascript:void(0)" className="dropdown-toggle" type="button" dataToggle="dropdown">
							  	<img src="icon_currency.svg" />
							  </a>

							  <ul className="dropdown-menu">
							    <li><a href="javascript:void(0)">Price 01</a></li>
							    <li><a href="javascript:void(0)">Price 02</a></li>
							  </ul>
							</div>
						</div> */}

						{/* <div className="notification-menu select-default">
							<div className="dropdown default-dropdown">
							  <a href="javascript:void(0)" className="dropdown-toggle" type="button" data-toggle="dropdown">
							  	<img src="icon_bell.svg" />
							  </a>

							  <ul className="dropdown-menu">
							    <li><a href="javascript:void(0)"> Notification 01</a></li>
							    <li><a href="javascript:void(0)"> Notification 02</a></li>
							  </ul>
							</div>
						</div> 
						<div className="user-settings select-default">
							<div className="dropdown default-dropdown">
							  <a href="javascript:void(0)" className="dropdown-toggle" type="button" data-toggle="dropdown">
							  	<img src={organization.attachment_url} className="profile-user-icon" />
							  </a>
						
							  <ul className="dropdown-menu">
							    <li><a href="#" onClick={handleLogout}>logout</a></li>
							  </ul>
							</div>
						</div>*/}
						<HeaderLogo 
							containerClass="user-settings select-default"
							logoClass="dropdown default-dropdown"
							attachment_url={attachment_url}
							handleLogout={handleLogout}
						/>
					</div>
				</div>
			</Header>

			<div id="main">
			  <div className="main-inner">
			  	<SideBar
			  		activeTab={activeTab}
			  	>
				</SideBar>
			  	
			  	<CommunityDetails 
					communities={communities}
					countries={countries}
					categoryInterst={categoryInterst}
					fetchAllCommunities={fetchAllCommunities}
					renderCommunitySelector={renderCommunitySelector}
				/>
			  </div>
			</div>
		</div>
	)
}