import React, { useEffect, useState, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {organizationList, switchOrganization, fetchOrganization, setOrganizationPaginationData, setSearchValue, saveOrganization } from '../../Redux/Actions/Organizations'
import Pagination from '../Common/Pagination';
import classNames from 'classnames';
import { setLoader } from '../../Redux/Actions/Loader';
import { useHistory } from "react-router-dom";
import { NotificationManager} from 'react-notifications';
import CreateOrganisation from './createOrganisation';
import { fetchCountries } from '../../Redux/Actions/Countries';

export default () => {
	const [organizationData, setOrganisationData] = useState([]);
	const [countryWiseCount, setCountryWiseCount] = useState([]);
	const [searchText, setSearchText] = useState(useSelector(state => state.organizationSearchText));
	const user = useSelector(state => state.auth.user)
	const initialPaginationData = useSelector(state => state.organizationPaginationData)
	const [paginationData, setpaginationData] = useState(initialPaginationData)
	let [isSearchPage, setSerachPage] = useState(0)
	const dispatch = useDispatch();
  let history = useHistory();
  const [createModel, setCreateModel] = useState(false);
  const [countries, setCountries] = useState([]);
  // const [errors, setErrorMsg] = useState([]);

	useEffect(async () => {
		setpaginationData(initialPaginationData)
	}, [initialPaginationData])

	useEffect(async () => {
		if(searchText === '') 
			await dispatch(setLoader(true));
		getOrganizationList(paginationData.current_page)
	}, [searchText])


	const handleSearch = (value) => {
		dispatch(setSearchValue(value))
		setSearchText(value)
	}

	const changePage = (current_page, sort_by = null, sort_order = null)=> {
		if(searchText && searchText !== ''){
			setSerachPage(isSearchPage++)
		}
		getOrganizationList(current_page, sort_by,sort_order)
	}

	const getOrganizationList = async(current_page, sort_by = null, sort_order = null) => {
		if(searchText && searchText !== ''){ 
			if(isSearchPage > 0){
				current_page = current_page ? current_page : paginationData.current_page
			}else{
				current_page = 1
			}
		}
		const data = { page: current_page ? current_page : paginationData.current_page, search: searchText, sort_by: sort_by ? sort_by : paginationData.sort_by, sort_order: sort_order ? sort_order : paginationData.sort_order }
		const response = await dispatch(organizationList(data));
		if(response.status)
			setOrganisationData(response.data.data);
			setCountryWiseCount(response.data.country_wise_count);
			dispatch(setOrganizationPaginationData({
				...initialPaginationData,
				per_page: response.data.per_page,
				current_page: response.data.current_page,
				total_count: response.data.total_count,
				total_pages: response.data.total_pages,
				sort_by: data.sort_by,
				sort_order: data.sort_order
			}))
			// setpaginationData(prevState => ({
			// 	...prevState,
			// 	per_page: response.data.per_page,
			// 	current_page: response.data.current_page,
			// 	total_count: response.data.total_count,
			// 	total_pages: response.data.total_pages
			// }))


			const resCountries = await dispatch(fetchCountries())
			if(resCountries.success)
				setCountries(resCountries.data);

		await dispatch(setLoader(false));
		}

	const organizationFigure = Object.keys(countryWiseCount).map(key => {
		return (
			<li key={Math.random()}>
				<h1>{countryWiseCount[key]}</h1>
				<p>Companies<br />operating in <strong>{key}</strong></p>
			</li>
		)	
	})

	const applySorting = (type) => {
		const sortOrder = paginationData.sort_by === type ? paginationData.sort_order === 'asc' ? 'desc' : 'asc' : 'asc'
		dispatch(setOrganizationPaginationData({
			...initialPaginationData,
			sort_by: type,
			sort_order: sortOrder
		}))
		getOrganizationList(paginationData.current_page, type, sortOrder);
	}

	const sortedClasses = (type) => {
	    var sortClasses = classNames(
			{[paginationData.sort_order]: paginationData.sort_by === type}
	    )
	    return sortClasses
	}

	const setSwitchOrganization = async(organizationId) => {
		localStorage.setItem('organization_id', organizationId)
		switchOrganization(organizationId, user);
		await dispatch(fetchOrganization(user.id));
		NotificationManager.success('Company changed successfully.')
		history.push("/communities");
	}

	const closeCreateModel = ()=> {
		setCreateModel(false)
		// setErrorMsg([])
	}

	const createOrganisationHandle = async(data)=> {
		await dispatch(setLoader(true));
		const resOrg = await dispatch(saveOrganization(data))
		if (resOrg && resOrg.data && resOrg.data.status === 'success') {
			setCreateModel(false)
			NotificationManager.success(resOrg.data.message)
			getOrganizationList(paginationData.current_page, paginationData.sort_by, paginationData.sort_order);
			await dispatch(setLoader(false));
		} else {
			NotificationManager.error(resOrg.data.message)
			setCreateModel(false)
			await dispatch(setLoader(false));
		}
	}

	/*const validateInput = (data) => {
	  const Errors = [];
	  Object.keys(data).map(key => {
	    if(isEmpty(data[key])) {
	      Errors.push({
	        name: key,
	        value: `Please provide value for ${key}.`
	      })
	      setErrorMsg(Errors)
	    }
	  })
	  return isEmpty(Errors);
	}*/

	return(
		<div id="view-dashboard">
			{
				createModel && (
					<CreateOrganisation createModel={createModel} countries={countries} closeCreateModel={closeCreateModel} createOrganisationHandle={createOrganisationHandle} />
				)
			}
			<div className="inner-view-dashboard">
				<div className="organizations-list organizations-list-justify-between">
					<ul className="organizations-count">
						<li>
							<h1>{paginationData.total_count}</h1>
							<p>Companies<br />in <strong>total</strong></p>
						</li>
						{/*{organizationFigure}*/}
						
					</ul>
					<div className="create-campagin" ><a href="javascript:void(0)" className="redirect-link" onClick={() => setCreateModel(true)}>CREATE ORGANIZATION</a></div>
				</div>
				<div className="user-table-list">
					<div className="table-responsive">
						<table className="table-column-sort">
							<tr>
								<th style={{minWidth: "60px"}}></th>
								<th style={{width: "180px"}}><div className="head-sort" onClick={(e) => applySorting('name')}>COMPANY <a href="javascript:void(0)" className="table-sort" className={sortedClasses('name')}></a></div></th>
								{/*<th style={{width: "160px"}}><div className="head-sort" onClick={(e) => applySorting('country')}>COUNTRY <a href="javascript:void(0)" className="table-sort"  className={sortedClasses('country')}></a></div></th>*/}
								<th style={{width: "100%", paddingRight: "0"}}>
									<div className="search-input text-right"><input type="search" className="" name="searchText" placeholder="Search"  value={searchText} onChange={(event) => handleSearch(event.target.value)}/></div>
								</th>
							</tr>
							{organizationData.map(organization =>
								<tr key={Math.random()}>
									<td onClick={() => setSwitchOrganization(organization.id)} className="user-list-img"><img src={organization.attachment_url || null} /></td>
									<td onClick={() => setSwitchOrganization(organization.id)}>{organization.name}</td>
									{/*<td onClick={() => setSwitchOrganization(organization.id)}><p>{organization.country_name}</p></td>*/}
									<td style={{width: "90px", textAlign: "right"}}><a href="#" onClick={() => setSwitchOrganization(organization.id)} className="link-redirect-arrow"><img src={require("../../assets/images/icon_arrow_right_blue.svg")} /></a></td>
								</tr>
								)}
						</table>
					</div>

					<div className="padination-numbers">
					{
						paginationData.total_pages > 1 && (
							<Pagination 
									current_page={paginationData.current_page}
									per_page={paginationData.per_page}
									total_count={paginationData.per_page*paginationData.total_pages}
									setpaginationData={changePage}
								/>
						)
					}
					</div>
				</div>
			</div>
		</div>
	)
}