import React, { useState, useRef } from 'react';
import Header from '../../Components/Header/Header';
import Field from '../../Components/TextField/Field';
import { NotificationManager} from 'react-notifications';
import { useDispatch } from 'react-redux';
import { passwordRecover } from '../../Redux/Actions/Auth';
import './style.scss';

export default (props) => {
    let params = window.location.search
    const queryString = require('query-string');
    const parsed = queryString.parse(params);
	const token = parsed.reset_password_token;
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');
	const submitBtn = useRef();
	const dispatch = useDispatch();
	const [passwordVisible, setPasswordVisibility] = useState(false);

	const submitDetails = async (e) => {
		e.preventDefault();
		if (password !== passwordConfirmation) {
			NotificationManager.error('Password Confirmation doesn`t match, please verify.', '', 10000, () => {})
			return null;
		}

		submitBtn.current.setAttribute('disabled', true);
		const { status, message } = await dispatch(passwordRecover({reset_password_token: token, password}))

		submitBtn.current.removeAttribute('disabled');
		status ? NotificationManager.success(message) : NotificationManager.error(message, '', 10000, () => {})
		if(status)
			props.history.push('/')
	}

	return(
		<div id="wrapper">

			<div id="main">
				<div className="login-form">
					<div className="login-inner">
						<h1>Update password.</h1>

						<form className="form-set" onSubmit={submitDetails}>
						  <div className="form-inputs">
								<Field
									type="password" 
									name="password" 
									onBlur={(e) => setPassword(e.target.value)}
									placeholder="Password" 
									required={true}
									minLength={6}
									value={password}
									containerClass="form-inputs email-space"
								/>
							</div>
							<div className="form-inputs">
								<Field
									type={passwordVisible ? "text" : "password"} 
									name="password_confirmation" 
									onBlur={(e) => setPasswordConfirmation(e.target.value)}
									placeholder="Password Confirmation" 
									required={true}
									minLength={6}
									value={passwordConfirmation}
									containerClass="form-inputs email-space"
								/>
								<a href="#" onClick={() => setPasswordVisibility(!passwordVisible)} className={`${passwordVisible ? "show-pass" : "password-eye"}`}>
									<img className="wd-14" src={passwordVisible ? require('../../assets/images/crossed_eye.svg') : require('../../assets/images/icon_eye.svg')} alt="icon-eye"/>
								</a>
							</div>
							<button type="button" onClick={submitDetails} ref={submitBtn} className="login-btn btn-rounded send-mail">UPDATE PASSWORD</button>

							<p className="center-heading"></p>

							<a href="/login" className="signup-btn btn-rounded">BACK TO LOGIN</a>
						</form>
					</div>
				</div>
			</div>			
		</div>
	)
}