import { SET_CATEGORY_INTERST } from '../Actions/Types/Types';
import isEmpty from 'lodash/isEmpty';

const initialState = {
  isAuthenticated: false,
  categoryInterest: []
}

const categories = (state = initialState, action = {}) => {
  switch(action.type) {
    case SET_CATEGORY_INTERST:
      return {
        isAuthenticated: !isEmpty(action.categoryInterest),
        categoryInterest: action.categoryInterest
      }

    default: return state;
  }
}

export default categories;