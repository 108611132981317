import React, { useState, useEffect } from 'react';
import List from './List/List';
import CreateCampaign from './Create/Form';
import './Campaigns.scss';

export default ({
	communities = [], 
	renderCommunitySelector = () => {},
	...props
}) => {
	const [showForm, setShowForm] = useState(props.location.pathname.includes('new'));

	useEffect(async () => {
		setShowForm(props.location.pathname.includes('new'));
	}, [showForm, props.location.pathname]);

	return(
		<>
			{
				showForm ? (
					<CreateCampaign
						renderCommunitySelector={renderCommunitySelector}
						setShowForm={setShowForm}
						communities={communities}
					/>
				) : (
					<List
						communities={communities}
						setShowForm={setShowForm}
						renderCommunitySelector={renderCommunitySelector}
					
					/>
				)
			}
		</>
	)
}