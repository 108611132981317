import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { SET_ACTIVE_TAB, SELECT_COMMUNITY, SET_COMMUNITY_ICON_PAGINATION_DATA, SET_COMMUNITY_FILTER_DATA, SET_COMMUNITY_ACTIVE_TAB } from '../Actions/Types/Types';

export function setActiveTab(value) {
  return {
    type: SET_ACTIVE_TAB,
    value
  }
}

export function setCommunityActiveTab(value) {
  return {
    type: SET_COMMUNITY_ACTIVE_TAB,
    value
  }
}


export function setCommunityFilters(value = {}) {
  return {
    type: SET_COMMUNITY_FILTER_DATA,
    value
  }
}

export function create(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.COMMUNITY.CREATE()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      if (res.status) {
        return { success: true, message: res.data.message }
      } else {
        return { success: false, message: res.data.message }
      }
    }).catch((err) => {
      return err.response;
    });
}

export function update(data, id) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.COMMUNITY.UPDATE()}/${id}`;
  return dispatch => axios.put(apiURL, data)
    .then(res => {
      if (res.status) {
        return { success: true, message: res.data.message }
      } else {
        return { success: false, message: res.data.message }
      }
    }).catch((err) => {
      return err.response;
    });
}


export function fetchOne(id) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.COMMUNITY.FETCH_ONE()}/${id}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      if (res.status) {
        return { success: true, data: res.data.data }
      } else {
        throw Error(res.statusText);
      }
    }).catch((err) => {
      return err.response;
    });
}

export function fetchCommunityIcons(community_id = '', params = '') {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.COMMUNITY.FETCH_ICONS()}?community_id=${community_id}`;
  return dispatch => axios.get(apiURL, {
    params: params
  })
    .then(res => {
      if (res.status) {
        return { success: true, data: res.data }
      } else {
        throw Error(res.statusText);
      }
    }).catch((err) => {
      return err.response;
    });
}


export function fetchAll() {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.COMMUNITY.FETCH_ALL()}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      if (res.status) {
        return { success: true, data: res.data.data }
      } else {
        throw Error(res.statusText);
      }
    }).catch((err) => {
      return err.response;
    });
}

export function fetchOrganizationCommunities() {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.COMMUNITY.FETCH_ORGANIZATION_COMMUNITIES()}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      if (res.status) {
        return { success: true, data: res.data.data }
      } else {
        throw Error(res.statusText);
      }
    }).catch((err) => {
      return err.response;
    });
}

export function sendNotification(data) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.NOTIFICATION.SEND()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      if (res.status) {
        return { status: true, message: res.data.message }
      } else {
        throw Error(res.statusText);
      }
    }).catch((err) => {
      return { status: false, message: err.response.data.message }
    });
}


const initialPageData = {per_page: '', current_page: 1, total_pages: '', total_count: '', sort_by: 'created_at', sort_order: 'asc'}
export function setCommunityIconPaginationData(value = initialPageData) {
  return {
    type: SET_COMMUNITY_ICON_PAGINATION_DATA,
    value
  }
}

export function selectCommunity(value) {
  return {
    type: SELECT_COMMUNITY,
    value
  }
}