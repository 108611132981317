import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Tooltip = (props) => {
  const randerTooltip = () => {
    if (props && props.visible) {
      return(<div data-toggle='tooltip' data-placement={props.position} title={props.title}>
        {props.children}
      </div>)
    } else {
      return props.children
    }
  };
  return (randerTooltip());
};

Tooltip.defaultProps = {
  position: 'right',
  visible: true
};

const propTypes = {
  title: PropTypes.string.isRequired,
  position: PropTypes.string,
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool,
};

Tooltip.propTypes = propTypes;

export default Tooltip;
