import $ from "jquery";
import "chosen-js/chosen.jquery.js";
import './style.css';
import { useRef, useLayoutEffect } from "react";

const Chosed = (props) => {
  const elmt = useRef()
  
  useLayoutEffect(()=> {
    const $elmt = $(elmt.current)
    const handleChange = (e) => {
      props.onChange(e);
    }
    $elmt.chosen({
      placeholder_text: props.dataplaceholder
    })
    $elmt.on('change', handleChange)

    $elmt.trigger("chosen:updated")

    return () => {
      $elmt.off('change', handleChange);
      $elmt.chosen('destroy');
    }
  }, [props.children])
  
  return (
    <div>
        <select ref={elmt} {...props}>
          {props.children}
        </select>
      </div>
  )
}

export default Chosed;