import React, { useState, useRef } from 'react';
import Header from '../../Components/Header/Header';
import Field from '../../Components/TextField/Field';
import { NotificationManager} from 'react-notifications';
import { useDispatch } from 'react-redux';
import { passwordRecover, completeProfile } from '../../Redux/Actions/Auth';
import './style.scss';

export default (props) => {
  let params = window.location.search
  let defaultValues = params.split('&token=')
  defaultValues = defaultValues[0]
  let newEmail = defaultValues.split('?email=')
  const queryString = require('query-string');
  const parsed = queryString.parse(params);
	const token = parsed.token;
	const [password, setPassword] = useState('');
	const [fullname, setFullname] = useState('');
	const [email, setEmail] = useState(newEmail[1]);
	const [passwordConfirmation, setPasswordConfirmation] = useState('');
	const submitBtn = useRef();
	const dispatch = useDispatch();
	const [passwordVisible, setPasswordVisibility] = useState(false);

	const submitDetails = async (e) => {
		let data = {}
		e.preventDefault();
		if (fullname == '') {
			NotificationManager.error('Please enter your full name.', '', 10000, () => {})
			return null;
		}

		if (password == '') {
			NotificationManager.error('Please enter your password.', '', 10000, () => {})
			return null;
		}

		if (password !== passwordConfirmation) {
			NotificationManager.error('Password Confirmation doesn`t match, please verify.', '', 10000, () => {})
			return null;
		}
		submitBtn.current.setAttribute('disabled', true);
		data.password = password
		data.email = email
		data.token = token
		data.full_name = fullname
		const { status, message } = await dispatch(completeProfile(data))
		submitBtn.current.removeAttribute('disabled');
		status ? NotificationManager.success(message) : NotificationManager.error(message, '', 10000, () => {})
		if(status)
			props.history.push('/')
	}

	return(
		<div id="wrapper">

			<div id="main">
				<div className="login-form">
					<div className="login-inner">
						<h1>Complete Your Profile.</h1>

						<form className="form-set" onSubmit={submitDetails}>
							  <div className="form-inputs">
									<Field
										type="text" 
										name="email" 
										// onBlur={(e) => setEmail(e.target.value)}
										placeholder="Email" 
										// required={true}
										minLength={6}
										value={email}
										containerClass="form-inputs email-space"
										disabled="disabled"
									/>
								</div>
								  <div className="form-inputs">
										<Field
											type="text" 
											name="fullname"
											onBlur={(e) => setFullname(e.target.value)}
											placeholder="Full Name" 
											required={true}
											minLength={6}
											value={fullname}
											containerClass="form-inputs email-space"
										/>
									</div>
						  <div className="form-inputs">
								<Field
									type="password" 
									name="password" 
									onBlur={(e) => setPassword(e.target.value)}
									placeholder="Password" 
									required={true}
									minLength={6}
									value={password}
									containerClass="form-inputs email-space"
								/>
							</div>
							<div className="form-inputs">
								<Field
									type={passwordVisible ? "text" : "password"} 
									name="password_confirmation" 
									onBlur={(e) => setPasswordConfirmation(e.target.value)}
									placeholder="Password Confirmation" 
									required={true}
									minLength={6}
									value={passwordConfirmation}
									containerClass="form-inputs email-space"
								/>
								<a href="#" onClick={() => setPasswordVisibility(!passwordVisible)} className={`${passwordVisible ? "show-pass" : "password-eye"}`}>
									<img className="wd-14" src={passwordVisible ? require('../../assets/images/crossed_eye.svg') : require('../../assets/images/icon_eye.svg')} alt="icon-eye"/>
								</a>
							</div>
							  
							<button type="button" onClick={submitDetails} ref={submitBtn} className="login-btn btn-rounded send-mail">SUBMIT</button>

							<p className="center-heading"></p>

							<a href="/login" className="signup-btn btn-rounded">BACK TO LOGIN</a>
						</form>
					</div>
				</div>
			</div>			
		</div>
	)
}