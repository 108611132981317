import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header/Header';
import SideBar from '../../Components/SideBar/SideBar';
import OrganisationDetails from '../../Components/OrganisationDetails/Organisation';
import { logout, fetchCurrentUser } from '../../Redux/Actions/Auth';
import { useSelector, useDispatch } from "react-redux";
import { setActiveTab } from '../../Redux/Actions/Community';
import '../../assets/javascript/external.js';
import Refresh from '../../Components/Common/Refresh';
import HeaderLogo from '../../Components/Header/HeaderLogo';

export default ({...props}) => {
	const activeTab = useSelector(state => state.activeTab);
	const organization = useSelector(state => state.organization.organization);
	const [organizationDetails, setOrganizationDetails] = useState({});
	const dispatch = useDispatch();
	
	let attachment_url = organization && organization.attachment_url && organization.attachment_url[0] && organization.attachment_url[0].media && organization.attachment_url[0].media.url
	
	useEffect(async() => {
		// Fetch current user
		await getCurrentUser();

		dispatch(setActiveTab('Organizations'))
 		const file = require('../../assets/javascript/external.js');
	 	const script = document.createElement('script');
		script.src = file;
	 	script.async = true;
	 	document.body.appendChild(script);
		return () => {
	    	document.body.removeChild(script);
		}
	}, []);

	const getCurrentUser = async() => {
		const token = localStorage.getItem('manageToken')
		const response = await dispatch(fetchCurrentUser(token));
		if(response.data && response.data.status === 'success'){
			setOrganizationDetails(response.data.data)
		}
	}

	const handleLogout = (e) => {
		e.preventDefault();
		dispatch(logout());
		props.history.go()
	}

	const [ignoredWarning, setIgnoredWarning] = useState(false);
	
	return(
		<div id="wrapper">
			<Header
				containerClass="header-inner"
				logoClass="logo"
			>
				<div className="top-header-right">
					<h2 className="header-heading">{activeTab === 'Organizations' ? 'Companies' : activeTab}</h2>
					<div className="user-settings-menu">
					<Refresh />
						{/* <div className="dollar-menu select-default">
							<div className="dropdown default-dropdown">
							  <a href="javascript:void(0)" className="dropdown-toggle" type="button" dataToggle="dropdown">
							  	<img src="icon_currency.svg" />
							  </a>

							  <ul className="dropdown-menu">
							    <li><a href="javascript:void(0)">Price 01</a></li>
							    <li><a href="javascript:void(0)">Price 02</a></li>
							  </ul>
							</div>
						</div> */}

						{/* <div className="notification-menu select-default">
							<div className="dropdown default-dropdown">
							  <a href="javascript:void(0)" className="dropdown-toggle" type="button" data-toggle="dropdown">
							  	<img src="icon_bell.svg" />
							  </a>

							  <ul className="dropdown-menu">
							    <li><a href="javascript:void(0)"> Notification 01</a></li>
							    <li><a href="javascript:void(0)"> Notification 02</a></li>
							  </ul>
							</div>
						</div> 
						<div className="user-settings select-default">
							<div className="dropdown default-dropdown">
							  <a href="javascript:void(0)" className="dropdown-toggle" type="button" data-toggle="dropdown">
							  	<img src={organization.attachment_url} className="profile-user-icon" />
							  </a>
						
							  <ul className="dropdown-menu">
							    <li><a href="#" onClick={handleLogout}>logout</a></li>
							  </ul>
							</div>
						</div>*/}

						<HeaderLogo 
							containerClass="user-settings select-default"
							logoClass="dropdown default-dropdown"
							attachment_url={attachment_url}
							handleLogout={handleLogout}
						/>

					</div>
				</div>
			</Header>

			<div id="main">
			  <div className="main-inner">
			  	<SideBar
			  		activeTab={activeTab}
			  	>
				</SideBar>
			  	
				
				{ ignoredWarning && (<OrganisationDetails />)}
				{ !ignoredWarning && (
					<div id="view-dashboard">
						<div className="inner-view-dashboard">
							<div className="warning-area">Warning. This area is not intended for use, you should update using the Showroom Dashboard. <a onClick={ () => setIgnoredWarning(true) }>Ignore warning</a></div>
						</div>
					</div>
				)}
			  </div>
			</div>
		</div>
	)
}