import React, { useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import {fetchAllUser, deleteUser, sendInviteUser, getSelectedCommunities, updateUserAccessCommunities} from '../../Redux/Actions/User';
import { NotificationManager} from 'react-notifications';
import DeletePopup from "./DeletePopup";
import { passwordReset } from '../../Redux/Actions/Auth';
import './UserList.scss';
import InvitePopup from './InvitePopup';
import EditUserCommunities from './EditUserCommunities';
import { setLoader } from '../../Redux/Actions/Loader';
import Pagination from '../Common/Pagination';
import classNames from 'classnames';
import { fetchAll, fetchOrganizationCommunities} from '../../Redux/Actions/Community';
import { fetchCurrentUser } from '../../Redux/Actions/Auth';
import {findIndex} from 'lodash'

const initialPageData = {per_page: '', current_page: 1, total_pages: '', total_count: '', sort_by: 'full_name', sort_order: 'asc'}
export default () => {
	const [usersData, setUsersData] = useState([]);
	const dispatch = useDispatch();
	const [visible, setvisible] = useState(false);
	const [inviteModel, setinviteModel] = useState(false);
	const [activeUser, setActiveUser] = useState({});
	const [type, setType] = useState("");
	const [paginationData, setpaginationData] = useState(initialPageData);
	const [communitiesData, setCommunitiesData] = useState([]);
	const [communityIds, setCommunityIds] = useState([]);
	const [editCommunities, setEditCommunities] = useState(false);
	const [selectedCommunities, setSelectedCommunities] = useState([]);
	const [userId, setUserId] = useState();
	const [currentUser, setCurrentUser] = useState({});
	const [roles, setRoles] = useState([]);
	const [userRole, setUserRole] = useState();


	useEffect(async () => {
		await dispatch(setLoader(true));
		getAllCommunities();
		getAllUser();
		getCurrentUser();
	}, [])

	const getAllUser = async(page = paginationData.current_page, sort_order= paginationData.sort_order, type=paginationData.sort_by) => {
		let params = {}
		params.page = page;
		// data.search = searchVal;
		params.sort_by = type
		params.sort_order = sort_order

		const {status, message, data, response} = await dispatch(fetchAllUser(params))
		if(status){
			setUsersData(data);
			setpaginationData(prevState => ({
				...prevState,
				per_page: response.per_page,
				current_page: response.current_page,
				total_count: response.total_count,
				total_pages: response.total_pages
			}))
		}
		!status && NotificationManager.error(message, '', 10000, () => {})
		await dispatch(setLoader(false));
	}
	const getAllCommunities = async() =>{
		const {success, data} = await dispatch(fetchOrganizationCommunities())
		if(success)
			setCommunitiesData(data);
		!success && NotificationManager.error("Communities not fetch..", 10000, () => {})
	}

	const getCurrentUser = async() => {
		const token = localStorage.getItem('manageToken')
		const response = await dispatch(fetchCurrentUser(token));
		const user_roles = ['user', 'admin']
		if(response.data && response.data.status === 'success'){
			setCurrentUser(response.data.data);
			if(response.data.data.role === 'superadmin'){
				user_roles.push('superadmin');
			}
			setRoles(user_roles);
		}
	}

	const handleCommunitySelect = (selectedList, selectedItem) => {
		console.log("selectedList:==========", selectedList)
		if(selectedList && selectedList.length > 0 ){
			let obj = {
				id: '',
				community_id: selectedItem.id 
			}
			communityIds.push(obj)
		}
    setCommunityIds([...communityIds])
  }

  const handleCommunityRemove = (selectedList, selectedItem) => {
		communityIds.splice(communityIds.findIndex(a => a.community_id === selectedItem.id) , 1)
    setCommunityIds([...communityIds])
  }


	const deleteUserRecord = async() => {
		const {status, message} = await dispatch(deleteUser(activeUser.id))
		if(status){
			NotificationManager.success(message)
			getAllUser();
		}else{
			NotificationManager.error(message, '', 10000, () => {})
		}
		await dispatch(setLoader(false));
	}

	const resetPassword = async() =>{
		const { status, message } = await dispatch(passwordReset({email: activeUser.email}))
		status ? NotificationManager.success(message) : NotificationManager.error(message, '', 10000, () => {})
		await dispatch(setLoader(false));
	}

	const openModel = (user, type) =>{
		setvisible(true)
		setType(type)
		setActiveUser(user)		
	}

	const closeModel = async(actionType, type) => {
		if (actionType){
			await dispatch(setLoader(true));
			type === "delete" ? deleteUserRecord() : resetPassword()
		}
		setType("");
		setvisible(false);
		setActiveUser({});
	}
  
  const handleCloseModal = ()=> {
  	// setType("")
  	setvisible(false)
  	setActiveUser({})
  	setCommunityIds([]);
  }

	const sendInvite = async(email, role) => {
		await dispatch(setLoader(true));
		setinviteModel(false)
		var formdata = new FormData()
		formdata.append('user[email]', email);
		formdata.append('user[role]', role);
		if(communityIds && communityIds.length > 0)
			communityIds.map((community, index) => {
				for (const key in community) {
					formdata.append(`user[user_communities_attributes][][${key}]`, community[key]);
				}
			})
		const { status, message } = await dispatch(sendInviteUser(formdata));
		status ? NotificationManager.success(message) : NotificationManager.error(message, '', 10000, () => {})
		setCommunityIds([]);
		getAllUser();
		await dispatch(setLoader(false));
	}

	const closeInviteModel = ()=> {
		setinviteModel(false)
	}

	const getUserCommunities = async(user_id, role) => {
		setEditCommunities(true);
		setSelectedCommunities([...[]])
		setCommunityIds([...[]])
		const { status, res} = await dispatch(getSelectedCommunities({id: user_id}));
		let filterData = []
		let communityIds = []
		res && res.data.map((community, index) => {
			const indexPage = findIndex(communitiesData, {id: community.community_id})
			if(indexPage > -1){
				let obj = {
					id: community.id,
					community_id: communitiesData[indexPage].id 
				}
				communityIds.push(obj)
				filterData.push(communitiesData[indexPage])
			}
		})
		setSelectedCommunities([...filterData])
		setCommunityIds([...communityIds])
		setUserId(user_id);
		setUserRole(role);
  }	
  
	const updateUserCommunities = async(role) => {
    await dispatch(setLoader(true));
		setEditCommunities(false);
		var formdata = new FormData()
		formdata.append('user[role]', role);
		formdata.append(`user[id]`, userId);
		if(communityIds && communityIds.length > 0)
			communityIds.map((community, index) => {
				for (const key in community) {
					formdata.append(`user[user_communities_attributes][][${key}]`, community[key]);
				}
			})
		const { status, message } = await dispatch(updateUserAccessCommunities(formdata));
		status ? NotificationManager.success(message) : NotificationManager.error(message, '', 10000, () => {})
		setCommunityIds([]);
		getAllUser();
		await dispatch(setLoader(false));
  }

	const handlePageChange = async (page) => {
		await setpaginationData(prevState => ({
			...prevState,
			current_page: page
		}))

		await getAllUser(page, paginationData.sort_order, paginationData.type);
	}

	const sortedClasses = (type) => {
		const isActive = paginationData.sort_by === type
		var sortClasses = classNames(
			'table-sort',
			{ 'table-sort-activer': isActive },
			{ [paginationData.sort_order]: isActive }
		)
		return sortClasses
	}

	const applySorting = async (type) => {
		const sortOrder = paginationData.sort_by === type ? paginationData.sort_order === 'asc' ? 'desc' : 'asc' : 'asc'
		await setpaginationData(prevState => ({
			...prevState,
			sort_by: type,
			sort_order: sortOrder
		}))
		await getAllUser(paginationData.page, sortOrder, type)
	}

	const openInviteModel = () => {
		setinviteModel(true);
		setSelectedCommunities([...[]]);
		setCommunityIds([...[]]);
	}

	return(
		<div id="view-dashboard">
			<DeletePopup visible={visible} closeModel={closeModel} type={type} handleCloseModal={handleCloseModal}/>
			<InvitePopup onRemove={handleCommunityRemove} onSelect={handleCommunitySelect} inviteModel={inviteModel} sendInvite={sendInvite} closeModel={closeInviteModel} communitiesData={communitiesData} roles={roles}/> 
			<EditUserCommunities onRemove={handleCommunityRemove} onSelect={handleCommunitySelect} editCommunities={editCommunities} closeModel={setEditCommunities} communitiesData={communitiesData} selectedCommunities={selectedCommunities} updateUserCommunities={updateUserCommunities} roles={roles}  userRole={userRole}/>
			<div className="inner-view-dashboard">
				{	
					currentUser && (currentUser.role === 'superadmin' || currentUser.role === 'admin') && (
						<div className="create-campagin"><a href="javascript:void(0)" className="redirect-link" onClick={() => openInviteModel()}>ADD TEAM MEMBER</a></div>
					)
				}
				<div className="user-table-list">
					<div className="table-responsive">
						<table>
							<tr>
								<th style={{minWidth: "60px"}}></th>
								<th onClick={(e) => applySorting('full_name')} ><div className="head-sort">NAME <a href="javascript:void(0)" className={sortedClasses('full_name')}></a></div></th>
								<th onClick={(e) => applySorting('email')} ><div className="head-sort">EMAIL <a href="javascript:void(0)" className={sortedClasses('email')}></a></div></th>
								<th></th>
								<th></th>
								<th></th>
							</tr>
							{usersData.map(user => 
								<tr>
									<td className="user-list-img"><img src={user.image} /></td>
									<td>{user.full_name}</td>
									<td>{user.email}</td>
									<td style={{width: "160px"}}><a href="javascript:void(0)" onClick={() => openModel(user, "reset password")}>RESET PASSWORD</a></td>
									<td style={{width: "90px"}}><a href="javascript:void(0)" className="link-delete" onClick={() => openModel(user, "delete")}>DELETE</a></td>
									<td style={{width: "160px"}}><a href="javascript:void(0)" onClick={() => getUserCommunities(user.id, user.role)}>EDIT USER</a></td>
								</tr>
							)}
						</table>
					</div>
				</div>
				{
					paginationData.total_pages > 1 && (
						<div className="padination-numbers">
							<Pagination 
								current_page={paginationData.current_page}
								per_page={paginationData.per_page}
								total_count={paginationData.total_count}
								setpaginationData={handlePageChange}
							/>
						</div>
					) 
				}
			</div>
		</div>
	)
}