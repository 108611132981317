export const dateOptions = {
	'apply_date': 'Apply latest',
	'select_reject_date': 'Approved/declined latest',
	'draft_upload_date': 'Draft lastest',
	'draft_approved_date': 'Content uploaded'
}

export const iconStatus = {
	'availability_pending': 'Pending',
	'availability_accepted': 'Available',
	'availability_declined': 'Unavailable',
	'shortlisted': 'shortlisted',
	'rejected': 'Rejected',
}

export const createMarkup = (string = '') => {
	return {__html: string};
}