import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { cloneDeep, isEmpty } from 'lodash';

const initialValue = {
	engagement:{
		min_value: '',
		max_value: ''
	},
	ci_engagement: {
		min_value: '',
		max_value: ''
	},
	followers:{
		min_value: '',
		max_value: ''
	},
	ci_followers:{
		min_value: '',
		max_value: ''
	},
	cost: {
		min_value: '',
		max_value: ''
	},
	ci_price: {
		min_value: '',
		max_value: ''
	}
}
export default ({
	applyFilters = () => {},
	clearFilters = () => {},
	filterType = "campiagn", 
	filterData
}) => {

	const [activeFilter, setActiveFilter] = useState([]);

	const [data, setData] = useState(isEmpty(filterData) ? initialValue : filterData);
	useEffect(async () => {
		setData(filterData)
	}, [filterData]);


	const handleToggle = (e) => {
		e.preventDefault();
		const index = activeFilter.indexOf(e.currentTarget.id)
		let values = cloneDeep(activeFilter);
		if (index > -1) {
		  values.splice(index, 1);
		} else {
			values.push(e.currentTarget.id)
		}

		setActiveFilter(values)
	}

	const isActive = (type) => {	return activeFilter.indexOf(type) > -1 }
	const isVisible = (type) => { return isActive(type) ? 'block' : 'none'}
	const getClassees = (type) => {
	    var headClasses = classNames(
			'accordion-heading',
			{'active-set': isActive(type)}
	    )
	    return headClasses
	}

	const handleFilterChange = (e) => {
		const {name, value} = e.target;
		setData(prevState =>  ({
			...prevState,
			[name] : value
		}))
	}

	const isChecked = (type) => {return data.gender === type}

	const handleClearFilter = (e, datavalue)=> {
		const {name, value} = e.target;
		let obj = {}
		setData(prevState =>  ({
			...prevState,
			[name] : ''
		}))
		clearFilters(e, {})
		setActiveFilter([])
	}
	return(
		<div className="communities-filter">
				<h5 className="h5basic01">Filter</h5>
				<div className="accordion-container">
					<div className="set"> <a className={getClassees('Followers')} id='Followers' onClick={handleToggle} href="javascript:void(0)">Followers <img src={require("../../assets/images/icon_arrow_up_larg.svg")} /></a>
						<div className="content-accordian" style={{display: isVisible('Followers')}}>
							<div className="filter-inputs">
									<input type="number" value={(filterType == "community") ? (data['followers[min_value]'] !== undefined ? data['followers[min_value]'] : '') : (data['ci_followers[min_value]'] !== undefined ? data['ci_followers[min_value]'] : '')} name={(filterType == "community") ? "followers[min_value]" : "ci_followers[min_value]"} placeholder="0" onChange={handleFilterChange}/>
									<input type="number" value={(filterType == "community") ? (data['followers[max_value]'] !== undefined ? data['followers[max_value]'] : '') : (data['ci_followers[max_value]'] !== undefined ? data['ci_followers[max_value]'] : '')} name={(filterType == "community") ? "followers[max_value]" : "ci_followers[max_value]"} placeholder="1 000 000+" onChange={handleFilterChange}/>
								</div>
						</div>
					</div>
					{/*<div className="set"> <a className={getClassees('Engagement')} id='Engagement' onClick={handleToggle} href="javascript:void(0)">Engagement <img src={require("../../assets/images/icon_arrow_up_larg.svg")} /></a>
						<div className="content-accordian" style={{display: isVisible('Engagement')}}>
							<div className="filter-inputs">
									<input type="number" value={(filterType == "community") ? (data['engagement[min_value]'] !== undefined ? data['engagement[min_value]'] : '') : (data['ci_engagement[min_value]'] !== undefined ? data['ci_engagement[min_value]'] : '' )} name={(filterType == "community") ? "engagement[min_value]" : "ci_engagement[min_value]"} placeholder="0%" onChange={handleFilterChange}/>
									<input type="number" value={(filterType == "community") ? (data['engagement[max_value]'] !== undefined ? data['engagement[max_value]'] : '') : (data['ci_engagement[max_value]'] !== undefined ? data['ci_engagement[max_value]'] : '' )} name={(filterType == "community") ? "engagement[max_value]" : "ci_engagement[max_value]"} placeholder="100%" onChange={handleFilterChange}/>
							</div>
						</div>
					</div>
					<div className="set"> <a className={getClassees('Cost')} id='Cost' onClick={handleToggle} href="javascript:void(0)">Cost <img src={require("../../assets/images/icon_arrow_up_larg.svg")} /></a>
						<div className="content-accordian" style={{display: isVisible('Cost')}}>
							<div className="filter-inputs">
									<input type="number" value={(filterType == "community") ? (data['cost[min_value]'] !== undefined ? data['cost[min_value]'] : '') : (data['ci_price[min_value]'] !== undefined ? data['ci_price[min_value]'] : '')} name={(filterType == "community") ? "cost[min_value]" : "ci_price[min_value]"} placeholder="400" onChange={handleFilterChange}/>
									<input type="number" value={(filterType == "community") ? (data['cost[max_value]'] !== undefined ? data['cost[max_value]'] : '' ) : (data['ci_price[max_value]'] !== undefined ? data['ci_price[max_value]'] : '' )} name={(filterType == "community") ? "cost[max_value]" : "ci_price[max_value]"} placeholder="50 000+" onChange={handleFilterChange}/>
							</div>
						</div>
					</div>*/}
					<div className="set"> <a className={getClassees('Gender')} id='Gender' onClick={handleToggle} href="javascript:void(0)">Gender <img src={require("../../assets/images/icon_arrow_up_larg.svg")} /></a>
						<div className="content-accordian" style={{display: isVisible('Gender')}}>
							<ul className="filter-check radio-check ">
								<li>
									<label className="container-checkbox">Female
										<input type="radio" name='gender' value="Female" onChange={handleFilterChange} defaultChecked={isChecked('Female')}/>
										<span className="checkmark"></span>
									</label>
								</li>
								<li>
									<label className="container-checkbox">Male
										<input type="radio" name='gender' value="Male" onChange={handleFilterChange} defaultChecked={isChecked('Male')}/>
										<span className="checkmark"></span>
									</label>
								</li>
								<li>
									<label className="container-checkbox">Other
										<input type="radio" name='gender' value="Other" onChange={handleFilterChange} defaultChecked={isChecked('Other')}/>
										<span className="checkmark"></span>
									</label>
								</li>
							</ul>
						</div>
					</div>
					<div className="set"> <a className={getClassees('Age')} id='Age' onClick={handleToggle} href="javascript:void(0)">Age <img src={require("../../assets/images/icon_arrow_up_larg.svg")} /></a>
						<div className="content-accordian" style={{display: isVisible('Age')}}>
							<div className="filter-inputs">
								<input type="number" defaultValue={data["age[min_value]"]}  name="age[min_value]" placeholder="18" onChange={handleFilterChange}/>
								<input type="number" defaultValue={data['age[max_value]']} name="age[max_value]" placeholder="99+" onChange={handleFilterChange}/> </div>
						</div>
					</div>

					{/*<div className="set"> <a className={getClassees('City')} id='City' onClick={handleToggle} href="javascript:void(0)">City <img src={require("../../assets/images/icon_arrow_up_larg.svg")} /></a>
						<div className="content-accordian" style={{display: isVisible('City')}}>
							<div className="filter-city-search">
								<input type="text" name="city" placeholder="Search by city" />
								 <ul className="added-cities">
									<li><a href="javascript:void(0)">X</a>Stockholm</li>
									<li><a href="javascript:void(0)">X</a>Uppsala</li>
								</ul> 
							</div>
						</div>
					</div>*/}

					<button className="login-btn btn-rounded mt-30" onClick={(e) => applyFilters(e, data)}>Apply Filter</button>
					<button className="login-btn btn-rounded mt-30" onClick={(e) => handleClearFilter(e, data)}>Clear Filter</button>
				</div>
			
		</div>
	)
}