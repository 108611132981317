import axios from 'axios';
import { API_CONFIG } from '../../config/api';

export function fetchPlatform() {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.PLATFORM.FETCH_ALL()}`;
	return dispatch => axios.get(apiURL)
	.then(res => {
		if(res.status) {
			return {success: true, data: res.data.data}
		} else {
			throw Error(res.statusText);
		}
	}).catch((err) => {
		return err.response;
	});
}