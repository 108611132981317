import  { useEffect, useState, useRef } from 'react';
const Field = ({
	type = 'text',
	name = '',
	onChange = () => {},
	onClick = () => {},
	onBlur = () => {},
	placeholder = '',
	description = '',
	containerClass = '',
	id = '',
	value = '',
	required = false,
	minLength = 0,
	maxLength = 255,
	autocomplete = false,
	children,
	disabled=""
}) => {

	const isFile = type === 'file'
	const [currentValue, setValue] = useState(value);
	const [isEdit, setIsEdited] = useState(false);
	const handleChange = (e) => {
		setValue(e.target.value)
	}

	const handleClick = (e) => {
		setIsEdited(true);
		onClick(e)
	}

	const handleBlur = (e) => {
		setIsEdited(false);
		onBlur(e);
	}

	useEffect(() => {
		setValue(value)
	}, [value])
	

	return(
		<>
			<div className={containerClass}>
				<label className={(isEdit || currentValue) ? "labelClass field-active" : "labelClass"}>{placeholder}</label>
				<input
					id={id}
					type={type} 
					name={name}
					onChange={(e) => handleChange(e)}
					onBlur={(e) => handleBlur(e)}
					onClick={(e) => handleClick(e)}
					value={isFile ? '' : currentValue}
					required={required}
					minLength={minLength}
					maxLength={maxLength}
					autocomplete={autocomplete}
					disabled={disabled}
				/>
				{ isFile && value ? (
						<>
							{	value.name ? <p>{value.name}</p> : null }
							<div className="preview">
								{	value ? <img alt=""
								src={value.name ? URL.createObjectURL(value) : value} 
								className="image_to_upload"/> : null	}
							</div>
						</>
					) : null
				}

				{ children }
			</div>
			{ description ?  <p> {description} </p> : null }
		</>
	)
}

export default Field