import React, { useState, useRef, useEffect} from 'react';
import Header from '../../Components/Header/Header';
import SideBar from '../../Components/SideBar/SideBar';
import { fetchAll, setActiveTab } from '../../Redux/Actions/Community';
import Refresh from '../../Components/Common/Refresh';
import Notifications from '../../Components/Notification/Notification';
import { logout } from '../../Redux/Actions/Auth';
import { useSelector, useDispatch } from "react-redux";
import './notification.scss';
import { allCampaigns } from '../../Redux/Actions/Campaign';

const Notification = (props) => {
	const activeTab = useSelector(state => state.activeTab);
	const organization = useSelector(state => state.organization.organization);
	const dispatch = useDispatch();
	const [communities, setCommunities] = useState([]);
	const [campaigns, setCampaigns] = useState([]);

	useEffect(async () => {
		await dispatch(setActiveTab('Notification'))

		// Fetch all created communities
		await fetchAllCommunities();

		// Fetch all campaigns
		await fetchAllCampaigns()

 		const file = require('../../assets/javascript/external.js');
	 	const script = document.createElement('script');
		script.src = file;
	 	script.async = true;
	 	document.body.appendChild(script);
		return () => {
	    	document.body.removeChild(script);
		}
	}, []);

	const fetchAllCommunities = async () => {
		const response = await dispatch(fetchAll())
		if(response.success)
			setCommunities(response.data);
	}

	const fetchAllCampaigns = async () => {
		const response = await dispatch(allCampaigns())

		if(response.success == 'success')
		console.log("response.data")
		setTimeout(()=> {
			setCampaigns(response.data);

		}, 1000)
	}
	const handleLogout = (e) => {
		e.preventDefault();
		dispatch(logout());
		props.history.go()
	}


	return(
		<div id="wrapper">
			<Header containerClass="header-inner" logoClass="logo">
				<div className="top-header-right">
					<h2 className="header-heading">{activeTab}</h2>
					<div className="user-settings-menu">
						<Refresh />
						{/* <div className="dollar-menu select-default">
							<div className="dropdown default-dropdown">
							  <a href="javascript:void(0)" className="dropdown-toggle" type="button" dataToggle="dropdown">
							  	<img src="icon_currency.svg" />
							  </a>

							  <ul className="dropdown-menu">
							    <li><a href="javascript:void(0)">Price 01</a></li>
							    <li><a href="javascript:void(0)">Price 02</a></li>
							  </ul>
							</div>
						</div> */}

						{/* <div className="notification-menu select-default">
							<div className="dropdown default-dropdown">
							  <a href="javascript:void(0)" className="dropdown-toggle" type="button" data-toggle="dropdown">
							  	<img src="icon_bell.svg" />
							  </a>

							  <ul className="dropdown-menu">
							    <li><a href="javascript:void(0)"> Notification 01</a></li>
							    <li><a href="javascript:void(0)"> Notification 02</a></li>
							  </ul>
							</div>
						</div> */}
						<div className="user-settings select-default">
							<div className="dropdown default-dropdown">
							  <a href="javascript:void(0)" className="dropdown-toggle" type="button" data-toggle="dropdown">
							  	<img src={organization.attachment_url} className="profile-user-icon" />
							  </a>
						
							  <ul className="dropdown-menu">
							    <li><a href="#" onClick={handleLogout}>logout</a></li>
							  </ul>
							</div>
						</div>
					</div>
				</div>
			</Header>

			<div id="main">
			  <div className="main-inner">
			  	<SideBar activeTab={activeTab} >
			  	</SideBar>
			  	<Notifications 
			  		show={true}
			  		communities={communities} 
			  		campaigns={campaigns} 
			  		{...props}
			  	/>
			  </div>
			</div>
		</div>
	)
}

export default Notification;