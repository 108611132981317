import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCountries } from '../../Redux/Actions/Countries';
import { fetchAllPlatform } from '../../Redux/Actions/Platforms';
import { fetchAllPriceList } from '../../Redux/Actions/PriceList';
import { setLoader } from '../../Redux/Actions/Loader';
import './PriceList.scss';

export default () => {
	const [countries, setCountries] = useState([]);
	const [platforms, setPlatforms] = useState([]);
	const [selectedPlatform, setselectedPlatform] = useState('');
	const [selectedCountry, setselectedCountry] = useState('');
	const [pricelist, setPricelist] = useState([]);
	const dispatch = useDispatch();

	useEffect(async () => {
		await fetchAllPlatforms();
		await fetchAllCountries();
	}, []);
	
	useEffect(async () => {
		await dispatch(setLoader(true));
		await fetchAllPriceLists();
	}, [selectedPlatform, selectedCountry]);


	const fetchAllCountries = async () => {
		const response = await dispatch(fetchCountries())
		if (response.success)
			setCountries(response.data);
			if(response.data) {
				response.data.map(item=> {
					if(item.name === 'Sweden'){
					  setselectedCountry(item.id)
					}
				})
			}
		}

		const fetchAllPlatforms = async () => {
			const response = await dispatch(fetchAllPlatform())
			if (response.success) {
				let items = response.data
				setPlatforms(items);
				if(items) {
					items.map(item=> {
						if(item.name === 'instagram'){
							setselectedPlatform(item.id)
						}
					})
				}
			}
		}

	const fetchAllPriceLists = async () => {
		var data = "?country_id=" + selectedCountry + "&platform_id=" + selectedPlatform
		const response = await dispatch(fetchAllPriceList(data))
		if (response.success)
			await dispatch(setLoader(false));
			setPricelist(response.data)
	}

	const platformChange = (event) => {
		setselectedPlatform(event.target.value)
	}

	const countryChange = (event) => {
		setselectedCountry(event.target.value);
	}

	return (
		<div id="view-dashboard">
			<div className="inner-view-dashboard">
				<div className="followers-price-list">
					<div className="table-responsive">
						<table>
							<tr>
								<th>
									<div className="select-communities-bar">
										<select onChange={platformChange} value={selectedPlatform} name="selectedPlatform" id="select-community01" className="custom-select sources price-select capital" placeholder="Instagram followers">
											{platforms.map(platform => (
												<option key={Math.random()} value={platform.id}>{platform.name} followers</option>
											))}
										</select>
									</div>
								</th>
								<th>
									<div className="select-communities-bar">
										<select onChange={countryChange} value={selectedCountry} name="selectedCountry" id="select-community01" className="custom-select sources price-select capital" placeholder="Cost in Sweden (sek)">
											{countries.map(country => (
												<option key={Math.random()} value={country.id}>Cost in {country.name} ({country.currency_code})</option>
											))}
										</select>
									</div>
								</th>
							</tr>
							{
								pricelist.length !== 0 ?
									pricelist.map(list => (
										<tr key={Math.random()}>
											<td>{list.range}</td>
											<td>{list.price}</td>
										</tr>
									))
									:
									<tr>
										<td colSpan="2">No Records Found</td>
									</tr>
							}
						</table>
					</div>
				</div>
			</div>
		</div>
	)
} 