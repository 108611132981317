import axios from 'axios';
import { SET_ACTIVE_SETTING_TAB } from '../Actions/Types/Types';

export function setSettingTab(value = 'organization_details') {
  return {
    type: SET_ACTIVE_SETTING_TAB,
    value
  }
}

