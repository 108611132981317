import { SET_CAMPAIGN_LIST_COMMUNITY, SET_CAMPAIGN_LIST_SEARCH_VALUE, SELECT_COMMUNITY, CAMPAIGN_LIST_SORT_FILTERS, SET_CAMPAIGN_ONBOARDING_FILTER_DATA, SET_TAB_SELECTION, SET_CAMPAIGN_LIST_TAB, SET_SELECTED_CAMPIAGN} from '../Actions/Types/Types';

export const campaignOnBoardFilters = (state = {}, action) => {
  switch(action.type) {
    case SET_CAMPAIGN_ONBOARDING_FILTER_DATA:
      return action.value

    default: 
      return state;
  }
}

export const activeOnBoardingTab = (state = 'pending', action) => {
  switch(action.type) {
    case SET_TAB_SELECTION:
      return action.value

    default: 
      return state;
  }
}

export const activeCampaignListTab = (state = '', action) => {
  switch(action.type) {
    case SET_CAMPAIGN_LIST_TAB:
      return action.value

    default: 
      return state;
  }
}

const initailSortFilters = { per_page: 25, current_page: 1, total_pages: '', total_count: '', sort_by: 'updated_at', sort_order: 'desc' }
export const campaigListSortFilters = (state = initailSortFilters, action) => {
  switch(action.type) {
    case CAMPAIGN_LIST_SORT_FILTERS:
      return action.value

    default: 
      return state;
  }
}

export const campaigListSearchValue = (state = '', action) => {
  switch(action.type) {
    case SET_CAMPAIGN_LIST_SEARCH_VALUE:
      return action.value

    default: 
      return state;
  }
}

export const activeCampiagn = (state = 'pending', action) => {
  switch(action.type) {
    case SET_TAB_SELECTION:
      return action.value

    default: 
      return state;
  }
}

export const selectedCampiagn = (state = '', action) => {
  switch(action.type) {
    case SET_SELECTED_CAMPIAGN:
      return action.value

    default: 
      return state;
  }
}

export const campaignListSelectedCommunity = (state = '', action) => {
  switch(action.type) {
    case SET_CAMPAIGN_LIST_COMMUNITY:
      return action.value

    default: 
      return state;
  }
}

