export const API_CONFIG = {
	BASE_API_URL: 'https://sicore.showroom.shopping',
	BASE_PATH: '/manage',
	BASE_SUPERADMIN_PATH: '/superadmin',
	API_VERSION_PATH: '/api/v1',
	API_VERSION_PATH_V2: '/api/v2',
	APP_VERSION_PATH: '/app/api',
	END_POINTS: {
		AUTH: {
			SIGN_IN: () => '/login',
			SIGN_UP: () => '/organizations'
		},
		USER: {
			FETCH_CURRENT: () => '/fetch_user',
			PASSWORD_RESET: () => '/send_reset_password_link',
			PASSWORD_RECOVER: () => '/password',
			USER_LIST: ()=> '/users',
			CHANGE_PASSWORD: () => '/user/change_password',
			COMPLETE_PROFILE: () => '/complete_profile',
			GET_USER_COMMUNITIES: () => '/users/get_user_communities',
			UPDATE_USER_COMMUNITIES: () => '/users/update_user_communities'
		},
		CAMPAIGN: {
			CREATE: () => '/campaigns',
			UPDATE: () => '/campaigns',
			FETCH_ONE: () => '/campaigns',
			FETCH_ALL_CAMPAING: () => '/campaigns',
			FETCH_APPLICATIONS: () => '/campaign_applications',
			SELECT_ICON: () => '/sortlist_icon',
			UNSELECT_ICON: () => '/unselect_icon',
			REMOVE_ICON: () => '/unshortlist_icon',
			SET_OPEN: () => '/campaign/set_open',
			VERIFY_POST: () => '/verify_post',
			SET_COMPLETED: () => '/campaign/set_completed',
			DELETE: () => '/campaign/soft_destroy',
			UPDATE_ENGAGEMENT: () => '/update_engagement_verification',
			SAVE_COMMENT: () => '/campaign_icons/save_comment',
			SEND_REQUEST: () => '/campaign_icons/send_request',
			APPROVED_DRAFTS: () => '/campaign_icons/approve_drafts',
			APPROVED_POST: () => '/campaign_icons/approve_post',
			UPDATE_POSTED_DATE: () => '/campaign_icons/update_posted_date',
			APPROVED_STORY: () => '/campaign_icons/approve_stories',
			DECLINE_STORY: () => '/campaign_icons/decline_stories',
			DECLINE_DRAFTS: () => '/campaign_icons/decline_drafts',
			RETRY_ENGAGEMENT: () => '/campaign_icons/retry_engagement',
			UPDATE_CAPTION: () => '/campaign_icons/update_caption',
			REFRESH_ICON_INSIGHT: () => '/campaign_icons/refresh_icon_insights',
			DELETE_CAMPAIGN_ICON: () => '/campaign_icons/delete_campaign_icon',
			REJECT_CAMPAIGN_ICON: () => '/campaign_icons/reject_campaign_icon',
			MOVE_CAMPAIGN_ICON: () => '/campaign_icons/move_icon_in_any_state',
			SOCIAL_MEDIA_VALUE: () => '/campaign_icons/change_social_media_value',
			ALL_CAMPAIGNS: () => '/all_campaigns',
			UPLOAD_DRAFT: () => '/campaign_icons/',
			TAB_NOTIFICATION: () => '/send_tab_notification',
			UPDATE_CAMPAIGN_STATUS: () => '/campaign/update_campaign_status'
		},
		COMMUNITY: {
			CREATE: () => '/communities',
			UPDATE: () => '/communities',
			FETCH_ONE: () => '/communities',
			FETCH_ALL: () => '/communities',
			FETCH_ICONS: () => '/filter_icons',
			FETCH_ORGANIZATION_COMMUNITIES: () => '/users/get_organization_communities'
		},
		COUNTRY: {
			FETCH_ALL: () => '/countries'
		},
		CATEGORY: {
			FETCH_ALL: () => '/categories',
			FETCH_ALL_CATEGORY_INTERESTS: () => '/categories'

		},
		PLATFORM: {
			FETCH_ALL: () => '/platforms'
		},
		PRICELIST: {
			FETCH_ALL: () => '/pricelists'
		},
		ORGANIZATION: {
			FETCH_ONE: () => '/organizations/',
			CREATE_NOTIFICATION: () => '/send_notification',
			CREATE_ORGANISATION: () => '/organizations'
		},
		ICON: {
			FETCH_ONE: () => '/campaign_icons/icon/show',
			FETCH_ALL: () => '/icons',
			EDIT: () => '/campaign_icons/amand_draft',
			APPROVE_DRAFT: () => '/campaign_icons/approve_draft',
			APPROVE_ICONS: () => '/approve_icons',
			CHECK_AVAILIBITY_STATUS: () => '/availibility_notification',
			FEEDBACKS: () => '/icon/feedbacks',
			SOLO_AVAILIBILITY_STATUS: ()=> '/solo_availibility_notification',
			SOLO_CONFIRM_STATUS: ()=> '/select_availability'
		},
		CONTENT: {
			FETCH_ALL: () => '/contents',
			SET_STATUS: () => '/content/set_status',
			SEND_NOTIFICATION: () => '/sendout_notification'
		},
		NOTIFICATION: {
			SEND: () => '/send_push_description'
		}
	}
};
