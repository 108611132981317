import React, { useState, useEffect, useRef } from 'react';
import Form from '../../Components/Community/Form';
import MobileSection from '../../Components/MobileSection/Details';
import CustomSelect from "../../Components/CustomSelect/Select";
import CommunityIcons from './Icons/Icons';
import { create, update, fetchAll, fetchOne, setCommunityActiveTab, selectCommunity } from '../../Redux/Actions/Community';
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import { isEmpty } from 'lodash';
import { NotificationManager} from 'react-notifications';
import { setLoader } from '../../Redux/Actions/Loader';


export default ({ 
	communities = [],
	countries = [],
	categoryInterst = [],
	fetchAllCommunities = () => {},
	renderCommunitySelector = () => {}
}) => {
	const organizationId = useSelector(state => !isEmpty(state.auth.user) ? state.auth.user.organization_detail.id : null)
	const initialSelectedTab = useSelector(state => state.communityActiveTab)
	const initialData = {
		name: '',
		code: '',
		organization_id: organizationId,
		country_ids: [],
		status: 'Open',
		media: ''	
	};
	const [communityData, setCommunityData] = useState(initialData);
	const selectCommunityId = useSelector(state => state.selectedCommunity)
	const [selectedCommunity, setSelectedCommunity] = useState(selectCommunityId);
	const [activeTab, setActiveTab] = useState(initialSelectedTab);
	const submitRef = useRef();
	const dispatch = useDispatch();

	useEffect(() => {
		setActiveTab(initialSelectedTab)
	}, [initialSelectedTab])

	const handleCountrySelect = (event) => {
		const selected = event.target.selectedOptions;
		let ids = [];
		for (var index in selected) {
			ids.push(selected[index].value);
		}

		setCommunityData(prevState => ({
			...prevState,
			country_ids: ids.filter(a => a)
		}))
	}

	const handleCategorySelect = (selectedList, selectedItem) => {
		setCommunityData(prevState => ({
			...prevState,
			category_ids : selectedList
		}))
	}

	const handleTabChange = (type) => {
		dispatch(setCommunityActiveTab(type))
		if(['edit_community', 'create_community'].includes(type)) {
			setCommunityData(initialData);
			setSelectedCommunity('');
		}
	}

	const fetchCommunityData = async (value = '') => {
		const response = await dispatch(fetchOne(value))
		if(response.success) {
			setSelectedCommunity(response.data.id)
			dispatch(selectCommunity(response.data.id))
			const media = response.data.attachments.length > 0 ? response.data.attachments[0].media : ''
			setCommunityData({
				name: response.data.name,
				code: response.data.code,
				organization_id: organizationId,
				status: response.data.closed ? 'Closed' : 'Open',
				country_ids: response.data.country_ids || [],
				category_ids: categoryInterst.filter(category => response.data.category_ids.indexOf(category.id) > -1) || [],
				upload_logo: response.data.logo,
				media: media
			})
		}
	}

	const handleCommunitySelect = async (e) => {
		e.preventDefault();
		const selected = communities.find(obj => obj.id === e.target.value);
		if (e.target.value === ""){
			setSelectedCommunity("")
			dispatch(selectCommunity(''))
			setCommunityData(initialData);
		}else{
			fetchCommunityData(e.target.value)
		}
	}

	const handleChange = (e) => {
		e.preventDefault();
		let {name, value, type} = e.target;
		if(type === 'file')
			value = e.target.files[0]

		setCommunityData(prevState => ({
			...prevState,
			[name] : value
		}))
	}

	const createCommunity = async (e) => {
		e.preventDefault();
		submitRef.current.setAttribute('disabled', true);
		await dispatch(setLoader(true));
		const { name, status, organization_id, media, upload_logo, country_ids, category_ids} = communityData;
		let data = {name, organization_id, country_ids, category_ids};
		data.category_ids = data.category_ids.map(a => a.id ? a.id : a)
		data['closed'] = status === 'Closed' ? true : false;

		if(status === 'Closed')
			data['code'] = communityData.code;

	    var formdata = new FormData()
	    for (const key in data) {
			formdata.append(`community[${key}]`, Array.isArray(data[key]) ? JSON.stringify(data[key]) : data[key]);
	    }

		if(upload_logo.name)
			formdata.append('[community][upload_logo]', upload_logo);

		if(media.name)
			formdata.append('[community][media][]', media);

		const res = await dispatch(activeTab === 'edit_community' ? update(formdata, selectedCommunity) : create(formdata))
		submitRef.current.removeAttribute('disabled');
		if(res.success) {
			await dispatch(setLoader(false));
			NotificationManager.success(res.message)
			fetchAllCommunities();
			setSelectedCommunity('');
			dispatch(selectCommunity(''))
			document.querySelector('a[href="#Community_icons"]').click()
		} else {
			await dispatch(setLoader(false));
			NotificationManager.error(res.data.message, '', 10000, () => {})
		}
	}

	const activeClasses = (type) => {
		return type === activeTab ? 'active' : ''
	}

	const activeContentClasses = (type) => {
		return type === activeTab ? 'tab-pane fade in active' : 'tab-pane fade'
	}

	return(
		<div id="view-dashboard">
			<div className="inner-view-dashboard">
				<div className="communities-content">
					<ul className="nav nav-tabs default-tabs">
						<li className={activeClasses('Community_icons')} onClick={() => handleTabChange('Community_icons')}>
							<a data-toggle="tab" href="#Community_icons">Brand Community creators
							</a>
						</li>
						<li className={activeClasses('recruit_icons')} onClick={() => handleTabChange('recruit_icons')}>
							<a data-toggle="tab" href="#recruit_icons">Invite creators
							</a>
						</li>
						<li className={activeClasses('edit_community')} onClick={() => handleTabChange('edit_community')}>
							<a data-toggle="tab" href="#edit_community">Edit brand community
							</a>
						</li>
						<li className={activeClasses('create_community')} onClick={() => handleTabChange('create_community')}>
							<a data-toggle="tab" href="#create_community">Create brand community
							</a>
						</li>
					</ul>
					<div className="tab-content">
						<div id="Community_icons" className={activeContentClasses('Community_icons')}>
							{
								activeTab === 'Community_icons' ? (
									<CommunityIcons
										selectedCommunity={selectedCommunity}
										renderCommunitySelector={renderCommunitySelector}
										handleCommunitySelect={handleCommunitySelect}
									/>
								) : null
							}
						</div>

						<div id="recruit_icons" className={activeContentClasses('recruit_icons')}>
						  <div className="new-recruits">
							<div className="row m0">
							  <div className="col-md-6 p0">
								<h3 className="h4basic01 ft-32">Invite your creators and make them your extended marketing department</h3>
								<h4>Leverage the power and influence of your consumers and brand fans by inviting them to you brand community</h4>
								<p className="same-text">
									Activate your creators in campaigns. Invite them to be a part of and creating content and your brand communication -atthe same time strengthen your relationship and loyalty between you and them.
								</p>
							  </div>
							</div>
							<div className="row recruit-img-text mr-remove">
							  <div className="col-md-6 p0">
								<img src="img_recruit.png" />
							  </div>
							  <div className="col-md-6">
								<h3>“Join our exclusive influencer team” 
								</h3>
							  </div>
							</div>
						  </div>
						</div>

						<div id="edit_community" className={activeContentClasses('edit_community')}>
						  <div className="edit-community">
							<div className="select-communities-bar">

								<CustomSelect name="sources" id="select-community02" value={selectedCommunity} className="sources custom-select" placeholder="Source Type" onChange={(e) => handleCommunitySelect(e)}>
									<option value='' key={Math.random()}>
										Select Brand Community.
									</option>
									
									{	renderCommunitySelector() }
								</CustomSelect>
							</div>

							<div className="row m0">
								<div className="col-md-6 p0">
									{
										activeTab === 'edit_community' ? (
											<Form
												handleSubmit={createCommunity}
												communityData={communityData}
												handleCountrySelect={handleCountrySelect}
												handleChange={handleChange}
												onSelect={handleCategorySelect}
												id={selectedCommunity}
												countries={countries}
												categories={categoryInterst}
												submitRef={submitRef}
												isEdit={true}
											/>
										) : null
									}
								</div>

								<div className="col-md-6">
									<MobileSection
										community={communityData}
									/>
								</div>
							</div>
						  </div>
						</div>

						<div id="create_community" className={activeContentClasses('create_community')}>
						  <div className="edit-community create-community">
							<div className="row m0">
								<div className="col-md-6 p0">
									{
								  		activeTab === 'create_community' ? (
											<Form
												handleSubmit={createCommunity}
												handleCountrySelect={handleCountrySelect}
												onSelect={handleCategorySelect}
												communityData={communityData}
												handleChange={handleChange}
												submitRef={submitRef}
												countries={countries}
												categories={categoryInterst}
												isEdit={false}
											/>
								  		) : null
									}
								</div>

								<div className="col-md-6">
									<MobileSection
										community={communityData}
									/>
								</div>
							</div>
						  </div>
						</div>
				  </div>
				</div>
			</div>
		</div>
	)
}