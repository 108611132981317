import Filters from '../../../../Components/Filters/Filters';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export default ({
  renderCardMarkup = () => {},
  applyFilters = () => {},
  applicationData = {},
  setSearchValue = () => {},
  applySorting = () => {},
  sortedClasses = () => {},
  filterData = {},
  searchVal = '',
  clearFilters =  () => {},
  handleSendNotification = () => {}
}) => {

  const [clipboardStatus, setClipboardStatus] = useState(false)
  const user = useSelector(state => state.auth.user);

  const handleNext = (e) => {
    e.preventDefault();
    localStorage && localStorage.setItem('buttonClick', 'next')
    if(document.querySelector(`a[href="#${e.target.dataset.ref}"]`))
      document.querySelector(`a[href="#${e.target.dataset.ref}"]`).click()
  }

  const copyToClipboard = (slug)=> {
    navigator.clipboard.writeText(slug)
    setClipboardStatus(true)

    setTimeout(function(){ 
      setClipboardStatus(false)
    }, 1000);
  }
  
  return(
    <>
      <div className="pending-request-details pending-request-details-update">
        <div className="requester-name">
          <div className="campaignNameStatus">
            <div>
              <h2>  
                {applicationData.campaign_name}
                &nbsp; {user && user.role === 'superadmin' && <img src={require("../../../../assets/images/notification-bell.svg")} alt="img" onClick={handleSendNotification} />}
              </h2>
            </div>
            <div className='action-bar-status' style={{'float':'right','margin-right':'15px'}}>
              STATUS: <span className={`${applicationData.campaign_status}`} style={{'text-transform':'uppercase'}}>{applicationData.campaign_status}</span>
            </div>
          </div>
          <div>
            {
              user && user.role === 'superadmin' && applicationData.slug && (
                <p className="campaign-icon-slug campaign-icon-slug-image" id="slug-text">
                  {applicationData.slug ? `Campaign ID : `+applicationData.slug : ''} 
                  <img src={require("../../../../assets/images/clipboard.svg")} alt="img" onClick={(e)=> copyToClipboard(applicationData.slug)}/>
                  { clipboardStatus && (<span>Copied !</span>)}
                </p>
              )
            }
            <p>{applicationData.community_name}</p>
          </div>
        </div>
{/*        <div>
          <button type="button" class="btn" style={{fontWeight: 'bold', color: 'white', background: '#209AF2', marginTop:'1px',marginBottom:'10px'}} onClick={handleSendNotification}>Send Notification</button>
        </div>*/}
        <div className="requester-other-details requester-other-details-update">
          <ul>
            <li><h2>{applicationData.total_count || 0}</h2><p>Applications</p></li>
            <li><h2>{applicationData.avg_engagement || 0}%</h2><p>Avg. Engagement</p></li>
            <li><h2>{applicationData.potential_reach || 0}</h2><p>Total Followers</p></li>
          </ul>
        </div>
      </div>

      <div className="filter-views">
        <Filters 
          applyFilters={applyFilters}
          filterData={filterData}
          clearFilters={clearFilters}
        />

        <div className="communities-views">
          <div className="inner-communities-views">
            <div className="progess-action-bar">
              <div className="bar-setup">
                {/*<div className="progress-bar-value">
                  <strong>{applicationData.budget ? applicationData.budget.budget_reached : 0}</strong>
                  <span>{applicationData.budget ? applicationData.budget.budget_percent : 0}%</span>
                  <strong>{applicationData.budget ? applicationData.budget.total_budget : 0}</strong>
                </div>
                <div className="bar-value-fill">
                  <div className="unfill-bar"></div>
                  <div className="filled-bar filled-percent" style={{width: `${applicationData.budget ? (applicationData.budget.budget_percent > 100) ? 100 : applicationData.budget.budget_percent : 0}%`}}></div>
                </div>
                <div className="currency-signature">Budget reached ({applicationData.budget ? applicationData.budget.currency_code : null})</div>*/}
              </div>
              <div className="action-bar text-right">
                <a href="javascript:void(0)" data-ref="community_Selected" onClick={handleNext} className="redirect-link">NEXT</a>
              </div>
            </div>
          <div className="sort-search-bar">
            <ul>
              <li onClick={(e) => applySorting('created_at')}><a className={sortedClasses('created_at')} href="javascript:void(0)">Recent</a></li>
              <li onClick={(e) => applySorting('followers')}><a className={sortedClasses('followers')} href="javascript:void(0)">Followers</a></li>
              <li onClick={(e) => applySorting('percentage')}><a className={sortedClasses('percentage')} href="javascript:void(0)">Engagement</a></li>
              <li onClick={(e) => applySorting('price')}><a className={sortedClasses('price')} href="javascript:void(0)">Cost</a></li>
              <li onClick={(e) => applySorting('city')}><a className={sortedClasses('city')} href="javascript:void(0)">City</a></li>
              <li><form onSubmit={(e) => applyFilters(e)}><div className="search-input"><input type="search" value={searchVal} onChange={(e) => setSearchValue(e.target.value)} className="" name="searchVal" placeholder="Search" /></div></form></li>
            </ul>
          </div>
          <div className="communities-users-list">
            <div className="users-list-sets clearfix">
              <ul>
                { renderCardMarkup(true)}
              </ul>
              {/* <div className="btn-next"><a href="javascript:void(0)" data-ref="community_Selected" onClick={handleNext} className="redirect-link">NEXT</a></div> */}
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  )
}