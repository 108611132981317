import React, { useState } from 'react';
import Filters from '../../../../Components/Filters/Filters';
import ConfirmationAlert from '../../../../Components/ConfirmationAlert/Alert';
import { NotificationManager} from 'react-notifications';
import { checkAvailibityRequest, approvalRequest } from '../../../../Redux/Actions/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { Chart } from "react-google-charts";

export default ({
  renderCardMarkup = () => {},
  applicationData = {},
  applyFilters = () => {},
  setSearchValue = () => {},
  applySorting = () => {},
  sortedClasses = () => {},
  filterData = {},
  campaignId = '',
  searchVal = '',
  clearFilters =  () => {},
  genderInsight = [],
  ageInsight = [],
  countryInsight = [],
  cityInsight = [],
	handleSendNotification = () => {}
}) => {
  const dispatch = useDispatch();
  const [alertShow, setAlertShow] = useState(false);
  const [activeAlertType, setActiveAlertType] = useState('');
  const user = useSelector(state => state.auth.user);
  const [clipboardStatus, setClipboardStatus] = useState(false)
  const [isDisplayGraph, setIsDisplayGraph] = useState(false);


  const sendCheckRequest = async () => {
    const response = await dispatch(checkAvailibityRequest({campaign_id: campaignId}))
    response.data.status !== 'error' ?   NotificationManager.success(response.data.message) : NotificationManager.error(response.data.message, '', 10000, () => {})
  }

  const sendApprovalRequest = async () => {
    const response = await dispatch(approvalRequest({campaign_id: campaignId}))
    response.data.status !== 'error' ?   NotificationManager.success(response.data.message) : NotificationManager.error(response.data.message, '', 10000, () => {})
  }

  const closeAlert = (type = 'CANCEL') => {
    if (type === 'YES') {
      activeAlertType === 'Availability' ? sendCheckRequest() : sendApprovalRequest()
    }
    setAlertShow(false);
    // setActiveAlertType('');
  }

  const showConfirmationAlert = (type) => {
    setActiveAlertType(type);
    setAlertShow(true);
  }

   const copyToClipboard = (slug)=> {
     navigator.clipboard.writeText(slug)
     setClipboardStatus(true)

     setTimeout(function(){ 
       setClipboardStatus(false)
     }, 1000);
   }

   const handleGraph = () => {
    setIsDisplayGraph(current => !current);
  }
  
  return(
    <>
      <div className="pending-request-details pending-request-details-update">
        <div className="requester-name">
          <div className="campaignNameStatus">
            <div>
              <h2>  
                {applicationData.campaign_name}
                &nbsp; {user && user.role === 'superadmin' && <img src={require("../../../../assets/images/notification-bell.svg")} alt="img" onClick={handleSendNotification} />}
              </h2>
            </div>
            <div  className='action-bar-status' style={{'float':'right','margin-right':'15px'}}>
              STATUS: <span className={`${applicationData.campaign_status}`} style={{'text-transform':'uppercase'}}>{applicationData.campaign_status}</span>
            </div>
          </div>
          {
            user && user.role === 'superadmin' && applicationData.slug && (
              <p className="campaign-icon-slug campaign-icon-slug-image" id="slug-text">
                {applicationData.slug ? `Campaign ID : `+applicationData.slug : ''} 
                <img src={require("../../../../assets/images/clipboard.svg")} alt="img" onClick={(e)=> copyToClipboard(applicationData.slug)}/>
                { clipboardStatus && (<span>Copied !</span>)}
              </p>
            )
          }
          <p>{applicationData.community_name}</p>
        </div>
{/*				<div>
					<button type="button" class="btn" style={{fontWeight: 'bold', color: 'white', background: '#209AF2', marginTop:'1px',marginBottom:'10px'}} onClick={handleSendNotification}>Send Notification</button>
				</div>*/}
        <div className="requester-other-details requester-other-details-update">
          <ul>
            <li><h2>{applicationData.total_count || 0}</h2><p>Selected</p></li>
            <li><h2>{applicationData.avg_engagement || 0}%</h2><p>Avg. Engagement</p></li>
            <li><h2>{applicationData.potential_reach || 0}</h2><p>Total Followers</p></li>
          </ul>
          {/*<div className="last-child-link"><a href="javascript:void(0)">GENERATE PDF</a></div>*/}
          <div className="btn-next"><a href="javascript:void(0)" onClick={(e) => showConfirmationAlert('Availability')} className="redirect-link send-request">Check Availability</a></div>
        </div>
        {
          user && user.role === 'superadmin' && (
            ageInsight && ageInsight.length > 1 && (
              <div className="center-block">
                <button className="login-btn btn-rounded mt-30" onClick={(e) => handleGraph()}>{isDisplayGraph ? "Hide Graph" : "Show Graph"}</button>
              </div>
            )
          )
        }
        {
          user && user.role === 'superadmin' && (
            <div className={"graph-box" + (isDisplayGraph ? "" : " hide")}>
              <ul>
                {
                  genderInsight && genderInsight.length > 1 && (
                    <li>
                      <h3>Gender Distribution</h3>
                        <div className="outer-graph-box">
                          <div class="graph-set-box">
                            <Chart
                              width={'280px'}
                              height={'280px'}
                              chartType="PieChart"
                              loader={<div>Loading Chart</div>}
                              data={genderInsight}
                              options={{
                                title: '',
                                legend: { position: 'top', 'alignment':'center' },
                                pieStartAngle: 100,
                                slices: [ {color: "#add8e6"}, {color: "#FFB6C1"}, {color: "#808080"} ],
                                tooltip: {
                                  text: 'percentage'
                                },
                                chartArea:{left:0,top:50,width: "100%"},
                               /* tooltip : { trigger: 'none' },*/
                              }}
                              rootProps={{ 'data-testid': '1' }}
                            />
                          </div>
                        </div>
                    </li>
                  )
                }
                {
                  ageInsight && ageInsight.length > 1 && (
                    <li>
                      <h3>Age Distribution</h3>
                        <div className="outer-graph-box">
                          <div class="graph-set-box">
                            <Chart
                              chartType="ColumnChart"
                              loader={<div>Loading Chart</div>}
                              data={ageInsight}
                              options={{
                                // Material design options
                                legend: { position: 'top'},
                               /* tooltip : { trigger: 'none' },*/
                                hAxis: {format: 'percent'},
                                colors: ['#add8e6', '#FFB6C1'],
                                vAxis: { minValue: 0},
                                chartArea:{top:50,width: "100%"},
                              }}
                              rootProps={{ 'data-testid': '2' }}
                            />
                          </div>
                        </div>
                    </li>
                  )
                }
              </ul>
              <ul>
                {
                  countryInsight && countryInsight.length > 1 && (
                    <li className="li-50">
                      <h3>Top Countries</h3>
                      <div className="outer-graph-box">
                        <div class="graph-set-box">
                          <Chart
                            width={'580px'}
                            height={'400px'}
                            chartType="BarChart"
                            loader={<div>Loading Chart</div>}
                            data={countryInsight}
                            options={{
                              title: '',
                              legend: { position: 'none' },
                              vAxis: { format: 'percent' },
                              tooltip : { text: 'percentage' },
                              chartArea:{left:170,top:40},
                              colors: ['#add8e6'],
                              annotations: {
                                textStyle: {
                                  color: 'black',
                                }
                              }
                            }}
                            // For tests
                            rootProps={{ 'data-testid': '1' }}
                          />
                        </div>
                      </div>
                    </li>
                  )
                }
                {
                  cityInsight && cityInsight.length > 1 && (
                    <li className="li-50">
                      <h3>Top Cities</h3>
                      <div className="outer-graph-box">
                        <div class="graph-set-box">
                          <Chart
                            width={'580px'}
                            height={'400px'}
                            chartType="BarChart"
                            loader={<div>Loading Chart</div>}
                            data={cityInsight}
                            options={{
                              title: '',
                              legend: { position: 'none' },
                              vAxis: { format: 'percent' },
                              tooltip : { text: 'percentage' },
                              chartArea:{left:120,top:40},
                              colors: ['#add8e6'],
                              annotations: {
                                textStyle: {
                                  color: 'black',
                                }
                              }
                            }}
                            // For tests
                            rootProps={{ 'data-testid': '1' }}
                          />
                        </div>
                      </div>
                    </li>
                  )
                }
              </ul>
            </div>
          )
        }
      </div>

      <div className="filter-views">
        <Filters 
          applyFilters={applyFilters}
          filterData={filterData}
          clearFilters={clearFilters}
        />

        <div className="communities-views">
          <div className="inner-communities-views">
            <div className="progess-action-bar">
              <div className="bar-setup">
                <div className="progress-bar-value" style={{justifyContent: ""}}>
                  <strong>{applicationData.budget ? applicationData.budget.budget_reached : 0}</strong>
                  <span>{applicationData.budget ? applicationData.budget.budget_percent : 0}%</span>
                  <strong>{applicationData.budget ? applicationData.budget.total_budget : 0}</strong>
                </div>
                <div className="bar-value-fill">
                  <div className="unfill-bar"></div>
                  <div className="filled-bar filled-percent" style={{width: `${applicationData.budget ? (applicationData.budget.budget_percent > 100) ? 100 : applicationData.budget.budget_percent  : 0}%`}}></div>
                </div>
                <div className="currency-signature">Budget reached ({applicationData.budget ? applicationData.budget.currency_code : null})</div>
              </div>
              <div className="action-bar text-right">
                <a href="javascript:void(0)" onClick={(e) => showConfirmationAlert('Approve')} className="redirect-link">APPROVE ICONS</a>
              </div>
            </div>
            <div className="sort-search-bar">
              <ul>
                <li onClick={(e) => applySorting('created_at')}><a className={sortedClasses('created_at')} href="javascript:void(0)">Recent</a></li>
                <li onClick={(e) => applySorting('followers')}><a className={sortedClasses('followers')} href="javascript:void(0)">Followers</a></li>
                <li onClick={(e) => applySorting('percentage')}><a className={sortedClasses('percentage')} href="javascript:void(0)">Engagement</a></li>
                <li onClick={(e) => applySorting('price')}><a className={sortedClasses('price')} href="javascript:void(0)">Cost</a></li>
                <li onClick={(e) => applySorting('city')}><a className={sortedClasses('city')} href="javascript:void(0)">City</a></li>
                <li><form onSubmit={(e) => applyFilters(e)}><div className="search-input"><input type="search" onChange={(e) => setSearchValue(e.target.value)} name="search" placeholder="Search" /></div></form></li>
              </ul>
            </div>

            <div className="communities-users-list">
              <div className="users-list-sets clearfix">
                <ul>
                  { renderCardMarkup(true, true, false)}
                </ul>
                {/*<div className="btn-next"><a href="javascript:void(0)" onClick={(e) => showConfirmationAlert('Availability')} className="redirect-link send-request">Check Availability</a></div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmationAlert
        visible={alertShow}
        handleClose={closeAlert}
        activeAlertType={activeAlertType}
        message="A notification will be sent out to all selected creators mobile devices asking them if they are available for this campaign according to the dates specified"
      />
    </>
  )
}