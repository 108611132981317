/**
 * Auth action types.
 */
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const SET_CATEGORY_INTERST = 'SET_CATEGORY_INTERST';
export const SET_ORGANIZATION_LIST = 'SET_ORGANIZATION_LIST';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const SET_ACTIVE_ASIDE = 'SET_ACTIVE_ASIDE';
export const SET_LOADER = 'SET_LOADER';
export const SWITCH_ORGANIZATION = 'SWITCH_ORGANIZATION';
export const SET_COMMUNITY_FILTER_DATA = 'SET_COMMUNITY_FILTER_DATA';
export const SET_CAMPAIGN_ONBOARDING_FILTER_DATA = 'SET_CAMPAIGN_ONBOARDING_FILTER_DATA';
export const SET_TAB_SELECTION = 'SET_TAB_SELECTION';
export const SET_COMMUNITY_ACTIVE_TAB= 'SET_COMMUNITY_ACTIVE_TAB';
export const SET_CAMPAIGN_LIST_TAB = 'SET_CAMPAIGN_LIST_TAB';
export const SET_ACTIVE_SETTING_TAB = 'SET_ACTIVE_SETTING_TAB';
export const CAMPAIGN_LIST_SORT_FILTERS = 'CAMPAIGN_LIST_SORT_FILTERS';
export const CONTENT_LIST_ARTICLE_SORT_FILTERS = 'CONTENT_LIST_ARTICLE_SORT_FILTERS';
export const CONTENT_LIST_VIDEO_SORT_FILTERS = 'CONTENT_LIST_VIDEO_SORT_FILTERS';
export const SET_CONTENT_PAGINATION_DATA = 'SET_CONTENT_PAGINATION_DATA';
export const SET_ICON_FILTERS = 'SET_ICON_FILTERS';
export const SET_ICON_FILTER_DATA = 'SET_ICON_FILTER_DATA';
export const SET_ICON_FEEDBACK_FILTERS = 'SET_ICON_FEEDBACK_FILTERS';
export const SET_COMMUNITY_ICON_PAGINATION_DATA = 'SET_COMMUNITY_ICON_PAGINATION_DATA';
export const SET_ORGANIZATION_PAGINATION_DATA='SET_ORGANIZATION_PAGINATION_DATA';
export const SET_ICON_LIST_INITIAL_DATA='SET_ICON_LIST_INITIAL_DATA';
export const SELECT_COMMUNITY = 'SELECT_COMMUNITY';
export const SET_CAMPAIGN_LIST_SEARCH_VALUE = 'SET_CAMPAIGN_LIST_SEARCH_VALUE';
export const SET_SELECTED_CAMPIAGN = 'SET_SELECTED_CAMPIAGN';
export const SET_CAMPAIGN_LIST_COMMUNITY = 'SET_CAMPAIGN_LIST_COMMUNITY';
export const SET_ORGANIZATION_SEARCH_DATA = 'SET_ORGANIZATION_SEARCH_DATA';
export const SET_ICON_LIST_SEARCH_VALUE = 'SET_ICON_LIST_SEARCH_VALUE';
export const SET_ICON_FEEDBACK_LIST_SEARCH_VALUE = 'SET_ICON_FEEDBACK_LIST_SEARCH_VALUE';