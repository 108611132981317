import axios from 'axios';
import { API_CONFIG } from '../../config/api';

export function fetchAllUser(params={}) {
	let apiURL = ''
	if (typeof params.page != "undefined") {
	  apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.USER.USER_LIST()}?page=${params.page}&sort_by=${params.sort_by}&sort_order=${params.sort_order}`; 
	}else{
	  apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.USER.USER_LIST()}`;
	}

	const token = localStorage.getItem('manageToken')
	return dispatch => axios.get(apiURL, { headers: { Authorization: token } })
	.then(res => {
		if(res.status) {
			return {status: true, message: res.data.message, data: res.data.data, response: res.data }
		} else {
			return {status: false, message: res.response.message}
		}
	}).catch((err) => {
		return {status: false, message: err.response.data.message}
	});
}

export function deleteUser(id) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.USER.USER_LIST()}/${id}`;
	const token = localStorage.getItem('manageToken')
	return dispatch => axios.delete(apiURL, { headers: { Authorization: token } })
	.then(res => {
		if(res.status) {
			return {status: true, message: res.data.message }
		} else {
			return {status: false, message: res.data.message}
		}
	}).catch((err) => {
		return {status: false, message: err.response.data.message}
	});
}

export function updateUser(data, id) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.USER.USER_LIST()}/${id}`;
	const token = localStorage.getItem('manageToken')
	return dispatch => axios.put(apiURL, data, { headers: { Authorization: token } })
	.then(res => {
		if(res.status === 200) {
			return {status: true, message: res.data.message }
		} else {
			return {status: false, message: res.data.message}
		}
	}).catch((err) => {
		return {status: false, message: err.response.data.message}
	});
}


export function sendInviteUser(data) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.USER.USER_LIST()}`;
	const token = localStorage.getItem('manageToken')
	return dispatch => axios.post(apiURL, data, { headers: { Authorization: token } })
	.then(res => {
		if(res.status === 200) {
			return {status: true, message: res.data.message }
		} else {
			return {status: false, message: res.data.message}
		}
	}).catch((err) => {
		return {status: false, message: err.response.data.message}
	});
}

export function getSelectedCommunities(data) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.USER.GET_USER_COMMUNITIES()}?id=${data.id}`;
	console.log("apiURL:===========>>>", apiURL)

	const token = localStorage.getItem('manageToken')
	return dispatch => axios.get(apiURL, { headers: { Authorization: token } })
	.then(res => {
		if(res.status === 200) {
			return {status: true, res: res.data}
		} else {
			return {status: false, message: res.data.message}
		}
	}).catch((err) => {
		return {status: false, message: err.response.data.message}
	});
}

export function updateUserAccessCommunities(data) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.USER.UPDATE_USER_COMMUNITIES()}`;
	const token = localStorage.getItem('manageToken')
	return dispatch => axios.put(apiURL, data, { headers: { Authorization: token } })
	.then(res => {
		if(res.status === 200) {
			return {status: true, message: res.data.message }
		} else {
			return {status: false, message: res.data.message}
		}
	}).catch((err) => {
		return {status: false, message: err.response.data.message}
	});
}