import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import {SET_ICON_FEEDBACK_LIST_SEARCH_VALUE, SET_ICON_LIST_SEARCH_VALUE, SET_ICON_FILTER_DATA, SET_ICON_FEEDBACK_FILTERS, SET_ICON_LIST_INITIAL_DATA} from '../Actions/Types/Types';;

export function fetchIconProfile(id) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ICON.FETCH_ONE()}?icon_id=${id}`;
	return dispatch => axios.get(apiURL)
	.then(res => {
		if(res.status) {
			return {success: true, data: res.data.data}
		} else {
			throw Error(res.statusText);
		}
	}).catch((err) => {
		return err.response;
	});
}

export function fetchIconList(data) {
	const params = `?page=${data.page}&search=${data.search}&sort_by=${data.sort_by}&sort_order=${data.sort_order}`
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_SUPERADMIN_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ICON.FETCH_ALL()}${params}`;
	return dispatch => axios.get(apiURL)
	.then(res => {
		if(res.status === 200) {
			return {success: true, data: res.data}
		} else {
			return {success: false, message: res.data.message}
		}
	}).catch((err) => {
		return {success: false, message: err.response.data.error}
	});
}

export function fetchIcon(id) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_SUPERADMIN_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ICON.FETCH_ALL()}/${id}`;
	return dispatch => axios.get(apiURL)
	.then(res => {
		if(res.status === 200) {
			return {success: true, data: res.data}
		} else {
			return {success: false, message: res.data.message}
		}
	}).catch((err) => {
		return {success: false, message: err.response.data.error}
	});
}

export function approvalRequest(data = {}) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ICON.APPROVE_ICONS()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function checkAvailibityRequest(data = {}) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ICON.CHECK_AVAILIBITY_STATUS()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function deleteIcon(id) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_SUPERADMIN_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ICON.FETCH_ALL()}/${id}`;
	return dispatch => axios.delete(apiURL)
	.then(res => {
		if(res.status) {
			return {status: true, message: res.data.message}
		} else {
			return {status: false, message: res.message}
		}
	}).catch((err) => {
		return {status: false, message: err.response.data.message}
	});
}

export function iconDraftApprove(data) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ICON.APPROVE_DRAFT()}`;
	return dispatch => axios.post(apiURL, data)
	.then(res => {
		return res;
	}).catch((err) => {
		return err.response;
	});
}

export function editAnIcon(data) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH_V2}${API_CONFIG.END_POINTS.ICON.EDIT()}`;
	return dispatch => axios.post(apiURL, data)
	.then(res => {
		return res;
	}).catch((err) => {
		return err.response;
	});
}


export function updateIcon(id, data) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_SUPERADMIN_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ICON.FETCH_ALL()}/${id}`;
	return dispatch => axios.put(apiURL, data)
	.then(res => {
		if(res.status === 200) {
			return {status: true, message: res.data.message}
		} else {
			return {status: false, message: res.data.message}
		}
	}).catch((err) => {
		return {status: false, message: err.response.data.error}
	});
}


export function iconFeedback(data) {
	const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_SUPERADMIN_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ICON.FEEDBACKS()}/?${data}`;
	return dispatch => axios.get(apiURL)
	.then(res => {
		if(res.status === 200) {
			return {success: true, data: res.data}
		} else {
			return {success: false, message: res.data.message}
		}
	}).catch((err) => {
		return {success: false, message: err.response.data.error}
	});
}

export function soloCheckAvailibityRequest(data = {}) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ICON.SOLO_AVAILIBILITY_STATUS()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}

export function soloConfirmAvailibityRequest(data = {}) {
  const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.BASE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ICON.SOLO_CONFIRM_STATUS()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      return res;
    }).catch((err) => {
      return err.response;
    });
}


const initailSortFilters = { per_page: 25, current_page: 1, total_pages: '', total_count: '', sort_by: 'created_at', sort_order: 'asc' }
export function setIconFeedbackFilterData(value = initailSortFilters) {
  return {
    type: SET_ICON_FEEDBACK_FILTERS,
    value
  }
}

const iconListInitialData = { per_page: '', current_page: 1, total_pages: '', total_count: '', sort_by: 'first_name', sort_order: 'asc' }
export function setIconListPaginationData(value = iconListInitialData) {
  return {
    type: SET_ICON_LIST_INITIAL_DATA,
    value
  }
}

export function setSearchValue(value = '') {
  return {
    type: SET_ICON_LIST_SEARCH_VALUE,
    value
  }
}

export function iconFeedbackSetSearchValue(value = '') {
  return {
    type: SET_ICON_FEEDBACK_LIST_SEARCH_VALUE,
    value
  }
}

