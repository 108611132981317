export default ({
	name = '',
	onChange = () => {},
	onClick = () => {},
	containerClass = '',
	labelClass = '',
	value = '',
	required = false,
	options = [],
	children
}) => {
	return(
		<div className={containerClass}>
			{
				options.map(option => {
					return (
						<label key={Math.random()} className={"container-checkbox"}>{option}
							<input 
								type="radio" 
								name={name} 
								checked={value === option} 
								value={option} 
								onChange={onChange}
								onClick={onClick}
								required={required}
							/>
							
							<span className="checkmark">
							</span>
						</label>
					)
				})
			}

			{ children }
		</div>
	)
}