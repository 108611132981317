import React, { useState, useEffect } from 'react';
import Pagination from '../../Common/Pagination';
import CustomSelect from "../../../Components/CustomSelect/Select";
import classNames from 'classnames';
import { createMarkup } from "../../../utils/common";
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { setCampaingListSearch, setCampaignFiltersData, fetchCommunityCampaigns, setCampaignsOpen, setCampaignListTab, setCampaignListCommunity, setCampaignsCompleted, deleteCampaign, updateCampaignStatus } from '../../../Redux/Actions/Campaign';
import { Link, useHistory } from 'react-router-dom'
import { NotificationManager } from 'react-notifications';
import { setLoader } from '../../../Redux/Actions/Loader';
import TextArea from "../../../Components/TextArea/Field";
import { Multiselect } from 'multiselect-react-dropdown';
import { sendNotification } from '../../../Redux/Actions/Community';
import { isEmpty } from 'lodash';


import moment from 'moment'
import { enGB } from 'date-fns/locale';
import { DatePickerCalendar } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import Modal from 'react-bootstrap/Modal'
import { toLower } from 'lodash'

const OPTIONS = Object.freeze({
	campaigns_all: '',
	campaigns_open: 'active',
	campaigns_completed: 'completed',
	campaigns_ongoing: 'ongoing',
	campaigns_action_required: 'action required' 
});

const initialCompletedData = {
	slug:'',
	status:'',
	datetime:''
} 

const CampaignList = ({
	communities = [],
	renderCommunitySelector = () => { },
	setShowForm = () => { }
}) => {
	const initalData = { title: "", description: "" }
	const initialSelectedTab = useSelector(state => state.activeCampaignListTab)
	const initialFiltersData = useSelector(state => state.campaigListSortFilters)
	const initialSearchValue = useSelector(state => state.campaigListSearchValue)
	const dispatch = useDispatch();
	const history = useHistory();
	const [searchVal, setSearchValue] = useState(initialSearchValue);
	const [campaigns, setCampaignsData] = useState([]);
	const initialSelectedCommunity = useSelector(state => state.campaignListSelectedCommunity)
	const [selectedCommunity, setSelectedCommunity] = useState(initialSelectedCommunity);
	const [activeTab, setActiveTab] = useState(initialSelectedTab);
	const [paginationData, setpaginationData] = useState(initialFiltersData);
	const [visible, setvisible] = useState(false);
	const [notificationData, setNotificationData] = useState(initalData);
	const [selectedNotificationCommunity, setselectedNotificationCommunity] = useState([])
	const [countrySearch, setCountrySearch] = useState(false);
	const [calendar, setCalendar] = useState(false);
	const [datetimeValue, setDatetime] = useState(new Date());
	const [completedData, setCompletedData] = useState(initialCompletedData);
	const [deleteCampaignModel, setDeleteCampaignModel] = useState(false);
	const [deleteCampaignSlug, setDeleteCampaignSlug] = useState('');
	const [date, setDate] = useState()

	useEffect(() => {
		setpaginationData(initialFiltersData)
	}, [initialFiltersData])

	useEffect(() => {
		setSelectedCommunity(initialSelectedCommunity)
	}, [initialSelectedCommunity])

	useEffect(() => {
		setActiveTab(initialSelectedTab)
	}, [initialSelectedTab])

	const searchValueUpdate = (value) => {
		setSearchValue(value)
		dispatch(setCampaingListSearch(value))
	}

	const handleCommunitySelect = async (e) => {
		e.preventDefault();
		fetchCampaigns(e.target.value)
		dispatch(setCampaignListCommunity(e.target.value))
	}

	const handleTabChange = (e, type) => {
		e.preventDefault();
		dispatch(setCampaignListTab(type))
		dispatch(setCampaignFiltersData({
			...paginationData,
			current_page: 1
		}))
		// setpaginationData(prevState => ({
		// 	...prevState,
		// 	current_page: 1
		// }))
	}

	useEffect(async () => {
		await dispatch(setLoader(true));
		fetchCampaigns(selectedCommunity, { page: paginationData.current_page, search: searchVal, sort_by: paginationData.sort_by, sort_order: paginationData.sort_order })
	}, [activeTab, paginationData.page, paginationData.sort_by, paginationData.sort_order]);

	const redirectToEdit = (e, id) => {
		e.preventDefault();
		history.push(`/campaigns/${id}/on-boarding`)
	}

	useEffect(async () => {
		if (searchVal !== '') {
			fetchCampaigns(selectedCommunity, { page: 1, search: searchVal, sort_by: paginationData.sort_by, sort_order: paginationData.sort_order })
		}
	}, [searchVal]);

	const activateCampaign = async (id, event) => {
		event.preventDefault();
		const data = {
			campaign_id: id
		}
		const response = await dispatch(setCampaignsOpen(data))

		const success = (response.status === 200)
		success ? NotificationManager.success(response.data.message) : NotificationManager.error(response.data.message, '', 10000, () => {})
		if (success) {
			let newState = cloneDeep(campaigns)
			let iconRecord = newState.find(a => a.id === id)
			iconRecord.status = 'active'
			setCampaignsData(newState)
		}
	}

	const setCampaignStatus = async(id, slug, status) => {
		const data = {campaign:
			{status: status,
			slug: slug}
		}
		const response = await dispatch(updateCampaignStatus(data));
		const success = (response.status === 200)
		success ? NotificationManager.success(response.data.message) : NotificationManager.error(response.data.message, '', 10000, () => {})
		if (success) {
			let newState = cloneDeep(campaigns)
			let iconRecord = newState.find(a => a.id === id)
			iconRecord.status = status
			setCampaignsData(newState)
		}
	}

	const fetchCampaigns = async (value = '', params = {}) => {
		const response = await dispatch(fetchCommunityCampaigns(value, params, activeTab));
		if (response && response.status === 'success') {
			await dispatch(setLoader(false));
			setCampaignsData(response.data);
			dispatch(setCampaignFiltersData({
				...paginationData,
				per_page: response.per_page,
				current_page: response.current_page,
				total_count: response.total_count,
				total_pages: response.total_pages
			}))
			// setpaginationData(prevState => ({
			// 	...prevState,
			// 	per_page: response.per_page,
			// 	current_page: response.current_page,
			// 	total_count: response.total_count,
			// 	total_pages: response.total_pages
			// }))
		}
	}

	const renderCountries = (names) => {
		names = names ? names : []
		let template = ''
		names.map(name => {
			template += `<span key={Math.random()}>${name}</span><br />`
		})
		return template
	}

	const handlePageChange = (page) => {
		fetchCampaigns(selectedCommunity, { page: page, sort_by: paginationData.sort_by, sort_order: paginationData.sort_order })
	}
	const openNotificationModel = (campaginData) => {
		setvisible(true);
		setNotificationData(prevState => ({
			...prevState,
			slug: campaginData.slug
		}))
		const selectedCommunity = communities.filter(community => campaginData.community_ids.indexOf(community.id) > -1)
		setselectedNotificationCommunity(selectedCommunity)
	}

	const closeModel = () => {
		setvisible(false)
		setNotificationData(initalData)
		setselectedNotificationCommunity([])
	}

	const handleNotificationData = (e) => {
		const { name, value } = e.target
		setNotificationData(prevState => ({
			...prevState,
			[name]: value
		}))
	}
	const handleNotificationCommunitieschange = (selectedList, selectedItem) => {
		(selectedList.length === 0) ? setselectedNotificationCommunity([]) : setselectedNotificationCommunity([selectedItem])
	}

	const validateInput = (data) => {
		const Errors = [];
		Object.keys(data).map(key => {
			if (isEmpty(data[key]))
				Errors.push(`Please provide value for ${key}.`)
		})

		Errors.map((error) => {
			NotificationManager.error(error, '', 10000, () => {})
		})

		return isEmpty(Errors);
	}
	const onCountryChange = (searchText) => {
		searchText ? setCountrySearch(true) : setCountrySearch(false)
	}
	const sendContentNotification = async () => {
		const { title, description, slug } = notificationData
		const data = { title, description, community_id: selectedNotificationCommunity.length > 0 ? selectedNotificationCommunity[0].id : "", slug }
		if (validateInput(data)) {
			var formdata = new FormData()
			for (const key in data) {
				formdata.append(key, data[key]);
			}
			const response = await dispatch(sendNotification(formdata))
			if (response.status) {
				NotificationManager.success(response.message)
				setNotificationData(initalData)
				setvisible(false);
			} else {
				NotificationManager.error(response.message, '', 10000, () => {})
			}
		}
	}

	const openCalenderModel = (campaginData) => {
		setCalendar(true);
		setCompletedData(prevState => ({
			...prevState,
			slug: campaginData.slug,
			status: 'completed'
		}))
	}

	const closeCalenderModel = () => {
		setCalendar(false);
		setDatetime(null);
		setCompletedData(initialCompletedData)
	}

	const handleDateSelect = (value, type) => {
		setDatetime(value);
		setCompletedData(prevState => ({
			...prevState,
			datetime: value
		}))
	}

	const submitCompletedData = async () => {
		const { status ,slug, datetime } = completedData
		let data = { status ,slug, datetime }
		var timestamp = ''
		if(data.datetime) {
			timestamp = data.datetime.getTime()
			data.datetime = `${data.datetime.getDate()}/${data.datetime.getMonth() + 1}/${data.datetime.getFullYear()} ${new Date(timestamp).toLocaleTimeString()} +0000`
		}else{
			timestamp = datetimeValue.getTime()
			data.datetime = `${datetimeValue.getDate()}/${datetimeValue.getMonth() + 1}/${datetimeValue.getFullYear()} ${new Date(timestamp).toLocaleTimeString()} +0000`
		}
		const response = await dispatch(setCampaignsCompleted(data))
		if (response && response.status) {
			NotificationManager.success(response.message)
			setCompletedData(initialCompletedData)
			setCalendar(false);
			setDatetime(new Date());
			let newState = cloneDeep(campaigns)
			let iconRecord = newState.find(a => a.slug === slug)
			iconRecord.status = 'completed'
			setCampaignsData(newState)
		} else {
			NotificationManager.error(response.message, '', 10000, () => {})
			setCalendar(false);
			setDatetime(new Date());
			setCompletedData(initialCompletedData)
		}
	}

	const openDeleteModel = (campaginData) => {
		setDeleteCampaignModel(true);
		setDeleteCampaignSlug(campaginData.slug)
	}

	const closeDeleteModel = () => {
		setDeleteCampaignModel(false);
		setDeleteCampaignSlug('');
	} 

	const deleteCampaignData = async () => {
		const response = await dispatch(deleteCampaign(deleteCampaignSlug))
		if (response && response.status) {
			NotificationManager.success(response.message)
			setDeleteCampaignModel(false);
			setDeleteCampaignSlug('');
			await dispatch(setLoader(true));
			fetchCampaigns(selectedCommunity, { page: paginationData.current_page, search: searchVal, sort_by: paginationData.sort_by, sort_order: paginationData.sort_order })
		} else {
			NotificationManager.error(response.message, '', 10000, () => {})
			setDeleteCampaignModel(false);
			setDeleteCampaignSlug('');
		}
	}

	const clickOnLink = (slug, status)=> {
		if(status === 'completed'){
			localStorage && localStorage.setItem('active-slug', slug)
		}
	}
	const NotificationModel = () => {
		return (
			<Modal
			  show={visible}
			  onHide={() => closeModel()}
			  aria-labelledby="contained-modal-title-vcenter"
			  centered
			  className="notification-open-modal"
			>
			  <div className="data-modal-form">
			  	<h2 className="modal-head">Notification</h2>
			  	<form>
			  		<div className="form-group">
			  			<input type="email" name="title" className="form-control" placeholder="Title" value={notificationData.title} required="true" onChange={handleNotificationData} />
			  		</div>

			  		<TextArea
			  			classes="notification-desc label-text"
			  			containerClass="form-group"
			  			name="description"
			  			value={notificationData.description}
			  			required={true}
			  			placeholder="Description"
			  			onBlur={handleNotificationData}
			  		/>
			  		<div id="campaign-page__community-selector" className="form-inputs">
			  			<label className={(countrySearch || (selectedNotificationCommunity.length > 0)) ? "labelClass field-active top-24" : "labelClass"}>Select Brand Community</label>

			  			<Multiselect
			  				options={communities}
			  				selectedValues={selectedNotificationCommunity}
			  				onSelect={handleNotificationCommunitieschange}
			  				onRemove={handleNotificationCommunitieschange}
			  				singleSelect={false}
			  				onSearch={onCountryChange}
			  				placeholder=""
			  				displayValue="name"
			  				className="custom-select sources"
			  			/>
			  		</div>
			  	</form>
			  	<div className="form-submittion">
			  		<button type="button" data-dismiss="modal" onClick={() => sendContentNotification()}>Send Notification</button>
			  		<button type="button" data-dismiss="modal" onClick={() => closeModel()}>CANCEL</button>
			  	</div>
			  </div>
			</Modal>

			/*<div id="invites" className="modal modal-flex" data-backdrop='static'>
				<div className="modal-dialog">
					<div className="data-modal-form">
						<h2 className="modal-head">Notification</h2>
						<form>
							<div className="form-group">
								<input type="email" name="title" className="form-control" placeholder="Title" value={notificationData.title} required="true" onChange={handleNotificationData} />
							</div>

							<TextArea
								classes="notification-desc label-text"
								containerClass="form-group"
								name="description"
								value={notificationData.description}
								required={true}
								placeholder="Description"
								onBlur={handleNotificationData}
							/>
							<div id="campaign-page__community-selector" className="form-inputs">
								<label className={(countrySearch || (selectedNotificationCommunity.length > 0)) ? "labelClass field-active top-24" : "labelClass"}>Select Brand Community</label>

								<Multiselect
									options={communities}
									selectedValues={selectedNotificationCommunity}
									onSelect={handleNotificationCommunitieschange}
									onRemove={handleNotificationCommunitieschange}
									singleSelect={false}
									onSearch={onCountryChange}
									placeholder=""
									displayValue="name"
									className="custom-select sources"
								/>
							</div>
						</form>
						<div className="form-submittion">
							<button type="button" data-dismiss="modal" onClick={() => sendContentNotification()}>Send Notification</button>
							<button type="button" data-dismiss="modal" onClick={() => closeModel()}>CANCEL</button>
						</div>
					</div>
				</div>
			</div>*/
		)
	}

	
	const CalenderModel = () => {
		return (
			<Modal
			  show={calendar}
			  onHide={() => closeCalenderModel()}
			  aria-labelledby="contained-modal-title-vcenter"
			  centered
			  className="calendar-open-modal"
			>
			  <div className="modal-dialog">
			  	<div className="data-modal-form">
			  		<form>
			  			<div>
			  	      <DatePickerCalendar date={completedData.datetime ?  new Date(completedData.datetime) : new Date()} onDateChange={(value, type) => handleDateSelect(value, type)} locale={enGB} />
			  	    </div>
			  		</form>
			  		<div className="form-submittion">
			  			<button type="button" data-dismiss="modal" onClick={() => submitCompletedData()}>Confirm</button>
			  			<button type="button" data-dismiss="modal" onClick={() => closeCalenderModel()}>CANCEL</button>
			  		</div>
			  	</div>
			  </div>
			</Modal> 
			/*<div id="invites" className="modal modal-flex" data-backdrop='static'>
				<div className="modal-dialog">
					<div className="data-modal-form">
						<form>
							<div>
					      <DatePickerCalendar date={completedData.datetime ?  new Date(completedData.datetime) : new Date()} onDateChange={(value, type) => handleDateSelect(value, type)} locale={enGB} />
					    </div>
						</form>
						<div className="form-submittion">
							<button type="button" data-dismiss="modal" onClick={() => submitCompletedData()}>Confirm</button>
							<button type="button" data-dismiss="modal" onClick={() => closeCalenderModel()}>CANCEL</button>
						</div>
					</div>
				</div>
			</div>*/
		)
	}

	const DeleteModel = () => {
		return (
			<Modal
			  show={deleteCampaignModel}
			  onHide={() => closeDeleteModel()}
			  aria-labelledby="contained-modal-title-vcenter"
			  centered
			  className="calendar-open-modal"
			>
			  <div className="modal-dialog">
			  	<div className="data-modal-form">
			  		<form>
			  			<h2 className="modal-head text-center">Are you sure</h2>
			  			<h2 className="modal-head text-center">You want to delete this campaign !</h2>
			  			<br/>
			  		</form>
			  		<div className="form-submittion">
			  			<button type="button" data-dismiss="modal" onClick={() => deleteCampaignData()}>Confirm</button>
			  			<button type="button" data-dismiss="modal" onClick={() => closeDeleteModel()}>CANCEL</button>
			  		</div>
			  	</div>
			  </div>
			</Modal>

			/*<div id="invites" className="modal modal-flex" data-backdrop='static'>
				<div className="modal-dialog">
					<div className="data-modal-form">
						<form>
							<h2 className="modal-head text-center">Are you sure</h2>
							<h2 className="modal-head text-center">You want to delete this campaign !</h2>
							<br/>
						</form>
						<div className="form-submittion">
							<button type="button" data-dismiss="modal" onClick={() => deleteCampaignData()}>Confirm</button>
							<button type="button" data-dismiss="modal" onClick={() => closeDeleteModel()}>CANCEL</button>
						</div>
					</div>
				</div>
			</div>*/
		)
	}


	const renderItems = () => {
		return (
			<div className="table-scroll-campaigns table-responsive campaigns-table-responsive overflow-x-scroll" >
				<table className="table campaigns-table">
					<thead>
						<tr>
							<th><div className="head-sort text-center">BRAND COMMUNITY</div></th>
							<th onClick={(e) => applySorting('name')}>
								<div className="head-sort">TITLE 
								<a href="javascript:void(0)" className={sortedClasses('name')}></a></div></th>
							<th >PLATFORM</th>
							<th onClick={(e) => applySorting('created_at')}>
								<div className="head-sort">CREATED AT <a href="javascript:void(0)" className={sortedClasses('created_at')}></a></div></th>
							<th onClick={(e) => applySorting('updated_at')} 
							><div className="head-sort">UPDATED AT <a href="javascript:void(0)" className={sortedClasses('updated_at')}></a></div></th>
							<th onClick={(e) => applySorting('country')} ><div className="head-sort">COUNTRY <a href="javascript:void(0)" className={sortedClasses('country')}></a></div></th>
							<th onClick={(e) => applySorting('budget')} style={{ minWidth: '130px' }}><div className="head-sort">BUDGET <a href="javascript:void(0)" className={sortedClasses('budget')}></a></div></th>
							<th onClick={(e) => applySorting('status')} style={{ minWidth: '325px' }}><div className="head-sort">STATUS <a href="javascript:void(0)" className={sortedClasses('status')}></a></div></th>
						</tr>
					</thead>
				</table>
				{
					campaigns.length > 0 ? campaigns.map((data) => {
						const attachment_url = data.logo
						return (
							<table key={Math.random()} className="table campaigns-table">
								<tbody>
									<tr className="content-full-table-rounded">
										<td style={{ minWidth: '170px' }} className="text-center"><div className="icon_campaign community-notify"><img src={attachment_url ? attachment_url : require("../../../assets/images/icon_campaign.png").default} alt="img" /></div></td>
										<td onClick={()=> clickOnLink(data.slug, data.status)} style={{ minWidth: '180px' }}><Link to={`/campaigns/${data.slug}/on-boarding`}><div className="single-line-title">{data.name}</div></Link></td>
										<td style={{ minWidth: '100px' }}><img src={toLower(data.platforms[0].name) == 'tiktok' ? require("../../../assets/images/img_tiktok.svg") : data.platforms[0].logo} alt="img" style={{width: '35%'}} /></td>
										<td onClick={()=> clickOnLink(data.slug, data.status)} style={{ minWidth: '170px' }}><Link to={`/campaigns/${data.slug}/on-boarding`}><div className="single-line-title">{moment(data.created_at).format("DD MMM YYYY") }</div></Link></td>
										<td onClick={()=> clickOnLink(data.slug, data.status)} style={{ minWidth: '170px' }}><Link to={`/campaigns/${data.slug}/on-boarding`}><div className="single-line-title">{moment(data.updated_at).format("DD MMM YYYY")}</div></Link></td>
										<td onClick={()=> clickOnLink(data.slug, data.status)} style={{ minWidth: '130px' }}><Link to={`/campaigns/${data.slug}/on-boarding`}><span dangerouslySetInnerHTML={createMarkup(renderCountries(data.country_names))} /></Link></td>
										<td onClick={()=> clickOnLink(data.slug, data.status)} style={{ minWidth: '100px', textAlign: 'lef' }}><Link to={`/campaigns/${data.slug}/on-boarding`}><strong>{data.budget} {data.currency_code}</strong></Link></td>
										<td style={{ minWidth: '325px' }}>

											<div className="multiple-actions">
												<div className="action-bar-status">
													<span className={`action-open ${data.status}`}>{data.status == 'on_hold' ? 'On Hold' : data.status}</span>
												</div>
					<div className="actions-menu-dropdown">
						<div className="dropdown default-dropdown">
							<a href="javascript:void(0)" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
								<img src={require("../../../assets/images/icon_more_menu_blue.svg")} />
							</a>
							{
								data.status === 'completed' ? (
									<ul className="dropdown-menu">
										<li><a href="javascript:void(0)" onClick={() => openDeleteModel(data)}>Delete</a></li>
									</ul>
									) : (
									<ul className="dropdown-menu">
										{data.status !== 'active' ? <li><a href="javascript:void(0)" onClick={(e) => activateCampaign(data.id, e)}>Set campaign to <strong>OPEN</strong></a></li> : null}
										<li><a href="javascript:void(0)" onClick={() => openCalenderModel(data)}>Set campaign to <strong>COMPLETED</strong></a></li>
										<li><a href="javascript:void(0)" onClick={() => openDeleteModel(data)}>Delete</a></li>
										{data.status !== 'on_hold' ? <li><a href="javascript:void(0)" onClick={() => setCampaignStatus(data.id, data.slug, "on_hold")}>Set campaign to <strong>ON HOLD</strong></a></li> : null}
										{data.status !== 'locked' ? <li><a href="javascript:void(0)" onClick={() => setCampaignStatus(data.id, data.slug, "locked")}>Set campaign to <strong>LOCKED</strong></a></li> : null}
										{data.status !== 'cancelled' ? <li><a href="javascript:void(0)" onClick={() => setCampaignStatus(data.id, data.slug, "cancelled")}>Set campaign to <strong>CANCELLED</strong></a></li> : null}
									</ul>
															)
														}
													</div>
												</div>
												<div className="action-edit"><Link to={`/campaigns/${data.slug}`}>edit</Link></div>
												<div onClick={()=> clickOnLink(data.slug, data.status)} className="action-go"><Link to={`/campaigns/${data.slug}/on-boarding`}><img src={require("../../../assets/images/icon_arrow_right_blue.svg")} alt="Go" /></Link></div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						)
					}) : (
							<table>
								<tbody>
									<tr>
										<td colSpan={6}>
											No Campaigns Found.
									</td>
									</tr>
								</tbody>
							</table>
						)
				}
			</div>
		)
	}

	const applySorting = (type) => {
		const sortOrder = paginationData.sort_by === type ? paginationData.sort_order === 'asc' ? 'desc' : 'asc' : 'asc'
		dispatch(setCampaignFiltersData({
			...paginationData,
			sort_by: type,
			sort_order: sortOrder
		}))

		// setpaginationData(prevState => ({
		// 	...prevState,
		// 	sort_by: type,
		// 	sort_order: sortOrder
		// }))
	}

	const sortedClasses = (type) => {
		const isActive = paginationData.sort_by === type
		var sortClasses = classNames(
			'table-sort',
			{ 'table-sort-activer': isActive },
			{ [paginationData.sort_order]: isActive }
		)
		return sortClasses
	}

	const tabContentMarkup = (type) => {
		return (
			<div className="table-scroll-campaigns">
				

				{
					campaigns ? (
						<>
							{renderItems(type)}
						</>
					) : (
							<table>
								<tbody>
									<tr>
										<td colSpan={6}>
											Please select a brand community.
					    				</td>
									</tr>
								</tbody>
							</table>
						)
				}
				{
					paginationData.total_pages > 1 && (
						<div className="padination-numbers">
							<Pagination
								current_page={paginationData.current_page}
								per_page={paginationData.per_page}
								total_count={paginationData.total_pages * paginationData.per_page}
								setpaginationData={handlePageChange}
							/>
						</div>
					)
				}
			</div>
		)
	}

	const activeClass = (type) => {
		return activeTab === type ? 'active' : ''
	}

	return (
		<div id="view-dashboard" className="over-flow">
			{visible ? NotificationModel() : null}
			{calendar ? CalenderModel() : null}
			{deleteCampaignModel ? DeleteModel() : null}
			<div className="inner-view-dashboard over-flow">
				<div className="communities-content">
					<div className="select-communities-bar">

						<CustomSelect name="sources" id="select-community01" value={selectedCommunity} className="custom-select sources" placeholder="All brand communities" onChange={(e) => handleCommunitySelect(e)}>
							<option value='' key={Math.random()}>
								All Brand Communities.
							</option>
							{renderCommunitySelector()}
						</CustomSelect>
					</div>

					<div className="create-campagin">
						<Link to='/campaigns/new' className="redirect-link" onClick={(e) => setShowForm(true)}>CREATE CAMPAIGN</Link>
					</div>

					<div className="capsule-tabs-search">
						<ul className="nav nav-tabs default-tabs capsule-tabs">
							<li className={activeClass('')}><a data-toggle="tab" href="#campaigns_all" onClick={(e) => handleTabChange(e, '')}>ALL</a></li>
							<li className={activeClass('active')}><a data-toggle="tab" href="#campaigns_open" onClick={(e) => handleTabChange(e, 'active')}>OPEN</a></li>
							<li className={activeClass('ongoing')}><a data-toggle="tab" href="#campaigns_ongoing" onClick={(e) => handleTabChange(e, 'ongoing')}>ONGOING</a></li>
							<li className={activeClass('completed')}><a data-toggle="tab" href="#campaigns_completed" onClick={(e) => handleTabChange(e, 'completed')}>COMPLETED</a></li>
							<li className={activeClass('action required')}><a data-toggle="tab" href="#campaigns_action_required" onClick={(e) => handleTabChange(e, 'action required')}>ACTION REQUIRED</a></li>
						</ul>
						<div className="search-input"><input type="search" value={searchVal} name="searchVal" placeholder="Search" onChange={(e) => searchValueUpdate(e.target.value)} /></div>
					</div>

					<div className="tab-content">
						<div id="campaigns_all" className="tab-pane fade in active">
							{tabContentMarkup('campaigns_all')}
						</div>
						<div id="campaigns_open" className="tab-pane fade">
							{tabContentMarkup('campaigns_open')}
						</div>
						<div id="campaigns_ongoing" className="tab-pane fade">
							{tabContentMarkup('campaigns_ongoing')}
						</div>
						<div id="campaigns_completed" className="tab-pane fade">
							{tabContentMarkup('campaigns_completed')}
						</div>
						<div id="campaigns_action_required" className="tab-pane fade">
							{tabContentMarkup('campaigns_action_required')}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CampaignList