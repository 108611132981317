import React, {useState} from 'react';

import { isEmpty, } from 'lodash';
import Modal from 'react-bootstrap/Modal';


let initialData = {
  
  country_id: '',
  
  name: ''


}

const attachData = [{id: "", media: ""}]

export default ({
  createModel = false,
  countries = [],
  closeCreateModel = ()=> {},
  createOrganisationHandle = ()=> {},
  // errors = []
}) => {
  const [organizationData, setOrganizationData] = useState(initialData);
  const [errors, setErrorMsg] = useState([]);
  const [attachments, setAttachments] = useState(attachData);

  const closeModel = ()=> {
    setErrorMsg([])
    setOrganizationData(initialData)
    closeCreateModel()
  }
  const handleChange = (e)=> {
    setOrganizationData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const handleCountrySelect = (e) => {
    const selected = countries.find(obj => obj.id === e.target.value);
    if (e.target.value !== ""){
      setOrganizationData(prevState => ({
        ...prevState,
        country_id : selected ? selected.id : ''
      }))
    }
  }

  const renderOptions = (data = [], selected = []) => {
    return data.map((record) => {
      return <option key={Math.random()} value={record.id}> {record.name}</option>
    })
  }

  const sumbitHandle =()=> {
    // let country_id = organizationData.country_id && organizationData.country_id.length > 0 && organizationData.country_id[0].id ? organizationData.country_id[0].id : ''

    const { name } = organizationData;
    let data = { name }
    
    if(validateInput(data)){

      data = Object.assign(data)
      let formdata = new FormData()

      for (const key in data) {
        formdata.append(`organization[${key}]`, data[key]);
      }

      if(attachments && attachments.length > 0){
        attachments.map((attach, index) => {
          if(attach.media){
            for (const key in attach) {
              formdata.append(`organization[attachments_attributes][][${key}]`, attach[key]);
            }
          }
        })
      }

      createOrganisationHandle(formdata)
      setOrganizationData(initialData)
      setErrorMsg([])
      closeCreateModel()
    }
  }

  const validateInput = (data) => {
    const Errors = [];
    Object.keys(data).map(key => {
      if(isEmpty(data[key])) {
        Errors.push({
          name: key,
          value: `Please provide value for ${key}.`
        })
        setErrorMsg(Errors)
      }
    })
    return isEmpty(Errors);
  }

  const handleAttachmentChange = (event, index) => {
      let value = event.target.files[0];
      let temp_attach = [...attachments];
      let temp_element = { ...temp_attach[index] };
      temp_element.media = value;
      temp_attach[index] = temp_element
      setAttachments( temp_attach );
  }

  const renderCountrySelector = () => {
    return countries.map(country => {
      return(
        <option key={Math.random()} value={country.id}>{country.name}
        </option>
      )
    })
  }


  return(
    <Modal
      show={createModel}
      onHide={() => closeModel()}
      className="verify-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="data-modal-form">
        <h2 className="modal-head">Create Organisation</h2>
        <form>
          <h5 className="error">{errors && errors[0] && errors[0].name === 'name' ? 'Please provide value for name.' : ''}</h5>
          <div className="form-group">
            <input type="text" name="name" className="form-control" placeholder="Name" required="true" value={organizationData.name} onChange={(e)=> handleChange(e)}/>
          </div>
          <div className="form-group">
            <input type="file" name="media" id="uploadFile" accept="image/*" title = "Logo" onChange={(e) => handleAttachmentChange(e, 0)}/>
          </div>
          {/*<h5 className="error">{errors && errors[0] && errors[0].name === 'country_id' ? 'Please provide value for country.' : ''}</h5>
          <div className="form-inputs select-country">
            <CustomSelect name="country_id" id="select-community02" value={organizationData.country_id} className="custom-select sources organization-select" placeholder="Select Country" onChange={(e) => handleCountrySelect(e)}>
              <option value='' key={Math.random()}>
                Select Country
              </option>
              
              { renderCountrySelector() }
            </CustomSelect>
          </div>*/}
        </form>
        <br/>
        <div className="form-submittion">
          <button type="button" data-dismiss="modal" onClick={()=> sumbitHandle()}>SUBMIT</button>
          <button type="button" data-dismiss="modal" onClick={()=> closeModel()}>CANCEL</button>
        </div>
      </div>
    </Modal>
  )
}