import { trim } from 'lodash';

export default ({ platform, clickOnLogo, icon }) => {
	return (
		<div className="social-media">
			<h5 onClick={(e)=> clickOnLogo(platform, icon)} style={{"cursor": "pointer"}}>
				{platform.platform.name === "instagram" ? <img src={require("../../assets/images/img_instagram.svg")} alt="img" /> : <img src={require("../../assets/images/img_tiktok.svg")} alt="img" />}
				{platform.platform.name}
			</h5>

			<div className="social-meter">
				<div className="social-percentages">
					<strong>{platform.followers || platform.followers || 0}
					</strong>Followers
				</div>
				<div className="social-percentages">
					<strong>{platform.percentage || 0}%
					</strong>Engagement
				</div>
				<div className="social-percentages">
					<strong>{trim(platform.price) || 'N/A'}
					</strong>Cost
				</div>
			</div>
		</div>
	)
}