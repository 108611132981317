import React, { useState, useRef, useEffect} from 'react';
import Header from '../../Components/Header/Header';
import Checkbox from '../../Components/CheckboxField/Field';
import { useDispatch } from 'react-redux';
import { login } from '../../Redux/Actions/Auth';
import { NotificationManager} from 'react-notifications';
import './login.scss';
import { capitalize } from 'lodash';

const Login = (props) => {
	const [loginData, setLoginData] = useState({
		email: localStorage.userName === 'undefined' ? '' : localStorage.userName,
		password: ''
	});
	const [remember, setRememberMe] = useState(!!localStorage.userName);
	const [passwordVisible, setPasswordVisibility] = useState(false);
	const submitBtn = useRef();
	const dispatch = useDispatch();

	const handleChange = (e) => {
		const {name , value} = e.target
		setLoginData(prevState => ({
			...prevState,
			...{
					[name] : value,
				}
		}))
	}

	const rememberMe = (e) => {
		const {checked} = e.target;
		checked ? localStorage.setItem('userName', loginData.email === undefined ? '' : loginData.email) : localStorage.removeItem('userName')
		setRememberMe(checked);
	}

	const submitDetails = async (e) => {
		e.preventDefault();
		submitBtn.current.setAttribute('disabled', true);
		const { email, password } = loginData;
		const data = {
			user: {
				username: email,
				password: password
			}
		}
		const { status, message } = await dispatch(login(data))
		submitBtn.current.removeAttribute('disabled');

		status ? NotificationManager.success(message) : NotificationManager.error(message, '', 10000, () => {})
		if(status)

		if(localStorage.searchurl){
			props.history.push(`${localStorage.searchurl}`)
			localStorage.removeItem("searchurl")
		}else{
			props.history.push('/')
		}
	}

	return(
		<div id="wrapper">

			<div id="main">
				<div className="login-form">
					<div className="login-inner">
						<h1>Welcome!</h1>

						<h3>Log in to continue to Showroom Manage.</h3>

						<form className="form-set" onSubmit={submitDetails}>
							<div className="form-inputs">
								<label className={loginData.email ? "labelClass field-active" : "labelClass"}>Email</label>
								<input
									id="email"
									type="email"
									name="email"
									required="true"
									onBlur={(e) => handleChange(e)}
									onChange={(e) => handleChange(e)}
									value={loginData.email}
								/>
							</div>
							<div className="form-inputs">
								<label className={loginData.password ?"labelClass field-active" : "labelClass"}>Password</label>
								<input
									id="password"
									type={passwordVisible ? "text" : "password"} 
									name="password"
									required="true"
									onChange={(e) => handleChange(e)}
									value={loginData.password}
								/>
								<a href="#" onClick={() => setPasswordVisibility(!passwordVisible)} className={`${passwordVisible ? "show-pass" : "password-eye"}`}>
									<img className="wd-14" src={passwordVisible ? require('../../assets/images/crossed_eye.svg') : require('../../assets/images/icon_eye.svg')} alt="icon-eye"/>
								</a>
							</div>

							<div className="login-links forgot-password"><a href="/forgot_password">Forgot your password?</a></div>

							<Checkbox
								onChange={rememberMe} 
								checked={remember}
								name="remember"
								label="Remember me"
								labelClass="container-checkbox"
								containerClass="remember-check"
							/>

							<button ref={submitBtn} type="submit" onClick={submitDetails} className="login-btn btn-rounded">LOG IN</button>

					
						</form>
					</div>
				</div>
			</div>

		</div>
	)
}

export default Login;