import  { useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

const Field = ({
	name = '',
	onChange = () => {},
	onClick = () => {},
	onBlur = () => {},
	placeholder = '',
	description = '',
	containerClass = '',
	classes = '',
	id = '',
	value = '',
	required = false,
	readonly = false,
	autoFocus = true,
	children
}) => {
	const textareaElement = useRef();
	const style = {
		padding: "9px",
		boxSizing: "border-box",
		fontSize: "15px",
		overflow: "hidden",
   };

	return(
		<>
			<div className={containerClass}>
				<TextareaAutosize
					ref={textareaElement}
					id={id}
					name={name}
					onChange={onChange}
					className={classes}
					onClick={onClick}
					onBlur={onBlur}
					placeholder={placeholder}
					defaultValue={value}
					required={required}
					disabled={readonly}
					style={style}
					minRows={5}
				/>
				{ children }
			</div>
			{ description ? <p>{description}</p> : null }
		</>
	)
}

export default Field